import type {Uuid4, SiyavulaId} from '../../types';
import {
  PATH_HOME_LEARNER,
  PATH_HOME_PARENT,
  PATH_HOME_TEACHER
} from '../../../home/home.paths';

export type User = {
  uuid: Uuid4;
  siyavula_id: SiyavulaId;
  role_id: Exclude<ROLE, ROLE.PUBLIC>;
  created_at: Date;
};

export const enum ROLE {
  STUDENT = 0,
  TEACHER = 1,
  MANAGER = 2,
  PARENT = 3,
  PUBLIC = 4
}

// export type UserRole = {
//   id: ROLE;
//   name: string;
// };

// type RoleNames = Record<ROLE, string>;

// const ROLE_NAMES: RoleNames = {
//   [ROLE.STUDENT]: 'Learner',
//   [ROLE.TEACHER]: 'Teacher',
//   [ROLE.MANAGER]: 'Manager',
//   [ROLE.PARENT]: 'Parent',
//   [ROLE.PUBLIC]: 'Public'
// };

type RoleHomePaths = Record<Exclude<ROLE, ROLE.PUBLIC>, string>;

const ROLE_PATHS: RoleHomePaths = {
  [ROLE.STUDENT]: PATH_HOME_LEARNER,
  [ROLE.TEACHER]: PATH_HOME_TEACHER,
  [ROLE.MANAGER]: PATH_HOME_LEARNER,
  [ROLE.PARENT]: PATH_HOME_PARENT
};

export const getHomeUrl = (user: User): string => {
  return ROLE_PATHS[user.role_id];
};
