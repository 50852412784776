import React from 'react';
import {Page} from '../components';
import logHistory from '../utils/logHistory';

const ForbiddenPage: React.FC = () => {
  // logHistory('ForbiddenPage');

  return <Page meta={{title: '403'}} />;
};

export default ForbiddenPage;
