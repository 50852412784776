import {bisectRight} from 'd3-array';
import _ from 'lodash';
import React from 'react';

const increments = [
  0, 6.25, 12.5, 18.75, 25, 31.25, 37.5, 43.75, 50, 56.25, 62.5, 68.75, 75,
  81.25, 87.5, 93.75, 100
];

const getDisplayValue = _.memoize((value: number) => {
  const roundedValue = value >= 98 ? 100 : value; // TODO Is this correct?
  const index = bisectRight(increments, roundedValue);

  return increments[index > 0 ? index - 1 : 0];
});

const MasteryMeter: React.FC<{
  value: number;
}> = (props) => {
  const {value} = props;
  const displayValue = getDisplayValue(value);
  const basename = displayValue.toString().replace('.', '_');

  return (
    <img
      className="tw-relative tw-m-0 tw-inline-block tw-border-none tw-p-0 tw-align-middle tw-leading-none"
      src={require(`./img/practice-section-4-${basename}.svg`)}
      alt={`${value}%`}
    />
  );
};

export default React.memo(MasteryMeter);
