import filterTwoWayArrows from './filterTwoWayArrows';
import filterChemSubscripts from './filterChemSubscripts';
import filterChemSuperscripts from './filterChemSuperscripts';
import filterText from './filterText';
import filterLeftArrow from './filterLeftArrow';
import filterLessThan from './filterLessThan';

export default function filterChemTypes(str: string): string {
  str = filterTwoWayArrows(str);
  str = filterChemSubscripts(str);
  str = filterChemSuperscripts(str);

  // Don't touch the order of function calls underneath until tests are properly in place and a reason is found for this ordering.
  str = filterText(str);
  str = filterLeftArrow(str);
  str = filterLessThan(str);

  return str;
}
