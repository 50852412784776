import React from 'react';
import {Page} from '../../../core/components';

const TypographyPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Typography (🚧)</h1>

        <section>
          <h2>Headings and paragraphs</h2>
          <h1>
            h1 Heading. Lorem ipsum dolor sit amet, consectetur adipisicing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua
          </h1>
          <h2>
            h2 Heading. Lorem ipsum dolor sit amet, consectetur adipisicing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua
          </h2>
          <h3>
            h3 Heading. Lorem ipsum dolor sit amet, consectetur adipisicing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua
          </h3>
          <h4>
            h4 Heading. Lorem ipsum dolor sit amet, consectetur adipisicing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua
          </h4>
          <h5>
            h5 Heading. Lorem ipsum dolor sit amet, consectetur adipisicing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua
          </h5>
          <h6>
            h6 Heading. Lorem ipsum dolor sit amet, consectetur adipisicing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua
          </h6>
          <p>
            Paragraph. Lorem ipsum dolor sit amet, consectetur adipisicing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
          </p>
        </section>

        <section>
          <h2>Inline text elements</h2>
          <p>
            This line of text has <a href="/">a link</a>.
          </p>
          <p>
            You can use the mark tag to <mark>highlight</mark> text.
          </p>
          <p>
            <del>This line of text is meant to be treated as deleted text.</del>
          </p>
          <p>
            <s>
              This line of text is meant to be treated as no longer accurate.
            </s>
          </p>
          <p>
            <ins>
              This line of text is meant to be treated as an addition to the
              document.
            </ins>
          </p>
          <p>
            <u>This line of text will render as underlined</u>
          </p>
          <p>
            <small>
              This line of text is meant to be treated as fine print.
            </small>
          </p>
          <p>
            <strong>This line rendered as bold text.</strong>
          </p>
          <p>
            <em>This line rendered as italicized text.</em>
          </p>
        </section>

        <section>
          <h2>Unordered lists</h2>

          <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>
              Nulla volutpat aliquam velit
              <ul>
                <li>Phasellus iaculis neque</li>
                <li>Purus sodales ultricies</li>
                <li>Vestibulum laoreet porttitor sem</li>
                <li>Ac tristique libero volutpat at</li>
              </ul>
            </li>
            <li>
              Faucibus porta lacus fringilla vel
              <ol>
                <li>Lorem ipsum dolor sit amet</li>
                <li>
                  Nulla volutpat aliquam velit
                  <ol>
                    <li>Phasellus iaculis neque</li>
                    <li>Purus sodales ultricies</li>
                    <li>Vestibulum laoreet porttitor sem</li>
                    <li>Ac tristique libero volutpat at</li>
                  </ol>
                </li>
                <li>Faucibus porta lacus fringilla vel</li>
                <li>Aenean sit amet erat nunc</li>
                <li>Eget porttitor lorem</li>
              </ol>
            </li>
            <li>Aenean sit amet erat nunc</li>
            <li>Eget porttitor lorem</li>
          </ul>
        </section>

        <section>
          <h2>Ordered lists</h2>

          <ol>
            <li>Lorem ipsum dolor sit amet</li>
            <li>
              Nulla volutpat aliquam velit
              <ol>
                <li>Phasellus iaculis neque</li>
                <li>Purus sodales ultricies</li>
                <li>Vestibulum laoreet porttitor sem</li>
                <li>Ac tristique libero volutpat at</li>
              </ol>
            </li>
            <li>
              Faucibus porta lacus fringilla vel
              <ul>
                <li>Lorem ipsum dolor sit amet</li>
                <li>
                  Nulla volutpat aliquam velit
                  <ul>
                    <li>Phasellus iaculis neque</li>
                    <li>Purus sodales ultricies</li>
                    <li>Vestibulum laoreet porttitor sem</li>
                    <li>Ac tristique libero volutpat at</li>
                  </ul>
                </li>
                <li>Faucibus porta lacus fringilla vel</li>
                <li>Aenean sit amet erat nunc</li>
                <li>Eget porttitor lorem</li>
              </ul>
            </li>
            <li>Aenean sit amet erat nunc</li>
            <li>Eget porttitor lorem</li>
          </ol>
        </section>

        <section>
          <h2>Horizontal rule</h2>

          <hr />
        </section>

        <section>
          <h2>Blockquotes</h2>

          {/* Default blockquote */}
          <blockquote>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </blockquote>

          {/* Blockquote with source */}
          <blockquote>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <footer>
              Someone famous in <cite title="Source Title">Source Title</cite>
            </footer>
          </blockquote>

          {/* Legacy: default blockquote */}
          <div className="quote">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

          {/* Legacy: blockquote with source */}
          <div className="quote">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <footer>
              Someone famous in <cite title="Source Title">Source Title</cite>
            </footer>
          </div>
        </section>

        <section>
          <h2>Notes</h2>

          {/* Block */}
          <div className="note">
            <span className="note-header">Note:</span> Lorem ipsum dolor sit
            amet, <a href="/">consectetur adipisicing elit</a>, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </div>

          {/* Block with paragraph */}
          <div className="note">
            <p>
              Lorem ipsum dolor sit amet,{' '}
              <a href="/">consectetur adipisicing elit</a>, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

          {/* Block with unordered list */}
          <div className="note">
            <span className="note-header">Note:</span>
            <ul>
              <li>Lorem ipsum dolor sit amet</li>
              <li>
                Nulla volutpat aliquam velit
                <ul>
                  <li>Phasellus iaculis neque</li>
                  <li>Purus sodales ultricies</li>
                  <li>Vestibulum laoreet porttitor sem</li>
                  <li>Ac tristique libero volutpat at</li>
                </ul>
              </li>
              <li>Faucibus porta lacus fringilla vel</li>
              <li>Aenean sit amet erat nunc</li>
              <li>Eget porttitor lorem</li>
            </ul>
          </div>

          {/* Block with ordered list */}
          <div className="note">
            <span className="note-header">Note:</span>
            <ol>
              <li>Lorem ipsum dolor sit amet</li>
              <li>
                Nulla volutpat aliquam velit
                <ol>
                  <li>Phasellus iaculis neque</li>
                  <li>Purus sodales ultricies</li>
                  <li>Vestibulum laoreet porttitor sem</li>
                  <li>Ac tristique libero volutpat at</li>
                </ol>
              </li>
              <li>Faucibus porta lacus fringilla vel</li>
              <li>Aenean sit amet erat nunc</li>
              <li>Eget porttitor lorem</li>
            </ol>
          </div>

          {/* Inline */}
          <p>
            <span className="note inlinetip">
              Lorem ipsum dolor sit amet,{' '}
              <a href="/">consectetur adipisicing elit</a>, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </p>
        </section>

        <section>
          <h2>Tips</h2>

          {/* Block */}
          <div className="note tip">
            <span className="note-header">Tip:</span> Lorem ipsum dolor sit
            amet, <a href="/">consectetur adipisicing elit</a>, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </div>

          {/* Block with paragraph */}
          <div className="note tip">
            <p>
              Lorem ipsum dolor sit amet,{' '}
              <a href="/">consectetur adipisicing elit</a>, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

          {/* Block with unordered list */}
          <div className="note tip">
            <span className="note-header">Tip:</span>
            <ul>
              <li>Lorem ipsum dolor sit amet</li>
              <li>
                Nulla volutpat aliquam velit
                <ul>
                  <li>Phasellus iaculis neque</li>
                  <li>Purus sodales ultricies</li>
                  <li>Vestibulum laoreet porttitor sem</li>
                  <li>Ac tristique libero volutpat at</li>
                </ul>
              </li>
              <li>Faucibus porta lacus fringilla vel</li>
              <li>Aenean sit amet erat nunc</li>
              <li>Eget porttitor lorem</li>
            </ul>
          </div>

          {/* Block with ordered list */}
          <div className="note tip">
            <span className="note-header">Tip:</span>
            <ol>
              <li>Lorem ipsum dolor sit amet</li>
              <li>
                Nulla volutpat aliquam velit
                <ol>
                  <li>Phasellus iaculis neque</li>
                  <li>Purus sodales ultricies</li>
                  <li>Vestibulum laoreet porttitor sem</li>
                  <li>Ac tristique libero volutpat at</li>
                </ol>
              </li>
              <li>Faucibus porta lacus fringilla vel</li>
              <li>Aenean sit amet erat nunc</li>
              <li>Eget porttitor lorem</li>
            </ol>
          </div>

          {/* Inline */}
          <p>
            <span className="note tip inlinetip">
              Lorem ipsum dolor sit amet,{' '}
              <a href="/">consectetur adipisicing elit</a>, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </p>
        </section>

        <section>
          <h2>Instructions</h2>

          {/* Block */}
          <div className="note instruction">
            <span className="note-header">Instruction:</span> Lorem ipsum dolor
            sit amet, <a href="/">consectetur adipisicing elit</a>, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </div>

          {/* Block with paragraph */}
          <div className="note instruction">
            <p>
              Lorem ipsum dolor sit amet,{' '}
              <a href="/">consectetur adipisicing elit</a>, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

          {/* Block with unordered list */}
          <div className="note instruction">
            <span className="note-header">Instruction:</span>
            <ul>
              <li>Lorem ipsum dolor sit amet</li>
              <li>
                Nulla volutpat aliquam velit
                <ul>
                  <li>Phasellus iaculis neque</li>
                  <li>Purus sodales ultricies</li>
                  <li>Vestibulum laoreet porttitor sem</li>
                  <li>Ac tristique libero volutpat at</li>
                </ul>
              </li>
              <li>Faucibus porta lacus fringilla vel</li>
              <li>Aenean sit amet erat nunc</li>
              <li>Eget porttitor lorem</li>
            </ul>
          </div>

          {/* Block with ordered list */}
          <div className="note instruction">
            <span className="note-header">Instruction:</span>
            <ol>
              <li>Lorem ipsum dolor sit amet</li>
              <li>
                Nulla volutpat aliquam velit
                <ol>
                  <li>Phasellus iaculis neque</li>
                  <li>Purus sodales ultricies</li>
                  <li>Vestibulum laoreet porttitor sem</li>
                  <li>Ac tristique libero volutpat at</li>
                </ol>
              </li>
              <li>Faucibus porta lacus fringilla vel</li>
              <li>Aenean sit amet erat nunc</li>
              <li>Eget porttitor lorem</li>
            </ol>
          </div>

          {/* Inline */}
          <p>
            <span className="note instruction inlinetip">
              Lorem ipsum dolor sit amet,{' '}
              <a href="/">consectetur adipisicing elit</a>, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </p>
        </section>
      </article>
    </Page>
  );
};

export default TypographyPage;
