import {
  IonAvatar,
  IonButton,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonLabel,
  IonList,
  IonReorderGroup,
  IonReorder,
  IonCheckbox,
  IonRadio,
  IonRadioGroup,
  IonToggle,
  IonItemSliding
} from '@ionic/react';
import React from 'react';
import {Emoji, FlagIcon, Icon, Page} from '../../../core/components';
import {Code} from '../../components';

const ListPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>List (🚧)</h1>

        <h2>Text</h2>

        <div className="tw-not-prose">
          <IonList>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                Curabitur at turpis vel libero suscipit varius sit amet a tortor
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <h2>With headline and supporting text</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  quis orci non nulla condimentum maximus.
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        </div>

        <h2>Media</h2>

        <div className="tw-not-prose">
          <IonList>
            <IonItem>
              <IonLabel className="ion-text-wrap">Icon</IonLabel>
              <Icon name="tennisball" slot="start" />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Emoji</IonLabel>
              <Emoji shortcode=":zebra_face:" slot="start" />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Flag</IonLabel>
              <FlagIcon name="za" slot="start" />
            </IonItem>
            <IonItem>
              <IonAvatar slot="start">
                <img
                  alt="Silhouette of a person's head"
                  src="https://ionicframework.com/docs/img/demos/avatar.svg"
                />
              </IonAvatar>
              <IonLabel className="ion-text-wrap">Avatar</IonLabel>
            </IonItem>
          </IonList>
        </div>

        <h2>Clickable items</h2>

        <div className="tw-not-prose">
          <IonList>
            <IonItem href="javascript:void(0);">
              <IonLabel className="ion-text-wrap">Link</IonLabel>
            </IonItem>
            <IonItem href="javascript:void(0);" disabled>
              <IonLabel className="ion-text-wrap">Disabled link</IonLabel>
            </IonItem>
            <IonItem button detail>
              <IonLabel className="ion-text-wrap">Button</IonLabel>
            </IonItem>
            <IonItem button detail disabled>
              <IonLabel className="ion-text-wrap">Disabled button</IonLabel>
            </IonItem>
          </IonList>
        </div>

        <h2>List controls</h2>

        <h3>With buttons</h3>

        <div className="tw-not-prose">
          <IonList>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                Curabitur at turpis vel libero suscipit varius sit amet a tortor
              </IonLabel>
              <IonButton slot="end">Edit</IonButton>
              <IonButton slot="end" color="danger">
                Delete
              </IonButton>
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <h2>With headline and supporting text</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  quis orci non nulla condimentum maximus.
                </p>
              </IonLabel>
              <IonButton slot="end">
                <Icon slot="icon-only" name="pencil" />
              </IonButton>
              <IonButton slot="end" color="danger">
                <Icon slot="icon-only" name="trash" />
              </IonButton>
            </IonItem>
          </IonList>
        </div>

        <h3>With sliding items</h3>

        <div className="tw-not-prose">
          <IonList>
            <IonItemSliding>
              <IonItem>
                <IonLabel className="ion-text-wrap">
                  Curabitur at turpis vel libero suscipit varius sit amet a
                  tortor
                </IonLabel>
              </IonItem>
              <IonItemOptions>
                <IonItemOption>
                  <Icon slot="icon-only" name="pencil" />
                </IonItemOption>
                <IonItemOption color="danger">
                  <Icon slot="icon-only" name="trash" />
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
            <IonItemSliding>
              <IonItem>
                <IonLabel className="ion-text-wrap">
                  <h2>With headline and supporting text</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    quis orci non nulla condimentum maximus.
                  </p>
                </IonLabel>
              </IonItem>
              <IonItemOptions>
                <IonItemOption>
                  <Icon slot="icon-only" name="pencil" />
                </IonItemOption>
                <IonItemOption color="danger">
                  <Icon slot="icon-only" name="trash" />
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          </IonList>
        </div>

        <h3>With leading icon and trailing radio</h3>

        <div className="tw-not-prose">
          <IonList>
            <IonRadioGroup>
              <IonItem>
                <IonLabel className="ion-text-wrap">Sunny</IonLabel>
                <Icon slot="start" name="sunny" />
                <IonRadio slot="end" />
              </IonItem>
              <IonItem>
                <IonLabel className="ion-text-wrap">Cloudy</IonLabel>
                <Icon slot="start" name="cloudy" />
                <IonRadio slot="end" />
              </IonItem>
              <IonItem>
                <IonLabel className="ion-text-wrap">Rainy</IonLabel>
                <Icon slot="start" name="rainy" />
                <IonRadio slot="end" />
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </div>

        <h3>With leading radio</h3>

        <div className="tw-not-prose">
          <IonList>
            <IonRadioGroup>
              <IonItem>
                <IonLabel className="ion-text-wrap">Sunny</IonLabel>
                <IonRadio slot="start" />
              </IonItem>
              <IonItem>
                <IonLabel className="ion-text-wrap">Cloudy</IonLabel>
                <IonRadio slot="start" />
              </IonItem>
              <IonItem>
                <IonLabel className="ion-text-wrap">Rainy</IonLabel>
                <IonRadio slot="start" />
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </div>

        <h3>With leading icon and trailing checkbox</h3>

        <div className="tw-not-prose">
          <IonList>
            <IonItem>
              <IonLabel className="ion-text-wrap">Tennis</IonLabel>
              <Icon slot="start" name="tennisball" />
              <IonCheckbox slot="end" />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Football</IonLabel>
              <Icon slot="start" name="football" />
              <IonCheckbox slot="end" />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Basketball</IonLabel>
              <Icon slot="start" name="basketball" />
              <IonCheckbox slot="end" />
            </IonItem>
          </IonList>
        </div>

        <h3>With leading checkbox</h3>

        <div className="tw-not-prose">
          <IonList>
            <IonItem>
              <IonLabel className="ion-text-wrap">Tennis</IonLabel>
              <IonCheckbox slot="start" />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Football</IonLabel>
              <IonCheckbox slot="start" />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Basketball</IonLabel>
              <IonCheckbox slot="start" />
            </IonItem>
          </IonList>
        </div>

        <h3>Toggle</h3>

        <div className="tw-not-prose">
          <IonList>
            <IonItem>
              <IonLabel className="ion-text-wrap">Wi-Fi</IonLabel>
              <Icon slot="start" name="wifi" />
              <IonToggle slot="end" />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Bluetooth</IonLabel>
              <Icon slot="start" name="bluetooth" />
              <IonToggle slot="end" />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Location</IonLabel>
              <Icon slot="start" name="location" />
              <IonToggle slot="end" />
            </IonItem>
          </IonList>
        </div>

        <h3>Reorder</h3>

        <div className="tw-not-prose">
          <IonList>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={(e) => e.detail.complete()}
            >
              <IonReorder>
                <IonItem>
                  <IonLabel className="ion-text-wrap">Item 1</IonLabel>
                  <Icon name="reorder-two" slot="end" />
                </IonItem>
              </IonReorder>
              <IonReorder>
                <IonItem>
                  <IonLabel className="ion-text-wrap">Item 2</IonLabel>
                  <Icon name="reorder-two" slot="end" />
                </IonItem>
              </IonReorder>
              <IonReorder>
                <IonItem>
                  <IonLabel className="ion-text-wrap">Item 3</IonLabel>
                  <Icon name="reorder-two" slot="end" />
                </IonItem>
              </IonReorder>
            </IonReorderGroup>
          </IonList>
        </div>
      </article>
    </Page>
  );
};

export default ListPage;
