/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-bullet-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'science'}</title>
    <circle fill="#00bcd4" cx={256} cy={256} r={256} />
    <path
      fill="#FFF"
      d="M294.223 307.359c0-8.41-2.969-14.867-8.906-19.371-5.938-4.502-16.625-9.252-32.063-14.25-15.438-4.996-27.66-9.92-36.664-14.77-24.543-13.26-36.813-31.121-36.813-53.586 0-11.676 3.289-22.092 9.871-31.246 6.58-9.152 16.031-16.303 28.352-21.449 12.32-5.145 26.148-7.719 41.488-7.719 15.438 0 29.191 2.797 41.266 8.387 12.072 5.592 21.449 13.484 28.129 23.676 6.68 10.193 10.02 21.771 10.02 34.734h-44.531c0-9.895-3.117-17.59-9.352-23.082s-14.992-8.238-26.273-8.238c-10.887 0-19.348 2.301-25.383 6.902-6.037 4.602-9.055 10.664-9.055 18.184 0 7.027 3.537 12.914 10.613 17.664 7.074 4.75 17.49 9.203 31.246 13.359 25.332 7.621 43.789 17.07 55.367 28.352s17.367 25.334 17.367 42.156c0 18.703-7.076 33.375-21.227 44.012-14.152 10.639-33.201 15.957-57.148 15.957-16.625 0-31.766-3.043-45.422-9.129s-24.072-14.422-31.246-25.012c-7.176-10.588-10.762-22.859-10.762-36.813h44.68c0 23.85 14.25 35.773 42.75 35.773 10.588 0 18.852-2.152 24.789-6.457s8.907-10.315 8.907-18.034z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
