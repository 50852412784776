/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'feedback'}</title>
    <circle fill="#DDD" cx={256} cy={256} r={256} />
    <path d="M256 272h144v8H256z" />
    <path fill="#008bb2" d="M144 192h64v64h-64z" />
    <path fill="#006D8C" d="M256 112h32v144h-32zM304 160h64v96h-64z" />
    <path fill="#008bb2" d="M224 112h32v144h-32z" />
    <path fill="#5B5B5B" d="M128 272h128v8H128M128 280h-8V112h8v153.089" />
    <path d="M412.762 338.423c2.159.354 3.238 1.281 3.238 2.776 0 .881-.5 1.863-1.503 2.904L393.56 365.52l4.959 30.25c.033.274.057.688.057 1.214 0 2.011-.785 3.018-2.365 3.018-.729 0-1.504-.239-2.311-.718l-25.895-14.276-25.904 14.276c-.851.479-1.622.718-2.311.718-.806 0-1.417-.285-1.82-.868-.4-.594-.6-1.303-.6-2.147 0-.25.033-.651.107-1.212l4.96-30.25-21-21.418c-.96-1.084-1.438-2.059-1.438-2.903 0-1.495 1.08-2.421 3.236-2.776L352.192 334l12.979-27.529c.73-1.646 1.679-2.471 2.834-2.471 1.146 0 2.092.823 2.822 2.471L383.807 334l28.955 4.423z" />
    <path
      fill="#FFF"
      d="m385.595 362.801 17.654-17.978-24.346-3.749-10.897-23.108-10.91 23.108-24.345 3.749 17.654 17.978-4.217 25.474 21.818-12.046 21.741 12.046z"
    />
    <path
      fill="#5B5B5B"
      d="M188.762 338.423c2.159.354 3.238 1.281 3.238 2.776 0 .881-.5 1.863-1.503 2.904L169.56 365.52l4.959 30.25c.033.274.055.688.055 1.214 0 2.011-.785 3.018-2.365 3.018-.73 0-1.504-.239-2.311-.718l-25.893-14.276-25.904 14.276c-.851.479-1.623.718-2.311.718-.806 0-1.417-.285-1.82-.868-.402-.594-.6-1.303-.6-2.147 0-.25.033-.651.109-1.212l4.959-30.25-21-21.418C96.48 343.02 96 342.046 96 341.199c0-1.495 1.08-2.422 3.236-2.776L128.192 334l12.979-27.529c.73-1.646 1.679-2.471 2.834-2.471 1.145 0 2.092.823 2.822 2.471L159.807 334l28.955 4.423z"
    />
    <path d="m161.595 362.801 17.654-17.978-24.346-3.749-10.897-23.108-10.91 23.108-24.345 3.749 17.654 17.978-4.217 25.474 21.818-12.046 21.741 12.046z" />
    <path
      fill="#008bb2"
      d="m161.595 362.801 17.654-17.978-24.346-3.749-10.897-23.108-10.91 23.108-24.345 3.749 17.654 17.978-4.217 25.474 21.818-12.046 21.741 12.046z"
    />
    <path d="m302.5 344.1-20.939 21.42 4.959 30.25c.03.271.051.69.051 1.213 0 2.01-.778 3.02-2.358 3.02-.729 0-1.505-.24-2.313-.72L256 385.01v-8.779l21.745 12.052-4.157-25.48 17.661-17.979-24.354-3.75L256 317.971V304c1 0 2.096.82 2.825 2.471L271.809 334l28.95 4.42c2.16.359 3.241 1.28 3.241 2.78 0 .88-.5 1.861-1.5 2.9z" />
    <path
      fill="#5B5B5B"
      d="m209.5 344.1 20.939 21.42-4.959 30.25c-.03.271-.051.69-.051 1.213 0 2.01.78 3.02 2.36 3.02.729 0 1.505-.24 2.315-.72L256 385.01v-8.779l-21.745 12.052 4.157-25.48-17.661-17.979 24.354-3.75L256 317.971V304c-1 0-2.096.82-2.825 2.471L240.192 334l-28.951 4.42c-2.16.359-3.241 1.28-3.241 2.78 0 .88.5 1.861 1.5 2.9z"
    />
    <path
      fill="#008bb2"
      d="M245.109 341.07 256 317.971v58.259l-21.75 12.049 4.16-25.479-17.66-17.98z"
    />
    <path
      fill="#FFF"
      d="m273.59 362.8 4.16 25.479L256 376.23v-58.259l10.891 23.099 24.359 3.75z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
