import React from 'react';
import {Page} from '../../core/components';
import logHistory from '../../core/utils/logHistory';

const TeacherHomePage: React.FC = () => {
  // logHistory('TeacherHomePage');

  return (
    <Page meta={{title: 'Home'}}>
      <div className="tw-page">
        <section>
          <h1 className="tw-mb-2 tw-text-3xl tw-font-bold lg:tw-text-4xl">
            Welcome!
          </h1>
          <div>Teacher home page</div>
        </section>
      </div>
    </Page>
  );
};

export default TeacherHomePage;
