import React from 'react';
import {useSelector} from 'react-redux';
import {selectSession} from '../../auth/auth.slice';
import {Page} from '../../core/components';
import {getPageTitle} from '../../core/models/activity/activity';
import ForbiddenPage from '../../core/pages/ForbiddenPage';
import logHistory from '../../core/utils/logHistory';
import {selectActivity} from '../activity.slice';
import Question from '../components/Question';

const QuestionPage: React.FC = () => {
  // logHistory('QuestionPage');

  const {is_authenticated} = useSelector(selectSession);
  const {activity, response, book, section} = useSelector(selectActivity);

  if (!is_authenticated) {
    return <ForbiddenPage />;
  }

  if (!activity || !response) {
    // TODO Error page or redirect
    return null;
  }

  return (
    <Page meta={{title: getPageTitle(activity.activity_type)}}>
      <div className="tw-page tw-grid tw-grid-cols-1 xl:tw-grid-cols-[auto_19em] xl:tw-gap-12">
        <div className="tw-max-w-prose">
          <Question
            activity={activity}
            response={response}
            book={book}
            section={section}
          />
        </div>
        <div className="tw-bg-red-50">SIDEBAR WIDGETS HERE</div>
      </div>
    </Page>
  );
};

export default QuestionPage;
