import type {AutocompleteTypes, TextFieldTypes} from '@ionic/core';
import {getConfig, IonInput, IonItem, IonLabel, IonNote} from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import './TextInput.md.scss';

export type TextInputProps = {
  autocomplete?: AutocompleteTypes;
  disabled?: boolean;
  helper?: string;
  hidden?: boolean;
  inputmode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  label?: string;
  lines?: 'full' | 'inset' | 'none';
  name: string;
  placeholder?: string;
  readonly?: boolean;
  showError?: boolean;
  type?: TextFieldTypes;
};

const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    control,
    formState: {errors}
  } = useFormContext();
  const {
    autocomplete,
    disabled,
    readonly,
    hidden,
    helper,
    inputmode,
    label,
    name,
    placeholder,
    showError = true,
    type = 'text'
  } = props;

  if (hidden) {
    return (
      <Controller
        control={control}
        name={name}
        render={({field: {value, onChange}}) => (
          <IonInput
            autocomplete={autocomplete}
            disabled={disabled}
            hidden={hidden}
            inputmode={inputmode}
            readonly={readonly}
            type={type}
            value={value}
            onIonChange={onChange}
          />
        )}
      />
    );
  }

  const error = errors[name]?.message;
  const mode = getConfig()?.get('mode') || 'md';
  const fill = mode === 'md' ? 'outline' : undefined;
  const position = mode === 'md' ? 'floating' : undefined;

  return (
    <IonItem className={clsx({'has-error': error})} fill={fill}>
      {label && <IonLabel position={position}>{label}</IonLabel>}
      <Controller
        control={control}
        name={name}
        render={({field: {value, onBlur, onChange}}) => (
          <IonInput
            autocomplete={autocomplete}
            color={error ? 'danger' : undefined}
            disabled={disabled}
            inputmode={inputmode}
            readonly={readonly}
            placeholder={placeholder}
            type={type}
            value={value}
            onIonBlur={onBlur}
            onIonChange={onChange}
          />
        )}
      />
      {error && showError && (
        <IonNote color="danger" slot="helper">
          {error}
        </IonNote>
      )}
      {!(error && showError) && helper && (
        <IonNote slot="helper">{helper}</IonNote>
      )}
    </IonItem>
  );
};

export default TextInput;
