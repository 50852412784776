import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, useParams} from 'react-router-dom';
import {selectSession} from '../../auth/auth.slice';
import {getNearestGrade} from '../../core/models/region-subject-grade/region-subject-grade';
import {selectGradesForRegionSubject} from '../../core/models/region-subject-grade/region-subject-grade.slice';
import type {SUBJECT_NAME} from '../../core/models/subject/subject';
import {selectSubjectIdFromName} from '../../core/models/subject/subject.slice';
import compilePath from '../../core/utils/compilePath';
import logHistory from '../../core/utils/logHistory';
import RedirectToUserHome from '../../home/pages/RedirectToUserHome';
import {
  PATH_PRACTICE_DASHBOARD_SUBJECT,
  PATH_PRACTICE_DASHBOARD_SUBJECT_GRADE
} from '../practice-dashboard.paths';

const RedirectToSubjectGrade: React.FC = () => {
  // logHistory('RedirectToSubjectGrade');

  const {subject_name} = useParams<{subject_name: SUBJECT_NAME}>();
  const {region, user_profile_general} = useSelector(selectSession);
  const subjectId = useSelector(selectSubjectIdFromName(subject_name));
  const subjectGrades = useSelector(
    selectGradesForRegionSubject(region, subjectId)
  );

  // If region does not have subject
  if (!subjectGrades.length) {
    return <RedirectToUserHome />;
  }

  const grades = _.uniq(
    subjectGrades.map((d) => d.grade).filter(Boolean)
  ) as number[];

  // If subject has no grades in region (INTL)
  if (!grades.length) {
    return (
      <Redirect
        to={compilePath(PATH_PRACTICE_DASHBOARD_SUBJECT, {
          subject_name
        })}
      />
    );
  }

  const grade = getNearestGrade(grades, user_profile_general?.grade);

  if (!grade) {
    return <RedirectToUserHome />;
  }

  return (
    <Redirect
      to={compilePath(PATH_PRACTICE_DASHBOARD_SUBJECT_GRADE, {
        subject_name,
        grade
      })}
    />
  );
};

export default RedirectToSubjectGrade;
