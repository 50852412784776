import {IonLabel} from '@ionic/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {countriesSelectors} from '../../models/country/country.slice';
import FlagIcon from '../FlagIcon/FlagIcon';
import Select from '../Select/Select';
import type {SelectItem, SelectProps} from '../Select/types';

const Option: React.FC<{item: SelectItem}> = ({item}) => (
  <>
    <IonLabel className="ion-text-wrap">{item.full_name}</IonLabel>
    <FlagIcon name={item.icon_name} slot="end" />
  </>
);

type CountrySelectProps = Pick<
  SelectProps,
  'disabled' | 'helper' | 'label' | 'name'
>;

const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  const countries = useSelector(countriesSelectors.selectAll);

  return (
    <Select
      {...props}
      items={countries}
      itemToValue={({id}) => id}
      itemToLabel={({full_name}) => full_name}
      filter={(items, inputValue) =>
        items?.filter(({full_name}) =>
          full_name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
        )
      }
      searchable
      searchPlaceholder="Search countries"
      optionComponent={Option}
    />
  );
};

export default CountrySelect;
