import {demoApi} from '../../api';
import type {MasterSchool} from './master-school';

type Request = {
  country_code: 'ZA' | 'RW';
  school_name: string;
};

const api = demoApi.injectEndpoints({
  endpoints: (build) => ({
    searchMasterSchools: build.query<MasterSchool[], Request>({
      query: ({country_code, school_name}) => ({
        url: 'master-schools/search',
        method: 'POST',
        body: {country_code, school_name}
      }),
      transformResponse: (value: {data: any}): MasterSchool[] => value.data
    })
  })
});

export const {useSearchMasterSchoolsQuery} = api;
