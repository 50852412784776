import {
  IonButton,
  IonCheckbox,
  IonChip,
  IonItem,
  IonLabel,
  IonRadio
} from '@ionic/react';
import chroma from 'chroma-js';
import {bestColorContrast} from 'chrys/src/js/bestColorContrast';
import _ from 'lodash';
import React from 'react';
import {Icon, Page} from '../../../core/components';
import tailwindConfig from '../../../core/data/tailwind.config';
import {Code} from '../../components';
import Note from '../../components/Note';

type Color = Record<string, string>;
type Colors = Record<string, Color>;

const exclude = ['inherit', 'current', 'transparent', 'black', 'white'];

const uiColors: Colors = Object.fromEntries(
  Object.entries(tailwindConfig.theme.colors)
    .filter(
      ([hue, shades]) => !hue.startsWith('marketing-') && !exclude.includes(hue)
    )
    .map((entry) => entry as [string, Color])
);

const marketingColors: Colors = Object.fromEntries(
  Object.entries(tailwindConfig.theme.colors)
    .filter(
      ([hue, shades]) => hue.startsWith('marketing-') && !exclude.includes(hue)
    )
    .map((entry) => entry as [string, Color])
);

const Palette: React.FC<{colors: Colors}> = (props) => {
  const {colors} = props;

  return (
    <div className="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 2xl:tw-grid-cols-5">
      {Object.entries(colors).map((entry, index) => {
        const [hue, shades] = entry;
        const sortedShades = _.sortBy(
          Object.entries(shades).map((d) => [parseInt(d[0], 10), d[1]]),
          (d) => d[0]
        );
        const hex = shades[500];

        return (
          <div key={index}>
            <div
              className="tw-p-3 tw-text-center first:tw-rounded-t"
              style={{
                background: hex,
                color: bestColorContrast(hex, ['#000', '#fff'])
              }}
            >
              <span className="tw-text-sm">{hue}</span>
            </div>
            {React.Children.toArray(
              sortedShades.map((entry, index) => {
                const [shade, hex] = entry;

                return (
                  <div
                    key={index}
                    className="tw-grid tw-grid-cols-2 tw-justify-between tw-p-3 last:tw-rounded-b"
                    style={{
                      background: hex,
                      color: bestColorContrast(hex, ['#000', '#fff'])
                    }}
                  >
                    <span className="tw-text-left tw-font-mono tw-text-xs tw-uppercase">
                      {shade}
                    </span>
                    <span className="tw-text-right tw-font-mono tw-text-xs tw-uppercase">
                      {hex}
                    </span>
                  </div>
                );
              })
            )}
          </div>
        );
      })}
    </div>
  );
};

type RoleColor = [string, number?];

const roleColorMap: Map<
  string,
  {
    x: {
      bg: RoleColor;
      fg: RoleColor;
    };
    y: {
      bg: RoleColor;
      fg: RoleColor;
    };
  }
> = new Map([
  [
    'primary',
    {
      x: {
        bg: ['blue', 500],
        fg: ['white']
      },
      y: {
        bg: ['white'],
        fg: ['blue', 600]
      }
    }
  ],
  [
    'secondary',
    {
      x: {
        bg: ['teal', 500],
        fg: ['white']
      },
      y: {
        bg: ['white'],
        fg: ['teal', 600]
      }
    }
  ],
  [
    'tertiary',
    {
      x: {
        bg: ['indigo', 500],
        fg: ['white']
      },
      y: {
        bg: ['white'],
        fg: ['indigo', 600]
      }
    }
  ],
  [
    'success',
    {
      x: {
        bg: ['green', 500],
        fg: ['white']
      },
      y: {
        bg: ['white'],
        fg: ['green', 600]
      }
    }
  ],
  [
    'warning',
    {
      x: {
        bg: ['amber', 500],
        fg: ['brown', 900]
      },
      y: {
        bg: ['white'],
        fg: ['amber', 700]
      }
    }
  ],
  [
    'danger',
    {
      x: {
        bg: ['red', 500],
        fg: ['white']
      },
      y: {
        bg: ['white'],
        fg: ['red', 600]
      }
    }
  ]
]);

function getRoleColorStyle(bg: RoleColor, fg: RoleColor) {
  const bgHex = _.get(tailwindConfig.theme.colors, bg.join('.'));
  const fgHex = _.get(tailwindConfig.theme.colors, fg.join('.'));
  const contrast = _.round(chroma.contrast(bgHex, fgHex), 1);

  return {
    style: {
      backgroundColor: bgHex,
      color: fgHex
    },
    bg: {
      style: {
        backgroundColor: bgHex,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor:
          chroma(bgHex).hex() === '#ffffff'
            ? tailwindConfig.theme.colors.grey[500]
            : bgHex
      },
      label: bg.join(' ')
    },
    fg: {
      style: {
        backgroundColor: fgHex,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor:
          chroma(fgHex).hex() === '#ffffff'
            ? tailwindConfig.theme.colors.grey[500]
            : fgHex
      },
      label: fg.join(' ')
    },
    contrast
  };
}

const Swatch: React.FC<{color: string}> = ({color}) => {
  const colorDef = roleColorMap.get(color);

  if (!colorDef) {
    return null;
  }

  const x = getRoleColorStyle(colorDef.x.bg, colorDef.x.fg);
  const y = getRoleColorStyle(colorDef.y.bg, colorDef.y.fg);

  return (
    <>
      {[x, y].map((d, index) => (
        <div
          key={index}
          className="tw-grid tw-max-w-fit tw-grid-flow-col tw-gap-2 tw-whitespace-nowrap"
        >
          <div className="tw-grid tw-w-[9rem] tw-grid-flow-col" style={d.style}>
            <div className="tw-p-2">
              <div className="tw-text-xs">Extra small</div>
              <div className="tw-text-lg">Large</div>
            </div>
            <div className="tw-p-2 tw-text-right">
              <div className="tw-text-xs">{d.contrast}</div>
            </div>
          </div>
          <div className="tw-px-2">
            <div>
              <span
                className="tw-mr-2 tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-align-middle tw-leading-none"
                style={d.bg.style}
              />
              <span className="tw-font-mono tw-text-xs tw-leading-none">
                {d.bg.label}
              </span>
            </div>
            <div>
              <span
                className="tw-mr-2 tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-align-middle tw-leading-none"
                style={d.fg.style}
              />
              <span className="tw-font-mono tw-text-xs tw-leading-none">
                {d.fg.label}
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const ColoursPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Colours</h1>

        <h2>Roles</h2>

        <div className="tw-not-prose">
          <table className="sv-table">
            <thead>
              <tr>
                <th>Role</th>
                <th>Swatches</th>
                <th>Components</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Primary</th>
                <td>
                  <Swatch color="primary" />
                </td>
                <td>
                  <div className="tw-flex tw-items-center tw-space-x-4">
                    <IonButton color="primary" fill="solid">
                      Button
                    </IonButton>

                    <IonItem lines="none">
                      <IonLabel>Checkbox</IonLabel>
                      <IonCheckbox slot="start" />
                    </IonItem>

                    <IonItem lines="none">
                      <IonLabel>Radio</IonLabel>
                      <IonRadio slot="start" />
                    </IonItem>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Secondary</th>
                <td>
                  <Swatch color="secondary" />
                </td>
                <td>
                  <div className="tw-flex tw-items-center tw-space-x-4">
                    <IonButton color="primary" fill="solid">
                      Primary
                    </IonButton>
                    <IonButton color="secondary" fill="solid">
                      Secondary
                    </IonButton>

                    <IonChip color="secondary">
                      <Icon name="checkmark" set="ionicons" />
                      <IonLabel>Filter chip</IonLabel>
                    </IonChip>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Tertiary</th>
                <td>
                  <Swatch color="tertiary" />
                </td>
                <td>
                  <div className="tw-flex tw-items-center tw-space-x-4">
                    <IonButton color="primary" fill="solid">
                      Primary
                    </IonButton>
                    <IonButton color="secondary" fill="solid">
                      Secondary
                    </IonButton>
                    <IonButton color="tertiary" fill="solid">
                      Tertiary
                    </IonButton>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Success</th>
                <td>
                  <Swatch color="success" />
                </td>
                <td>
                  <div className="tw-flex tw-items-center tw-space-x-4">
                    <IonButton color="success" fill="solid">
                      Success
                    </IonButton>

                    <span>
                      <Icon name="checkmark-circle" color="success" />
                      <IonLabel>Success message</IonLabel>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Warning</th>
                <td>
                  <Swatch color="warning" />
                </td>
                <td>
                  <div className="tw-flex tw-items-center tw-space-x-4">
                    <IonButton color="warning" fill="solid">
                      Warning
                    </IonButton>

                    <span>
                      <Icon name="warning" color="warning" />
                      <IonLabel>Warning message</IonLabel>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Danger</th>
                <td>
                  <Swatch color="danger" />
                </td>
                <td>
                  <div className="tw-flex tw-items-center tw-space-x-4">
                    <IonButton color="danger" fill="solid">
                      Danger
                    </IonButton>

                    <span>
                      <Icon name="close-circle" color="danger" />
                      <IonLabel>Error message</IonLabel>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Note type="info">
            <a
              href="https://m3.material.io/styles/color/the-color-system/color-roles"
              rel="noopener noreferrer"
              target="_blank"
            >
              Material Design 3 - Color roles
            </a>
          </Note>
        </div>

        <h2>Usage</h2>

        <div className="tw-not-prose">
          <div className="tw-bg-indigo-500 tw-p-3 tw-text-yellow-500">
            Lorem ipsum
          </div>
          <Code
            code={`
import tailwindConfig from 'src/core/data/tailwind.config';
const backgroundColor = tailwindConfig.theme.colors.indigo[500];
const color = tailwindConfig.theme.colors.yellow[500];
`}
            language="typescript"
          />
          <Code
            code={`
<div className="tw-bg-indigo-500 tw-text-yellow-500">Lorem ipsum</div>
`}
            language="markup"
          />
          <Code
            code={`
.class-name {
  background-color: theme('colors.indigo.500');
  color: theme('colors.yellow.500');
}
`}
            language="scss"
          />
          <Note type="do">
            Use the utility classes or the <code>theme()</code> function, not
            hexadecimal or RGB values. Named colours are easier to understand
            and simpler to maintain.
          </Note>
        </div>

        <h2>UI Palette</h2>

        <div className="tw-not-prose">
          <Palette colors={uiColors} />
        </div>

        <h2>Marketing Palette</h2>

        <div className="tw-not-prose">
          <Palette colors={marketingColors} />
        </div>
      </article>
    </Page>
  );
};

export default ColoursPage;
