import {Route} from 'react-router-dom';
import AuthRoute from '../core/pages/AuthRoute';
import PrivateRoute from '../core/pages/PrivateRoute';
import {
  PATH_HOME_PUBLIC,
  PATH_HOME_LEARNER,
  PATH_HOME_TEACHER
} from './home.paths';
import LearnerHomePage from './pages/LearnerHomePage';
import PublicHomePage from './pages/PublicHomePage';
import TeacherHomePage from './pages/TeacherHomePage';

const routes = [
  <Route exact path={PATH_HOME_PUBLIC}>
    <PublicHomePage />
  </Route>,
  <Route exact path={PATH_HOME_LEARNER}>
    <PrivateRoute requiresLearner>
      <LearnerHomePage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_HOME_TEACHER}>
    <PrivateRoute requiresTeacher>
      <TeacherHomePage />
    </PrivateRoute>
  </Route>
];

// const routes = [
//   <Route exact path={PATH_HOME_PUBLIC}>
//     <PublicHomePage />
//   </Route>,
//   <AuthRoute exact path={PATH_HOME_LEARNER}>
//     <LearnerHomePage />
//   </AuthRoute>,
//   <AuthRoute exact path={PATH_HOME_TEACHER}>
//     <TeacherHomePage />
//   </AuthRoute>
// ];

export default routes;
