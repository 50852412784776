import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import type {SerializedError} from '@reduxjs/toolkit';
import type {RootState} from './store';
import {clearSession} from '../auth/auth.slice';

const {REACT_APP_SIYAVULA_API_URL: siteUrl} = process.env;

export const demoApi = createApi({
  reducerPath: 'demoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: siteUrl + '/api/demo/',
    prepareHeaders: (headers, {getState}) => {
      const {client_token, user_token} = (getState() as RootState).auth;

      if (client_token) {
        headers.set('JWT', client_token);
      }

      if (user_token) {
        // headers.set('Authorization', `Bearer ${user_token}`);
        headers.set('Authorization', `JWT ${user_token}`);
      }

      return headers;
    }
  }),
  endpoints: () => ({}),
  keepUnusedDataFor: 0
});

const baseQuery = fetchBaseQuery({
  baseUrl: siteUrl + '/api/siyavula/v1/',
  prepareHeaders: (headers, {getState}) => {
    const {client_token, user_token} = (getState() as RootState).auth;

    if (client_token) {
      headers.set('JWT', client_token);
    }

    if (user_token) {
      // headers.set('Authorization', `Bearer ${user_token}`);
      headers.set('Authorization', `JWT ${user_token}`);
    }

    return headers;
  }
});

// Based on https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const message = transformError(result.error)?.message?.toLowerCase();

    if (
      message &&
      (message.includes('client token expired') ||
        message.includes('user token expired') ||
        message.includes('invalid client token') ||
        message.includes('invalid user token'))
    ) {
      api.dispatch(clearSession());
    }
  }

  return result;
};

export const siyavulaApi = createApi({
  reducerPath: 'siyavulaApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  keepUnusedDataFor: 0
});

type ApiError = {
  code?: string;
  message?: string;
};

type ApiErrors = ApiError[];

/**
 * TODO Try to refactor for type safety. FetchBaseQueryError and SerializedError are similar but not equal.
 */
export function transformError(
  error: undefined | FetchBaseQueryError | SerializedError
): undefined | ApiError {
  if (!error) {
    return undefined;
  }

  // Query error
  if ('status' in error) {
    // Client error
    if (
      error.status === 'FETCH_ERROR' ||
      error.status === 'PARSING_ERROR' ||
      error.status === 'CUSTOM_ERROR'
    ) {
      return {
        code: error.status,
        message: error.error
      };
    }

    // Server error
    if (typeof error.status === 'number') {
      if (!error.data) {
        return undefined;
      }

      if (typeof error.data === 'string') {
        return {
          code: undefined,
          message: error.data
        };
      }

      if (Object.hasOwn(error.data, 'errors')) {
        const {errors} = error.data as {errors: ApiErrors};

        if (errors.length) {
          return {
            code: errors[0].code,
            message: errors[0].message
          };
        }
      }
    }
  }
  // Client error
  else {
    return {
      code: error.code,
      message: error.message
    };
  }
}
