import {Route} from 'react-router-dom';
import PrivateRoute from '../core/pages/PrivateRoute';
import {
  PATH_CREATE_PRACTICE_ACTIVITY,
  PATH_SEQUENCED_QUESTION,
  PATH_UNSEQUENCED_QUESTION
} from './activity.paths';
import CreatePracticeActivity from './pages/CreatePracticeActivity';
import QuestionPage from './pages/QuestionPage';

const routes = [
  <Route exact path={PATH_CREATE_PRACTICE_ACTIVITY}>
    <PrivateRoute requiresLogin>
      <CreatePracticeActivity />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_UNSEQUENCED_QUESTION}>
    <PrivateRoute requiresLogin>
      <QuestionPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_SEQUENCED_QUESTION}>
    <PrivateRoute requiresLogin>
      <QuestionPage />
    </PrivateRoute>
  </Route>
];

export default routes;
