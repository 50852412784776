import {useIonToast} from '@ionic/react';
import {skipToken} from '@reduxjs/toolkit/query/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {selectSession} from '../../auth/auth.slice';
import {transformError} from '../../core/api';
import {Page, PageHeader} from '../../core/components';
import {dangerToastOptions} from '../../core/components/Toast';
import {selectGradeName} from '../../core/models/grade/grade.slice';
import type {SUBJECT_NAME} from '../../core/models/subject/subject';
import {
  selectSubjectFullName,
  selectSubjectIdFromName
} from '../../core/models/subject/subject.slice';
import NotFoundPage from '../../core/pages/NotFoundPage';
import logHistory from '../../core/utils/logHistory';
import PracticeToc from '../components/PracticeToc';
import {useGetPracticeTocQuery} from '../practice-dashboard.api';

type Props = {
  subject_name: SUBJECT_NAME;
  grade: string;
};

const PracticeDashboardPage: React.FC<Props> = React.memo((props) => {
  // logHistory('PracticeDashboardPage');

  const {subject_name, grade} = props;
  const subjectId = useSelector(selectSubjectIdFromName(subject_name));
  const {region, is_authenticated, siyavula_id} = useSelector(selectSession);
  const subjectFullName = useSelector(selectSubjectFullName(subjectId));
  const gradeName = useSelector(selectGradeName(region, parseInt(grade, 10)));

  // Load data
  const {data, error, isLoading, isFetching, isUninitialized} =
    useGetPracticeTocQuery(
      is_authenticated && siyavula_id
        ? {siyavula_id, subject_name, grade: parseInt(grade, 10)}
        : skipToken,
      {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true
      }
    );
  const isReady = !isUninitialized && !(isLoading || isFetching);

  // const [presentToast] = useIonToast();

  if (isUninitialized) {
    return null;
  } else if (isReady && error) {
    // TODO Add error handling
    // presentToast({
    //   ...dangerToastOptions,
    //   message: transformError(error)?.code
    // });
  } else if (isReady && !data) {
    return <NotFoundPage />;
  }

  return (
    <Page
      progress={isReady}
      meta={{
        title: {
          short: 'Practice',
          long: `Practice: ${subjectFullName} ${gradeName}`
        }
      }}
    >
      {isReady && data && (
        <div className="tw-page">
          <PageHeader heading={`${subjectFullName} ${gradeName}`} />
          <h3 className="tw-mb-6 tw-text-xl tw-font-bold lg:tw-text-2xl">
            Table of Contents
          </h3>
          <PracticeToc book={data} />
        </div>
      )}
    </Page>
  );
});

const Wrapper: React.FC = () => (
  <PracticeDashboardPage {...useParams<Props>()} />
);

export default Wrapper;
