import {yupResolver} from '@hookform/resolvers/yup';
import {IonButton} from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Page, Checkbox, CheckboxGroup, Message} from '../../../core/components';
import yup from '../../../core/vendor/yup';

const CheckboxPage: React.FC = () => {
  const options = [
    {label: 'Alpha', value: 'alpha'},
    {label: 'Omega', value: 'omega'}
  ];
  const singleAssert = yup
    .boolean()
    .required('Required')
    .oneOf([true], 'Required');
  const multipleAssert = yup
    .array(yup.string())
    .compact()
    .required('Required')
    .min(1, 'Required');
  const schema = yup.object({
    empty_checkbox: singleAssert,
    completed_checkbox: singleAssert,
    disabled_checkbox: singleAssert,
    empty_checkbox_group: multipleAssert,
    completed_checkbox_group: multipleAssert,
    disabled_checkbox_group: multipleAssert
  });
  const form = useForm({
    defaultValues: {
      empty_checkbox: false,
      completed_checkbox: true,
      disabled_checkbox: true,
      indeterminate_checkbox: false,
      empty_checkbox_group: [],
      completed_checkbox_group: ['omega'],
      disabled_checkbox_group: ['omega']
    },
    resolver: yupResolver(schema)
  });
  const errors = Object.entries(form.formState.errors).map(([field, error]) => [
    field,
    _.isArray(error) ? error.map((e) => e.message).join('') : error.message
  ]);
  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
  };

  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Checkbox</h1>

        <div className="tw-not-prose">
          {!!errors.length && (
            <>
              {errors.map((error) => (
                <Message key={error[0]} color="danger">
                  {error[0]}: {error[1]}
                </Message>
              ))}
              <br />
            </>
          )}

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Checkbox
                name="empty_checkbox"
                label="Unchecked checkbox"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <Checkbox
                name="completed_checkbox"
                label="Checked checkbox"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <Checkbox
                name="disabled_checkbox"
                label="Disabled checkbox"
                helper="Lorem ipsum dolor sit amet"
                disabled
              />
              <br />
              <CheckboxGroup
                name="empty_checkbox_group"
                label="Group of checkboxes that has none checked by default:"
                helper="Lorem ipsum dolor sit amet"
                options={options}
              />
              <br />
              <CheckboxGroup
                name="completed_checkbox_group"
                label="Group of checkboxes that has some checked by default:"
                helper="Lorem ipsum dolor sit amet"
                options={options}
              />
              <br />
              <CheckboxGroup
                name="disabled_checkbox_group"
                label="Group of disabled checkboxes:"
                helper="Lorem ipsum dolor sit amet"
                options={options.map((option) => ({...option, disabled: true}))}
              />
              <br />
              <IonButton type="submit">Submit</IonButton>
            </form>
          </FormProvider>
        </div>
      </article>
    </Page>
  );
};

export default CheckboxPage;
