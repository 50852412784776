export default function filterNumericalMinus(str: string): string {
  const matches = str.match(/-/gi);

  if (matches) {
    if (!str.match(/\//gi)) {
      var search: string = '-'; // Hyphen-minus
      var replacement: string = '\u2013'; // En dash
      var regexp: RegExp = /\+|-|\*|\(|\{|\[|;/; // ?
      var alphanumRegexp: RegExp = /[a-z,0-9]/i;
      var chars: string[] = str.split('');
      var i: number;
      var length: number = chars.length;

      for (i = 0; i < length - 1; i++) {
        if (i === 0) {
          if (chars[i] === search) {
            if (chars[i + 1] === search) {
              chars[i + 1] = replacement;
            } else {
              chars[i] = replacement;
            }
          }
        } else if (
          chars[i] === search &&
          chars[i + 1].match(alphanumRegexp) &&
          chars[i - 1] !== '^' &&
          chars[i - 1].match(regexp)
        ) {
          chars[i] = replacement;
        } else if (
          chars[i] === search &&
          chars[i + 1].match(alphanumRegexp) &&
          i !== 1
        ) {
          if (chars[i - 1] === ' ' || chars[i - 1] === '\\ ') {
            if (chars[i - 2].match(regexp)) {
              chars[i] = replacement;
            }
          }
        }
      }

      str = chars.join('');
    }
  }

  return str;
}
