/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'mastery'}</title>
    <circle fill="#008bb2" cx={256} cy={256} r={256} />
    <path
      fill="#ffd500"
      d="M256 96.001c-3.65.008-6.648 2.62-8.957 7.823l-41.099 87.174-91.696 14.021c-6.828 1.125-10.248 4.057-10.248 8.792 0 2.672 1.512 5.761 4.555 9.193l66.5 67.822-15.71 95.79c-.231 1.775-.337 3.046-.337 3.838 0 2.672.634 4.916 1.898 6.797 1.277 1.847 3.213 2.749 5.765 2.749 2.184 0 4.623-.758 7.316-2.272l82.013-45.2V96.001z"
    />
    <path
      fill="#ebb200"
      d="m397.744 205.004-91.689-14.006-41.102-87.174C262.64 98.607 259.646 96 256.016 96l-.017.001v256.527l.017-.009 82.001 45.208c2.555 1.515 5.01 2.272 7.317 2.272 5.003 0 7.489-3.188 7.489-9.556 0-1.666-.077-2.979-.183-3.847l-15.702-95.79 66.301-67.815c3.176-3.297 4.76-6.408 4.76-9.197.001-4.733-3.417-7.668-10.255-8.79z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
