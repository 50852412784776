import _ from 'lodash';

export default (value: string | number | undefined): number | undefined => {
  if (value === undefined) {
    return value;
  }

  if (_.isNumber(value)) {
    return value;
  }

  let string = value.trim();

  if (string.length) {
    return parseInt(string, 10);
  }
};
