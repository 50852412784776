import spliceString from './spliceString';

export default function filterTrigFunctions(str: string): string {
  var matches = str.match(
    /([a-zA-Z]+(tan|cos|pi|sin))|((tan|cos|pi|sin)[a-zA-Z]+)/g
  );

  if (matches) {
    matches.forEach((match) => {
      let replacement = '' + match;
      const length = match.length + (match.length - 1);

      for (let i = 1; i < length; i += 2) {
        replacement = spliceString(replacement, i, '*');
      }

      str = str.replace(match, replacement);
    });
  }

  return str;
}
