import {yupResolver} from '@hookform/resolvers/yup';
import {IonButton} from '@ionic/react';
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {Page, Telephone, Message} from '../../../core/components';
import {
  selectDiallingCodeMap,
  selectDiallingCodes
} from '../../../core/models/country/country.slice';
import yup from '../../../core/vendor/yup';

const TelephonePage: React.FC = () => {
  const diallingCodeMap = useSelector(selectDiallingCodeMap);
  const diallingCodes = useSelector(selectDiallingCodes);
  const diallingCodeAssert = yup
    .string()
    .ensure()
    .required('Required')
    .oneOf(diallingCodes, 'Must be a valid dialling code');

  const schema = yup.object({
    empty_telephone_dialling_code: diallingCodeAssert,
    empty_telephone_national_number: yup
      .string()
      .required('Required')
      .when('empty_telephone_dialling_code', (dialling_code, schema) =>
        schema.phone(diallingCodeMap[dialling_code])
      ),
    completed_telephone_dialling_code: diallingCodeAssert,
    completed_telephone_national_number: yup
      .string()
      .required('Required')
      .when('completed_telephone_dialling_code', (dialling_code, schema) =>
        schema.phone(diallingCodeMap[dialling_code])
      ),
    disabled_telephone_dialling_code: diallingCodeAssert,
    disabled_telephone_national_number: yup
      .string()
      .required('Required')
      .when('disabled_telephone_dialling_code', (dialling_code, schema) =>
        schema.phone(diallingCodeMap[dialling_code])
      )
  });
  const form = useForm({
    defaultValues: {
      empty_telephone_dialling_code: null,
      empty_telephone_national_number: '',
      completed_telephone_dialling_code: '27',
      completed_telephone_national_number: '0831112222',
      disabled_telephone_dialling_code: '27',
      disabled_telephone_national_number: '0831112222'
    },
    resolver: yupResolver(schema)
  });
  const errors = Object.entries(form.formState.errors).map(([field, error]) => [
    field,
    error.message
  ]);
  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
  };

  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Telephone</h1>

        <div className="tw-not-prose">
          {!!errors.length && (
            <>
              {errors.map((error) => (
                <Message key={error[0]} color="danger">
                  {error[0]}: {error[1]}
                </Message>
              ))}
              <br />
            </>
          )}

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Telephone
                name="empty_telephone"
                label="Empty telephone"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <Telephone
                name="completed_telephone"
                label="Completed telephone"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <Telephone
                name="disabled_telephone"
                label="Disabled telephone"
                helper="Lorem ipsum dolor sit amet"
                disabled
              />
              <br />
              <IonButton type="submit">Submit</IonButton>
            </form>
          </FormProvider>
        </div>
      </article>
    </Page>
  );
};

export default TelephonePage;
