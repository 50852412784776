import filterSqrt from './filterSqrt';

export default function filterBrackets(input: string): string {
  // Preserve content that will be transformed by `filterSqrt` by temporarily replacing it
  // with a token. At the end of this function, the token is replaced with the original content.
  // This is needed because both filter functions operate on "sqrt" values.
  var output: string = filterSqrt(input, '__ROOT__');

  var test_caret: boolean = /\^[\(]+/g.test(output);
  var test_frac: boolean = /\/[\(]+/g.test(output);
  var test_sqrt: boolean = /sqrt[\(]+/g.test(output);
  var test_multiple_caret: RegExpMatchArray | null = output.match(/\^[\(]+/g);
  var test_multiple_frac: RegExpMatchArray | null = output.match(/\/[\(]+/g);
  var test_multiple_sqrt: RegExpMatchArray | null = output.match(/sqrt[\(]+/g);
  var test_curly_brackets: boolean = /{/g.test(output);
  var test_square_brackets: boolean = /\[/g.test(output);

  if (!/\^|\/|sqrt/g.test(output)) {
    return input;
  }
  if (test_caret && test_frac) {
    return input;
  }
  if (test_caret && test_sqrt) {
    return input;
  }
  if (test_frac && test_sqrt) {
    return input;
  }
  if (test_caret && test_sqrt && test_frac) {
    return input;
  }
  if (test_multiple_sqrt) {
    if (test_multiple_sqrt.length > 1) {
      return input;
    }
  }
  if (test_multiple_frac) {
    if (test_multiple_frac.length > 1) {
      return input;
    }
  }
  if (test_multiple_caret) {
    if (test_multiple_caret.length > 1) {
      return input;
    }
  }
  if (test_square_brackets) {
    return input;
  }
  if (test_curly_brackets) {
    return input;
  }

  var operator: string;

  if (test_caret) {
    operator = '^';
  } else if (test_frac) {
    operator = '/';
  } else if (test_sqrt) {
    operator = 'sqrt';
  } else {
    return input;
  }

  var strLen: number = output.length;
  var strParts: string[] = output.split(operator);
  var strPartsLen: number = strParts.length;
  var i: number;
  var j: number;
  var openingBrackets: number = 0;
  var closingBracket: string = ')';
  var tempStr: string;
  var rebuiltExpressionArr: string[] = [];

  for (i = 0; i < strPartsLen; i++) {
    /*
     * The string is split by operator. Each chunk now needs to be examined
     * for brackets.
     */
    tempStr = strParts[i];

    if (tempStr) {
      /*
       * Opening brackets increment `openingBrackets` by 1 and closing
       * brackets decrement it by 1. This helps us know if the opening
       * brackets have a corresponding closing bracket.
       */
      for (j = 0; j < strLen; j++) {
        if (tempStr[j] === '(') {
          openingBrackets += 1;
        } else if (tempStr[j] === ')') {
          openingBrackets -= 1;
          /*
           * As soon as all the opening brackets are matched we can assume
           * the fraction or exponent is over.
           */
          if (openingBrackets === 0) {
            break;
          }
        }
      }

      /*
       * If we did not find sufficient closing brackets we must not add a
       * closing bracket.
       */
      if (openingBrackets > 0) {
        closingBracket = '';
      } else {
        closingBracket = ')';
      }

      /*
       * If there are brackets and we are not at the start of the chunk then
       * we can add brackets appropriately. This tests to see if the
       * brackets go around the whole chunk or just a part of the chunk.
       */
      if (/\(/.test(tempStr) && i > 0) {
        if (j === tempStr.length) {
          rebuiltExpressionArr.push('(' + tempStr + closingBracket);
        } else {
          rebuiltExpressionArr.push(
            '(' + tempStr.substr(0, j) + closingBracket + tempStr.substr(j)
          );
        }
      } else {
        rebuiltExpressionArr.push(tempStr);
      }
    }
  }

  var rebuiltExpressionArrLen = rebuiltExpressionArr.length;

  if (!rebuiltExpressionArrLen) {
    return input;
  }

  if (rebuiltExpressionArrLen === 1 && operator === 'sqrt') {
    output = operator + rebuiltExpressionArr[0];
  } else {
    output = rebuiltExpressionArr.join(operator);
  }

  output = output.replace(/__ROOT__/g, 'sqrt');

  return output;
}
