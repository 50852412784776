/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'star'}</title>
    <path
      fill="#ffd500"
      d="M512 198.39c.001-7.971-5.755-12.914-17.271-14.804l-154.426-23.589-69.224-146.819C267.185 4.391 262.142 0 256.027 0l-.028.001c-6.146.014-11.196 4.414-15.084 13.176l-69.22 146.819L17.26 183.611C5.76 185.506 0 190.444 0 198.418c0 4.5 2.547 9.703 7.672 15.483l112 114.227-26.459 161.331c-.39 2.988-.568 5.13-.568 6.464 0 4.5 1.068 8.279 3.196 11.447 2.151 3.11 5.412 4.63 9.71 4.63 3.679 0 7.786-1.276 12.321-3.827L256 432.046v-.001l.026-.012 138.107 76.138c4.304 2.553 8.438 3.827 12.324 3.827 8.426 0 12.613-5.37 12.613-16.094 0-2.806-.13-5.018-.31-6.479l-26.445-161.329L503.983 213.88c5.349-5.553 8.017-10.793 8.017-15.49z"
    />
    <path
      fill="#ebb200"
      opacity={0.8}
      d="m494.729 183.585-154.426-23.589-69.224-146.818C267.185 4.391 262.142 0 256.027 0L256 .001v432.045l.001-.001v-.001l.026-.012 138.107 76.138c4.304 2.553 8.438 3.827 12.324 3.827 8.426 0 12.613-5.37 12.613-16.094 0-2.806-.13-5.018-.31-6.479l-26.445-161.329L503.983 213.88c5.349-5.553 8.017-10.792 8.017-15.49.001-7.972-5.755-12.914-17.271-14.805z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
