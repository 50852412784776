import {MathJax, MathJaxContext} from 'better-react-mathjax';
import React from 'react';
import {Page, PageHeader} from '../../../core/components';
import config from '../../../core/config';

const QuestionPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page">
        <div className="sv tw-max-w-prose">
          <div className="sv-question">
            <MathJaxContext {...config.mathjaxContext}>
              <MathJax>
                <PageHeader
                  heading="Sed in turpis in sem lacinia (🚧)"
                  subheading="Quisque bibendum odio sit amet tincidunt maximus"
                />
                <form>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: require('../../data/questions.html.json')
                    }}
                  />
                </form>
              </MathJax>
            </MathJaxContext>
          </div>
        </div>
      </article>
    </Page>
  );
};

export default QuestionPage;
