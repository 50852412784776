import spliceString from './spliceString';

export default function filterChemSubscripts(str: string): string {
  const matches = str.match(/[)a-z]+[0-9]+/gi);

  if (matches) {
    matches.forEach((match) => {
      // If a number follows a letter or closing bracket, prefix it with an underscore
      const firstDigit = match.match(/[0-9]/) as string[];
      const firstIndex = match.indexOf(firstDigit[0]);
      const splicedString = spliceString(match, firstIndex, '_');

      str = str.replace(match, splicedString);
    });
  }

  return str;
}
