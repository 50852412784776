import parseResponseType from './parseResponseType';
import filterUnicodeReplacements from './filterUnicodeReplacements';
import filterMinusLessThan from './filterMinusLessThan';
import filterMinusGreaterThan from './filterMinusGreaterThan';
import filterBackSlash from './filterBackSlash';
import filterLiteralTimes from './filterLiteralTimes';
import filterChemTypes from './filterChemTypes';
import filterNumericalMinus from './filterNumericalMinus';
import filterPipes from './filterPipes';
import filterBrackets from './filterBrackets';
import filterMultipleExponents from './filterMultipleExponents';
import filterUnionSymbol from './filterUnionSymbol';
import filterEToTimesTen from './filterEToTimesTen';
import filterXToTimes from './filterXToTimes';
import filterAsteriskToTimes from './filterAsteriskToTimes';
import filterSpecSuperscripts from './filterSpecSuperscripts';
import filterText from './filterText';
import filterAllTypes from './filterAllTypes';
import filterLessThanGreaterThan from './filterLessThanGreaterThan';

import {
  CHEMCOMPOUND,
  CHEMEQUATION,
  COORDINATE,
  ELECTROCHEMCELL,
  EQUATION,
  EXPRESSION,
  FRACTION,
  INTERVAL,
  NUMERIC,
  POLYNOMIAL,
  SPECTROSCOPIC,
  STRING
} from './constants';

export default function filter(value: string, responseType: string) {
  const {type} = parseResponseType(responseType);

  value = filterUnicodeReplacements(value);

  // We call `filterMinusLessThan` here rather than at the end of this
  // function in `filterAllTypes` so that left arrows in chem response
  // types are handled correctly.
  value = filterMinusLessThan(value);

  // We replace the '->' with '- >' for non-chemistry response types
  // We also replace ge and le for non-chemistry response types
  if (
    type !== CHEMCOMPOUND &&
    type !== CHEMEQUATION &&
    type !== ELECTROCHEMCELL
  ) {
    value = filterLessThanGreaterThan(value);
    value = filterMinusGreaterThan(value);
  }

  if (type !== ELECTROCHEMCELL) {
    value = filterBackSlash(value);
  }

  value = filterLiteralTimes(value);

  switch (type) {
    case CHEMCOMPOUND:
      value = filterChemTypes(value);
      break;

    case CHEMEQUATION:
      value = filterChemTypes(value);
      break;

    case COORDINATE:
      value = filterNumericalMinus(value);
      break;

    case ELECTROCHEMCELL:
      value = filterPipes(value);
      value = filterChemTypes(value);
      break;

    case EQUATION:
      value = filterBrackets(value);
      value = filterMultipleExponents(value);
      value = filterNumericalMinus(value);
      break;

    case EXPRESSION:
      value = filterBrackets(value);
      value = filterMultipleExponents(value);
      value = filterNumericalMinus(value);
      break;

    case FRACTION:
      value = filterNumericalMinus(value);
      break;

    case INTERVAL:
      value = filterUnionSymbol(value);
      value = filterNumericalMinus(value);
      break;

    case NUMERIC:
      value = filterBrackets(value);
      value = filterEToTimesTen(value);
      value = filterXToTimes(value);
      value = filterAsteriskToTimes(value);
      value = filterNumericalMinus(value);
      value = filterMultipleExponents(value);
      break;

    case POLYNOMIAL:
      value = filterNumericalMinus(value);
      value = filterBrackets(value);
      break;

    case SPECTROSCOPIC:
      value = filterSpecSuperscripts(value);
      value = filterBackSlash(value);
      // These filters MUST be called last and in this order. Don't question it, just do it.
      value = filterText(value);
      break;

    case STRING:
      value = filterText(value);
      break;
  }

  value = filterAllTypes(value);

  return value;
}
