import {IonBadge, IonItem, IonLabel} from '@ionic/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {selectSession} from '../../auth/auth.slice';
import {Page} from '../../core/components';
import logHistory from '../../core/utils/logHistory';
import {selectToken} from '../../push-notifications/push-notifications.slice';

const LearnerHomePage: React.FC = () => {
  // logHistory('LearnerHomePage');
  const {user_profile_general} = useSelector(selectSession);
  const storedToken = useSelector(selectToken);

  return (
    <Page meta={{title: 'Home'}}>
      <div className="tw-page">
        <section>
          <h1 className="tw-mb-2 tw-text-3xl tw-font-bold lg:tw-text-4xl">
            Welcome, {user_profile_general?.name}!
          </h1>
        </section>
        <section className="tw-my-6 tw-rounded tw-bg-grey-100 tw-p-4">
          <p className="tw-mt-0 tw-mb-4 tw-uppercase">Testing</p>
          <IonItem>
            <IonLabel>Push notifications</IonLabel>
            {!storedToken && <IonBadge color="danger">No FCM token</IonBadge>}
            {!!storedToken && (
              <IonBadge color="success">Has FCM token</IonBadge>
            )}
          </IonItem>
        </section>
      </div>
    </Page>
  );
};

export default LearnerHomePage;
