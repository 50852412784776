const operations: [RegExp, string][] = [
  // Filter the string for various Unicode parentheses
  [/（/g, '('],
  [/）/g, ')'],
  [/﹙/g, '('],
  [/﹚/g, ')'],
  [/⟮/g, '('],
  [/⟯/g, ')'],

  // Replace minus with hyphen-minus.
  [/\u2212/g, '-'], // minus

  // Replace asterisk operator with asterisk.
  [/\u2217/g, '*'], // asterisk operator

  // Tidy up superscripts
  [/\^¹/g, '^1'],
  [/\^²/g, '^2'],
  [/\^³/g, '^3'],
  [/\^⁴/g, '^4'],
  [/\^⁵/g, '^5'],
  [/\^⁶/g, '^6'],
  [/\^⁷/g, '^7'],
  [/\^⁸/g, '^8'],
  [/\^⁹/g, '^9'],

  /**
   * Replace Unicode space characters with equivalent ASCII characters.
   * For future we should have one function that replaces all Unicode characters
   * This may need to be combined with the replace spaces function
   * Although the tests show this should work in reality these are first getting translated to \\text{u}_xxxx
   * (xxxx is the character code) so e.g. \u000A becomes \\text{u}_000A
   */
  // Strip newline characters.
  [/\u000A/g, ''], // line feed (\n)
  [/\u000C/g, ''], // form feed (\f)
  [/\u000D/g, ''], // carriage return (\r)
  [/\u0085/g, ''], // next line
  [/\u2028/g, ''], // line separator
  [/\u2029/g, ''], // paragraph separator

  // Replace with space character.
  [/\s/g, ' '],

  // Strip.
  [/\u007F/g, ''], // delete

  // Strip multiple consecutive spaces.
  [/ {2,}/g, ' ']
];

export default function filterUnicodeReplacements(str: string): string {
  return operations.reduce(
    (result, operation) => result.replace(...operation),
    str
  );
}
