import React from 'react';
import {Page} from '../../../core/components';
import {Code} from '../../components';
import Note from '../../components/Note';

const GridsPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Responsive Design and Grids</h1>

        <h2>Breakpoints</h2>

        <div className="tw-not-prose">
          <table className="sv-table">
            <thead>
              <tr>
                <td>Prefix</td>
                <td>Minimum width</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>sm</td>
                <td>576px</td>
              </tr>
              <tr>
                <td>md</td>
                <td>768px</td>
              </tr>
              <tr>
                <td>lg</td>
                <td>992px</td>
              </tr>
              <tr>
                <td>xl</td>
                <td>1200px</td>
              </tr>
              <tr>
                <td>2xl</td>
                <td>1536px</td>
              </tr>
            </tbody>
          </table>

          <Note type="info">
            <a
              href="https://tailwindcss.com/docs/responsive-design"
              rel="noopener noreferrer"
              target="_blank"
            >
              Tailwind - Responsive design
            </a>
          </Note>
        </div>

        <h2>Grids</h2>

        <div className="tw-not-prose">
          <p>
            A responsive grid that has 3 columns by default (mobile-first) and 6
            columns at medium breakpoint:
          </p>

          <div className="tw-grid tw-grid-cols-3 tw-gap-2 md:tw-grid-cols-6">
            <div className="tw-bg-pink-100 tw-p-2">01</div>
            <div className="tw-bg-pink-100 tw-p-2">02</div>
            <div className="tw-bg-pink-100 tw-p-2">03</div>
            <div className="tw-bg-pink-100 tw-p-2">04</div>
            <div className="tw-bg-pink-100 tw-p-2">05</div>
            <div className="tw-bg-pink-100 tw-p-2">06</div>
            <div className="tw-bg-pink-100 tw-p-2">07</div>
            <div className="tw-bg-pink-100 tw-p-2">08</div>
            <div className="tw-bg-pink-100 tw-p-2">09</div>
            <div className="tw-bg-pink-100 tw-p-2">10</div>
            <div className="tw-bg-pink-100 tw-p-2">11</div>
            <div className="tw-bg-pink-100 tw-p-2">12</div>
          </div>

          <Code
            code={`
<div className="tw-grid tw-grid-cols-3 tw-gap-4 md:tw-grid-cols-6">
  <div>01</div>
  <div>02</div>
  <div>03</div>
  ...
  <div>10</div>
  <div>11</div>
  <div>12</div>
</div>
`}
            language="jsx"
          />

          <Note type="info">
            <a
              href="https://tailwindcss.com/docs/display#grid"
              rel="noopener noreferrer"
              target="_blank"
            >
              Tailwind - Display: Grid
            </a>
          </Note>

          <Note type="do">
            Use the Tailwind utility classes, not the Ionic grid component.
          </Note>
        </div>
      </article>
    </Page>
  );
};

export default GridsPage;
