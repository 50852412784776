import {Route} from 'react-router-dom';
import PrivateRoute from '../core/pages/PrivateRoute';
import PracticeDashboardPage from './pages/PracticeDashboardPage';
import RedirectToSubjectGrade from './pages/RedirectToSubjectGrade';
import {
  PATH_PRACTICE_DASHBOARD_REDIRECT_TO_GRADE,
  PATH_PRACTICE_DASHBOARD_SUBJECT_GRADE
} from './practice-dashboard.paths';

const routes = [
  <Route exact path={PATH_PRACTICE_DASHBOARD_REDIRECT_TO_GRADE}>
    <RedirectToSubjectGrade />
  </Route>,
  <Route exact path={PATH_PRACTICE_DASHBOARD_SUBJECT_GRADE}>
    <PrivateRoute requiresLogin>
      <PracticeDashboardPage />
    </PrivateRoute>
  </Route>
];

export default routes;
