/**
 * NOTE ion-datetime's change event handling causes an infinite loop. The suggested workarounds are
 * to add a key attribute with the current value and to compare the values before calling RHF.
 * - https://github.com/ionic-team/ionic-framework/issues/20106
 * - https://github.com/ionic-team/ionic-framework/pull/25858
 *
 * NOTE ion-datetime throws a warning about being unable to parse date string when setting an array
 * of strings as a value and multiple=true. That's how it's meant to be used according to the docs:
 * - https://ionicframework.com/docs/api/datetime#value
 */
import type {DatetimePresentation} from '@ionic/core';
import {getConfig, IonDatetime, IonItem, IonLabel, IonNote} from '@ionic/react';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import isArrayEqual from '../../utils/isArrayEqual';
import './DatetimePicker.md.scss';

type DatetimePickerProps = {
  disabled?: boolean;
  helper?: string;
  label?: string;
  lines?: 'full' | 'inset' | 'none';
  multiple?: boolean;
  name: string;
  presentation: DatetimePresentation;
};

const DatetimePicker: React.FC<DatetimePickerProps> = (props) => {
  const {
    control,
    formState: {errors}
  } = useFormContext();
  const {
    disabled,
    helper,
    label,
    lines = 'none',
    multiple,
    name,
    presentation
  } = props;
  const error = errors[name]?.message;
  const mode = getConfig()?.get('mode') || 'md';
  const position = mode === 'md' ? 'stacked' : undefined;

  return (
    <IonItem className={clsx({'has-error': error})} lines={lines}>
      {label && <IonLabel position={position}>{label}</IonLabel>}
      <Controller
        control={control}
        name={name}
        render={({field: {value, onBlur, onChange}}) => (
          <IonDatetime
            // key={value}
            color={error ? 'danger' : undefined}
            disabled={disabled}
            multiple={multiple}
            presentation={presentation}
            value={_.isArray(value) ? (value.length ? value : null) : value}
            onIonBlur={onBlur}
            onIonChange={(e) => {
              if (multiple) {
                if (
                  _.isArray(value) &&
                  _.isArray(e.detail.value) &&
                  !isArrayEqual(value, e.detail.value)
                ) {
                  onChange(e);
                }
              } else {
                onChange(e);
              }
            }}
          />
        )}
      />
      {error && (
        <IonNote color="danger" slot="helper">
          {error}
        </IonNote>
      )}
      {!error && helper && <IonNote slot="helper">{helper}</IonNote>}
    </IonItem>
  );
};

export default DatetimePicker;
