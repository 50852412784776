import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React from 'react';
import config from '../../config';
import type {PageMeta} from '../../types';

const Page: React.FC<{
  progress?: boolean | number;
  meta?: PageMeta;
}> = (props) => {
  const {progress, children} = props;
  const meta = {
    ...config.pageMeta,
    ...props.meta
  };
  const title = typeof meta.title === 'string' ? meta.title : meta.title.short;

  let progressType: 'determinate' | 'indeterminate';
  let progressValue: number;

  if (progress === undefined) {
    progressType = 'indeterminate';
    progressValue = 100;
  } else if (progress === true) {
    progressType = 'indeterminate';
    progressValue = 100;
  } else if (progress === false) {
    progressType = 'indeterminate';
    progressValue = 0;
  } else {
    progressType = 'determinate';
    progressValue = progress;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          {progressValue < 100 && (
            <IonProgressBar
              type={progressType}
              value={progressValue}
            ></IonProgressBar>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default Page;
