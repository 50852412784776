import type React from 'react';
import logHistory from '../../core/utils/logHistory';
import {useLogout} from '../auth.hooks';

const LogoutPage: React.FC = () => {
  // logHistory('LogoutPage');

  useLogout();

  return null;
};

export default LogoutPage;
