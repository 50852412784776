import {REGION} from '../region/region';
import {SUBJECT, SUBJECT_NAME} from '../subject/subject';

export type RegionSubjectGrade = {
  id: number | string; // INTL has string ID
  region: REGION;
  subject_id: SUBJECT;
  subject_name: SUBJECT_NAME;
  subject_short_name: string;
  subject_full_name: string;
  subject_icon_name: string;
  grade: number | null; // INTL has null grade
  grade_name: string | null; // INTL has null grade
  grade_short_name: string | null; // INTL has null grade
  grade_full_name: string | null; // INTL has null grade
};

export function getNearestGrade(grades: number[], preferredGrade?: number) {
  if (preferredGrade) {
    // Find grades lower or equal to preferred grade, and return highest of those
    const eligibleGrades = grades
      .filter((d) => d <= preferredGrade)
      .sort((a, b) => a - b);

    if (eligibleGrades.length) {
      return eligibleGrades.slice(-1)[0];
    }
  }

  return grades[0]; // Default to lowest grade
}
