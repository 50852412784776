import {combineReducers} from 'redux';
import {demoApi, siyavulaApi} from './api';
import authReducer from '../auth/auth.slice';
import activityReducer from '../activity/activity.slice';
import countriesReducer from './models/country/country.slice';
import curriculumsReducer from './models/curriculum/curriculum.slice';
import pushNotificationsReducer from '../push-notifications/push-notifications.slice';
import regionSubjectGradesReducer from './models/region-subject-grade/region-subject-grade.slice';
import regionsReducer from './models/region/region.slice';
import subjectsReducer from './models/subject/subject.slice';
import gradesReducer from './models/grade/grade.slice';

export default combineReducers({
  [demoApi.reducerPath]: demoApi.reducer,
  [siyavulaApi.reducerPath]: siyavulaApi.reducer,
  auth: authReducer,
  activity: activityReducer,
  countries: countriesReducer,
  curriculums: curriculumsReducer,
  pushNotifications: pushNotificationsReducer,
  regionSubjectGrades: regionSubjectGradesReducer,
  regions: regionsReducer,
  subjects: subjectsReducer,
  grades: gradesReducer
});
