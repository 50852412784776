/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'beta'}</title>
    <path
      fill="#035E82"
      d="M456.48 113.342 279.52 11.381C272.352 7.244 264.288 5.12 256 5.12s-16.352 2.124-23.521 6.261L55.52 113.342A46.983 46.983 0 0 0 32 154.037v203.925c0 16.771 9.072 32.312 23.52 40.693l176.96 101.963c7.169 4.137 15.345 6.262 23.521 6.262 8.288 0 16.353-2.125 23.521-6.262L456.48 398.655A46.974 46.974 0 0 0 480 357.962V154.037c0-16.769-9.07-32.309-23.52-40.695z"
    />
    <path
      fill="#FFD500"
      d="M65.505 130.671C57.174 135.47 52 144.422 52 154.037v203.925c0 9.579 5.194 18.543 13.556 23.394L242.465 483.29c4.075 2.352 8.751 3.591 13.535 3.591V25.121c-4.854 0-9.404 1.205-13.525 3.583L65.505 130.671z"
    />
    <path
      fill="#EBB200"
      d="M446.441 130.64 269.535 28.711c-4.133-2.384-8.682-3.59-13.535-3.59V486.88h.001c4.853 0 9.401-1.206 13.522-3.584l176.972-101.97c8.332-4.799 13.505-13.749 13.505-23.364V154.037c0-9.578-5.196-18.543-13.559-23.397z"
    />
    <path
      fill="#035E82"
      d="M343.786 171.804c1.329-17.296-3.531-31.397-14.585-42.296-11.054-10.901-25.998-16.555-44.829-16.965A77.298 77.298 0 0 0 256 117.149v34.718c.12-.108.234-.22.356-.328 7.421-6.549 16.044-9.723 25.871-9.519 8.289.411 14.684 3.431 19.188 9.059 4.503 5.63 6.295 12.948 5.373 21.953-1.127 10.747-5.017 19.089-11.668 25.025-6.655 5.937-15.354 9.007-26.101 9.211l-10.746-.155L256 223.194v12.181l18.545.14c9.006.513 15.787 4.351 20.344 11.514 4.553 7.166 6.115 15.917 4.682 26.253-1.637 11.667-6.627 21.163-14.967 28.479-8.039 7.052-17.575 10.693-28.604 10.951v29.497c1.156.073 2.321.131 3.5.161 13.51.305 25.869-2.383 37.076-8.063s20.241-13.867 27.097-24.563c6.856-10.694 10.798-22.85 11.821-36.463.82-11.975-1.175-22.721-5.987-32.24-4.811-9.519-12.026-16.631-21.646-21.34 22.516-11.972 34.489-27.939 35.925-47.897z"
    />
    <g fill="#008BB2">
      <path d="M256 312.713c-.418.01-.835.025-1.258.025-14.842-.203-26.049-4.4-33.622-12.588l21.342-123.126c1.812-10.268 6.328-18.651 13.538-25.157v-34.718a82.71 82.71 0 0 0-7.86 3.3c-11.567 5.58-21.086 13.383-28.556 23.413-7.472 10.031-12.028 21.135-13.664 33.314L168 399.482h36.231l13.356-70.314c11.001 7.776 23.808 12.119 38.413 13.042v-29.497z" />
      <path d="M256 235.375v-12.181l-1.72 12.168z" />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
