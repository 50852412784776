import React from 'react';
import type {Emoji} from './emoji';
import shortcodeToEmoji from './utils/shortcodeToEmoji';

type Props = {
  emoji?: Emoji;
  shortcode: string;
  slot?: 'start' | 'end';
};

const EmojiComponent: React.FC<Props> = (props) => {
  const {shortcode, slot} = props;
  const emoji = props.emoji || shortcodeToEmoji(shortcode);

  if (!emoji) {
    return <span slot={slot}>{shortcode}</span>;
  }

  return (
    <img
      className="!tw-my-0 tw-inline-block tw-h-5 tw-w-5 tw-overflow-visible tw-align-middle tw-leading-none"
      src={require(`./img/u${emoji.image}.png`)}
      alt={emoji.name}
      slot={slot}
    />
  );
};

export default React.memo(EmojiComponent);
