import {createSelector, createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../core/store';
import type {Nullable} from '../core/types';
import type {Activity} from '../core/models/activity/activity';
import type {Book} from '../core/models/book/book';
import type {Chapter} from '../core/models/chapter/chapter';
import type {Response} from '../core/models/response/response';
import type {Section} from '../core/models/section/section';

export type UnsequencedActivityState = Activity & {
  is_sequenced: boolean;
};

export type SequencedActivityState = Activity & {
  is_sequenced: boolean;
  sequence_id: number;
};

export type ResponseState = Response & {
  response_index: number;
  repeat_attempt: boolean;
  complete: boolean;
  question_title: string;
  question_html: string;
  question_html_hash: string;
};

export type BookState = Book;

export type ChapterState = Chapter & {
  mastery: number;
};

export type SectionState = Section & {
  mastery: number;
};

type State = {
  activity: UnsequencedActivityState | SequencedActivityState;
  response: ResponseState;
  book: BookState;
  chapter: ChapterState;
  section: SectionState;
};

const slice = createSlice({
  name: 'activity',
  initialState: {
    activity: null,
    response: null
  } as Nullable<State>,
  reducers: {
    setActivity: (
      state,
      {
        payload: {activity, response, book, chapter, section}
      }: PayloadAction<State>
    ) => {
      state.activity = activity;
      state.response = response;
      state.book = book;
      state.chapter = chapter;
      state.section = section;
    }
  }
});

export const {setActivity} = slice.actions;

export default slice.reducer;

export const selectActivity = createSelector(
  (state: RootState) => state.activity,
  (activity) => activity
);
