import {ONE_OF} from './constants';

export default function parseResponseType(str: string): {
  type: string;
  modifier?: string;
} {
  if (str.indexOf(ONE_OF) === 0) {
    var match = str.match(/\((.*)\)/m);

    if (!match) {
      throw new Error('One-of response type ' + str + ' cannot be parsed');
    }

    str = match[1];
  }

  let type: string;
  let modifier: string | undefined;
  const index: number = str.indexOf('.');

  if (index > 0) {
    type = str.substring(0, index);
    modifier = str.substring(index + 1);
  } else {
    type = str;
    modifier = undefined;
  }

  return {
    type,
    modifier
  };
}
