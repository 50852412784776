import {IonButton} from '@ionic/react';
import {MathJax, MathJaxContext} from 'better-react-mathjax';
import jQuery from 'jquery';
import React, {useLayoutEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {PageHeader} from '../../core/components';
import config from '../../core/config';
// import scrollToNode from '../../core/utils/scrollToNode';
import {getDashboardUrl} from '../../core/models/activity/activity';
import {selectSubjectName} from '../../core/models/subject/subject.slice';
import compilePath from '../../core/utils/compilePath';
import LivePreview from '../../core/vendor/frontend-components/LivePreview/ts/LivePreview';
import {
  useAnswerPracticeQuestionMutation,
  useRetryPracticeQuestionMutation,
  useNextPracticeQuestionMutation
} from '../activity.api';
import {PATH_UNSEQUENCED_QUESTION} from '../activity.paths';
import type {
  UnsequencedActivityState,
  ResponseState,
  BookState,
  SectionState
} from '../activity.slice';
import {setActivity} from '../activity.slice';
import '../../core/vendor/frontend-components/Question/css/Question.css';

declare global {
  interface Window {
    MathJax?: any;
  }
}

function initHiddenCheckbox(
  $checkbox: JQuery.PlainObject,
  $button: JQuery.PlainObject
): void {
  if (!$checkbox.length || !$button.length) {
    return;
  }

  $checkbox.off('click').on('click', function (this: JQuery.PlainObject) {
    const nextChecked = !jQuery(this).is(':checked');
    let iconName, label;

    if (nextChecked) {
      iconName = $button.data('showIconName');
      label = $button.data('showLabel');
    } else {
      iconName = $button.data('hideIconName');
      label = $button.data('hideLabel');
    }

    const icon = `<span class="icon icon-${iconName} sv-button__icon--left" aria-hidden="true" title=""></span>`;

    $button.html(`${icon}${label}`);
  });
}

function initHowToWriteAnswer(form: HTMLFormElement | null): void {
  if (!form) {
    return;
  }

  console.log('initHowToWriteAnswer');

  const $checkbox = jQuery('.how-to-write__state', form);

  initHiddenCheckbox($checkbox, $checkbox.siblings('.how-to-write__button'));
}

function initSolution(form: HTMLFormElement | null): void {
  if (!form) {
    return;
  }

  console.log('initSolution');

  jQuery('.response-solution__state', form).each(function () {
    const $checkbox = jQuery(this);

    initHiddenCheckbox(
      $checkbox,
      $checkbox.siblings('.response-solution__button')
    );
  });
}

function initLivePreview(
  form: HTMLFormElement | null,
  response_index: number
): void {
  console.log('initLivePreview 1');

  if (!form || !window.MathJax?.Hub?.Register) {
    return;
  }

  console.log('initLivePreview 2');

  const $response = jQuery(`[data-response-index="${response_index}"]`, form);
  // const $body = jQuery('.response-query-body', $response);
  const $inputs = jQuery('.response-query input[type="text"]', $response);
  const $types = jQuery('.response-type', $response);
  const $preview = jQuery(
    `.sv-live-preview[data-response-index="${response_index}"]`,
    form
  );

  if (!$inputs.length || !$types.length || !$preview.length) {
    return;
  }

  // const $preview = jQuery('<span hidden />');
  // $body.append($preview);

  new LivePreview({
    responseTypeElms: $types,
    responseInputElms: $inputs,
    previewElm: $preview
  }).init();
}

const Question: React.FC<{
  activity: UnsequencedActivityState;
  response: ResponseState;
  book?: BookState | null;
  section?: SectionState | null;
}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {activity, response, book, section} = props;
  const {id: activity_id} = activity;
  const subjectName = useSelector(selectSubjectName(book?.subject_id));

  const dashboardUrl =
    book && subjectName
      ? getDashboardUrl(activity.activity_type, subjectName, book.grade)
      : undefined;

  const {
    id: response_id,
    response_index,
    question_title,
    question_html,
    question_html_hash
  } = response;
  const formRef = useRef(null);
  const [
    answerPracticeQuestion,
    {error: answerError, isLoading: answerIsLoading}
  ] = useAnswerPracticeQuestionMutation();
  const [
    retryPracticeQuestion,
    {error: retryError, isLoading: retryIsLoading}
  ] = useRetryPracticeQuestionMutation();
  const [nextPracticeQuestion, {error: nextError, isLoading: nextIsLoading}] =
    useNextPracticeQuestionMutation();

  useLayoutEffect(() => {
    initHowToWriteAnswer(formRef.current);
    initSolution(formRef.current);
    initLivePreview(formRef.current, response_index);

    // if (formRef.current) {
    //   scrollToNode(jQuery('a[name="now"]', formRef.current));
    // }
  }, [response_id, response_index, question_html_hash]);

  const onAnswer = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (answerIsLoading) {
      return;
    }

    const $form = jQuery(e.target);
    const $inputs = jQuery(
      '.response-query input, .response-query select',
      $form
    );
    const $checkAnswerButton = jQuery('.check-answer-button', $form);
    const $howToWriteCheckbox = jQuery('.how-to-write__state', $form);

    $inputs.prop('readonly', true);
    $checkAnswerButton.prop('disabled', 'disabled');
    $howToWriteCheckbox.prop('disabled', 'disabled');

    const body = Object.fromEntries(
      $inputs
        .not('[name*="|readonly"]')
        .serializeArray()
        .map((d) => [d.name, d.value])
    );

    await answerPracticeQuestion({
      activity_id: activity_id,
      response_id: response_id,
      body
    })
      .unwrap()
      .then((data) => {
        dispatch(setActivity(data));
        history.replace(
          compilePath(PATH_UNSEQUENCED_QUESTION, {
            activity_id: data.activity.id,
            response_id: data.response.id,
            response_index: data.response.response_index
          })
        );
      })
      .catch(() => {}); // No-op because error returned by mutation will be surfaced
  };

  const onRetry = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    await retryPracticeQuestion({
      activity_id: activity_id,
      response_id: response_id
    })
      .unwrap()
      .then((data) => {
        dispatch(setActivity(data));
        history.replace(
          compilePath(PATH_UNSEQUENCED_QUESTION, {
            activity_id: data.activity.id,
            response_id: data.response.id,
            response_index: data.response.response_index
          })
        );
      })
      .catch(() => {}); // No-op because error returned by mutation will be surfaced
  };

  const onNext = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    await nextPracticeQuestion({
      activity_id: activity_id,
      response_id: response_id
    })
      .unwrap()
      .then((data) => {
        dispatch(setActivity(data));
        history.replace(
          compilePath(PATH_UNSEQUENCED_QUESTION, {
            activity_id: data.activity.id,
            response_id: data.response.id,
            response_index: data.response.response_index
          })
        );
      })
      .catch(() => {}); // No-op because error returned by mutation will be surfaced
  };

  // Key prop forces MathJax component to update
  return (
    <div className="sv">
      <div className="sv-question">
        <MathJaxContext
          {...config.mathjaxContext}
          onLoad={() => {
            initLivePreview(formRef.current, response_index);
          }}
        >
          <MathJax key={question_html_hash}>
            <PageHeader heading={question_title} subheading={section?.title} />
            <form ref={formRef} onSubmit={onAnswer}>
              <div dangerouslySetInnerHTML={{__html: question_html}} />
              {response.complete && (
                <div className="tw-grid tw-grid-flow-row tw-gap-2 sm:tw-grid-flow-col">
                  <IonButton onClick={onRetry} type="button" color="primary">
                    Try question again
                  </IonButton>
                  <IonButton onClick={onNext} type="button" color="secondary">
                    Go to next question
                  </IonButton>
                  {dashboardUrl && (
                    <IonButton
                      href={dashboardUrl}
                      type="button"
                      color="tertiary"
                    >
                      Go to dashboard
                    </IonButton>
                  )}
                </div>
              )}
            </form>
          </MathJax>
        </MathJaxContext>
      </div>
    </div>
  );
};

export default Question;
