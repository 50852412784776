import React from 'react';
import {Page, LanguagePicker} from '../../../core/components';

const LanguagePickerPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Language Picker</h1>

        <LanguagePicker />
      </article>
    </Page>
  );
};

export default LanguagePickerPage;
