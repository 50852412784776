import {useIonLoading, useIonToast} from '@ionic/react';
import {checkmarkCircle} from 'ionicons/icons';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/query/react';
import type {LoginRequest} from './auth.api';
import {useGetUserQuery, useLoginMutation, useLogoutMutation} from './auth.api';
import {PATH_AUTH_LOGIN} from './auth.paths';
import {selectSession, setUser, setSession, clearSession} from './auth.slice';
import {getHomeUrl} from '../core/models/user/user';

/**
 * If user is not authenticated and session storage has user ID and tokens, then verify these,
 * fetch the user and update the auth state.
 */
export const useRestoreUser = () => {
  const dispatch = useDispatch();
  const {is_authenticated, client_token, user_token, siyavula_id} =
    useSelector(selectSession);
  const {data} = useGetUserQuery(
    !is_authenticated && client_token && user_token && siyavula_id
      ? {siyavula_id}
      : skipToken
  );

  useEffect(() => {
    if (data) {
      dispatch(setUser(data));
    }
  }, [data, dispatch]);
};

// type LoginOptions = LoginRequest & {
//   returnTo?: string;
// };

// export const useLogin = (options: LoginOptions) => {
//   const {region, identifier, password, returnTo} = options;
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const {is_authenticated} = useSelector(selectSession);
//   const [login, {isLoading}] = useLoginMutation();

//   useEffect(() => {
//     if (isLoading || is_authenticated) {
//       return;
//     }
//     login({region, identifier, password})
//       .unwrap()
//       .then((data) => {
//         dispatch(setSession(data));
//         history.replace(returnTo || getHomeUrl(data.user));
//       });
//   });
// };

type LogoutOptions = {
  returnTo?: string;
};

export const useLogout = (options: LogoutOptions = {}) => {
  const {returnTo = PATH_AUTH_LOGIN} = options;
  const dispatch = useDispatch();
  const history = useHistory();
  const {is_authenticated, siyavula_id} = useSelector(selectSession);
  const [logout, {isLoading}] = useLogoutMutation();
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();

  useEffect(() => {
    if (isLoading || !is_authenticated || !siyavula_id) {
      return;
    }
    presentLoading({spinner: 'dots'});
    logout({siyavula_id}).then(() => {
      dispatch(clearSession());
      dismissLoading();
      presentToast({
        message: 'You’ve been logged out.',
        duration: 2000,
        color: 'success',
        icon: checkmarkCircle
      });
      history.replace(returnTo);
    });
  });
};
