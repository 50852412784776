export type Boundary = {
  value: number;
  inclusive: boolean;
};

export type Bin = {
  color: string;
  lower: Boundary;
  upper: Boundary;
};

export default (value: number, bins: Bin[]): Bin | undefined => {
  const matches = bins.filter((bin) => {
    const {lower, upper} = bin;

    if (lower.inclusive && upper.inclusive) {
      if (value >= lower.value && value <= upper.value) {
        return bin;
      }
    } else if (lower.inclusive && !upper.inclusive) {
      if (value >= lower.value && value < upper.value) {
        return bin;
      }
    } else if (!lower.inclusive && upper.inclusive) {
      if (value > lower.value && value <= upper.value) {
        return bin;
      }
    } else if (!lower.inclusive && !upper.inclusive) {
      if (value > lower.value && value < upper.value) {
        return bin;
      }
    }

    return false;
  });

  if (matches.length) {
    return matches[0];
  }

  return undefined;
};
