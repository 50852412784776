import filterCommas from './filterCommas';
import filterDoubleStar from './filterDoubleStar';
import filterFractionSlash from './filterFractionSlash';
import filterGreaterThanMinus from './filterGreaterThanMinus';
import filterLessThanMinus from './filterLessThanMinus';
import filterPlusMinus from './filterPlusMinus';
import filterSpaces from './filterSpaces';
import filterSqrt from './filterSqrt';
import filterTerminalCaret from './filterTerminalCaret';
import filterTrigFunctions from './filterTrigFunctions';
import filterUnicodeSqrt from './filterUnicodeSqrt';

export default function filterAllTypes(str: string): string {
  str = filterPlusMinus(str);
  str = filterGreaterThanMinus(str);
  str = filterLessThanMinus(str);
  str = filterTrigFunctions(str);
  str = filterCommas(str);
  str = filterFractionSlash(str);
  str = filterUnicodeSqrt(str);
  str = filterSpaces(str);
  str = filterTerminalCaret(str);
  str = filterDoubleStar(str);
  str = filterSqrt(str);

  return str;
}
