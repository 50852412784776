import {REGION} from '../../models/region/region';
import phonenumber from 'google-libphonenumber';
import * as yup from 'yup';

const phoneUtil = phonenumber.PhoneNumberUtil.getInstance();

declare module 'yup' {
  export interface StringSchema {
    phone(region?: string, message?: string): StringSchema;
  }
}

yup.addMethod(
  yup.string,
  'phone',
  function phone(region?: string, message?: string) {
    const defaultRegion = region || REGION.ZA;

    return this.test(
      'phone',
      message || 'Must be a valid phone number',
      (value?: string) => {
        if (!value) {
          return false;
        }

        try {
          const number = phoneUtil.parseAndKeepRawInput(value, defaultRegion);

          if (!phoneUtil.isPossibleNumber(number)) {
            return false;
          }

          return phoneUtil.isValidNumberForRegion(number, defaultRegion);
        } catch {
          return false;
        }
      }
    );
  }
);

export default yup;
