import spliceString from './spliceString';

export default function filterSpecSuperscripts(str: string): string {
  let matches = str.match(/[a-z]+[0-9]+/gi);

  if (matches) {
    matches.forEach((match) => {
      // If a number follows a letter, prefix it with a caret
      const firstDigit = match.match(/[0-9]/) as string[];
      const firstIndex = match.indexOf(firstDigit[0]);
      const splicedString = spliceString(match, firstIndex, '^');

      str = str.replace(match, splicedString);
    });
  }

  // brackets associated with exponents do not render correctly in mathjax so these must be fixed
  // Spectroscopic notation is very precise. It always has the form: numeric character, alpha character
  // exponent, numeric character.
  // This regex tests to see if a string starts with at least one alpha character then a ^
  // followed by at least one bracket followed by at least one numeric charcter.
  matches = str.match(/[a-z]+\^\(+[0-9]+\)*/gi);

  if (matches) {
    matches.forEach((match) => {
      const firstIndex = match.indexOf('(');
      const lastIndex = match.lastIndexOf(')');
      let splicedString: string;

      if (lastIndex !== -1) {
        splicedString =
          match.substr(0, firstIndex) +
          '(' +
          match.substr(firstIndex, lastIndex + 1) +
          ')';
      } else {
        splicedString =
          match.substr(0, firstIndex) + '(' + match.substr(firstIndex);
      }

      str = str.replace(match, splicedString);
    });
  }

  return str;
}
