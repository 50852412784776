import {IonButton} from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import {useImmer} from 'use-immer';
import Icon from '../Icon/Icon';
import type {TextInputProps} from '../TextInput/TextInput';
import TextInput from '../TextInput/TextInput';
import './Password.md.scss';

type PasswordProps = Omit<TextInputProps, 'autocomplete' | 'type'> & {
  autocomplete?: 'new-password' | 'current-password';
  masked?: boolean;
  maskToggle?: boolean;
};

const Password: React.FC<PasswordProps> = (props) => {
  const {
    autocomplete = 'new-password',
    masked = true,
    maskToggle = true
  } = props;
  const [isMasked, setIsMasked] = useImmer<boolean>(masked);
  const type = isMasked ? 'password' : 'text';
  const iconName = isMasked ? 'eye' : 'eye-off';
  const ariaLabel = isMasked ? 'Show password' : 'Hide password';

  return (
    <div
      className={clsx({
        'item-password': true,
        'has-mask-toggle': maskToggle
      })}
    >
      <TextInput {...props} type={type} autocomplete={autocomplete} />

      {maskToggle && (
        <IonButton
          fill="clear"
          aria-label={ariaLabel}
          onClick={() => setIsMasked(!isMasked)}
        >
          <Icon name={iconName} set="ionicons" slot="icon-only" />
        </IonButton>
      )}
    </div>
  );
};

export default Password;
