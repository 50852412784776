/**
 * NOTE IonListHeader is not used because text inside .list-header-inner does not wrap.
 * The element cannot be styled because it has no part attribute.
 */
import {IonItem, IonLabel, IonList, IonNote, IonCheckbox} from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import './CheckboxGroup.md.scss';

type Props = {
  helper?: string;
  label?: string;
  lines?: 'full' | 'inset' | 'none';
  name: string;
  options: {label: string; value: string; disabled?: boolean}[];
};

const CheckboxGroup: React.FC<Props> = (props) => {
  const {helper, label, lines = 'none', name, options} = props;
  const {
    control,
    formState: {errors}
  } = useFormContext();
  const error = errors[name]?.message;

  return (
    <IonList
      className={clsx({'list-checkbox-group': true, 'has-error': error})}
      lines={lines}
    >
      <Controller
        control={control}
        name={name}
        render={({field: {value, onChange}}) => {
          const valueArray = value || [];

          return (
            <>
              {label && <div className="list-header">{label}</div>}
              {options.map((option) => (
                <IonItem
                  key={option.value}
                  className="item-checkbox"
                  lines="none"
                >
                  <IonLabel>{option.label}</IonLabel>
                  <IonCheckbox
                    slot="start"
                    disabled={option.disabled}
                    value={option.value}
                    checked={valueArray.includes(option.value)}
                    onIonChange={(e) => {
                      let nextValue = [...valueArray];

                      if (e.target.checked) {
                        if (!nextValue.includes(e.target.value)) {
                          nextValue.push(e.target.value);
                        }
                      } else {
                        nextValue = nextValue.filter(
                          (d) => d !== e.target.value
                        );
                      }

                      onChange(nextValue);
                    }}
                  />
                </IonItem>
              ))}
            </>
          );
        }}
      />
      {error && (
        <IonNote color="danger" slot="error">
          {error}
        </IonNote>
      )}
      {!error && helper && <IonNote slot="helper">{helper}</IonNote>}
    </IonList>
  );
};

export default CheckboxGroup;
