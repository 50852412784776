export type Curriculum = {
  id: CURRICULUM;
  long_name: string;
  flag_icon: string;
};

export const enum CURRICULUM {
  CAPS = 'CAPS',
  NG = 'NG',
  INTL = 'INTL',
  CBC = 'CBC'
}
