import React from 'react';
import {Icon} from '../../core/components';
import type {IconName} from '../../core/components/Icon/Icon';

const map: Record<
  string,
  {label: string; bgColor: string; iconName: IconName; iconColor: string}
> = {
  info: {
    label: 'Read',
    bgColor: 'tw-bg-grey-50',
    iconName: 'book',
    iconColor: 'tw-text-indigo-500'
  },
  do: {
    label: 'Do',
    bgColor: 'tw-bg-green-50',
    iconName: 'thumbs-up',
    iconColor: 'tw-text-green-700'
  },
  dont: {
    label: 'Don’t',
    bgColor: 'tw-bg-red-50',
    iconName: 'thumbs-down',
    iconColor: 'tw-text-red-700'
  }
};

const Note: React.FC<{type: 'info' | 'do' | 'dont'}> = ({children, type}) => {
  const {bgColor, label, iconName, iconColor} = map[type];

  return (
    <div className={'tw-my-6 tw-rounded tw-p-3 ' + bgColor}>
      <Icon
        name={iconName}
        set="ionicons"
        className={'tw-mr-3 tw-text-lg ' + iconColor}
      />
      <strong>{label}:</strong> {children}
    </div>
  );
};

export default Note;
