import React from 'react';
import type {LEAGUE} from '../../models/public-leaderboard/public-leaderboard';
import type {SUBJECT_NAME} from '../../models/subject/subject';
import './LeagueIcon.css';

type Props = {
  subject_name: SUBJECT_NAME;
  league: LEAGUE;
};

const LeagueIcon: React.FC<Props> = (props) => {
  const {subject_name, league} = props;
  const iconName = `${subject_name}-${league}`.toLowerCase();

  /* eslint @typescript-eslint/no-var-requires: 0 */
  return React.createElement(require('./icons/' + iconName).default);
};

export default React.memo(LeagueIcon);
