export type HeroIcon = 'hero-icon';

export const set: HeroIcon = 'hero-icon';

export type HeroIconName =
  | '1-million-maths'
  | 'assignment-completed'
  | 'atom'
  | 'beta'
  | 'earth'
  | 'effort'
  | 'exam-completed'
  | 'feedback'
  | 'flask'
  | 'interactive-textbook-completed'
  | 'mastery'
  | 'question'
  | 'responsive'
  | 'star'
  | 'trophy'
  | 'weekly-goal';

export const names: HeroIconName[] = [
  '1-million-maths',
  'assignment-completed',
  'atom',
  'beta',
  'earth',
  'effort',
  'exam-completed',
  'feedback',
  'flask',
  'interactive-textbook-completed',
  'mastery',
  'question',
  'responsive',
  'star',
  'trophy',
  'weekly-goal'
];
