import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../store';
import type {Country} from './country';
import data from '../../data/countries.json';

const sortComparer = (a: Country, b: Country) =>
  a.full_name.localeCompare(b.full_name);

const adapter = createEntityAdapter<Country>({
  sortComparer
});

const slice = createSlice({
  name: 'countries',
  initialState: {
    ids: [...(data as Country[])].sort(sortComparer).map((d) => d.id),
    entities: Object.fromEntries((data as Country[]).map((d) => [d.id, d]))
  },
  reducers: {
    countriesSetAll: adapter.setAll
  }
});

export const {countriesSetAll} = slice.actions;

export default slice.reducer;

export const countriesSelectors = adapter.getSelectors(
  (state: RootState) => state.countries
);

export const selectDiallingCodeMap = (state: RootState) =>
  Object.fromEntries(
    countriesSelectors
      .selectAll(state)
      .map(({dialling_code, id}) => [dialling_code, id])
  );

export const selectDiallingCodes = (state: RootState) =>
  countriesSelectors.selectAll(state).map(({dialling_code}) => dialling_code);
