import {
  IonCheckbox,
  IonRadio,
  IonRadioGroup,
  IonItem,
  IonList
} from '@ionic/react';
import React from 'react';
import {useImmer} from 'use-immer';
import Option from './Option';
import type {OptionsProps} from './types';
import {toArray} from './utils';

const Options: React.FC<OptionsProps> = (props) => {
  const {
    value,
    multiple,
    searchValue,
    items,
    itemToValue,
    itemToLabel,
    optionComponent = Option,
    onSelectValue
  } = props;

  const [nextValues, setNextValues] = useImmer<string[]>(toArray(value));

  if (items === undefined) {
    return null;
  }

  if (!items.length) {
    return <div className="tw-mt-4">No results for "{searchValue}"</div>;
  }

  if (!multiple) {
    return (
      <IonList>
        <IonRadioGroup
          value={nextValues.length ? nextValues[0] : undefined}
          onIonChange={(e) => {
            const nextState = [e.detail.value];

            setNextValues(nextState);
            onSelectValue(nextState);
          }}
        >
          {items.map((item, index) => {
            const value = itemToValue(item);
            const label = itemToLabel(item);

            return (
              <IonItem
                className="item-radio"
                lines="full"
                key={index + '-' + value}
              >
                {React.createElement(optionComponent, {item, label})}
                <IonRadio slot="start" value={value} />
              </IonItem>
            );
          })}
        </IonRadioGroup>
      </IonList>
    );
  }

  return (
    <IonList>
      {items.map((item, index) => {
        const value = itemToValue(item);
        const label = itemToLabel(item);

        return (
          <IonItem
            className="item-checkbox"
            lines="full"
            key={index + '-' + value}
          >
            {React.createElement(optionComponent, {item, label})}
            <IonCheckbox
              slot="start"
              value={value}
              checked={nextValues.includes(value)}
              onIonChange={(e) => {
                const value = e.detail.value;
                const checked = e.target.checked;

                let nextState = [...nextValues];
                if (checked) {
                  if (!nextState.includes(value)) {
                    nextState.push(value);
                  }
                } else {
                  nextState = nextState.filter((d) => d !== value);
                }

                setNextValues(nextState);
                onSelectValue(nextState);
              }}
            />
          </IonItem>
        );
      })}
    </IonList>
  );
};

export default Options;
