/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'question'}</title>
    <path
      fill="#0DAA88"
      d="M498.667 276c-16.082-16.659-39.753-18.161-55.836-1.5l-45.972 47.616c-11.96 12.384-26.386 12.41-34.806 2.117-5.569-6.82-3.369-27.856-1.811-41.2l23.054-196.397c2.063-17.546-10-34.498-26.939-36.63-16.936-2.133-32.33 11.359-34.393 28.902l-25.43 154.26c-1.329 3.837-4.176 4.5-4.499-.666L283.385 32c0-17.674-13.829-32-30.894-32-17.061 0-30.89 14.326-30.89 32l-.61 202.002c.162 2.331-1.769 2.999-2.116.336L191.393 76.66c-3.031-17.394-19.104-29.946-35.89-26.804-16.79 3.141-27.941 20.785-24.911 38.175l24.472 184.521c.025 3.281-1.039 5.157-2.543.948l-36.509-101.162c-5.712-16.654-23.372-26.36-39.45-20.444-16.077 5.916-24.48 25.212-18.771 41.864l64.002 216.714a154.13 154.13 0 0 0 1.05 3.564l.135.445c.011.035.027.065.037.1C140.74 471.124 192.004 512 252.491 512c38.721 0 78.942-14.146 109.56-38.482l.002-.002c33.074-20.63 139.016-138.683 139.016-138.683 16.089-16.659 13.682-42.17-2.402-58.833z"
    />
    <path
      fill="#087F5D"
      opacity={0.7}
      d="m442.831 274.5-45.972 47.616c-11.96 12.384-26.386 12.41-34.806 2.117-5.569-6.82-3.369-27.856-1.811-41.2l23.054-196.397c2.063-17.546-10-34.498-26.939-36.63-16.936-2.133-32.33 11.359-34.393 28.902l-25.43 154.26c-1.329 3.837-4.176 4.5-4.499-.666L283.385 32c0-16.445-11.974-29.983-27.385-31.789V511.96c37.631-.824 76.357-14.841 106.051-38.442l.002-.002c33.074-20.63 139.016-138.683 139.016-138.683 16.09-16.659 13.683-42.17-2.401-58.833-16.083-16.659-39.754-18.161-55.837-1.5z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
