import _ from 'lodash';
import {compile} from 'path-to-regexp';
import queryString from 'query-string';

const memoizedCompile = _.memoize(compile);

export default (
  path: string,
  params: Record<string, any> = {},
  query: Record<string, any> = {},
  fragment: string = '',
  stringifyOptions: queryString.StringifyOptions = {}
) => {
  const queryStr = Object.keys(query).length
    ? `?${queryString.stringify(query, stringifyOptions)}`
    : '';
  const fragmentStr = fragment.length ? `#${fragment}` : '';

  return memoizedCompile(path)(params) + queryStr + fragmentStr;
};
