/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-league-icon"
    viewBox="0 0 448 512"
    {...props}
  >
    <title>{'chemistry platinum'}</title>
    <path
      fill="#a4c624"
      d="M224 40.437 80 124.218v167.565l144 83.78 144-83.78V124.218L224 40.437zM348 280.28l-124 72.145-124-72.145V135.72l124-72.146 124 72.146v144.56z"
    />
    <path
      fill="#a4c624"
      d="M108 140.322V275.68l116 67.489 116-67.489V140.322L224 72.831z"
    />
    <path
      fill="#fff"
      d="M100 135.721v144.56l124 72.145 124-72.145v-144.56L224 63.575l-124 72.146zM340 275.68l-116 67.489-116-67.489V140.322l116-67.491 116 67.491V275.68z"
    />
    <path
      fill="#FFF"
      d="M260.594 193.188h-13.563l-1.875-12.125c-1.793-1.791-4.053-3.208-6.781-4.25-2.729-1.041-5.948-1.563-9.656-1.563-7.917 0-14.011 2.875-18.281 8.625-4.271 5.75-6.406 13.25-6.406 22.5v3.188c0 9.25 2.125 16.771 6.375 22.563s10.25 8.688 18 8.688c3.584 0 6.865-.521 9.845-1.563 2.979-1.041 5.28-2.458 6.905-4.25l1.875-12.125h13.563v18c-3.667 4.25-8.344 7.636-14.03 10.156-5.688 2.521-12.054 3.781-19.095 3.781-12.209 0-22.209-4.229-30-12.688-7.792-8.458-11.688-19.313-11.688-32.563V206.5c0-13.208 3.875-24.063 11.625-32.563s17.771-12.75 30.063-12.75c7.041 0 13.396 1.281 19.063 3.844 5.666 2.563 10.354 5.948 14.063 10.156v18.002h-.002z"
    />
    <g fill="#848484">
      <path d="M42.379 451.34v20.039H30.66v-56.875h22.188c4.271 0 8.027.781 11.27 2.344s5.735 3.783 7.48 6.66c1.744 2.878 2.617 6.152 2.617 9.824 0 5.573-1.908 9.968-5.723 13.184-3.815 3.217-9.096 4.824-15.84 4.824H42.379zm0-9.492h10.469c3.099 0 5.462-.729 7.09-2.188 1.627-1.458 2.441-3.541 2.441-6.25 0-2.786-.82-5.039-2.461-6.758s-3.906-2.604-6.797-2.656H42.379v17.852zM93.98 461.965h24.883v9.414H82.262v-56.875H93.98v47.461zM158.434 459.66h-20.547l-3.906 11.719H121.52l21.172-56.875h10.859l21.289 56.875h-12.461l-3.945-11.719zm-17.383-9.492h14.219l-7.148-21.289-7.071 21.289zM218.277 423.996h-17.422v47.383h-11.719v-47.383h-17.188v-9.492h46.328v9.492zM237.418 471.379h-11.719v-56.875h11.719v56.875zM294.527 471.379h-11.719l-22.813-37.422v37.422h-11.719v-56.875h11.719l22.852 37.5v-37.5h11.68v56.875zM347.809 414.504v37.461c0 6.225-1.947 11.146-5.84 14.766-3.894 3.62-9.213 5.43-15.957 5.43-6.641 0-11.914-1.758-15.82-5.273s-5.898-8.346-5.977-14.492v-37.891h11.719v37.539c0 3.725.892 6.439 2.676 8.145 1.783 1.706 4.251 2.559 7.402 2.559 6.588 0 9.935-3.463 10.039-10.391v-37.852h11.758zM372.73 414.504l14.609 41.25 14.531-41.25h15.391v56.875h-11.758v-15.547l1.172-26.836-15.352 42.383h-8.047l-15.313-42.344 1.172 26.797v15.547h-11.719v-56.875h15.314z" />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
