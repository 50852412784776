export const enum SEARCH_TYPE {
  FILTER = 'FILTER',
  SEARCH = 'SEARCH'
}

export type SelectItem = Record<string, any>;

export type SelectOption = {value: string; label: string};

export type SelectProps = {
  clearInput?: boolean;
  disabled?: boolean;
  helper?: string;
  label?: string;
  lines?: 'full' | 'inset' | 'none';
  multiple?: boolean;
  name: string;
  placeholder?: string;
  showError?: boolean;
  searchable?: boolean;
  searchMinLength?: number;
  searchPlaceholder?: string;
  items?: SelectItem[];
  itemsQueryFn?: any; // TODO Add type
  itemsQueryArgs?: (inputValue: string) => Record<string, any>;
  filter?: (
    items: SelectItem[] | undefined,
    inputValue: string
  ) => SelectItem[] | undefined;
  itemToValue?: (item: SelectItem) => string;
  itemToLabel?: (item: SelectItem) => string;
  optionComponent?: React.FC<OptionProps>;
  optionsComponent?: React.FC<OptionsProps>;
  onSelectValue?: (args: {
    value: null | string | string[];
    items: SelectItem[];
  }) => void;
  onSelectSearchValue?: (args: {value: string}) => void;
};

export type ModalProps = {
  value: null | string | string[];
  label: string;
  multiple: boolean;
  searchable: boolean;
  searchPlaceholder: string;
  searchInput: React.RefObject<HTMLIonInputElement>;
  searchValue: string;
  setSearchValue: (value: string) => void;
  isSearchValueValid: boolean;
  isLoading: boolean;
  items?: SelectItem[];
  itemToValue: (item: SelectItem) => string;
  itemToLabel: (item: SelectItem) => string;
  optionComponent?: React.FC<OptionProps>;
  optionsComponent?: React.FC<OptionsProps>;
  onSave: (values: string[]) => void;
  onCancel: () => void;
  onSelectSearchValue: () => void;
};

export type OptionsProps = {
  value: null | string | string[];
  multiple?: boolean;
  searchValue: string;
  items?: SelectItem[];
  itemToValue: (item: SelectItem) => string;
  itemToLabel: (item: SelectItem) => string;
  optionComponent?: React.FC<OptionProps>;
  onSelectValue: (values: string[]) => void;
  onSelectSearchValue: () => void;
};

export type OptionProps = {
  item: SelectItem;
  label: string;
};
