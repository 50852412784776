import React from 'react';
import {Page} from '../../../core/components';

const TablePage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Table (🚧)</h1>

        <div className="tw-not-prose">
          <table className="sv-table sv-table--striped sv-table--bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="sv-table__cell sv-table__cell--numeric"
                >
                  #
                </th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th
                  scope="col"
                  className="sv-table__cell sv-table__cell--numeric"
                >
                  Age
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  scope="row"
                  className="sv-table__cell sv-table__cell--numeric"
                >
                  1
                </th>
                <td>Jane</td>
                <td>Doe</td>
                <td className="sv-table__cell sv-table__cell--numeric">45</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="sv-table__cell sv-table__cell--numeric"
                >
                  2
                </th>
                <td>John</td>
                <td>Dewey</td>
                <td className="sv-table__cell sv-table__cell--numeric">43</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="sv-table__cell sv-table__cell--numeric"
                >
                  3
                </th>
                <td>Joel</td>
                <td>Doe</td>
                <td className="sv-table__cell sv-table__cell--numeric">44</td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    </Page>
  );
};

export default TablePage;
