import {demoApi} from '../core/api';
import type {SiyavulaId} from '../core/types';

type SaveTokenRequest = {
  siyavula_id: SiyavulaId;
  token: string;
};

type SaveTokenResponse = {};

type ClearTokenRequest = {
  siyavula_id: SiyavulaId;
  token: string;
};

type ClearTokenResponse = {};

const api = demoApi.injectEndpoints({
  endpoints: (build) => ({
    saveToken: build.mutation<SaveTokenResponse, SaveTokenRequest>({
      query: ({siyavula_id, token}) => ({
        url: `push-notifications/save-user-token/${siyavula_id}`,
        method: 'POST',
        body: {token}
      })
    }),
    clearToken: build.mutation<ClearTokenResponse, ClearTokenRequest>({
      query: ({siyavula_id, token}) => ({
        url: `push-notifications/delete-user-token/${siyavula_id}`,
        method: 'POST',
        body: {token}
      })
    })
  })
});

export const {useSaveTokenMutation, useClearTokenMutation} = api;
