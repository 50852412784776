import {IonProgressBar} from '@ionic/react';
import React from 'react';
import './ProgressBar.scss';

type ProgressBarProps = {
  value: number;
};

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const {value} = props;

  return (
    <IonProgressBar
      className="sv-progress-bar"
      value={value / 100}
      type="determinate"
    />
  );
};

export default ProgressBar;
