import type {ToastOptions} from '@ionic/react';
import {
  checkmarkCircleSharp as successIcon,
  warningSharp as warningIcon,
  closeCircleSharp as dangerIcon
} from 'ionicons/icons';

export const successToastOptions: ToastOptions = {
  color: 'success',
  icon: successIcon,
  duration: 3000
};

export const warningToastOptions: ToastOptions = {
  color: 'warning',
  icon: warningIcon,
  duration: 3000
};

export const dangerToastOptions: ToastOptions = {
  color: 'danger',
  icon: dangerIcon,
  duration: 3000
};
