import React from 'react';
import {VictoryPie} from 'victory';
import tailwindConfig from '../../data/tailwind.config';
import type {Bin} from '../ProgressDonut/utils/binValue';
import binValue from '../ProgressDonut/utils/binValue';

const defaultColorValueBins: Bin[] = [
  {
    color: tailwindConfig.theme.colors.blue[100],
    lower: {
      value: 0,
      inclusive: true
    },
    upper: {
      value: 1,
      inclusive: false
    }
  },
  {
    color: tailwindConfig.theme.colors.blue[500],
    lower: {
      value: 1,
      inclusive: true
    },
    upper: {
      value: 100,
      inclusive: true
    }
  }
];

function transformStar(x: number, y: number, size: number) {
  const scale = size / 512; // Glyph size
  const inverseScale = 1 / scale;
  const originX = x - size / 2;
  const originY = y - size / 2;
  const translateX = originX * inverseScale;
  const translateY = originY * inverseScale;

  return `scale(${scale}, ${scale}), translate(${translateX}, ${translateY})`;
}

type MasteryProgressDonutProps = {
  borderWidth?: number;
  colorBar?: string;
  colorValue?: string;
  colorValueBins?: Bin[];
  size?: number;
  value: number;
};

const MasteryProgressDonut: React.FC<MasteryProgressDonutProps> = (props) => {
  const {
    borderWidth = 16,
    colorBar = tailwindConfig.theme.colors.blue[100],
    colorValueBins = defaultColorValueBins,
    size = 80,
    value
  } = props;
  const colorValue = props.colorValue
    ? props.colorValue
    : binValue(value, colorValueBins)?.color ||
      tailwindConfig.theme.colors.blue[500];
  const innerRadius = (size - borderWidth) / 2;

  return (
    <svg
      className="tw-relative tw-m-0 tw-inline-block tw-border-none tw-p-0 tw-align-middle tw-leading-none"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      preserveAspectRatio="xMidYMid meet"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={transformStar(size / 2, ((size / 2) * 116) / 120, 32)}>
        <path
          fill="#000"
          opacity="0.2"
          d="M133.561 501c-8.836.001-16-7.161-16.001-15.996 0-.907.077-1.813.23-2.706l25.017-145.839L36.832 233.175c-6.327-6.167-6.457-16.295-.289-22.622a16.002 16.002 0 0 1 9.156-4.665l146.456-21.277 65.496-132.69c3.91-7.922 13.504-11.176 21.428-7.266a15.998 15.998 0 0 1 7.268 7.266l65.495 132.69 146.456 21.277c8.744 1.269 14.804 9.384 13.535 18.126a15.99 15.99 0 0 1-4.668 9.16L401.189 336.459l25.017 145.839c1.493 8.709-4.358 16.979-13.068 18.472a16.009 16.009 0 0 1-10.146-1.606l-130.993-68.856-130.994 68.856a16.021 16.021 0 0 1-7.444 1.836z"
        />
        <path
          fill="#F5BD00"
          d="M482.297 189.889l-146.456-21.277-65.495-132.69a15.993 15.993 0 0 0-7.268-7.266A15.903 15.903 0 0 0 256 27.019v387.289l130.991 68.855a15.996 15.996 0 0 0 10.146 1.606c8.71-1.492 14.562-9.762 13.068-18.471l-25.017-145.839 105.975-103.283a15.996 15.996 0 0 0 4.668-9.16c1.27-8.743-4.79-16.858-13.534-18.127z"
        />
        <path
          fill="#FFD300"
          d="M256 27.018c-5.895.004-11.564 3.26-14.35 8.903l-65.495 132.69-146.456 21.278a16.002 16.002 0 0 0-9.156 4.665c-6.167 6.327-6.038 16.455.289 22.622l105.976 103.283-25.018 145.839a16.04 16.04 0 0 0-.23 2.706c.001 8.835 7.165 15.997 16.001 15.996 2.593 0 5.148-.631 7.443-1.838l130.994-68.856.002.001V27.018z"
        />
      </g>
      <VictoryPie
        groupComponent={<g transform="translate(1,1)" />}
        standalone={false}
        width={size - 2}
        height={size - 2}
        innerRadius={innerRadius}
        data={[
          {x: 1, y: value, fill: colorValue},
          {x: 2, y: 100 - value, fill: colorBar}
        ]}
        labels={() => null}
        padding={0}
        style={{
          data: {
            fill: ({datum}) => datum.fill,
            strokeWidth: 0
          }
        }}
      />
    </svg>
  );
};

export default MasteryProgressDonut;
