import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle
} from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useRestoreUser} from '../../../auth/auth.hooks';
import {selectSession} from '../../../auth/auth.slice';
import {useRegisterPushNotifications} from '../../../push-notifications/push-notifications.hooks';
import type {RegionSubjectGrade} from '../../models/region-subject-grade/region-subject-grade';
import {selectSubjectGradesForRegion} from '../../models/region-subject-grade/region-subject-grade.slice';
import {ROLE} from '../../models/user/user';
import {
  PATH_AUTH_LOGIN,
  PATH_AUTH_LOGOUT,
  PATH_STYLE_GUIDE_INDEX,
  PATH_HOME_LEARNER,
  PATH_HOME_PARENT,
  PATH_HOME_PUBLIC,
  PATH_HOME_TEACHER,
  PATH_PRACTICE_DASHBOARD_REDIRECT_TO_GRADE,
  PATH_PRACTICE_DASHBOARD_SUBJECT
} from '../../paths';
import compilePath from '../../utils/compilePath';
import type {IconName} from '../Icon/Icon';
import Icon from '../Icon/Icon';
import './Menu.css';

type Page = {
  title: string;
  path: string;
  icon: IconName;
};

const getPages = _.memoize(
  (
    regionSubjectGrades: RegionSubjectGrade[],
    isAuthenticated: boolean,
    roleId?: ROLE
  ): Page[] => {
    if (isAuthenticated) {
      const practicePages = _.uniqBy(regionSubjectGrades, 'subject_name').map(
        (d) => {
          const {subject_name, subject_short_name} = d;
          const grades = regionSubjectGrades
            .filter((d) => d.subject_name === subject_name)
            .map((d) => d.grade)
            .filter(Boolean);
          const path = grades.length
            ? PATH_PRACTICE_DASHBOARD_REDIRECT_TO_GRADE
            : PATH_PRACTICE_DASHBOARD_SUBJECT; // INTL

          return {
            title: `Practice ${subject_short_name}`,
            path: compilePath(path, {subject_name}),
            icon: 'shapes'
          } as Page;
        }
      );

      if (roleId === ROLE.TEACHER) {
        return [
          {
            title: 'Home',
            path: PATH_HOME_TEACHER,
            icon: 'grid'
          },
          {
            title: 'Log Out',
            path: PATH_AUTH_LOGOUT,
            icon: 'key'
          }
        ] as Page[];
      }

      if (roleId === ROLE.PARENT) {
        return [
          {
            title: 'Home',
            path: PATH_HOME_PARENT,
            icon: 'grid'
          },
          {
            title: 'Log Out',
            path: PATH_AUTH_LOGOUT,
            icon: 'key'
          }
        ] as Page[];
      }

      return [
        [
          {
            title: 'Home',
            path: PATH_HOME_LEARNER,
            icon: 'grid'
          }
        ] as Page[],
        practicePages,
        [
          {
            title: 'Style Guide',
            path: PATH_STYLE_GUIDE_INDEX,
            icon: 'documents'
          },
          {
            title: 'Log Out',
            path: PATH_AUTH_LOGOUT,
            icon: 'key'
          }
        ] as Page[]
      ].flat();
    }

    return [
      {
        title: 'Home',
        path: PATH_HOME_PUBLIC,
        icon: 'grid'
      },
      {
        title: 'Log In',
        path: PATH_AUTH_LOGIN,
        icon: 'key'
      }
    ] as Page[];
  },
  (...args) => JSON.stringify(args)
);

const Menu: React.FC = () => {
  const location = useLocation();
  const {region, is_authenticated, user} = useSelector(selectSession);
  const regionSubjectGrades = useSelector(selectSubjectGradesForRegion(region));
  const pages: Page[] = getPages(
    regionSubjectGrades,
    is_authenticated,
    user?.role_id
  );

  useRestoreUser();
  useRegisterPushNotifications();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList>
          <IonListHeader>Menu</IonListHeader>
          {pages.map((page, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={location.pathname === page.path ? 'selected' : ''}
                  routerLink={page.path}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <Icon slot="start" name={page.icon} />
                  <IonLabel>{page.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
