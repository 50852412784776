import React from 'react';

type Props = {
  heading: string;
  subheading?: string;
};

const PageHeader: React.FC<Props> = (props) => {
  const {heading, subheading} = props;

  return (
    <header className="tw-mb-8">
      <h1
        className="tw-mb-2 tw-text-3xl tw-font-bold lg:tw-text-4xl"
        dangerouslySetInnerHTML={{__html: heading}}
      />
      {subheading && (
        <h2
          className="tw-text-base tw-font-normal tw-text-grey-900"
          dangerouslySetInnerHTML={{__html: subheading}}
        />
      )}
    </header>
  );
};

export default PageHeader;
