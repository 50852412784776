import jQuery from 'jquery';
import {MathJax2Object} from 'better-react-mathjax';
import _ from 'lodash';
import {
  CHEMCOMPOUND,
  CHEMEQUATION,
  CHOICE,
  COORDINATE,
  ELECTROCHEMCELL,
  EQUATION,
  EXPRESSION,
  FRACTION,
  INTERVAL,
  LIST,
  NUMERIC,
  ONE_OF,
  POLYNOMIAL,
  RELATIONAL,
  SPECTROSCOPIC
} from '../../ChemJax/ts/constants';
import filter from '../../ChemJax/ts/filter';
import parseResponseType from '../../ChemJax/ts/parseResponseType';

declare var MathJax: MathJax2Object;

const moduleName = 'LivePreview';

type Selector = JQuery.Selector | JQuery.PlainObject;
type Selectors = Selector[];

class LivePreview {
  config: {
    includeResponseTypes: string[];
    responseTypeElms: Selector | Selectors;
    responseInputElms: Selector | Selectors;
    previewElm: Selector;
    debug: boolean;
  };

  flags: {
    init: boolean; // If `init` has been fired
    mathJaxLoaded: boolean; // If MathJax and its dependencies have loaded
    contentInit: boolean; // If `initContent` has been fired
    contentReady: boolean; // If MathJax has typeset the empty preview element
    console: boolean;
  };

  $preview: JQuery.PlainObject | null;
  id: string;
  math: any | null;
  responseTypes: string[];
  updateQueue: {value: string; responseType: string} | null;

  constructor(config: {
    debug?: boolean;
    includeResponseTypes?: string[];
    responseTypeElms: Selector | Selectors;
    responseInputElms: Selector | Selectors;
    previewElm: Selector;
  }) {
    this.config = {
      debug: false,
      includeResponseTypes: [
        CHEMCOMPOUND,
        CHEMEQUATION,
        CHOICE,
        COORDINATE,
        ELECTROCHEMCELL,
        EQUATION,
        EXPRESSION,
        FRACTION,
        INTERVAL,
        LIST,
        NUMERIC,
        ONE_OF,
        POLYNOMIAL,
        RELATIONAL,
        SPECTROSCOPIC
      ],
      ...config
    };

    this.flags = {
      init: false,
      mathJaxLoaded: false,
      contentInit: false,
      contentReady: false,
      console: !!console
    };

    this.$preview = null;
    this.id = _.uniqueId('sv-');
    this.math = null;
    this.responseTypes = [];
    this.updateQueue = null;
  }

  init(): LivePreview {
    this.log(`${moduleName}.init id=${this.id}`);

    if (this.flags.init) {
      return this;
    }

    const self = this;
    const {id, config, onMathJaxLoaded} = this;

    self.responseTypes = jQuery(config.responseTypeElms)
      .map((i, elm) => jQuery(elm).text().trim().toLowerCase())
      .get();

    const $target = jQuery(config.previewElm);
    const hidePreview = $target.is(':hidden');
    const $preview = jQuery(
      '<section class="sv-live-preview" style="display: ' +
        (hidePreview ? 'none' : 'block') +
        ';" id="' +
        id +
        '"><header class="sv-live-preview__header"><h1>Preview</h1></header>' +
        '<div class="sv-live-preview__body">' +
        '<svg class="svg-icon sv-spinner sv-live-preview__spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><use xlink:href="#icon-spinner"/></svg>' +
        '<div class="sv-live-preview__content" id="' +
        id +
        '-content">` `</div>' +
        '</div>' +
        '</section>'
    );

    $target.replaceWith($preview);

    this.$preview = $preview;

    self.flags.init = true;

    jQuery(config.responseInputElms).each(function (i) {
      const $elm = jQuery(this);

      if ($elm.prop('readonly')) {
        return;
      }

      const inputId = _.uniqueId('sv-');
      const responseType = self.responseTypes[i];
      const parsed = parseResponseType(responseType);
      let callback;

      if (~self.config.includeResponseTypes.indexOf(parsed.type)) {
        callback = function (
          this: JQuery.PlainObject,
          inputId: string,
          responseType: string
        ) {
          const value = this.value.trim();

          if (value.length) {
            self.update(inputId, value, responseType);
          } else {
            self.hide();
          }
        }.bind(this, inputId, responseType);
      } else {
        callback = function () {
          self.hide();
        };
      }

      $elm.on(
        'focus.' + moduleName + ' keyup.' + moduleName,
        _.throttle(callback, 100)
      );
    });

    if (MathJax.Hub.Register) {
      // Callback is fired after MathJax, jax config and extension files have been loaded
      // and page typesetting is done
      MathJax.Hub.Register.StartupHook('End', onMathJaxLoaded.bind(this));
    }

    return this;
  }

  destroy(): void {
    this.log(`${moduleName}.destroy id=${this.id}`);
  }

  log(msg: string): void {
    if (this.config.debug && this.flags.console) {
      console.log(msg);
    }
  }

  initContent(): LivePreview {
    this.log(`${moduleName}.initContent id=${this.id}`);

    if (this.flags.contentInit || !this.$preview) {
      return this;
    }

    this.flags.contentInit = true;

    this.$preview.addClass('sv-live-preview--show-content');

    // TODO Check whether MathJax.Hub.Queue() is called correctly. This was copied from JS.
    MathJax.Hub.Queue(
      ['Typeset', MathJax.Hub, this.id + '-content'],
      // @ts-ignore
      this.onContentReady.bind(this)
    );

    return this;
  }

  update(
    inputId: string | null,
    value: string,
    responseType: string
  ): LivePreview {
    this.log(`${moduleName}.update id=${this.id} inputId=${inputId}`);

    const {
      flags: {mathJaxLoaded, contentReady}
    } = this;

    this.show();

    // If MathJax hasn't completely loaded, then cache the update request.
    // The last requested update will be fulfilled after both MathJax and the
    // preview element are ready.
    if (!mathJaxLoaded) {
      this.updateQueue = {value, responseType};
      return this;
    }

    // If MathJax hasn't done the initial typeset of the empty preview element,
    // then cache the update request.
    // The last requested update will be fulfilled after both MathJax and the
    // preview element are ready.
    if (!contentReady) {
      this.updateQueue = {value, responseType};
      return this.initContent();
    }

    MathJax.Hub.Queue(['Text', this.math, filter(value, responseType)]);

    return this;
  }

  show(): LivePreview {
    this.log(`${moduleName}.show id=${this.id}`);

    if (!this.$preview) {
      return this;
    }

    this.$preview.show();

    return this;
  }

  hide(): LivePreview {
    this.log(`${moduleName}.hide id=${this.id}`);

    if (!this.$preview) {
      return this;
    }

    this.$preview.hide();

    return this;
  }

  onMathJaxLoaded(): LivePreview {
    this.log(`${moduleName}.onMathJaxLoaded id=${this.id}`);

    this.flags.mathJaxLoaded = true;

    if (!this.$preview) {
      return this;
    }

    jQuery('.sv-live-preview__spinner', this.$preview).remove();

    // If the preview is already visible, then init the content element. This
    // could happen if the preview was initially hidden and the user entered
    // a value before MathJax and its dependencies finished loading. Because
    // the preview element is now visible, it's safe to init the content element
    // and show the queued value.
    if (!this.$preview.is(':hidden')) {
      return this.initContent();
    }

    return this;
  }

  onContentReady(): LivePreview {
    this.log(`${moduleName}.onContentReady id=${this.id}`);

    if (this.flags.contentReady) {
      return this;
    }

    const [math] = MathJax.Hub.getAllJax(this.id + '-content');

    this.math = math;

    this.flags.contentReady = true;

    if (this.updateQueue) {
      // Fulfill the last requested update
      this.update(null, this.updateQueue.value, this.updateQueue.responseType);
    }

    return this;
  }
}

export default LivePreview;
