import {Route} from 'react-router-dom';
import {PATH_AUTH_LOGIN, PATH_AUTH_LOGOUT} from './auth.paths';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';

const routes = [
  <Route exact path={PATH_AUTH_LOGIN}>
    <LoginPage />
  </Route>,
  <Route exact path={PATH_AUTH_LOGOUT}>
    <LogoutPage />
  </Route>
];

export default routes;
