/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-league-icon"
    viewBox="0 0 448 512"
    {...props}
  >
    <title>{'chemistry silver'}</title>
    <path
      fill="#a4c624"
      d="M224 40.437 80 124.218v167.565l144 83.78 144-83.78V124.218L224 40.437zM348 280.28l-124 72.145-124-72.145V135.72l124-72.146 124 72.146v144.56z"
    />
    <path
      fill="#a4c624"
      d="M108 140.322V275.68l116 67.489 116-67.489V140.322L224 72.831z"
    />
    <path
      fill="#fff"
      d="M100 135.721v144.56l124 72.145 124-72.145v-144.56L224 63.575l-124 72.146zM340 275.68l-116 67.489-116-67.489V140.322l116-67.491 116 67.491V275.68z"
    />
    <path
      fill="#FFF"
      d="M260.594 193.188h-13.563l-1.875-12.125c-1.793-1.791-4.053-3.208-6.781-4.25-2.729-1.041-5.948-1.563-9.656-1.563-7.917 0-14.011 2.875-18.281 8.625-4.271 5.75-6.406 13.25-6.406 22.5v3.188c0 9.25 2.125 16.771 6.375 22.563s10.25 8.688 18 8.688c3.584 0 6.865-.521 9.845-1.563 2.979-1.041 5.28-2.458 6.905-4.25l1.875-12.125h13.563v18c-3.667 4.25-8.344 7.636-14.03 10.156-5.688 2.521-12.054 3.781-19.095 3.781-12.209 0-22.209-4.229-30-12.688-7.792-8.458-11.688-19.313-11.688-32.563V206.5c0-13.208 3.875-24.063 11.625-32.563s17.771-12.75 30.063-12.75c7.041 0 13.396 1.281 19.063 3.844 5.666 2.563 10.354 5.948 14.063 10.156v18.002h-.002z"
    />
    <g fill="#848484">
      <path d="M130.504 456.457c0-2.213-.781-3.912-2.344-5.098-1.563-1.185-4.375-2.435-8.438-3.75-4.063-1.314-7.279-2.61-9.648-3.887-6.459-3.489-9.688-8.189-9.688-14.102 0-3.072.865-5.813 2.598-8.223 1.731-2.408 4.219-4.29 7.461-5.645 3.242-1.354 6.881-2.031 10.918-2.031 4.063 0 7.682.736 10.859 2.207 3.177 1.472 5.645 3.549 7.402 6.23 1.758 2.683 2.637 5.729 2.637 9.141h-11.719c0-2.604-.82-4.629-2.461-6.074s-3.945-2.168-6.914-2.168c-2.865 0-5.092.605-6.68 1.816-1.589 1.211-2.383 2.807-2.383 4.785 0 1.85.931 3.398 2.793 4.648 1.861 1.25 4.603 2.422 8.223 3.516 6.666 2.006 11.523 4.492 14.57 7.461s4.57 6.667 4.57 11.094c0 4.922-1.862 8.783-5.586 11.582-3.725 2.8-8.737 4.199-15.039 4.199-4.375 0-8.359-.801-11.953-2.402s-6.335-3.795-8.223-6.582c-1.889-2.786-2.832-6.016-2.832-9.688h11.758c0 6.276 3.75 9.414 11.25 9.414 2.786 0 4.961-.566 6.523-1.699s2.346-2.713 2.346-4.744zM162.652 471.379h-11.719v-56.875h11.719v56.875zM185.23 461.965h24.883v9.414h-36.602v-56.875h11.719v47.461zM229.801 457.277l12.891-42.773h13.047l-19.805 56.875h-12.227l-19.727-56.875h13.008l12.813 42.773zM295.309 446.652h-22.5v15.313h26.406v9.414H261.09v-56.875h38.047v9.492h-26.328v13.555h22.5v9.101zM327.145 450.559h-9.336v20.82H306.09v-56.875h21.133c6.719 0 11.9 1.498 15.547 4.492 3.646 2.995 5.469 7.227 5.469 12.695 0 3.881-.84 7.116-2.52 9.707-1.68 2.592-4.226 4.655-7.637 6.191l12.305 23.242v.547h-12.578l-10.664-20.819zm-9.336-9.493h9.453c2.942 0 5.221-.748 6.836-2.246 1.614-1.497 2.422-3.561 2.422-6.191 0-2.682-.762-4.791-2.285-6.328-1.523-1.536-3.861-2.305-7.012-2.305h-9.414v17.07z" />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
