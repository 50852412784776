import {Route} from 'react-router-dom';
import PrivateRoute from '../core/pages/PrivateRoute';
import IndexPage from './pages/IndexPage';
import ButtonPage from './pages/components/ButtonPage';
import CheckboxPage from './pages/components/CheckboxPage';
import DateInputPage from './pages/components/DateInputPage';
import DatetimePickerPage from './pages/components/DatetimePickerPage';
import LanguagePickerPage from './pages/components/LanguagePickerPage';
import ListPage from './pages/components/ListPage';
import MasteryMeterPage from './pages/components/MasteryMeterPage';
import MasteryProgressDonutPage from './pages/components/MasteryProgressDonutPage';
import PasswordPage from './pages/components/PasswordPage';
import ProgressBarPage from './pages/components/ProgressBarPage';
import ProgressDonutPage from './pages/components/ProgressDonutPage';
import QuestionsPage from './pages/components/QuestionPage';
import RadioPage from './pages/components/RadioPage';
import SelectPage from './pages/components/SelectPage';
import TablePage from './pages/components/TablePage';
import TelephonePage from './pages/components/TelephonePage';
import TextInputPage from './pages/components/TextInputPage';
import ColoursPage from './pages/foundations/ColoursPage';
import EmojiPage from './pages/foundations/EmojiPage';
import GridsPage from './pages/foundations/GridsPage';
import IconsPage from './pages/foundations/IconsPage';
import TypographyPage from './pages/foundations/TypographyPage';
import {
  PATH_STYLE_GUIDE_INDEX,
  PATH_STYLE_GUIDE_TYPOGRAPHY,
  PATH_STYLE_GUIDE_COLOURS,
  PATH_STYLE_GUIDE_ICONS,
  PATH_STYLE_GUIDE_QUESTION,
  PATH_STYLE_GUIDE_CHECKBOX,
  PATH_STYLE_GUIDE_DATETIME_PICKER,
  PATH_STYLE_GUIDE_TEXT_INPUT,
  PATH_STYLE_GUIDE_PASSWORD,
  PATH_STYLE_GUIDE_PROGRESS_BAR,
  PATH_STYLE_GUIDE_PROGRESS_DONUT,
  PATH_STYLE_GUIDE_RADIO,
  PATH_STYLE_GUIDE_DATE_INPUT,
  PATH_STYLE_GUIDE_TELEPHONE,
  PATH_STYLE_GUIDE_SELECT,
  PATH_STYLE_GUIDE_GRIDS,
  PATH_STYLE_GUIDE_EMOJI,
  PATH_STYLE_GUIDE_MASTERY_METER,
  PATH_STYLE_GUIDE_MASTERY_PROGRESS_DONUT,
  PATH_STYLE_GUIDE_LIST,
  PATH_STYLE_GUIDE_BUTTON,
  PATH_STYLE_GUIDE_TABLE,
  PATH_STYLE_GUIDE_LANGUAGE_PICKER
} from './style-guide.paths';

const routes = [
  <Route exact path={PATH_STYLE_GUIDE_INDEX}>
    <PrivateRoute requiresLogin>
      <IndexPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_TYPOGRAPHY}>
    <PrivateRoute requiresLogin>
      <TypographyPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_COLOURS}>
    <PrivateRoute requiresLogin>
      <ColoursPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_GRIDS}>
    <PrivateRoute requiresLogin>
      <GridsPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_EMOJI}>
    <PrivateRoute requiresLogin>
      <EmojiPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_ICONS}>
    <PrivateRoute requiresLogin>
      <IconsPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_CHECKBOX}>
    <PrivateRoute requiresLogin>
      <CheckboxPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_DATE_INPUT}>
    <PrivateRoute requiresLogin>
      <DateInputPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_DATETIME_PICKER}>
    <PrivateRoute requiresLogin>
      <DatetimePickerPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_LANGUAGE_PICKER}>
    <PrivateRoute requiresLogin>
      <LanguagePickerPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_TEXT_INPUT}>
    <PrivateRoute requiresLogin>
      <TextInputPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_PASSWORD}>
    <PrivateRoute requiresLogin>
      <PasswordPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_RADIO}>
    <PrivateRoute requiresLogin>
      <RadioPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_SELECT}>
    <PrivateRoute requiresLogin>
      <SelectPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_TELEPHONE}>
    <PrivateRoute requiresLogin>
      <TelephonePage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_MASTERY_METER}>
    <PrivateRoute requiresLogin>
      <MasteryMeterPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_MASTERY_PROGRESS_DONUT}>
    <PrivateRoute requiresLogin>
      <MasteryProgressDonutPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_PROGRESS_BAR}>
    <PrivateRoute requiresLogin>
      <ProgressBarPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_PROGRESS_DONUT}>
    <PrivateRoute requiresLogin>
      <ProgressDonutPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_LIST}>
    <PrivateRoute requiresLogin>
      <ListPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_TABLE}>
    <PrivateRoute requiresLogin>
      <TablePage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_BUTTON}>
    <PrivateRoute requiresLogin>
      <ButtonPage />
    </PrivateRoute>
  </Route>,
  <Route exact path={PATH_STYLE_GUIDE_QUESTION}>
    <PrivateRoute requiresLogin>
      <QuestionsPage />
    </PrivateRoute>
  </Route>
];

export default routes;
