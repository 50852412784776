/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-bullet-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'maths'}</title>
    <circle fill="#fb990a" cx={256} cy={256} r={256} />
    <path
      fill="#FFF"
      d="M200.484 147.938 256 304.688l55.219-156.75h58.484v216.125h-44.68v-59.078l4.453-101.977-58.336 161.055h-30.578l-58.188-160.906 4.453 101.828v59.078h-44.531V147.938h58.188z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
