import _ from 'lodash';
import emojiMap from '../emoji';
import type {Emoji} from '../emoji';

function shortcodeToEmoji(shortcode: string): Emoji | undefined {
  const [shortName, variationShortName] = _.trim(shortcode, ':')
    .split(':')
    .filter((d, i) => !(i % 2));

  if (variationShortName) {
    return (
      emojiMap.get(`${shortName}_${variationShortName}`) ||
      emojiMap.get(shortName)
    );
  }

  return emojiMap.get(shortName);
}

export default shortcodeToEmoji;
