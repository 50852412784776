import Highlight, {defaultProps} from 'prism-react-renderer';
import type {Language} from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/dracula';
import React from 'react';

const aliases: Partial<Record<Language, string>> = {
  markup: 'html'
};

const Code: React.FC<{code: string; language: Language}> = ({
  code,
  language
}) => (
  <Highlight
    {...defaultProps}
    theme={theme}
    code={code.trim()}
    language={language}
  >
    {({className, style, tokens, getLineProps, getTokenProps}) => (
      <div className="tw-relative">
        <pre
          className={
            className +
            ' tw-my-6 tw-rounded tw-p-3 tw-text-[0.8125rem] tw-leading-5'
          }
          style={{...style}}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({line, key: i})}>
              {line.map((token, key) => (
                <span {...getTokenProps({token, key})} />
              ))}
            </div>
          ))}
        </pre>
        <span className="tw-absolute tw-top-0 tw-right-0 tw-bg-[#fff]/10 tw-p-3 tw-font-mono tw-text-xs tw-uppercase tw-leading-none tw-text-white">
          {aliases[language] || language}
        </span>
      </div>
    )}
  </Highlight>
);

export default Code;
