import {createSelector, createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../core/store';

// TODO Add expiration timestamp
export type State = {
  token: string;
};

const slice = createSlice({
  name: 'pushNotifications',
  initialState: {
    token: localStorage.getItem('push_notifications_token')
  },
  reducers: {
    setToken: (state, {payload: {token}}: PayloadAction<State>) => {
      localStorage.setItem('push_notifications_token', token);

      state.token = token;
    },
    unsetToken: (state) => {
      localStorage.removeItem('push_notifications_token');

      state.token = null;
    }
  }
});

export const {setToken, unsetToken} = slice.actions;

export default slice.reducer;

export const selectToken = createSelector(
  (state: RootState) => state.pushNotifications,
  ({token}) => token
);
