// TODO Separate session (preferences, tokens) from user state
// TODO Use https://github.com/ionic-team/ionic-storage instead of localStorage directly
import {createSelector, createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../core/store';
import {CURRICULUM} from '../core/models/curriculum/curriculum';
import {REGION} from '../core/models/region/region';
import type {Nullable, SiyavulaId} from '../core/types';
import type {User} from '../core/models/user/user';
import type {UserProfileGeneral} from '../core/models/user-profile-general/user-profile-general';
import type {UserProfileTeacher} from '../core/models/user-profile-teacher/user-profile-teacher';

export type PreferencesState = {
  region: REGION;
  curriculum: CURRICULUM;
};

export type AuthState = {
  is_authenticated: boolean; // Whether session has tokens and tokens have been verified
};

export type TokenState = {
  client_token: string;
  user_token: string;
  siyavula_id: SiyavulaId;
};

export type UserState = {
  user: User;
  user_profile_general: UserProfileGeneral;
  user_profile_teacher: UserProfileTeacher;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    region: REGION.ZA,
    curriculum: CURRICULUM.CAPS,
    is_authenticated: false,
    client_token: localStorage.getItem('client_token'),
    user_token: localStorage.getItem('user_token'),
    siyavula_id: localStorage.getItem('siyavula_id'),
    user: null,
    user_profile_general: null,
    user_profile_teacher: null
  } as PreferencesState &
    AuthState &
    Nullable<TokenState> &
    Nullable<UserState>,
  reducers: {
    setSession: (
      state,
      {
        payload: {
          region,
          curriculum,
          client_token,
          user_token,
          siyavula_id,
          user,
          user_profile_general,
          user_profile_teacher
        }
      }: PayloadAction<PreferencesState & TokenState & UserState>
    ) => {
      localStorage.setItem('client_token', client_token);
      localStorage.setItem('user_token', user_token);
      localStorage.setItem('siyavula_id', siyavula_id);

      state.region = region;
      state.curriculum = curriculum;

      state.is_authenticated = true;

      state.client_token = client_token;
      state.user_token = user_token;
      state.siyavula_id = siyavula_id;

      state.user = user;
      state.user_profile_general = user_profile_general;
      state.user_profile_teacher = user_profile_teacher;
    },
    setUser: (
      state,
      {
        payload: {user, user_profile_general, user_profile_teacher}
      }: PayloadAction<UserState>
    ) => {
      state.is_authenticated = true; // Tokens are verified during getUser request

      state.user = user;
      state.user_profile_general = user_profile_general;
      state.user_profile_teacher = user_profile_teacher;
    },
    clearSession: (state) => {
      localStorage.removeItem('client_token');
      localStorage.removeItem('user_token');
      localStorage.removeItem('siyavula_id');

      state.is_authenticated = false;

      state.client_token = null;
      state.user_token = null;
      state.siyavula_id = null;

      state.user = null;
      state.user_profile_general = null;
      state.user_profile_teacher = null;
    }
  }
});

export const {setSession, setUser, clearSession} = slice.actions;

export default slice.reducer;

export const selectSession = createSelector(
  (state: RootState) => state.auth,
  (session) => session
);
