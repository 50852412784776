/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'weekly goal'}</title>
    <defs>
      <circle id="a" cx={256} cy={256} r={256} />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <g clipPath="url(#b)">
      <path fill="#27428D" d="M0 0h512v512H0z" />
      <path fill="#15266B" d="M256 0h256v512H256z" />
      <path
        fill="#45B449"
        d="M256 272c-52 .066-151.667 148.735-192 240h384c-40.333-91.265-140-239.934-192-240z"
      />
      <path
        fill="#066B48"
        d="M416 332c-39 .05-113.75 111.552-144 180h288c-30.25-68.448-105-179.95-144-180z"
      />
      <defs>
        <path id="c" d="M256 0h256v512H256z" />
      </defs>
      <clipPath id="d">
        <use xlinkHref="#c" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#d)"
        fill="#2C8D2D"
        d="M256 272c-52 .066-151.667 148.735-192 240h384c-40.333-91.265-140-239.934-192-240z"
      />
    </g>
    <path
      fill="#00BCD4"
      d="M156.338 166.302c17.753 14.032 4.747 41.415-17.16 36.125-21.266-5.137-20.193-35.961 1.393-40.17 4.877-.945 11.648.789 15.767 4.045zm49.382 37.289c-2.348 9.871-37.797 28.947-48.767 26.244-2.548-.629-2.985.456-7.033 17.482l-3.7 15.551 2.473.245c.063.1 7.565.058 12.03.683 4.464.632 17.953-.11 21.045 4.361 3.542 5.128-5.179 26.116-6.604 31.919-2.033 8.286-2.744 15.995-10.093 16.955-3.513.456-8.222-1.904-9.428-8.898l6.863-24.05-9.756.009c-5.969-.859-13.776-1.341-13.833-1.29-.059.05-2.553 4.859-5.549 10.695-5.882 11.439-6.767 12.822-9.22 14.267-3.039 1.786-46.909 10.736-49.993 10.215-7.167-1.224-10.5-9.448-6.327-15.624 2.153-3.173 18.186-6.765 31.916-9.719l12.05-4.47 5.553-13.236c-2.914-.277-10.234-7.633-9.934-13.352.395-7.509 6.463-34.468 5.382-34.735-3.408-.831-15.334 5.695-23.107 12.654-9.365 8.385-14.104 8.879-18.693 1.953-3.621-5.458-2.428-9.127 5.279-16.217 18.543-17.065 34.763-21.57 60.63-16.846 22.829 4.172 29.691 2.785 47.208-9.548 4.83-3.404 9.51-6.407 10.4-6.678 6.67-2.024 12.896 4.328 11.208 11.43z"
    />
    <path
      fill="#FFD500"
      d="M306 84.748c0-1.557-1.125-2.522-3.373-2.892l-30.162-4.607-13.521-28.676C258.186 46.857 257.2 46 256.005 46H256c-1.2.003-2.187.862-2.946 2.574l-13.52 28.676-30.163 4.612c-2.246.37-3.371 1.334-3.371 2.892 0 .879.498 1.895 1.499 3.023l21.875 22.31-5.168 31.51c-.076.584-.111 1.002-.111 1.263 0 .879.208 1.616.624 2.236.42.607 1.057.904 1.897.904.718 0 1.52-.249 2.406-.748L256 130.383l.005-.002 26.975 14.871c.841.5 1.648.749 2.407.749 1.646 0 2.464-1.05 2.464-3.144 0-.548-.026-.98-.062-1.265l-5.166-31.51 21.811-22.308c1.045-1.085 1.566-2.109 1.566-3.026z"
    />
    <path
      fill="#EBB200"
      d="m302.627 81.856-30.162-4.607-13.521-28.676C258.186 46.857 257.2 46 256.005 46H256v84.383l.005-.002 26.975 14.871c.841.5 1.648.749 2.407.749 1.646 0 2.464-1.05 2.464-3.144 0-.549-.026-.98-.062-1.265l-5.166-31.51 21.811-22.308C305.48 86.69 306 85.666 306 84.749c0-1.558-1.125-2.523-3.373-2.893z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
