/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-bullet-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'physics'}</title>
    <circle fill="#00bcd4" cx={256} cy={256} r={256} />
    <path
      fill="#fff"
      d="M233.777 287.914v76.148h-44.531V147.938h84.313c16.229 0 30.504 2.969 42.824 8.906s21.795 14.375 28.426 25.309c6.629 10.936 9.945 23.379 9.945 37.332 0 21.178-7.25 37.877-21.746 50.098-14.498 12.223-34.563 18.332-60.191 18.332h-39.04zm0-36.07h39.781c11.775 0 20.756-2.77 26.941-8.313 6.184-5.541 9.277-13.457 9.277-23.75 0-10.588-3.117-19.148-9.352-25.68s-14.844-9.895-25.828-10.094h-40.82v67.837z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
