import type {Uuid4} from '../core/types';
import type {
  UnsequencedActivityState,
  ResponseState,
  BookState,
  ChapterState,
  SectionState
} from './activity.slice';
import {siyavulaApi} from '../core/api';
import {ACTIVITY_TYPE} from '../core/models/activity/activity';

type CreatePracticeActivityRequest = {
  section_id: number;
};

type AnswerUnsequencedQuestionRequest = {
  activity_id: Uuid4;
  response_id: Uuid4;
  body: object;
};

type RetryUnsequencedQuestionRequest = {
  activity_id: Uuid4;
  response_id: Uuid4;
};

type NextUnsequencedQuestionRequest = {
  activity_id: Uuid4;
  response_id: Uuid4;
};

type PracticeQuestionResponse = {
  activity: UnsequencedActivityState;
  response: ResponseState;
  book: BookState;
  chapter: ChapterState;
  section: SectionState;
};

function transformPracticeResponse(value: {
  activity: UnsequencedActivityState;
  response: ResponseState;
  practice: {
    book: BookState;
    chapter: ChapterState;
    section: SectionState;
  };
}): PracticeQuestionResponse {
  const {activity, response, practice} = value;

  return {
    activity: {
      ...activity,
      activity_type: ACTIVITY_TYPE.PRACTICE,
      is_sequenced: false
    },
    response: {
      ...response,
      activity_id: activity.id
    },
    book: {
      ...practice.book
    },
    chapter: {
      ...practice.chapter
    },
    section: {
      ...practice.section
    }
  };
}

const answerUnsequencedQuestionQuery = ({
  activity_id,
  response_id,
  body
}: AnswerUnsequencedQuestionRequest) => ({
  url: `activity/${activity_id}/response/${response_id}/submit-answer`,
  method: 'POST',
  body: body
});

const retryUnsequencedQuestionQuery = ({
  activity_id,
  response_id
}: RetryUnsequencedQuestionRequest) => ({
  url: `activity/${activity_id}/response/${response_id}/retry`,
  method: 'POST'
});

const nextUnsequencedQuestionQuery = ({
  activity_id,
  response_id
}: NextUnsequencedQuestionRequest) => ({
  url: `activity/${activity_id}/response/${response_id}/next`,
  method: 'POST'
});

const api = siyavulaApi.injectEndpoints({
  endpoints: (build) => ({
    createPracticeActivity: build.mutation<
      PracticeQuestionResponse,
      CreatePracticeActivityRequest
    >({
      query: ({section_id}) => ({
        url: `activity/create/practice/${section_id}`,
        method: 'POST'
      }),
      transformResponse: transformPracticeResponse
    }),
    answerPracticeQuestion: build.mutation<
      PracticeQuestionResponse,
      AnswerUnsequencedQuestionRequest
    >({
      query: answerUnsequencedQuestionQuery,
      transformResponse: transformPracticeResponse
    }),
    retryPracticeQuestion: build.mutation<
      PracticeQuestionResponse,
      RetryUnsequencedQuestionRequest
    >({
      query: retryUnsequencedQuestionQuery,
      transformResponse: transformPracticeResponse
    }),
    nextPracticeQuestion: build.mutation<
      PracticeQuestionResponse,
      NextUnsequencedQuestionRequest
    >({
      query: nextUnsequencedQuestionQuery,
      transformResponse: transformPracticeResponse
    })
  })
});

export const {
  useCreatePracticeActivityMutation,
  useAnswerPracticeQuestionMutation,
  useRetryPracticeQuestionMutation,
  useNextPracticeQuestionMutation
} = api;
