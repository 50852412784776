import React from 'react';
import Icon from '../Icon/Icon';
import './LanguagePicker.scss';

type LanguagePickerProps = {
  language?: string;
};

const LanguagePicker: React.FC<LanguagePickerProps> = (props) => {
  return (
    <div className="sv-language-picker">
      <input
        className="sv-language-picker__checkbox"
        type="checkbox"
        id="language-picker-example-1-toggle"
      />
      <label
        className="sv-language-picker__button"
        htmlFor="language-picker-example-1-toggle"
      >
        <span>
          <span>Kies 'n taal</span>
          <span>(nog nie vertaal na Afr nie)</span>
        </span>
        <Icon name="chevron-back" />
      </label>
      <div className="sv-language-picker__target">Eng | Afr</div>
    </div>
  );
};

export default LanguagePicker;
