import spliceString from './spliceString';

export default function filterEToTimesTen(str: string): string {
  const matches = str.match(/[0-9]+e-*[0-9]*/g);

  if (matches) {
    matches.forEach((match) => {
      const index = match.indexOf('e');
      let splicedString = match.replace('e', '^');
      splicedString = spliceString(splicedString, index, '*10');

      str = str.replace(match, splicedString);
    });
  }

  return str;
}
