import {yupResolver} from '@hookform/resolvers/yup';
import {IonButton} from '@ionic/react';
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Page, TextInput, Message} from '../../../core/components';
import yup from '../../../core/vendor/yup';

const TextInputPage: React.FC = () => {
  const assert = yup.string().required('Required');
  const schema = yup.object({
    empty_input: assert,
    completed_input: assert,
    disabled_input: assert,
    input_without_label: assert
  });
  const form = useForm({
    defaultValues: {
      empty_input: '',
      completed_input: 'lorem ipsum',
      disabled_input: 'lorem ipsum',
      input_without_label: 'lorem ipsum'
    },
    resolver: yupResolver(schema)
  });
  const errors = Object.entries(form.formState.errors).map(([field, error]) => [
    field,
    error.message
  ]);
  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
  };

  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Text Input</h1>

        <div className="tw-not-prose">
          {!!errors.length && (
            <>
              {errors.map((error) => (
                <Message key={error[0]} color="danger">
                  {error[0]}: {error[1]}
                </Message>
              ))}
              <br />
            </>
          )}

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <TextInput
                type="text"
                name="empty_input"
                label="Empty input"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <TextInput
                type="text"
                name="completed_input"
                label="Completed input"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <TextInput
                type="text"
                name="disabled_input"
                label="Disabled input"
                helper="Lorem ipsum dolor sit amet"
                disabled
              />
              <br />
              <TextInput type="text" name="input_without_label" />
              <br />
              <IonButton type="submit">Submit</IonButton>
            </form>
          </FormProvider>
        </div>
      </article>
    </Page>
  );
};

export default TextInputPage;
