/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'exam completed'}</title>
    <circle fill="#EB477E" cx={256} cy={256} r={256} />
    <path
      fill="#585858"
      d="M224 120V88h-80c-8.836 0-16 7.164-16 16v304c0 8.837 7.164 16 16 16h112V120h-32z"
    />
    <path d="M368 88h-80v32h-32v304h112c8.837 0 16-7.163 16-16V104c0-8.836-7.163-16-16-16z" />
    <path fill="#FFF" d="M144 104h224v304H144z" />
    <path fill="#E7E7E7" d="M256 104h112v304H256z" />
    <path
      fill="#848484"
      d="M240 88c0-8.837 7.163-16 16-16V48c-22.091 0-40 17.909-40 40v18c-1.25 15-23.737 17.5-18.737 30H256v-32c-8.837 0-16-7.164-16-16z"
    />
    <path
      fill="#585858"
      d="M296 106V88c0-22.091-17.908-40-40-40v24c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16v32h58.737c5-12.5-17.487-15-18.737-30z"
    />
    <path
      fill="#008BB2"
      d="M184.992 351.156h6.406c2.708 0 4.712-.709 6.015-2.129 1.302-1.419 1.953-3.392 1.953-5.918 0-2.291-.651-4.127-1.953-5.508-1.303-1.38-3.19-2.07-5.664-2.07-2.136 0-3.933.625-5.391 1.875-1.459 1.25-2.188 2.891-2.188 4.922h-11.055l-.078-.234c-.156-4.453 1.549-8.125 5.117-11.016 3.567-2.891 7.994-4.336 13.281-4.336 5.884 0 10.579 1.406 14.082 4.219 3.502 2.813 5.254 6.823 5.254 12.031 0 2.475-.781 4.824-2.344 7.051s-3.711 3.965-6.445 5.215c3.15 1.12 5.553 2.839 7.207 5.156 1.653 2.318 2.48 5.014 2.48 8.086 0 5.209-1.895 9.304-5.684 12.285-3.789 2.982-8.64 4.473-14.55 4.473-5.287 0-9.824-1.399-13.613-4.199-3.789-2.799-5.619-6.751-5.488-11.855l.078-.234h11.055c0 2.188.781 3.991 2.344 5.41 1.563 1.42 3.541 2.129 5.938 2.129 2.629 0 4.707-.729 6.23-2.188 1.523-1.458 2.285-3.438 2.285-5.938 0-2.994-.749-5.188-2.246-6.582-1.498-1.393-3.705-2.09-6.621-2.09h-6.406v-8.555zM173.039 304.848v-7.461l18.398-19.727c2.657-3.047 4.544-5.618 5.665-7.715 1.119-2.096 1.68-4.043 1.68-5.84 0-2.396-.645-4.355-1.934-5.879s-3.119-2.285-5.489-2.285c-2.631 0-4.629.893-5.996 2.676-1.367 1.784-2.051 4.16-2.051 7.129h-11.094l-.078-.234c-.131-5.13 1.556-9.473 5.059-13.027 3.502-3.555 8.223-5.332 14.16-5.332 5.86 0 10.469 1.523 13.829 4.57s5.039 7.109 5.039 12.188c0 3.438-.944 6.615-2.832 9.531-1.889 2.917-5.02 6.719-9.395 11.406l-10.118 11.055.078.195h14.766l.469-5.625h8.672v14.375h-38.828zM176.766 217.602l9.609-1.172v-38.516h-9.727v-6.523l21.094-3.828v48.867l9.609 1.172v6.836h-30.586v-6.836z"
    />
    <path
      fill="#585858"
      d="M256 208h16v8h-16zM256 256h88v8h-88zM256 272h88v8h-88zM256 288h56v8h-56zM256 176h16v8h-16zM256 192h16v8h-16zM256 336h88v8h-88zM256 352h88v8h-88zM256 368h88v8h-88z"
    />
    <path fill="#035E82" d="M288 168h56v56h-56z" />
    <path
      fill="#848484"
      d="M232 336h24v8h-24zM232 352h24v8h-24zM232 368h24v8h-24zM232 288h24v8h-24zM232 272h24v8h-24zM232 256h24v8h-24zM232 208h24v8h-24zM232 192h24v8h-24zM232 176h24v8h-24z"
    />
    <circle fill="#FFF" cx={400} cy={400} r={112} />
    <path
      fill="#45B449"
      d="M400 304c-53.014 0-96 42.986-96 96s42.986 96 96 96 96-42.986 96-96-42.986-96-96-96zm49.243 63.9-59.614 76.5c-.472.471-1.243 1.5-2.186 1.5-.986 0-1.629-.686-2.186-1.243-.559-.558-33.814-32.528-33.814-32.528l-.643-.643c-.258-.387-.473-.857-.473-1.372s.215-.985.473-1.371c.171-.172.299-.3.471-.515 3.3-3.472 9.986-10.5 10.414-10.928.557-.559 1.029-1.287 2.057-1.287 1.072 0 1.758.9 2.271 1.415.515.515 19.287 18.558 19.287 18.558l47.7-61.287c.429-.342.943-.6 1.5-.6s1.071.215 1.5.558l13.114 10.329c.343.428.558.942.558 1.5.043.557-.172 1.028-.429 1.414z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
