import {IonIcon} from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import type {Ionicons, IoniconsName} from './icons.ionicons';
import ionicons, {set as ioniconsSet} from './icons.ionicons';
import type {Ui, UiName} from './icons.sv-ui-icon';
import ui, {set as uiSet} from './icons.sv-ui-icon';

export type IconName = IoniconsName | UiName;

const getIcon = _.memoize(
  (name: IconName, set?: Ionicons | Ui): string | undefined => {
    if (set === ioniconsSet) {
      if (ionicons.has(name as IoniconsName)) {
        return ionicons.get(name as IoniconsName);
      } else {
        return undefined;
      }
    }

    if (set === uiSet) {
      if (ui.has(name as UiName)) {
        return ui.get(name as UiName);
      } else {
        return undefined;
      }
    }

    if (ui.has(name as UiName)) {
      return ui.get(name as UiName);
    }

    if (ionicons.has(name as IoniconsName)) {
      return ionicons.get(name as IoniconsName);
    }
  },
  (...args) => JSON.stringify(args)
);

type Props = {
  name: IconName;
  set?: Ionicons | Ui;
  size?: 'small' | 'large';
} & Pick<
  React.HTMLAttributes<HTMLIonIconElement>,
  'className' | 'color' | 'slot'
>;

const Icon: React.FC<Props> = (props) => {
  const {className, color, size, slot} = props;
  const icon = getIcon(props.name, props.set);

  if (!icon) {
    return null;
  }

  if (slot) {
    return (
      <IonIcon
        className={className}
        icon={icon}
        color={color}
        size={size}
        slot={slot}
      />
    );
  }

  return (
    <IonIcon className={className} icon={icon} color={color} size={size} />
  );
};

export default React.memo(Icon);
