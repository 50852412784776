export const PATH_STYLE_GUIDE_INDEX = '/style-guide';

export const PATH_STYLE_GUIDE_TYPOGRAPHY =
  '/style-guide/foundations/typography';
export const PATH_STYLE_GUIDE_COLOURS = '/style-guide/foundations/colours';
export const PATH_STYLE_GUIDE_EMOJI = '/style-guide/foundations/emoji';
export const PATH_STYLE_GUIDE_ICONS = '/style-guide/foundations/icons';
export const PATH_STYLE_GUIDE_GRIDS = '/style-guide/foundations/grids';

export const PATH_STYLE_GUIDE_LIST = '/style-guide/components/list';
export const PATH_STYLE_GUIDE_TABLE = '/style-guide/components/table';
export const PATH_STYLE_GUIDE_BUTTON = '/style-guide/components/button';
export const PATH_STYLE_GUIDE_CHECKBOX = '/style-guide/components/checkbox';
export const PATH_STYLE_GUIDE_DATE_INPUT = '/style-guide/components/date-input';
export const PATH_STYLE_GUIDE_DATETIME_PICKER =
  '/style-guide/components/datetime-picker';
export const PATH_STYLE_GUIDE_LANGUAGE_PICKER =
  '/style-guide/components/language-picker';
export const PATH_STYLE_GUIDE_PASSWORD = '/style-guide/components/password';
export const PATH_STYLE_GUIDE_MASTERY_METER =
  '/style-guide/components/mastery-meter';
export const PATH_STYLE_GUIDE_MASTERY_PROGRESS_DONUT =
  '/style-guide/components/mastery-progress-donut';
export const PATH_STYLE_GUIDE_PROGRESS_BAR =
  '/style-guide/components/progress-bar';
export const PATH_STYLE_GUIDE_PROGRESS_DONUT =
  '/style-guide/components/progress-donut';
export const PATH_STYLE_GUIDE_RADIO = '/style-guide/components/radio';
export const PATH_STYLE_GUIDE_SELECT = '/style-guide/components/select';
export const PATH_STYLE_GUIDE_TELEPHONE = '/style-guide/components/telephone';
export const PATH_STYLE_GUIDE_TEXT_INPUT = '/style-guide/components/text-input';

export const PATH_STYLE_GUIDE_QUESTION = '/style-guide/practice/question';
