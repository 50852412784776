import {
  IonGrid,
  IonRow,
  IonCol,
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar
} from '@ionic/react';
import React from 'react';
import {Link} from 'react-router-dom';
import {PATH_CREATE_PRACTICE_ACTIVITY} from '../../activity/activity.paths';
import {MasteryMeter} from '../../core/components';
import {CURRICULUM} from '../../core/models/curriculum/curriculum';
import compilePath from '../../core/utils/compilePath';
import type {PracticeTocBook} from '../practice-dashboard.api';
import './PracticeToc.scss';

const PracticeToc: React.FC<{
  book: PracticeTocBook;
}> = (props) => {
  const {book} = props;

  return (
    <div className="sv-practice-toc">
      <div className="sv-practice-toc__header">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              sizeMd="7"
              sizeLg="8"
              className="ion-hide-md-down"
            >
              <strong>Chapters</strong>
            </IonCol>
            <IonCol size="12" sizeMd="5" sizeLg="4">
              <strong>Mastery</strong>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <IonAccordionGroup>
        {book.chapters.map((chapter) => (
          <IonAccordion key={chapter.id} value={chapter.id.toString()}>
            <IonItem slot="header" lines="none">
              <IonLabel className="ion-text-wrap">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" sizeMd="7" sizeLg="8">
                      {book.curriculum === CURRICULUM.INTL ? (
                        <strong>
                          {chapter.topic_number}. {chapter.number}
                        </strong>
                      ) : (
                        <strong>{chapter.number}.</strong>
                      )}{' '}
                      {chapter.title}
                    </IonCol>
                    <IonCol size="12" sizeMd="5" sizeLg="4">
                      <IonProgressBar
                        type="determinate"
                        value={chapter.mastery / 100}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonLabel>
            </IonItem>
            <div slot="content">
              <IonList lines="none">
                {chapter.sections.map((section) => (
                  <IonItem key={section.id}>
                    <IonLabel className="ion-text-wrap">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="12" sizeMd="7" sizeLg="8">
                            <Link
                              to={compilePath(PATH_CREATE_PRACTICE_ACTIVITY, {
                                section_id: section.id
                              })}
                            >
                              {book.curriculum === CURRICULUM.INTL ? (
                                <strong>{chapter.topic_number}.</strong>
                              ) : (
                                <strong>
                                  {chapter.number}.{section.number}
                                </strong>
                              )}{' '}
                              {section.title}
                            </Link>
                          </IonCol>
                          <IonCol size="12" sizeMd="5" sizeLg="4">
                            <MasteryMeter value={section.mastery} />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonAccordion>
        ))}
      </IonAccordionGroup>
      <div className="sv-practice-toc__footer">
        <IonButton disabled>Start practising</IonButton>
      </div>
    </div>
  );
};

export default PracticeToc;
