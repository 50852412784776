/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'responsive'}</title>
    <circle fill="#DDD" cx={256} cy={256} r={256} />
    <path fill="#E9E9E9" d="M254 145h147v175H254z" />
    <g fill="#E9E9E9">
      <path d="M306 286.941c0-.873.631-.941 1.546-.941h10.115c.874 0 1.339.027 1.339.941v.127c0 .873-.424.932-1.339.932h-10.115c-.874 0-1.546-.016-1.546-.932v-.127zM312.333 420.266c-2.211 0-4-1.789-4-4.001 0-2.21 1.789-3.999 4-3.999s4 1.789 4 3.999a4.013 4.013 0 0 1-4 4.001zM280 304h64v96h-64z" />
    </g>
    <path fill="#FFF" d="M112 145h142v175H112z" />
    <path d="M254 320v16h26v-16h-26zm154.42-192H254v17h147v175h-57v16h64.42c4.29 0 7.58-3.65 7.58-8.05V136.02c0-4.41-3.22-8.02-7.58-8.02zM288 410c0 6-16 6-16 6h-18v-64h18v32c0 16 16 23 16 26z" />
    <path d="M342.59 272h-60.01c-5.45 0-10.58 4.45-10.58 9.95v139.98c0 5.45 5.13 10.07 10.58 10.07h59.96c5.45 0 9.46-4.62 9.46-10.07V281.95c0-5.37-3.97-9.95-9.41-9.95zM306 286.939c0-.869.63-.939 1.55-.939h10.11c.88 0 1.34.029 1.34.939v.131c0 .869-.42.93-1.34.93h-10.11c-.88 0-1.55-.02-1.55-.93v-.131zm6.33 133.331a4 4 0 0 1-4-4.01c0-2.21 1.79-3.99 4-3.99s4 1.78 4 3.99c0 2.22-1.81 4.01-4 4.01zM344 400h-64v-96h64v96z" />
    <g fill="#5B5B5B">
      <path d="M254 145v-17H104.08c-4.29 0-8.08 3.61-8.08 8.02v191.93c0 4.47 3.72 8.05 8.08 8.05H254v-16H112V145h142zM254 352v64h-30s-16 0-16-6c0-3 16-10 16-26v-32h30z" />
    </g>
    <path fill="#006D8C" d="M296 320h32v32h-32z" />
    <path fill="#5B5B5B" d="M288 360h48v8h-48zM288 376h48v8h-48z" />
    <path fill="#008bb2" d="M138 176h64v64h-64zM222 176h32v64h-32z" />
    <path fill="#006D8C" d="M306 176h64v64h-64z" />
    <path fill="#CCC" d="M138 256h116v8H138zM138 272h116v8H138z" />
    <path fill="#006D8C" d="M254 176h32v64h-32z" />
    <path
      fill="#5B5B5B"
      d="M254 256h116v8H254zM280 272v.33c-3.75.96-6.939 3.939-7.779 7.67H254v-8h26z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
