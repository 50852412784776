import {useIonToast} from '@ionic/react';
import type {ToastOptions} from '@ionic/react';
import clsx from 'clsx';
import {initializeApp} from 'firebase/app';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage
} from 'firebase/messaging';
import {notificationsSharp} from 'ionicons/icons';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectSession} from '../auth/auth.slice';
import {
  firebase as firebaseConfig,
  firebaseMessaging as firebaseMessagingConfig
} from '../core/config';
import {
  useClearTokenMutation,
  useSaveTokenMutation
} from './push-notifications.api';
import {unsetToken, selectToken, setToken} from './push-notifications.slice';

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const {vapidKey} = firebaseMessagingConfig;

/**
 * Register for push notifications if the user is authenticated.
 */
export const useRegisterPushNotifications = () => {
  const dispatch = useDispatch();
  const {is_authenticated, siyavula_id} = useSelector(selectSession);
  const storedToken = useSelector(selectToken);
  const [saveToken, {isLoading: isSavingToken}] = useSaveTokenMutation();
  const [clearToken] = useClearTokenMutation();
  const [presentToast] = useIonToast();

  // NOTE Queue is necessary because Ionic does not support stacking of toasts:
  // https://github.com/ionic-team/ionic-framework/issues/20389
  // TODO Consider moving this to global state if it interferes with toasts created elsewhere
  const [toastQueue, setToastQueue] = useState<ToastOptions[]>([]);
  const [isPresentingToast, setIsPresentingToast] = useState<boolean>(false);

  useEffect(() => {
    if (!is_authenticated || !siyavula_id || isSavingToken) {
      return;
    }

    // Fetch FCM token from Firebase
    getToken(messaging, {
      vapidKey
    })
      .then((currentToken) => {
        if (currentToken) {
          if (storedToken !== currentToken) {
            // Save token in backend
            saveToken({siyavula_id, token: currentToken})
              .unwrap()
              .then(() => {
                // Add token to app state
                dispatch(setToken({token: currentToken}));
              })
              .catch(() => {
                // Remove token from app state
                dispatch(unsetToken());
              });
          }
        }
        // TODO Find out why this could happen and decide whether to clear stored token
        else {
          if (storedToken) {
            // Remove token from backend
            clearToken({siyavula_id, token: storedToken})
              .unwrap()
              .finally(() => {
                // Remove token from app state
                dispatch(unsetToken());
              });
          }
        }
      })
      // Network error or user denied permission
      .catch(() => {
        if (storedToken) {
          // Remove token from backend
          clearToken({siyavula_id, token: storedToken})
            .unwrap()
            .finally(() => {
              // Remove token from app state
              dispatch(unsetToken());
            });
        }
      });
  }, [is_authenticated, siyavula_id, isSavingToken]);

  useEffect(() => {
    onMessage(messaging, (payload: MessagePayload) => {
      const {notification} = payload;

      if (notification) {
        const {title, body} = notification;
        const toast: ToastOptions = {
          cssClass: clsx({
            'push-notification': true,
            'has-header': !!title,
            'has-message': !!body
          }),
          icon: notificationsSharp,
          header: title,
          message: body,
          position: 'top',
          buttons: [
            {
              text: 'Dismiss',
              role: 'cancel'
            }
          ]
        };
        setToastQueue([...toastQueue, toast]);
      }
    });
  }, []);

  useEffect(() => {
    if (!isPresentingToast && toastQueue.length) {
      presentToast({
        ...toastQueue[0],
        onDidDismiss: () => setIsPresentingToast(false)
      });
      setToastQueue(toastQueue.slice(1));
      setIsPresentingToast(true);
    }
  }, [toastQueue, isPresentingToast]);
};
