import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../store';
import type {REGION} from '../region/region';
import type {SUBJECT} from '../subject/subject';
import type {RegionSubjectGrade} from './region-subject-grade';
import data from '../../data/region-subject-grades.json';

const sortComparer = (a: RegionSubjectGrade, b: RegionSubjectGrade) => {
  if (a.region != b.region) {
    return a.region.localeCompare(b.region);
  }
  if (a.subject_id != b.subject_id) {
    return a.subject_id - b.subject_id;
  }
  if (!a.grade || !b.grade) {
    return 0;
  }
  return a.grade - b.grade;
};

const adapter = createEntityAdapter<RegionSubjectGrade>({
  sortComparer
});

const slice = createSlice({
  name: 'regionSubjectGrades',
  initialState: {
    ids: [...(data as RegionSubjectGrade[])]
      .sort(sortComparer)
      .map((d) => d.id),
    entities: Object.fromEntries(
      (data as RegionSubjectGrade[]).map((d) => [d.id, d])
    )
  },
  reducers: {
    regionSubjectGradesSetAll: adapter.setAll
  }
});

export const {regionSubjectGradesSetAll} = slice.actions;

export default slice.reducer;

export const regionSubjectGradesSelectors = adapter.getSelectors(
  (state: RootState) => state.regionSubjectGrades
);

export const selectSubjectGradesForRegion =
  (region: REGION) => (state: RootState) =>
    regionSubjectGradesSelectors
      .selectAll(state)
      .filter((entity) => entity.region === region);

export const selectGradesForRegionSubject =
  (region: REGION, subject_id?: SUBJECT) => (state: RootState) =>
    regionSubjectGradesSelectors
      .selectAll(state)
      .filter(
        (entity) => entity.region === region && entity.subject_id === subject_id
      );
