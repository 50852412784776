import {siyavulaApi} from '../core/api';
import type {Overwrite} from '../core/types';
import {CURRICULUM} from '../core/models/curriculum/curriculum';
import {SUBJECT_NAME} from '../core/models/subject/subject';
import type {SiyavulaId} from '../core/types';
import type {Book} from '../core/models/book/book';
import type {Chapter} from '../core/models/chapter/chapter';
import type {Section} from '../core/models/section/section';

type PracticeTocRequest = {
  siyavula_id: SiyavulaId;
  subject_name: SUBJECT_NAME;
  grade: number;
};

type PracticeTocSection = Section & {
  mastery: number;
};

type PracticeTocChapter = Overwrite<
  Chapter,
  {
    display_topic: boolean;
    topic_number: number;
    topic: string;
    mastery: number;
    sections: PracticeTocSection[];
  }
>;

export type PracticeTocBook = Overwrite<
  Book,
  {
    curriculum: CURRICULUM;
    chapters: PracticeTocChapter[];
  }
>;

const api = siyavulaApi.injectEndpoints({
  endpoints: (build) => ({
    getPracticeToc: build.query<PracticeTocBook, PracticeTocRequest>({
      query: ({siyavula_id, subject_name, grade}) => ({
        url: `toc/user/${siyavula_id}/subject/${subject_name}/grade/${grade}`,
        method: 'GET'
      })
    })
  })
});

export const {useGetPracticeTocQuery} = api;
