import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../store';
import {REGION} from '../region/region';
import type {Grade} from './grade';
import data from '../../data/grades.json';

const adapter = createEntityAdapter<Grade>();

const slice = createSlice({
  name: 'grades',
  initialState: {
    ids: (data as Grade[]).map((d) => d.id),
    entities: Object.fromEntries((data as Grade[]).map((d) => [d.id, d]))
  },
  reducers: {
    gradesSetAll: adapter.setAll
  }
});

export const {gradesSetAll} = slice.actions;

export default slice.reducer;

export const gradesSelectors = adapter.getSelectors(
  (state: RootState) => state.grades
);

export const selectGradeName =
  (region: REGION, grade: number) => (state: RootState) => {
    const entity = gradesSelectors
      .selectAll(state)
      .filter((entity) => entity.region === region && entity.grade === grade)
      .pop();

    if (region === REGION.ZA) {
      return entity?.full_name;
    }

    return entity?.short_name;
  };
