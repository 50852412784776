import Emoji from '../Emoji';
import shortcodeToEmoji from './shortcodeToEmoji';

const variationRe = /^:skin-tone-[2-6]:$/;

const splitRe = /(:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?)/;

const isShortcode = (d: string) => d.startsWith(':') && d.endsWith(':');

const isVariationShortcode = (d: string) => variationRe.test(d);

function emojify(
  text: string,
  options?: {
    strip?: boolean;
  }
): (JSX.Element | string)[] {
  const {strip = false} = options || {};

  return text
    .split(splitRe)
    .filter((part) => part !== undefined && part !== '')
    .map((part) => {
      if (!isShortcode(part)) {
        return part;
      }

      // Split regexp matches both shortname and variation, e.g. matches of ":hand::skin-tone-6:"
      // are [":hand::skin-tone-6:", ":skin-tone-6:"]. Remove the unwanted 2nd match here.
      if (isVariationShortcode(part)) {
        return undefined;
      }

      const emoji = shortcodeToEmoji(part);

      if (emoji) {
        return <Emoji key={part} shortcode={part} emoji={emoji} />;
      }

      if (strip) {
        return undefined;
      }

      return part;
    })
    .filter((part) => part !== undefined) as (JSX.Element | string)[];
}

export default emojify;
