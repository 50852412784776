import {getConfig, IonItem, IonLabel, IonNote, IonInput} from '@ionic/react';
import clsx from 'clsx';
import _ from 'lodash';
import {DateTime} from 'luxon';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {useImmer} from 'use-immer';
import toNumber from '../../utils/toNumber';
import './DateInput.md.scss';

function parseValue(value: string) {
  if (!value) {
    return;
  }

  const matches = value.match(/^(\d{4})-(\d{2})-(\d{2})$/);

  if (matches?.length) {
    const year = matches[1];
    const month = _.trimStart(matches[2], '0');
    const day = _.trimStart(matches[3], '0');

    return {year, month, day};
  }
}

function getValue(
  day: string,
  month: string,
  year: string,
  isSubmitted: boolean
): [string, {shouldValidate: boolean}] {
  const dayNumber = toNumber(day);
  const monthNumber = toNumber(month);
  const yearNumber = toNumber(year);
  let value = '';

  if (dayNumber && monthNumber && yearNumber) {
    const m = _.padStart(monthNumber.toString(), 2, '0');
    const d = _.padStart(dayNumber.toString(), 2, '0');
    value = `${yearNumber}-${m}-${d}`;
  }

  return [
    value,
    {
      shouldValidate:
        isSubmitted && day.length >= 1 && month.length >= 1 && year.length >= 4
    }
  ];
}

type InputProps = {
  disabled?: boolean;
  hasError?: boolean;
  label?: string;
  type: 'day' | 'month' | 'year';
  min?: number;
  max?: number;
  maxLength: number;
  onChange: any; // TODO Add definition
  value?: string | number;
};

const Input: React.FC<InputProps> = (props) => {
  const {
    disabled,
    hasError,
    label,
    min,
    max,
    maxLength,
    type,
    value,
    onChange
  } = props;
  const mode = getConfig()?.get('mode') || 'md';
  const fill = mode === 'md' ? 'outline' : undefined;
  const position = mode === 'md' ? 'floating' : undefined;

  return (
    <IonItem fill={fill} className={clsx({'has-error': hasError})}>
      {label && <IonLabel position={position}>{label}</IonLabel>}
      <IonInput
        type="number"
        autocomplete={`bday-${type}`}
        inputMode="numeric"
        pattern={`\\d{${maxLength}}`}
        min={min}
        max={max}
        maxlength={maxLength}
        value={value}
        disabled={disabled}
        onIonChange={(e) => onChange(e.detail.value)}
      />
    </IonItem>
  );
};

type DateInputProps = {
  disabled?: boolean;
  helper?: string;
  label?: string;
  lines?: 'full' | 'inset' | 'none';
  max?: string;
  min?: string;
  name: string;
  placeholder?: string;
};

const DateInput: React.FC<DateInputProps> = (props) => {
  const {disabled, helper, label, name, min, max} = props;
  const {
    control,
    formState: {errors, isSubmitted},
    getValues,
    setValue
  } = useFormContext();
  const error = errors[name]?.message;

  const minDay = 1;
  const maxDay = 31;
  const minMonth = 1;
  const maxMonth = 12;
  const minYear = min ? DateTime.fromISO(min).year : undefined;
  const maxYear = max ? DateTime.fromISO(max).year : undefined;

  const defaultValue = getValues(name);
  const {
    day: defaultDay = '',
    month: defaultMonth = '',
    year: defaultYear = ''
  } = parseValue(defaultValue) || {};
  const [day, setDay] = useImmer<string>(defaultDay);
  const [month, setMonth] = useImmer<string>(defaultMonth);
  const [year, setYear] = useImmer<string>(defaultYear);

  return (
    <div className={clsx({'item-date-input': true, 'has-error': error})}>
      {label && <IonLabel>{label}</IonLabel>}
      <Controller
        control={control}
        name={name}
        render={() => (
          <div className="tw-grid tw-max-w-xs tw-grid-cols-[2fr_2fr_3fr] tw-gap-2">
            <Input
              label="Day"
              type="day"
              maxLength={2}
              min={minDay}
              max={maxDay}
              value={day}
              disabled={disabled}
              hasError={!!error}
              onChange={(value: string) => {
                const day = value.trim().slice(0, 2);
                const [fieldValue, options] = getValue(
                  day,
                  month,
                  year,
                  isSubmitted
                );
                setDay(day);
                setValue(name, fieldValue, {
                  ...options,
                  shouldDirty: true,
                  shouldTouch: true
                });
              }}
            />
            <Input
              label="Month"
              type="month"
              maxLength={2}
              min={minMonth}
              max={maxMonth}
              value={month}
              disabled={disabled}
              hasError={!!error}
              onChange={(value: string) => {
                const month = value.trim().slice(0, 2);
                const [fieldValue, options] = getValue(
                  day,
                  month,
                  year,
                  isSubmitted
                );
                setMonth(month);
                setValue(name, fieldValue, {
                  ...options,
                  shouldDirty: true,
                  shouldTouch: true
                });
              }}
            />
            <Input
              label="Year"
              type="year"
              maxLength={4}
              min={minYear}
              max={maxYear}
              value={year}
              disabled={disabled}
              hasError={!!error}
              onChange={(value: string) => {
                const year = value.trim().slice(0, 4);
                const [fieldValue, options] = getValue(
                  day,
                  month,
                  year,
                  isSubmitted
                );
                setYear(year);
                setValue(name, fieldValue, {
                  ...options,
                  shouldDirty: true,
                  shouldTouch: true
                });
              }}
            />
          </div>
        )}
      />
      {error && (
        <IonNote color="danger" slot="helper">
          {error}
        </IonNote>
      )}
      {!error && helper && <IonNote slot="helper">{helper}</IonNote>}
    </div>
  );
};

export default DateInput;
