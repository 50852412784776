/**
 * NOTE IonListHeader is not used because text inside .list-header-inner does not wrap.
 * The element cannot be styled because it has no part attribute.
 */
import {
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRadioGroup,
  IonRadio
} from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import './RadioGroup.md.scss';

type Props = {
  allowEmptySelection?: boolean;
  helper?: string;
  label?: string;
  lines?: 'full' | 'inset' | 'none';
  name: string;
  options: {label: string; value: string; disabled?: boolean}[];
};

const RadioGroup: React.FC<Props> = (props) => {
  const {
    allowEmptySelection,
    helper,
    label,
    lines = 'none',
    name,
    options
  } = props;
  const {
    control,
    formState: {errors}
  } = useFormContext();
  const error = errors[name]?.message;

  return (
    <IonList
      className={clsx({'list-radio-group': true, 'has-error': error})}
      lines={lines}
    >
      <Controller
        control={control}
        name={name}
        render={({field: {value, onChange}}) => (
          <IonRadioGroup
            allowEmptySelection={allowEmptySelection}
            value={value}
            onIonChange={onChange}
          >
            {label && <div className="list-header">{label}</div>}
            {options.map(({value, label, disabled}) => (
              <IonItem key={value} className="item-radio" lines="none">
                <IonLabel>{label}</IonLabel>
                <IonRadio slot="start" disabled={disabled} value={value} />
              </IonItem>
            ))}
          </IonRadioGroup>
        )}
      />
      {error && (
        <IonNote color="danger" slot="error">
          {error}
        </IonNote>
      )}
      {!error && helper && <IonNote slot="helper">{helper}</IonNote>}
    </IonList>
  );
};

export default RadioGroup;
