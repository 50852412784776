import React from 'react';
import type {FlagIconName} from './icons';
import {names} from './icons';
import './icons.css';

type Props = {
  name: FlagIconName;
} & Pick<React.HTMLAttributes<HTMLIonIconElement>, 'className' | 'slot'>;

const FlagIcon: React.FC<Props> = (props) => {
  const {className, name, slot} = props;

  if (!names.includes(name)) {
    return null;
  }

  let computedClassName = `sv-flag-icon-${name}`;
  if (className) {
    computedClassName += ' ' + className;
  }

  return <span className={computedClassName} slot={slot} />;
};

export default React.memo(FlagIcon);
