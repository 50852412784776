import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {selectSession} from '../../auth/auth.slice';
import {ROLE} from '../../core/models/user/user';
import logHistory from '../../core/utils/logHistory';
import {
  PATH_HOME_PUBLIC,
  PATH_HOME_LEARNER,
  PATH_HOME_TEACHER
} from '../home.paths';

const RedirectToUserHome: React.FC = () => {
  // logHistory('RedirectToUserHome');

  const {is_authenticated, user} = useSelector(selectSession);
  let to;

  // TODO Check whether truthiness of is_authenticated and user could be mismatched
  if (is_authenticated && user) {
    if (user.role_id === ROLE.STUDENT) {
      to = PATH_HOME_LEARNER;
    } else if (user.role_id === ROLE.TEACHER) {
      to = PATH_HOME_TEACHER;
    } else {
      to = PATH_HOME_LEARNER;
    }
  } else {
    to = PATH_HOME_PUBLIC;
  }

  return <Redirect to={to} />;
};

export default RedirectToUserHome;
