import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import React from 'react';
import {Provider} from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import {Menu} from './core/components';
import routes from './core/routes';
import {store} from './core/store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'; // Enabled because Tailwind doesn't work with shadow DOM out of box. Must be re-implemented for Tailwind.
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css'; // Enabled because ion-col (grid component) can be configured using both React props and HTML classes, and these share breakpoints. Must be re-implemented for Tailwind.

/* Theme variables */
import './core/theme/variables.ionic.css';
import './core/theme/variables.misc.css';

/* Theme CSS */
import './core/theme/ionic.scss';
import './core/theme/ionic.md.scss';
import './core/theme/ionic.ios.scss';
import './core/theme/tailwind.css';
import './core/theme/normalize.scss';
import './core/theme/table.scss'; // Temporary

setupIonicReact({
  rippleEffect: false
});
smoothscroll.polyfill();

const App: React.FC = () => {
  return (
    <IonApp>
      <Provider store={store}>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main" animated={false}>
              {React.Children.toArray(routes)}
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </Provider>
    </IonApp>
  );
};

export default App;
