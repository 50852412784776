import type {MathJaxContextProps} from 'better-react-mathjax';
import type {PageMeta} from './types';

// TODO Move to env
export const firebase = {
  apiKey: 'AIzaSyAx8_ZIUY68vu1X3S00ywcasgbkuW9ATUU',
  authDomain: 'siyavula-5330c.firebaseapp.com',
  projectId: 'siyavula-5330c',
  storageBucket: 'siyavula-5330c.appspot.com',
  messagingSenderId: '869504462768',
  appId: '1:869504462768:web:f62574a4a6e07cd81f3075'
};

// TODO Move to env
export const firebaseMessaging = {
  vapidKey:
    'BHW4VmVNI1timX04bXcOFr4fGegF6M8seeWQVpP1hU5fumZvV3et43M-Vn84lzGEmMVn-BK8S7swP0LBsmA71Do'
};

export default {
  pageMeta: {
    title: 'Demo App',
    description: 'Lorem ipsum'
  } as PageMeta,
  mathjaxContext: {
    config: {
      'HTML-CSS': {
        availableFonts: ['TeX'],
        preferredFont: 'TeX',
        webFont: 'TeX'
      },
      MathMenu: {
        showFontMenu: false,
        showLocale: false,
        showRenderer: false
      }
    },
    src: '/assets/mathjax/MathJax.js?config=TeX-MML-AM_HTMLorMML-full&locale=en',
    version: 2
  } as MathJaxContextProps
};
