import {yupResolver} from '@hookform/resolvers/yup';
import {IonButton} from '@ionic/react';
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import * as yup from 'yup';
import {transformError} from '../../core/api';
import {Page, Message, TextInput} from '../../core/components';
import {getHomeUrl} from '../../core/models/user/user';
import logHistory from '../../core/utils/logHistory';
import type {LoginRequest} from '../auth.api';
import {useLoginMutation} from '../auth.api';
import {setSession, selectSession} from '../auth.slice';

const schema = yup.object({
  identifier: yup.string().required('Required'),
  password: yup.string().required('Required')
});

const LoginPage: React.FC = () => {
  // logHistory('LoginPage');

  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  const methods = useForm<LoginRequest>({
    // defaultValues: {
    //   identifier: 'BUJ2WKX',
    //   password: 'password'
    // }, // TODO Remove after dev
    resolver: yupResolver(schema)
  });
  const {region, is_authenticated} = useSelector(selectSession);
  const {
    handleSubmit
    // formState: {errors}
  } = methods;
  const [login, {error, isLoading}] = useLoginMutation();
  // const returnTo = ((location.state || {}) as {returnTo?: string}).returnTo;

  const onSubmit = handleSubmit((data) => {
    if (isLoading || is_authenticated) {
      return;
    }
    login({...data, region})
      .unwrap()
      .then((data) => {
        dispatch(setSession(data));
        // const _returnTo = returnTo || getHomeUrl(data.user);
        const _returnTo = getHomeUrl(data.user);
        history.replace(_returnTo);
      })
      .catch(() => {}); // No-op because error returned by mutation will be surfaced
  });

  return (
    <Page meta={{title: 'Log In'}}>
      <div className="tw-page">
        {error && (
          <>
            <Message color="danger">{transformError(error)?.message}</Message>
            <br />
          </>
        )}
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <TextInput type="text" name="identifier" label="Siyavula ID" />
            <br />
            <TextInput type="password" name="password" label="Password" />
            <br />
            <IonButton expand="block" type="submit" disabled={isLoading}>
              Log in
            </IonButton>
          </form>
        </FormProvider>
      </div>
    </Page>
  );
};

export default LoginPage;
