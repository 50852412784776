import _ from 'lodash';

export const toArray = (value: null | string | string[]) => {
  if (value === null) {
    return [];
  }

  if (_.isArray(value)) {
    return value;
  }

  return [value];
};
