import {yupResolver} from '@hookform/resolvers/yup';
import {IonButton} from '@ionic/react';
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Page, DatetimePicker, Message} from '../../../core/components';
import yup from '../../../core/vendor/yup';

const DatetimePickerPage: React.FC = () => {
  const singleAssert = yup.string().ensure().required('Required');
  const multipleAssert = yup
    .array(yup.string())
    .compact()
    .required('Required')
    .min(1, 'Required');
  const schema = yup.object({
    empty_date: singleAssert,
    completed_date: singleAssert,
    disabled_date: singleAssert,
    empty_multiple_dates: multipleAssert,
    completed_multiple_dates: multipleAssert,
    disabled_multiple_dates: multipleAssert
  });
  const form = useForm({
    defaultValues: {
      empty_date: null,
      completed_date: '2022-05-21',
      disabled_date: '2022-05-21',
      empty_multiple_dates: [],
      completed_multiple_dates: ['2022-05-21', '2022-05-26'],
      disabled_multiple_dates: ['2022-05-21', '2022-05-26']
    },
    resolver: yupResolver(schema)
  });
  const errors = Object.entries(form.formState.errors).map(([field, error]) => [
    field,
    error.message
  ]);
  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
  };

  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Date and Time Picker</h1>

        <div className="tw-not-prose">
          {!!errors.length && (
            <>
              {errors.map((error) => (
                <Message key={error[0]} color="danger">
                  {error[0]}: {error[1]}
                </Message>
              ))}
              <br />
            </>
          )}

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <DatetimePicker
                name="empty_date"
                label="Empty input"
                helper="Lorem ipsum dolor sit amet"
                presentation="date"
              />
              <br />
              <DatetimePicker
                name="completed_date"
                label="Completed input"
                helper="Lorem ipsum dolor sit amet"
                presentation="date"
              />
              <br />
              <DatetimePicker
                name="disabled_date"
                label="Disabled input"
                helper="Lorem ipsum dolor sit amet"
                presentation="date"
                disabled
              />
              <br />
              <DatetimePicker
                name="empty_multiple_dates"
                label="Multiple dates that has none selected by default"
                helper="Lorem ipsum dolor sit amet"
                presentation="date"
                multiple
              />
              <br />
              <DatetimePicker
                name="completed_multiple_dates"
                label="Multiple dates that has some selected by default"
                helper="Lorem ipsum dolor sit amet"
                presentation="date"
                multiple
              />
              <br />
              <DatetimePicker
                name="disabled_multiple_dates"
                label="Disabled multiple dates that has a very long label"
                helper="Lorem ipsum dolor sit amet"
                presentation="date"
                disabled
                multiple
              />
              <br />
              <IonButton type="submit">Submit</IonButton>
            </form>
          </FormProvider>
        </div>
      </article>
    </Page>
  );
};

export default DatetimePickerPage;
