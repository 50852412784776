// import type {Config} from 'tailwindcss/types/config';

const config = {
  theme: {
    colors: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    fontFamily: {
      sans: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
      serif: ['"Roboto Slab"', 'Georgia', 'Times', 'serif'],
      mono: ['Consolas', '"Liberation Mono"', 'Menlo', 'Courier', 'monospace']
    },
    fontSize: {
      xs: ['0.75rem', {lineHeight: '1.5'}],
      sm: ['0.875rem', {lineHeight: '1.5'}],
      base: ['1rem', {lineHeight: '1.5'}],
      lg: ['1.125rem', {lineHeight: '1.25'}],
      xl: ['1.25rem', {lineHeight: '1.25'}],
      '2xl': ['1.5rem', {lineHeight: '1.1875'}],
      '3xl': ['1.875rem', {lineHeight: '1.125'}],
      '4xl': ['2.25rem', {lineHeight: '1.125'}],
      '5xl': ['3rem', {lineHeight: '1'}],
      '6xl': ['3.75rem', {lineHeight: '1'}],
      '7xl': ['4.5rem', {lineHeight: '1'}],
      '8xl': ['6rem', {lineHeight: '1'}],
      '9xl': ['8rem', {lineHeight: '1'}]
    },
    screens: {
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',
      '2xl': '1536px'
    },
    typography: {
      DEFAULT: {
        css: {
          'hr + h2': {marginTop: 0},
          'hr + h3': {marginTop: 0},
          'hr + h4': {marginTop: 0},
          'h2 + h2': {marginTop: 0},
          'h2 + h3': {marginTop: 0},
          'h2 + h4': {marginTop: 0},
          'h3 + h2': {marginTop: 0},
          'h3 + h3': {marginTop: 0},
          'h3 + h4': {marginTop: 0},
          'h4 + h2': {marginTop: 0},
          'h4 + h3': {marginTop: 0},
          'h4 + h4': {marginTop: 0},
          '--tw-prose-body': 'currentColor',
          '--tw-prose-headings': 'currentColor',
          '--tw-prose-lead': 'currentColor',
          '--tw-prose-links': '#008bb2',
          '--tw-prose-bold': 'currentColor',
          '--tw-prose-counters': 'currentColor',
          '--tw-prose-bullets': 'currentColor',
          '--tw-prose-hr': 'currentColor',
          '--tw-prose-quotes': 'currentColor',
          '--tw-prose-quote-borders': 'currentColor',
          '--tw-prose-captions': 'currentColor',
          '--tw-prose-code': 'currentColor',
          '--tw-prose-pre-code': 'currentColor',
          '--tw-prose-pre-bg': 'currentColor',
          '--tw-prose-th-borders': 'currentColor',
          '--tw-prose-td-borders': 'currentColor',
          '--tw-prose-invert-body': 'currentColor',
          '--tw-prose-invert-headings': 'currentColor',
          '--tw-prose-invert-lead': 'currentColor',
          '--tw-prose-invert-links': 'currentColor',
          '--tw-prose-invert-bold': 'currentColor',
          '--tw-prose-invert-counters': 'currentColor',
          '--tw-prose-invert-bullets': 'currentColor',
          '--tw-prose-invert-hr': 'currentColor',
          '--tw-prose-invert-quotes': 'currentColor',
          '--tw-prose-invert-quote-borders': 'currentColor',
          '--tw-prose-invert-captions': 'currentColor',
          '--tw-prose-invert-code': 'currentColor',
          '--tw-prose-invert-pre-code': 'currentColor',
          '--tw-prose-invert-pre-bg': 'rgb(0 0 0 / 50%)',
          '--tw-prose-invert-th-borders': 'currentColor',
          '--tw-prose-invert-td-borders': 'currentColor',
          maxWidth: 'none',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          a: {textDecoration: 'none', fontWeight: 'normal'},
          'a:focus': {textDecoration: 'underline', fontWeight: 'normal'},
          'a:hover': {textDecoration: 'underline', fontWeight: 'normal'},
          strong: {fontWeight: 'bold'},
          p: {marginTop: '1.5em', marginBottom: '1.5em'},
          h1: {
            fontWeight: 'bold',
            fontSize: '1.875rem',
            lineHeight: '1.125',
            marginTop: 0,
            marginBottom: '0.9em'
          },
          h2: {
            fontWeight: 'bold',
            fontSize: '1.5rem',
            lineHeight: '1.1875',
            marginTop: '1.9em',
            marginBottom: '0.95em'
          },
          h3: {
            fontWeight: 'bold',
            fontSize: '1.25rem',
            lineHeight: '1.25',
            marginTop: '2em',
            marginBottom: '1em'
          },
          h4: {
            fontWeight: 'bold',
            fontSize: '1.125rem',
            lineHeight: '1.25',
            marginTop: '2em',
            marginBottom: '1em'
          },
          h5: {
            fontWeight: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            marginTop: '1.5em',
            marginBottom: '1.5em'
          },
          h6: {
            fontWeight: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            marginTop: '1.5em',
            marginBottom: '1.5em'
          },
          ol: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
            paddingLeft: '1.75em'
          },
          ul: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
            paddingLeft: '1.75em'
          },
          li: {marginTop: '0.5em', marginBottom: '0.5em'},
          'ol > li': {paddingLeft: '0.375em'},
          'ul > li': {paddingLeft: '0.375em'},
          '> ul > li p': {marginTop: '0.75em', marginBottom: '0.75em'},
          '> ul > li > *:first-child': {marginTop: '1.5em'},
          '> ul > li > *:last-child': {marginBottom: '1.5em'},
          '> ol > li > *:first-child': {marginTop: '1.5em'},
          '> ol > li > *:last-child': {marginBottom: '1.5em'},
          'ul ul, ul ol, ol ul, ol ol': {
            marginTop: '0.75em',
            marginBottom: '0.75em'
          },
          hr: {marginTop: '2.5em', marginBottom: '2.5em'}
        }
      },
      lg: {
        css: {
          'hr + h2': {marginTop: 0},
          'hr + h3': {marginTop: 0},
          'hr + h4': {marginTop: 0},
          'h2 + h2': {marginTop: 0},
          'h2 + h3': {marginTop: 0},
          'h2 + h4': {marginTop: 0},
          'h3 + h2': {marginTop: 0},
          'h3 + h3': {marginTop: 0},
          'h3 + h4': {marginTop: 0},
          'h4 + h2': {marginTop: 0},
          'h4 + h3': {marginTop: 0},
          'h4 + h4': {marginTop: 0},
          fontSize: 'inherit',
          lineHeight: 'inherit',
          p: {marginTop: '1.5em', marginBottom: '1.5em'},
          h1: {
            fontWeight: 'bold',
            fontSize: '2.25rem',
            lineHeight: '1.125',
            marginTop: 0,
            marginBottom: '0.9em'
          },
          h2: {
            fontWeight: 'bold',
            fontSize: '1.875rem',
            lineHeight: '1.125',
            marginTop: '1.8em',
            marginBottom: '0.9em'
          },
          h3: {
            fontWeight: 'bold',
            fontSize: '1.5rem',
            lineHeight: '1.1875',
            marginTop: '1.9em',
            marginBottom: '0.95em'
          },
          h4: {
            fontWeight: 'bold',
            fontSize: '1.25rem',
            lineHeight: '1.25',
            marginTop: '2em',
            marginBottom: '1em'
          },
          h5: {
            fontWeight: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            marginTop: '1.5em',
            marginBottom: '1.5em'
          },
          h6: {
            fontWeight: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            marginTop: '1.5em',
            marginBottom: '1.5em'
          },
          hr: {marginTop: '3em', marginBottom: '3em'}
        }
      }
    },
    aspectRatio: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      '14': '14',
      '15': '15',
      '16': '16'
    },
    supports: {},
    columns: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      auto: 'auto',
      '3xs': '16rem',
      '2xs': '18rem',
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      '7xl': '80rem'
    },
    spacing: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    animation: {
      none: 'none',
      spin: 'spin 1s linear infinite',
      ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
      pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      bounce: 'bounce 1s infinite'
    },
    aria: {
      checked: 'checked="true"',
      disabled: 'disabled="true"',
      expanded: 'expanded="true"',
      hidden: 'hidden="true"',
      pressed: 'pressed="true"',
      readonly: 'readonly="true"',
      required: 'required="true"',
      selected: 'selected="true"'
    },
    backdropBlur: {
      '0': '0',
      none: '0',
      sm: '4px',
      DEFAULT: '8px',
      md: '12px',
      lg: '16px',
      xl: '24px',
      '2xl': '40px',
      '3xl': '64px'
    },
    backdropBrightness: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5',
      '200': '2'
    },
    backdropContrast: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '100': '1',
      '125': '1.25',
      '150': '1.5',
      '200': '2'
    },
    backdropGrayscale: {'0': '0', DEFAULT: '100%'},
    backdropHueRotate: {
      '0': '0deg',
      '15': '15deg',
      '30': '30deg',
      '60': '60deg',
      '90': '90deg',
      '180': '180deg'
    },
    backdropInvert: {'0': '0', DEFAULT: '100%'},
    backdropOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '40': '0.4',
      '50': '0.5',
      '60': '0.6',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '90': '0.9',
      '95': '0.95',
      '100': '1'
    },
    backdropSaturate: {
      '0': '0',
      '50': '.5',
      '100': '1',
      '150': '1.5',
      '200': '2'
    },
    backdropSepia: {'0': '0', DEFAULT: '100%'},
    backgroundColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    backgroundImage: {
      none: 'none',
      'gradient-to-t': 'linear-gradient(to top, var(--tw-gradient-stops))',
      'gradient-to-tr':
        'linear-gradient(to top right, var(--tw-gradient-stops))',
      'gradient-to-r': 'linear-gradient(to right, var(--tw-gradient-stops))',
      'gradient-to-br':
        'linear-gradient(to bottom right, var(--tw-gradient-stops))',
      'gradient-to-b': 'linear-gradient(to bottom, var(--tw-gradient-stops))',
      'gradient-to-bl':
        'linear-gradient(to bottom left, var(--tw-gradient-stops))',
      'gradient-to-l': 'linear-gradient(to left, var(--tw-gradient-stops))',
      'gradient-to-tl': 'linear-gradient(to top left, var(--tw-gradient-stops))'
    },
    backgroundOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '40': '0.4',
      '50': '0.5',
      '60': '0.6',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '90': '0.9',
      '95': '0.95',
      '100': '1'
    },
    backgroundPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top'
    },
    backgroundSize: {auto: 'auto', cover: 'cover', contain: 'contain'},
    blur: {
      '0': '0',
      none: '0',
      sm: '4px',
      DEFAULT: '8px',
      md: '12px',
      lg: '16px',
      xl: '24px',
      '2xl': '40px',
      '3xl': '64px'
    },
    brightness: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5',
      '200': '2'
    },
    borderColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      },
      DEFAULT: 'currentColor'
    },
    borderOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '40': '0.4',
      '50': '0.5',
      '60': '0.6',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '90': '0.9',
      '95': '0.95',
      '100': '1'
    },
    borderRadius: {
      none: '0px',
      sm: '0.125rem',
      DEFAULT: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.75rem',
      '2xl': '1rem',
      '3xl': '1.5rem',
      full: '9999px'
    },
    borderSpacing: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    borderWidth: {
      '0': '0px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      DEFAULT: '1px'
    },
    boxShadow: {
      sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      DEFAULT: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
      '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
      inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
      none: 'none'
    },
    boxShadowColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    caretColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    accentColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      },
      auto: 'auto'
    },
    contrast: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '100': '1',
      '125': '1.25',
      '150': '1.5',
      '200': '2'
    },
    container: {},
    content: {none: 'none'},
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      help: 'help',
      'not-allowed': 'not-allowed',
      none: 'none',
      'context-menu': 'context-menu',
      progress: 'progress',
      cell: 'cell',
      crosshair: 'crosshair',
      'vertical-text': 'vertical-text',
      alias: 'alias',
      copy: 'copy',
      'no-drop': 'no-drop',
      grab: 'grab',
      grabbing: 'grabbing',
      'all-scroll': 'all-scroll',
      'col-resize': 'col-resize',
      'row-resize': 'row-resize',
      'n-resize': 'n-resize',
      'e-resize': 'e-resize',
      's-resize': 's-resize',
      'w-resize': 'w-resize',
      'ne-resize': 'ne-resize',
      'nw-resize': 'nw-resize',
      'se-resize': 'se-resize',
      'sw-resize': 'sw-resize',
      'ew-resize': 'ew-resize',
      'ns-resize': 'ns-resize',
      'nesw-resize': 'nesw-resize',
      'nwse-resize': 'nwse-resize',
      'zoom-in': 'zoom-in',
      'zoom-out': 'zoom-out'
    },
    divideColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      },
      DEFAULT: 'currentColor'
    },
    divideOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '40': '0.4',
      '50': '0.5',
      '60': '0.6',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '90': '0.9',
      '95': '0.95',
      '100': '1'
    },
    divideWidth: {
      '0': '0px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      DEFAULT: '1px'
    },
    dropShadow: {
      sm: '0 1px 1px rgb(0 0 0 / 0.05)',
      DEFAULT: ['0 1px 2px rgb(0 0 0 / 0.1)', '0 1px 1px rgb(0 0 0 / 0.06)'],
      md: ['0 4px 3px rgb(0 0 0 / 0.07)', '0 2px 2px rgb(0 0 0 / 0.06)'],
      lg: ['0 10px 8px rgb(0 0 0 / 0.04)', '0 4px 3px rgb(0 0 0 / 0.1)'],
      xl: ['0 20px 13px rgb(0 0 0 / 0.03)', '0 8px 5px rgb(0 0 0 / 0.08)'],
      '2xl': '0 25px 25px rgb(0 0 0 / 0.15)',
      none: '0 0 #0000'
    },
    fill: {
      none: 'none',
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    grayscale: {'0': '0', DEFAULT: '100%'},
    hueRotate: {
      '0': '0deg',
      '15': '15deg',
      '30': '30deg',
      '60': '60deg',
      '90': '90deg',
      '180': '180deg'
    },
    invert: {'0': '0', DEFAULT: '100%'},
    flex: {'1': '1 1 0%', auto: '1 1 auto', initial: '0 1 auto', none: 'none'},
    flexBasis: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      auto: 'auto',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      full: '100%'
    },
    flexGrow: {'0': '0', DEFAULT: '1'},
    flexShrink: {'0': '0', DEFAULT: '1'},
    fontWeight: {
      thin: '100',
      extralight: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900'
    },
    gap: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    gradientColorStops: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    gridAutoColumns: {
      auto: 'auto',
      min: 'min-content',
      max: 'max-content',
      fr: 'minmax(0, 1fr)'
    },
    gridAutoRows: {
      auto: 'auto',
      min: 'min-content',
      max: 'max-content',
      fr: 'minmax(0, 1fr)'
    },
    gridColumn: {
      auto: 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
      'span-7': 'span 7 / span 7',
      'span-8': 'span 8 / span 8',
      'span-9': 'span 9 / span 9',
      'span-10': 'span 10 / span 10',
      'span-11': 'span 11 / span 11',
      'span-12': 'span 12 / span 12',
      'span-full': '1 / -1'
    },
    gridColumnEnd: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      auto: 'auto'
    },
    gridColumnStart: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      auto: 'auto'
    },
    gridRow: {
      auto: 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
      'span-full': '1 / -1'
    },
    gridRowStart: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      auto: 'auto'
    },
    gridRowEnd: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      auto: 'auto'
    },
    gridTemplateColumns: {
      '1': 'repeat(1, minmax(0, 1fr))',
      '2': 'repeat(2, minmax(0, 1fr))',
      '3': 'repeat(3, minmax(0, 1fr))',
      '4': 'repeat(4, minmax(0, 1fr))',
      '5': 'repeat(5, minmax(0, 1fr))',
      '6': 'repeat(6, minmax(0, 1fr))',
      '7': 'repeat(7, minmax(0, 1fr))',
      '8': 'repeat(8, minmax(0, 1fr))',
      '9': 'repeat(9, minmax(0, 1fr))',
      '10': 'repeat(10, minmax(0, 1fr))',
      '11': 'repeat(11, minmax(0, 1fr))',
      '12': 'repeat(12, minmax(0, 1fr))',
      none: 'none'
    },
    gridTemplateRows: {
      '1': 'repeat(1, minmax(0, 1fr))',
      '2': 'repeat(2, minmax(0, 1fr))',
      '3': 'repeat(3, minmax(0, 1fr))',
      '4': 'repeat(4, minmax(0, 1fr))',
      '5': 'repeat(5, minmax(0, 1fr))',
      '6': 'repeat(6, minmax(0, 1fr))',
      none: 'none'
    },
    height: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      auto: 'auto',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      full: '100%',
      screen: '100vh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content'
    },
    inset: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      auto: 'auto',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      full: '100%'
    },
    keyframes: {
      spin: {to: {transform: 'rotate(360deg)'}},
      ping: {'75%, 100%': {transform: 'scale(2)', opacity: '0'}},
      pulse: {'50%': {opacity: '.5'}},
      bounce: {
        '0%, 100%': {
          transform: 'translateY(-25%)',
          animationTimingFunction: 'cubic-bezier(0.8,0,1,1)'
        },
        '50%': {
          transform: 'none',
          animationTimingFunction: 'cubic-bezier(0,0,0.2,1)'
        }
      }
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0em',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em'
    },
    lineHeight: {
      '3': '.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      none: '1',
      tight: '1.25',
      snug: '1.375',
      normal: '1.5',
      relaxed: '1.625',
      loose: '2'
    },
    listStyleType: {none: 'none', disc: 'disc', decimal: 'decimal'},
    margin: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      auto: 'auto',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    maxHeight: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      full: '100%',
      screen: '100vh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content'
    },
    maxWidth: {
      '0': '0rem',
      none: 'none',
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      '7xl': '80rem',
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      prose: '75ch',
      'screen-sm': '576px',
      'screen-md': '768px',
      'screen-lg': '992px',
      'screen-xl': '1200px',
      'screen-2xl': '1536px'
    },
    minHeight: {
      '0': '0px',
      full: '100%',
      screen: '100vh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content'
    },
    minWidth: {
      '0': '0px',
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content'
    },
    objectPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top'
    },
    opacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '40': '0.4',
      '50': '0.5',
      '60': '0.6',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '90': '0.9',
      '95': '0.95',
      '100': '1'
    },
    order: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      first: '-9999',
      last: '9999',
      none: '0'
    },
    padding: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    placeholderColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    placeholderOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '40': '0.4',
      '50': '0.5',
      '60': '0.6',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '90': '0.9',
      '95': '0.95',
      '100': '1'
    },
    outlineColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    outlineOffset: {'0': '0px', '1': '1px', '2': '2px', '4': '4px', '8': '8px'},
    outlineWidth: {'0': '0px', '1': '1px', '2': '2px', '4': '4px', '8': '8px'},
    ringColor: {
      DEFAULT: '#008bb2',
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    ringOffsetColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    ringOffsetWidth: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px'
    },
    ringOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '40': '0.4',
      '50': '0.5',
      '60': '0.6',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
      DEFAULT: '0.5'
    },
    ringWidth: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      DEFAULT: '3px'
    },
    rotate: {
      '0': '0deg',
      '1': '1deg',
      '2': '2deg',
      '3': '3deg',
      '6': '6deg',
      '12': '12deg',
      '45': '45deg',
      '90': '90deg',
      '180': '180deg'
    },
    saturate: {'0': '0', '50': '.5', '100': '1', '150': '1.5', '200': '2'},
    scale: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5'
    },
    scrollMargin: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    scrollPadding: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    sepia: {'0': '0', DEFAULT: '100%'},
    skew: {
      '0': '0deg',
      '1': '1deg',
      '2': '2deg',
      '3': '3deg',
      '6': '6deg',
      '12': '12deg'
    },
    space: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    stroke: {
      none: 'none',
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    strokeWidth: {'0': '0', '1': '1', '2': '2'},
    textColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    textDecorationColor: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      red: {
        '50': '#fae0e0',
        '100': '#fcc0ba',
        '200': '#faa095',
        '300': '#f47f71',
        '400': '#eb5b4f',
        '500': '#e02e2e',
        '600': '#c92525',
        '700': '#b31c1c',
        '800': '#9e1314',
        '900': '#88080b'
      },
      orange: {
        '50': '#fde9df',
        '100': '#ffd0bb',
        '200': '#ffb797',
        '300': '#fc9e73',
        '400': '#f78450',
        '500': '#ef6a2c',
        '600': '#df5a23',
        '700': '#cf4a1a',
        '800': '#c03a11',
        '900': '#b02807'
      },
      amber: {
        '50': '#fff2d9',
        '100': '#ffe4b8',
        '200': '#ffd595',
        '300': '#ffc671',
        '400': '#ffb749',
        '500': '#ffa600',
        '600': '#f19200',
        '700': '#e27f00',
        '800': '#d46b00',
        '900': '#c55700'
      },
      yellow: {
        '50': '#fff9d9',
        '100': '#fff2bb',
        '200': '#ffeb9c',
        '300': '#ffe479',
        '400': '#ffdd50',
        '500': '#ffd500',
        '600': '#f5c300',
        '700': '#ebb200',
        '800': '#e0a000',
        '900': '#d68f00'
      },
      lime: {
        '50': '#f6f7dc',
        '100': '#eeeeba',
        '200': '#e5e697',
        '300': '#dbdd74',
        '400': '#d0d44e',
        '500': '#c3cc17',
        '600': '#afb711',
        '700': '#9ca30c',
        '800': '#898f07',
        '900': '#777b03'
      },
      green: {
        '50': '#e3f4e4',
        '100': '#c6e7c4',
        '200': '#a9dba5',
        '300': '#8bce87',
        '400': '#6bc168',
        '500': '#45b449',
        '600': '#39a03b',
        '700': '#2c8d2d',
        '800': '#1f7b1e',
        '900': '#11680f'
      },
      teal: {
        '50': '#dbf2ed',
        '100': '#bae4d8',
        '200': '#99d6c4',
        '300': '#77c7af',
        '400': '#50b99b',
        '500': '#0daa88',
        '600': '#0b9472',
        '700': '#087f5d',
        '800': '#066b48',
        '900': '#035734'
      },
      cyan: {
        '50': '#d9f5f9',
        '100': '#bceaf1',
        '200': '#9edeea',
        '300': '#7dd3e3',
        '400': '#56c7db',
        '500': '#00bcd4',
        '600': '#039eb8',
        '700': '#04829c',
        '800': '#046682',
        '900': '#024c68'
      },
      blue: {
        '50': '#d9eef3',
        '100': '#b9d9e6',
        '200': '#98c5d9',
        '300': '#75b1cc',
        '400': '#4d9ebf',
        '500': '#008bb2',
        '600': '#02749a',
        '700': '#035e82',
        '800': '#03496b',
        '900': '#013455'
      },
      indigo: {
        '50': '#e0e5f0',
        '100': '#bfc5e0',
        '200': '#9ea6cf',
        '300': '#7d88bf',
        '400': '#596caf',
        '500': '#2f519e',
        '600': '#27428d',
        '700': '#1e347c',
        '800': '#15266b',
        '900': '#0a185b'
      },
      purple: {
        '50': '#efdff2',
        '100': '#dfbde4',
        '200': '#ce9ad6',
        '300': '#bc78c8',
        '400': '#aa54b9',
        '500': '#962bab',
        '600': '#822298',
        '700': '#6e1886',
        '800': '#5b0f74',
        '900': '#480662'
      },
      pink: {
        '50': '#fce3ec',
        '100': '#fcc7d5',
        '200': '#fbaabe',
        '300': '#f78ca8',
        '400': '#f26c93',
        '500': '#eb477e',
        '600': '#d33a69',
        '700': '#bb2c54',
        '800': '#a41f40',
        '900': '#8c102d'
      },
      brown: {
        '50': '#efe5e0',
        '100': '#dec5ba',
        '200': '#cda796',
        '300': '#bb8973',
        '400': '#a76c51',
        '500': '#925031',
        '600': '#854228',
        '700': '#77341f',
        '800': '#6a2716',
        '900': '#5d180e'
      },
      grey: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c0c0c0',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      bronze: {
        '50': '#f4ebe8',
        '100': '#e7d1c9',
        '200': '#dab8ac',
        '300': '#cca08f',
        '400': '#be8873',
        '500': '#ae7058',
        '600': '#97614c',
        '700': '#805341',
        '800': '#6a4436',
        '900': '#55372b'
      },
      silver: {
        '50': '#f4f4f4',
        '100': '#e7e7e7',
        '200': '#dadada',
        '300': '#cdcdcd',
        '400': '#c1c1c1',
        '500': '#b4b4b4',
        '600': '#9c9c9c',
        '700': '#848484',
        '800': '#6e6e6e',
        '900': '#585858'
      },
      gold: {
        '50': '#fef7db',
        '100': '#ffecb8',
        '200': '#ffe194',
        '300': '#fed770',
        '400': '#facd48',
        '500': '#f5c300',
        '600': '#d4a902',
        '700': '#b48f03',
        '800': '#957704',
        '900': '#775f04'
      },
      platinum: {
        '50': '#fbfcfb',
        '100': '#f7f7f6',
        '200': '#f2f3f1',
        '300': '#edefec',
        '400': '#e9eae7',
        '500': '#e4e6e2',
        '600': '#c5c7c4',
        '700': '#a8a9a6',
        '800': '#8b8c8a',
        '900': '#6f706e'
      },
      'marketing-blue': {
        '50': '#deebf2',
        '100': '#bdd7e5',
        '200': '#9bc4d8',
        '300': '#78b0cb',
        '400': '#4f9dbf',
        '500': '#008bb2',
        '600': '#15708e',
        '700': '#1a556c',
        '800': '#193c4b',
        '900': '#14252d'
      },
      'marketing-cyan': {
        '50': '#d1eef4',
        '100': '#a2dde9',
        '200': '#89d5e4',
        '300': '#6ecdde',
        '400': '#4bc4d9',
        '500': '#00bcd4',
        '600': '#1a95a8',
        '700': '#1f707e',
        '800': '#1d4d56',
        '900': '#172d31'
      },
      'marketing-teal': {
        '50': '#ddece9',
        '100': '#bbd9d3',
        '200': '#a6cec6',
        '300': '#91c2b9',
        '400': '#7cb7ac',
        '500': '#66ac9f',
        '600': '#53897f',
        '700': '#416860',
        '800': '#2f4843',
        '900': '#1e2a28'
      },
      'marketing-green': {
        '50': '#e6ecd5',
        '100': '#ccd8aa',
        '200': '#b8ca8b',
        '300': '#a4bc6d',
        '400': '#8fae4e',
        '500': '#7aa02c',
        '600': '#638026',
        '700': '#4c6120',
        '800': '#36441a',
        '900': '#222913'
      },
      'marketing-lime': {
        '50': '#efeacb',
        '100': '#dfd497',
        '200': '#d4c87b',
        '300': '#c9bd5e',
        '400': '#bdb23f',
        '500': '#b0a716',
        '600': '#8d8519',
        '700': '#6b6418',
        '800': '#4a4615',
        '900': '#2c2911'
      },
      'marketing-yellow': {
        '50': '#fce6bd',
        '100': '#f8cd7a',
        '200': '#f5c564',
        '300': '#f1bd4c',
        '400': '#ecb631',
        '500': '#e7ae00',
        '600': '#b78a10',
        '700': '#896814',
        '800': '#5e4814',
        '900': '#362a10'
      },
      'marketing-amber': {
        '50': '#ffe4cb',
        '100': '#ffc897',
        '200': '#fcb97a',
        '300': '#f7aa5d',
        '400': '#f29b3e',
        '500': '#eb8c16',
        '600': '#bb7018',
        '700': '#8d5517',
        '800': '#613c15',
        '900': '#382410'
      },
      'marketing-orange': {
        '50': '#ffe3d6',
        '100': '#ffc7ac',
        '200': '#feb08c',
        '300': '#fb996c',
        '400': '#f6824d',
        '500': '#ef6a2c',
        '600': '#be5626',
        '700': '#904320',
        '800': '#633119',
        '900': '#3a1f12'
      },
      'marketing-red': {
        '50': '#ffe3db',
        '100': '#ffc6b7',
        '200': '#fda994',
        '300': '#f78b73',
        '400': '#ef6c52',
        '500': '#e54a32',
        '600': '#b73e2a',
        '700': '#8b3222',
        '800': '#61261b',
        '900': '#3a1a13'
      },
      'marketing-pink': {
        '50': '#fde3e8',
        '100': '#fac6d0',
        '200': '#f5abbb',
        '300': '#ef90a6',
        '400': '#e87492',
        '500': '#df557f',
        '600': '#b14666',
        '700': '#86384e',
        '800': '#5d2a37',
        '900': '#371b22'
      },
      'marketing-purple': {
        '50': '#f0e6f1',
        '100': '#e0cde2',
        '200': '#c9aacd',
        '300': '#b388b8',
        '400': '#9c66a4',
        '500': '#84458f',
        '600': '#6b3974',
        '700': '#532e59',
        '800': '#3c2340',
        '900': '#271828'
      },
      'marketing-indigo': {
        '50': '#e7e9f3',
        '100': '#cfd2e7',
        '200': '#a9aed4',
        '300': '#828cc0',
        '400': '#596cad',
        '500': '#264d9a',
        '600': '#243f7d',
        '700': '#213260',
        '800': '#1c2646',
        '900': '#151a2c'
      },
      'marketing-brown': {
        '50': '#f0e7e4',
        '100': '#e1cfc8',
        '200': '#ccafa3',
        '300': '#b6907f',
        '400': '#a0725d',
        '500': '#89553c',
        '600': '#6f4532',
        '700': '#563728',
        '800': '#3e291f',
        '900': '#271b15'
      }
    },
    textDecorationThickness: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      auto: 'auto',
      'from-font': 'from-font'
    },
    textUnderlineOffset: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      auto: 'auto'
    },
    textIndent: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem'
    },
    textOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '40': '0.4',
      '50': '0.5',
      '60': '0.6',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '90': '0.9',
      '95': '0.95',
      '100': '1'
    },
    transformOrigin: {
      center: 'center',
      top: 'top',
      'top-right': 'top right',
      right: 'right',
      'bottom-right': 'bottom right',
      bottom: 'bottom',
      'bottom-left': 'bottom left',
      left: 'left',
      'top-left': 'top left'
    },
    transitionDelay: {
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms'
    },
    transitionDuration: {
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms',
      DEFAULT: '150ms'
    },
    transitionProperty: {
      none: 'none',
      all: 'all',
      DEFAULT:
        'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
      colors:
        'color, background-color, border-color, text-decoration-color, fill, stroke',
      opacity: 'opacity',
      shadow: 'box-shadow',
      transform: 'transform'
    },
    transitionTimingFunction: {
      DEFAULT: 'cubic-bezier(0.4, 0, 0.2, 1)',
      linear: 'linear',
      in: 'cubic-bezier(0.4, 0, 1, 1)',
      out: 'cubic-bezier(0, 0, 0.2, 1)',
      'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)'
    },
    translate: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      full: '100%'
    },
    width: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      auto: 'auto',
      px: '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      full: '100%',
      screen: '100vw',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content'
    },
    willChange: {
      auto: 'auto',
      scroll: 'scroll-position',
      contents: 'contents',
      transform: 'transform'
    },
    zIndex: {
      '0': '0',
      '10': '10',
      '20': '20',
      '30': '30',
      '40': '40',
      '50': '50',
      auto: 'auto'
    }
  },
  corePlugins: [
    'preflight',
    'container',
    'accessibility',
    'pointerEvents',
    'visibility',
    'position',
    'inset',
    'isolation',
    'zIndex',
    'order',
    'gridColumn',
    'gridColumnStart',
    'gridColumnEnd',
    'gridRow',
    'gridRowStart',
    'gridRowEnd',
    'float',
    'clear',
    'margin',
    'boxSizing',
    'display',
    'height',
    'maxHeight',
    'minHeight',
    'width',
    'minWidth',
    'maxWidth',
    'flex',
    'flexShrink',
    'flexGrow',
    'flexBasis',
    'tableLayout',
    'borderCollapse',
    'borderSpacing',
    'transformOrigin',
    'translate',
    'rotate',
    'skew',
    'scale',
    'transform',
    'animation',
    'cursor',
    'touchAction',
    'userSelect',
    'resize',
    'scrollSnapType',
    'scrollSnapAlign',
    'scrollSnapStop',
    'scrollMargin',
    'scrollPadding',
    'listStylePosition',
    'listStyleType',
    'appearance',
    'columns',
    'breakBefore',
    'breakInside',
    'breakAfter',
    'gridAutoColumns',
    'gridAutoFlow',
    'gridAutoRows',
    'gridTemplateColumns',
    'gridTemplateRows',
    'flexDirection',
    'flexWrap',
    'placeContent',
    'placeItems',
    'alignContent',
    'alignItems',
    'justifyContent',
    'justifyItems',
    'gap',
    'space',
    'divideWidth',
    'divideStyle',
    'divideColor',
    'divideOpacity',
    'placeSelf',
    'alignSelf',
    'justifySelf',
    'overflow',
    'overscrollBehavior',
    'scrollBehavior',
    'textOverflow',
    'whitespace',
    'wordBreak',
    'borderRadius',
    'borderWidth',
    'borderStyle',
    'borderColor',
    'borderOpacity',
    'backgroundColor',
    'backgroundOpacity',
    'backgroundImage',
    'gradientColorStops',
    'boxDecorationBreak',
    'backgroundSize',
    'backgroundAttachment',
    'backgroundClip',
    'backgroundPosition',
    'backgroundRepeat',
    'backgroundOrigin',
    'fill',
    'stroke',
    'strokeWidth',
    'objectFit',
    'objectPosition',
    'padding',
    'textAlign',
    'textIndent',
    'verticalAlign',
    'fontFamily',
    'fontSize',
    'fontWeight',
    'textTransform',
    'fontStyle',
    'fontVariantNumeric',
    'lineHeight',
    'letterSpacing',
    'textColor',
    'textOpacity',
    'textDecoration',
    'textDecorationColor',
    'textDecorationStyle',
    'textDecorationThickness',
    'textUnderlineOffset',
    'fontSmoothing',
    'placeholderColor',
    'placeholderOpacity',
    'caretColor',
    'accentColor',
    'opacity',
    'backgroundBlendMode',
    'mixBlendMode',
    'boxShadow',
    'boxShadowColor',
    'outlineStyle',
    'outlineWidth',
    'outlineOffset',
    'outlineColor',
    'ringWidth',
    'ringColor',
    'ringOpacity',
    'ringOffsetWidth',
    'ringOffsetColor',
    'blur',
    'brightness',
    'contrast',
    'dropShadow',
    'grayscale',
    'hueRotate',
    'invert',
    'saturate',
    'sepia',
    'filter',
    'backdropBlur',
    'backdropBrightness',
    'backdropContrast',
    'backdropGrayscale',
    'backdropHueRotate',
    'backdropInvert',
    'backdropOpacity',
    'backdropSaturate',
    'backdropSepia',
    'backdropFilter',
    'transitionProperty',
    'transitionDelay',
    'transitionDuration',
    'transitionTimingFunction',
    'willChange',
    'content'
  ],
  plugins: [
    {
      config: {
        theme: {
          aspectRatio: {
            '1': '1',
            '2': '2',
            '3': '3',
            '4': '4',
            '5': '5',
            '6': '6',
            '7': '7',
            '8': '8',
            '9': '9',
            '10': '10',
            '11': '11',
            '12': '12',
            '13': '13',
            '14': '14',
            '15': '15',
            '16': '16'
          }
        },
        variants: {aspectRatio: ['responsive']}
      }
    },
    null
  ],
  content: {
    relative: false,
    files: ['./src/**/*.tsx'],
    extract: {},
    transform: {}
  },
  prefix: 'tw-',
  variants: {aspectRatio: ['responsive']},
  presets: [],
  darkMode: 'media',
  variantOrder: [
    'first',
    'last',
    'odd',
    'even',
    'visited',
    'checked',
    'empty',
    'read-only',
    'group-hover',
    'group-focus',
    'focus-within',
    'hover',
    'focus',
    'focus-visible',
    'active',
    'disabled'
  ],
  important: false,
  separator: ':',
  safelist: [],
  blocklist: []
};

export default config;
