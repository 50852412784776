import _ from 'lodash';
import React from 'react';
import {Page, MasteryMeter} from '../../../core/components';
import {Code} from '../../components';

const MasteryMeterPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Mastery Meter</h1>

        <h2>Usage</h2>

        <div className="tw-not-prose">
          <MasteryMeter value={50} />
          <Code code={`<MasteryMeter value={50} />`} language="jsx" />
        </div>

        <h2>All</h2>

        <div className="tw-not-prose">
          <table className="sv-table">
            <thead>
              <tr>
                <th>Value</th>
                <th>Example</th>
              </tr>
            </thead>
            <tbody>
              {_.times(101, (value) => (
                <tr key={value}>
                  <td>{value}</td>
                  <td>
                    <MasteryMeter value={value} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </article>
    </Page>
  );
};

export default MasteryMeterPage;
