import {IonLabel} from '@ionic/react';
import React from 'react';
import type {OptionProps} from './types';

const Option: React.FC<OptionProps> = (props) => {
  const {label} = props;

  return <IonLabel className="ion-text-wrap">{label}</IonLabel>;
};

export default Option;
