import {useIonToast} from '@ionic/react';
import {useEffect} from 'react';
import type React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {transformError} from '../../core/api';
import {dangerToastOptions} from '../../core/components';
import compilePath from '../../core/utils/compilePath';
import logHistory from '../../core/utils/logHistory';
import {useCreatePracticeActivityMutation} from '../activity.api';
import {PATH_UNSEQUENCED_QUESTION} from '../activity.paths';
import {setActivity} from '../activity.slice';

const CreatePracticeActivity: React.FC = () => {
  // logHistory('CreatePracticeActivity');

  const {section_id} = useParams<{section_id: string}>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [createPracticeActivity, {error}] = useCreatePracticeActivityMutation();
  const [presentToast] = useIonToast();

  useEffect(() => {
    createPracticeActivity({section_id: parseInt(section_id, 10)})
      .unwrap()
      .then((data) => {
        dispatch(setActivity(data));
        history.replace(
          compilePath(PATH_UNSEQUENCED_QUESTION, {
            activity_id: data.activity.id,
            response_id: data.response.id,
            response_index: data.response.response_index
          })
        );
      })
      .catch(() => {}); // No-op because error returned by mutation will be surfaced
  }, [section_id, createPracticeActivity, dispatch, history]);

  useEffect(() => {
    if (error) {
      presentToast({
        ...dangerToastOptions,
        message: transformError(error)?.code
      });
    }
  }, [presentToast, error]);

  return null;
};

export default CreatePracticeActivity;
