import {IonItem, IonLabel, IonNote, IonCheckbox} from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import './Checkbox.md.scss';

type Props = {
  disabled?: boolean;
  helper?: string;
  indeterminate?: boolean;
  label?: string;
  lines?: 'full' | 'inset' | 'none';
  name: string;
};

const Checkbox: React.FC<Props> = (props) => {
  const {
    control,
    formState: {errors}
  } = useFormContext();
  const {disabled, helper, indeterminate, label, lines = 'none', name} = props;
  const error = errors[name]?.message;

  return (
    <IonItem
      className={clsx({'item-checkbox': true, 'has-error': error})}
      lines={lines}
    >
      {label && <IonLabel>{label}</IonLabel>}
      <Controller
        control={control}
        name={name}
        render={({field: {value, onBlur, onChange}}) => (
          <IonCheckbox
            slot="start"
            checked={value}
            disabled={disabled}
            indeterminate={indeterminate}
            onIonBlur={onBlur}
            onIonChange={(e) => onChange(e.target.checked)}
          />
        )}
      />
      {error && (
        <IonNote color="danger" slot="helper">
          {error}
        </IonNote>
      )}
      {!error && helper && <IonNote slot="helper">{helper}</IonNote>}
    </IonItem>
  );
};

export default Checkbox;
