import {yupResolver} from '@hookform/resolvers/yup';
import {IonButton} from '@ionic/react';
import _ from 'lodash';
import {DateTime} from 'luxon';
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Message, Page, DateInput} from '../../../core/components';
import yup from '../../../core/vendor/yup';

const DateInputPage: React.FC = () => {
  const now = DateTime.now();
  const min = DateTime.fromObject({
    day: 1,
    month: 1,
    year: now.minus({years: 16}).year
  });
  const max = DateTime.fromObject({
    day: 31,
    month: 12,
    year: now.minus({years: 6}).year
  });
  const assert = yup
    .date()
    .nullable()
    .transform((currentValue, originalValue) => {
      if (!currentValue) {
        return currentValue;
      }
      // By default, yup accepts invalid dates, e.g. 2016-02-30 is transformed to 2016-03-01.
      // The following does a strict transformation that disallows such dates.
      const dt = DateTime.fromISO(originalValue);
      return dt.isValid ? dt.toJSDate() : yup.DateSchema.INVALID_DATE;
    })
    .min(min.toISODate(), `Must be between ${min.year} and ${max.year}`)
    .max(max.toISODate(), `Must be between ${min.year} and ${max.year}`)
    .required('Required')
    .typeError('Must be a valid date');
  const schema = yup.object({
    empty_date_input: assert,
    completed_date_input: assert,
    disabled_date_input: assert
  });
  const form = useForm({
    defaultValues: {
      empty_date_input: null,
      completed_date_input: max.minus({months: 1, years: 1}).toISODate(),
      disabled_date_input: max.minus({months: 1, years: 1}).toISODate()
    },
    resolver: yupResolver(schema)
  });
  const errors = Object.entries(form.formState.errors).map(([field, error]) => [
    field,
    _.isArray(error) ? error.map((e) => e.message).join('') : error.message
  ]);
  const onSubmit = (data: any) => {
    // yup returns parsed ISO string as Date, so value must be transformed to original ISO string
    const originalData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        DateTime.fromJSDate(value as Date).toISODate()
      ])
    );
    console.log(JSON.stringify(originalData, null, 2));
  };

  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Date Input</h1>

        <div className="tw-not-prose">
          {!!errors.length && (
            <>
              {errors.map((error) => (
                <Message key={error[0]} color="danger">
                  {error[0]}: {error[1]}
                </Message>
              ))}
              <br />
            </>
          )}

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <DateInput
                name="empty_date_input"
                label="Empty input"
                helper="Lorem ipsum dolor sit amet"
                min={min.toISODate()}
                max={max.toISODate()}
              />
              <br />
              <DateInput
                name="completed_date_input"
                label="Completed input"
                helper="Lorem ipsum dolor sit amet"
                min={min.toISODate()}
                max={max.toISODate()}
              />
              <br />
              <DateInput
                name="disabled_date_input"
                label="Disabled input"
                helper="Lorem ipsum dolor sit amet"
                min={min.toISODate()}
                max={max.toISODate()}
                disabled
              />
              <br />
              <IonButton type="submit">Submit</IonButton>
            </form>
          </FormProvider>
        </div>
      </article>
    </Page>
  );
};

export default DateInputPage;
