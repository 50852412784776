/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'effort'}</title>
    <circle fill="#962bab" cx={256} cy={256} r={256} />
    <path
      fill="#ffd500"
      d="M256 96.503a20.031 20.031 0 0 0-4.41-.503c-11.052 0-20.01 8.954-20.01 20l-.395 126.251c.105 1.457-1.145 1.875-1.371.209l-17.802-98.549c-1.964-10.871-12.374-18.717-23.249-16.752-10.876 1.963-18.099 12.991-16.136 23.859l15.852 115.325c.016 2.052-.672 3.225-1.648.594l-23.649-63.227c-3.7-10.408-15.14-16.475-25.554-12.777-10.414 3.697-15.858 15.757-12.16 26.165l41.459 135.446c.216.747.445 1.489.681 2.228l.086.278c.007.021.017.041.024.063C179.2 390.452 212.407 416 251.591 416c1.467 0 2.938-.036 4.409-.097v-319.4z"
    />
    <path
      fill="#ebb200"
      d="M411.056 268.5c-10.417-10.412-25.752-11.351-36.17-.938l-29.776 29.76c-7.751 7.74-17.093 7.757-22.546 1.324-3.612-4.263-2.187-17.41-1.175-25.751l14.934-122.748c1.333-10.967-6.479-21.561-17.45-22.894-10.97-1.333-20.943 7.099-22.277 18.063l-16.473 96.412c-.861 2.398-2.707 2.813-2.916-.416L271.6 116c0-9.53-6.674-17.487-15.6-19.497v319.4c23.7-.966 47.888-9.635 66.561-23.955l.001-.001c21.422-12.893 90.052-86.677 90.052-86.677 10.418-10.412 8.862-26.355-1.558-36.77z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
