export type Subject = {
  id: SUBJECT;
  name: SUBJECT_NAME;
  short_name: string;
  full_name: string;
  icon_name: string;
};

export const enum SUBJECT {
  MATHS = 1,
  SCIENCE = 2,
  PHYSICS = 3,
  CHEMISTRY = 4
  // CAT = 5,
  // IT = 6
}

export const enum SUBJECT_NAME {
  MATHS = 'maths',
  SCIENCE = 'science',
  PHYSICS = 'physics',
  CHEMISTRY = 'chemistry'
  // CAT = 'cat',
  // IT = 'it'
}
