import activityRoutes from '../activity/activity.routes';
import authRoutes from '../auth/auth.routes';
import styleGuideRoutes from '../style-guide/style-guide.routes';
import homeRoutes from '../home/home.routes';
import practiceDashboardRoutes from '../practice-dashboard/practice-dashboard.routes';

export default [
  ...activityRoutes,
  ...authRoutes,
  ...homeRoutes,
  ...practiceDashboardRoutes,
  ...styleGuideRoutes
];
