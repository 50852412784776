import {IonLabel, IonNote} from '@ionic/react';
import clsx from 'clsx';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import DiallingCodeSelect from '../DiallingCodeSelect/DiallingCodeSelect';
import TextInput from '../TextInput/TextInput';
import './Telephone.md.scss';

type TelephoneProps = {
  disabled?: boolean;
  helper?: string;
  label?: string;
  name: string;
};

const Telephone: React.FC<TelephoneProps> = (props) => {
  const {disabled, helper, label, name} = props;
  const {
    control,
    formState: {errors}
  } = useFormContext();
  const diallingCodeName = name + '_dialling_code';
  const nationalNumberName = name + '_national_number';
  const error =
    errors[diallingCodeName]?.message || errors[nationalNumberName]?.message;

  return (
    <div className={clsx({'item-telephone': true, 'has-error': error})}>
      {label && <IonLabel>{label}</IonLabel>}
      <Controller
        control={control}
        name={name}
        render={() => (
          <div className="tw-grid tw-max-w-xs tw-grid-cols-[2fr_5fr] tw-gap-2">
            <DiallingCodeSelect
              name={diallingCodeName}
              label="Code"
              disabled={disabled}
              showError={false}
            />
            <TextInput
              name={nationalNumberName}
              label="Phone number"
              type="tel"
              autocomplete="tel"
              inputmode="tel"
              disabled={disabled}
              showError={false}
            />
          </div>
        )}
      />
      {error && (
        <IonNote color="danger" slot="helper">
          {error}
        </IonNote>
      )}
      {!error && helper && <IonNote slot="helper">{helper}</IonNote>}
    </div>
  );
};

export default Telephone;
