import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../store';
import type {Curriculum} from './curriculum';
import data from '../../data/curriculums.json';

const sortComparer = (a: Curriculum, b: Curriculum) =>
  a.long_name.localeCompare(b.long_name);

const adapter = createEntityAdapter<Curriculum>({
  sortComparer
});

const slice = createSlice({
  name: 'curriculums',
  initialState: {
    ids: [...(data as Curriculum[])].sort(sortComparer).map((d) => d.id),
    entities: Object.fromEntries((data as Curriculum[]).map((d) => [d.id, d]))
  },
  reducers: {
    curriculumsSetAll: adapter.setAll
  }
});

export const {curriculumsSetAll} = slice.actions;

export default slice.reducer;

export const curriculumsSelectors = adapter.getSelectors(
  (state: RootState) => state.curriculums
);
