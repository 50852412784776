import React from 'react';
import {VictoryPie} from 'victory';
import {TextSize} from 'victory-core';
import tailwindConfig from '../../data/tailwind.config';
import type {Bin} from './utils/binValue';
import binValue from './utils/binValue';

type Style = Record<string, number | string | string[]>;

const valueLabelStyle: Style = {
  color: '#000',
  fontFamily: tailwindConfig.theme.fontFamily.sans.join(','),
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: 'normal'
};

const percentLabelStyle: Style = {
  color: '#000',
  fontFamily: tailwindConfig.theme.fontFamily.sans.join(','),
  fontSize: 9,
  fontWeight: 'bold',
  letterSpacing: 'normal'
};

const percentWidth = getStringWidth('%', percentLabelStyle);

const defaultColorValueBins: Bin[] = [
  {
    color: tailwindConfig.theme.colors.red[300],
    lower: {
      value: 0,
      inclusive: true
    },
    upper: {
      value: 30,
      inclusive: false
    }
  },
  {
    color: tailwindConfig.theme.colors.amber[500],
    lower: {
      value: 30,
      inclusive: true
    },
    upper: {
      value: 50,
      inclusive: false
    }
  },
  {
    color: '#99C428', // lime-500 (66.67%), green-500 (33.33%)
    lower: {
      value: 50,
      inclusive: true
    },
    upper: {
      value: 70,
      inclusive: false
    }
  },
  {
    color: tailwindConfig.theme.colors.green[700],
    lower: {
      value: 70,
      inclusive: true
    },
    upper: {
      value: 100,
      inclusive: true
    }
  }
];

function getStringWidth(text: string, style: Style) {
  const {width} = TextSize.approximateTextSize(text, style);
  return Math.ceil(width);
}

type ProgressDonutProps = {
  borderWidth?: number;
  colorBar?: string;
  colorValue?: string;
  colorValueBins?: Bin[];
  size?: number;
  value: number;
};

const ProgressDonut: React.FC<ProgressDonutProps> = (props) => {
  const {
    borderWidth = 10,
    colorBar = tailwindConfig.theme.colors.grey[300],
    colorValueBins = defaultColorValueBins,
    size = 44,
    value
  } = props;
  const colorValue = props.colorValue
    ? props.colorValue
    : binValue(value, colorValueBins)?.color ||
      tailwindConfig.theme.colors.blue[200];
  const innerRadius = (size - borderWidth) / 2;
  const valueWidth = getStringWidth(value.toString(), valueLabelStyle);
  const labelWidth = valueWidth + percentWidth;
  const labelX = Math.round((size - labelWidth) / 2);
  const labelY = size / 2;
  const labelDx = value === 100 ? '0' : '0.1em';
  const percentLabelDx = value === 100 ? '0' : '0.1em';

  return (
    <svg
      className="tw-relative tw-m-0 tw-inline-block tw-border-none tw-p-0 tw-align-middle tw-leading-none"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      preserveAspectRatio="xMidYMid meet"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text
        x={labelX}
        y={labelY}
        dx={labelDx}
        dy="0.35em"
        style={valueLabelStyle}
      >
        {value}
        <tspan dx={percentLabelDx} style={percentLabelStyle}>
          %
        </tspan>
      </text>
      <VictoryPie
        groupComponent={<g transform="translate(1,1)" />}
        standalone={false}
        width={size - 2}
        height={size - 2}
        innerRadius={innerRadius}
        data={[
          {x: 1, y: value, fill: colorValue},
          {x: 2, y: 100 - value, fill: colorBar}
        ]}
        labels={() => null}
        padding={0}
        style={{
          data: {
            fill: ({datum}) => datum.fill,
            strokeWidth: 0
          }
        }}
      />
    </svg>
  );
};

export default ProgressDonut;
