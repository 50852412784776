/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-league-icon"
    viewBox="0 0 448 512"
    {...props}
  >
    <title>{'physics platinum'}</title>
    <path
      fill="#00bcd4"
      d="M224 40.437 80 124.218v167.565l144 83.78 144-83.78V124.218L224 40.437zM348 280.28l-124 72.145-124-72.145V135.72l124-72.146 124 72.146v144.56z"
    />
    <path
      fill="#00bcd4"
      d="M108 140.322V275.68l116 67.489 116-67.489V140.322L224 72.831z"
    />
    <path
      fill="#fff"
      d="M100 135.721v144.56l124 72.145 124-72.145v-144.56L224 63.575l-124 72.146zM340 275.68l-116 67.489-116-67.489V140.322l116-67.491 116 67.491V275.68z"
    />
    <path
      fill="#FFF"
      d="M229.406 162.5c10.416 0 18.635 2.678 24.656 8.031 6.021 5.354 9.03 12.365 9.03 21.031 0 8.709-3.011 15.709-9.03 21-6.021 5.292-14.24 7.938-24.656 7.938h-15.375v20.188l9.75 1.875V253.5h-37.688v-10.938l9.688-1.875v-65.313l-9.688-1.875v-11h43.313v.001zm-15.375 43.937h15.375c5.083 0 8.927-1.385 11.531-4.156 2.604-2.771 3.905-6.302 3.905-10.594 0-4.375-1.303-7.989-3.905-10.844-2.604-2.854-6.448-4.281-11.531-4.281h-15.375v29.875z"
    />
    <g fill="#848484">
      <path d="M42.379 451.34v20.039H30.66v-56.875h22.188c4.271 0 8.027.781 11.27 2.344s5.735 3.783 7.48 6.66c1.744 2.878 2.617 6.152 2.617 9.824 0 5.573-1.908 9.968-5.723 13.184-3.815 3.217-9.096 4.824-15.84 4.824H42.379zm0-9.492h10.469c3.099 0 5.462-.729 7.09-2.188 1.627-1.458 2.441-3.541 2.441-6.25 0-2.786-.82-5.039-2.461-6.758s-3.906-2.604-6.797-2.656H42.379v17.852zM93.98 461.965h24.883v9.414H82.262v-56.875H93.98v47.461zM158.434 459.66h-20.547l-3.906 11.719H121.52l21.172-56.875h10.859l21.289 56.875h-12.461l-3.945-11.719zm-17.383-9.492h14.219l-7.148-21.289-7.071 21.289zM218.277 423.996h-17.422v47.383h-11.719v-47.383h-17.188v-9.492h46.328v9.492zM237.418 471.379h-11.719v-56.875h11.719v56.875zM294.527 471.379h-11.719l-22.813-37.422v37.422h-11.719v-56.875h11.719l22.852 37.5v-37.5h11.68v56.875zM347.809 414.504v37.461c0 6.225-1.947 11.146-5.84 14.766-3.894 3.62-9.213 5.43-15.957 5.43-6.641 0-11.914-1.758-15.82-5.273s-5.898-8.346-5.977-14.492v-37.891h11.719v37.539c0 3.725.892 6.439 2.676 8.145 1.783 1.706 4.251 2.559 7.402 2.559 6.588 0 9.935-3.463 10.039-10.391v-37.852h11.758zM372.73 414.504l14.609 41.25 14.531-41.25h15.391v56.875h-11.758v-15.547l1.172-26.836-15.352 42.383h-8.047l-15.313-42.344 1.172 26.797v15.547h-11.719v-56.875h15.314z" />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
