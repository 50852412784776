import type {Uuid4} from '../../types';
import {
  PATH_PRACTICE_DASHBOARD_REDIRECT_TO_GRADE,
  PATH_PRACTICE_DASHBOARD_SUBJECT_GRADE
} from '../../../practice-dashboard/practice-dashboard.paths';
import compilePath from '../../utils/compilePath';
import type {SUBJECT_NAME} from '../subject/subject';

export type Activity = {
  id: Uuid4;
  activity_type: ACTIVITY_TYPE;
};

export const enum ACTIVITY_TYPE {
  ASSIGNMENT = 'assignment',
  CHERRY_PICKED = 'cherry_picked',
  DIFFICULTY = 'difficulty',
  EXAM = 'exam',
  INTERACTIVE_TEXTBOOK = 'interactive_textbook',
  PRACTICE = 'practice',
  STANDALONE = 'standalone',
  STANDALONELIST = 'standalonelist',
  TEACHER_SHARE = 'teacher_share',
  TOPIC = 'topic'
}

type ActivityPageTitle = Record<ACTIVITY_TYPE, string>;

const ActivityPageTitles: ActivityPageTitle = {
  [ACTIVITY_TYPE.ASSIGNMENT]: 'Assignment',
  [ACTIVITY_TYPE.CHERRY_PICKED]: 'Practice',
  [ACTIVITY_TYPE.DIFFICULTY]: 'Practice',
  [ACTIVITY_TYPE.EXAM]: 'Exam Prep',
  [ACTIVITY_TYPE.INTERACTIVE_TEXTBOOK]: 'Practice',
  [ACTIVITY_TYPE.PRACTICE]: 'Practice',
  [ACTIVITY_TYPE.STANDALONE]: 'Practice',
  [ACTIVITY_TYPE.STANDALONELIST]: 'Practice',
  [ACTIVITY_TYPE.TEACHER_SHARE]: 'Practice',
  [ACTIVITY_TYPE.TOPIC]: 'Exam Prep'
};

export const getPageTitle = (activity_type: ACTIVITY_TYPE): string => {
  return ActivityPageTitles[activity_type];
};

export const getDashboardUrl = (
  activity_type: ACTIVITY_TYPE,
  subject_name: SUBJECT_NAME,
  grade?: number
): string | undefined => {
  if (activity_type === ACTIVITY_TYPE.PRACTICE) {
    if (grade) {
      return compilePath(PATH_PRACTICE_DASHBOARD_SUBJECT_GRADE, {
        subject_name,
        grade
      });
    } else {
      return compilePath(PATH_PRACTICE_DASHBOARD_REDIRECT_TO_GRADE, {
        subject_name
      });
    }
  }
};
