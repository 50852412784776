/**
 * NOTE ion-radio does not behave like a HTML radio element. Use RadioGroup with a single option
 * instead of Radio.
 */
import {yupResolver} from '@hookform/resolvers/yup';
import {IonButton} from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Page, RadioGroup, Message} from '../../../core/components';
import yup from '../../../core/vendor/yup';

const RadioPage: React.FC = () => {
  const assert = yup.string().required('Required');
  const schema = yup.object({
    empty_radio: assert,
    completed_radio: assert,
    disabled_radio: assert,
    empty_radio_group: assert,
    completed_radio_group: assert,
    disabled_radio_group: assert
  });
  const form = useForm({
    defaultValues: {
      empty_radio: null,
      completed_radio: 'omega',
      disabled_radio: 'omega',
      empty_radio_group: null,
      completed_radio_group: 'omega',
      disabled_radio_group: 'omega'
    },
    resolver: yupResolver(schema)
  });
  const errors = Object.entries(form.formState.errors).map(([field, error]) => [
    field,
    _.isArray(error) ? error.map((e) => e.message).join('') : error.message
  ]);
  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
  };

  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Radio</h1>

        <div className="tw-not-prose">
          {!!errors.length && (
            <>
              {errors.map((error) => (
                <Message key={error[0]} color="danger">
                  {error[0]}: {error[1]}
                </Message>
              ))}
              <br />
            </>
          )}

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <RadioGroup
                name="empty_radio"
                helper="Lorem ipsum dolor sit amet"
                options={[{label: 'Unchecked radio', value: 'omega'}]}
              />
              <br />
              <RadioGroup
                name="completed_radio"
                helper="Lorem ipsum dolor sit amet"
                options={[{label: 'Checked radio', value: 'omega'}]}
              />
              <br />
              <RadioGroup
                name="disabled_radio"
                helper="Lorem ipsum dolor sit amet"
                options={[
                  {label: 'Disabled radio', value: 'omega', disabled: true}
                ]}
              />
              <br />
              <RadioGroup
                name="empty_radio_group"
                label="Group of radios that has none checked by default:"
                helper="Lorem ipsum dolor sit amet"
                options={[
                  {label: 'Alpha', value: 'alpha'},
                  {label: 'Omega', value: 'omega'}
                ]}
              />
              <br />
              <RadioGroup
                name="completed_radio_group"
                label="Group of radios that has some checked by default:"
                helper="Lorem ipsum dolor sit amet"
                options={[
                  {label: 'Alpha', value: 'alpha'},
                  {label: 'Omega', value: 'omega'}
                ]}
              />
              <br />
              <RadioGroup
                name="disabled_radio_group"
                label="Group of disabled radios:"
                helper="Lorem ipsum dolor sit amet"
                options={[
                  {label: 'Alpha', value: 'alpha', disabled: true},
                  {label: 'Omega', value: 'omega', disabled: true}
                ]}
              />
              <br />
              <IonButton type="submit">Submit</IonButton>
            </form>
          </FormProvider>
        </div>
      </article>
    </Page>
  );
};

export default RadioPage;
