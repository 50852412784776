import {
  IonItem,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonLabel
} from '@ionic/react';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {useImmer} from 'use-immer';
import {useSearchMasterSchoolsQuery} from '../../models/master-school/master-school.api';
import {REGION} from '../../models/region/region';
import Select from '../Select/Select';
import type {SelectProps, OptionsProps} from '../Select/types';
import {toArray} from '../Select/utils';
import TextInput from '../TextInput/TextInput';

type SchoolNotFoundProps = {
  value: string;
  onSelectSearchValue: () => void;
};

const SchoolNotFound: React.FC<SchoolNotFoundProps> = (props) => {
  const {value, onSelectSearchValue} = props;

  if (!value) {
    return null;
  }

  return (
    <>
      <br />
      <div>Can’t find your school? Use this one:</div>
      <IonList>
        <IonRadioGroup onIonChange={() => onSelectSearchValue()}>
          <IonItem className="item-radio" lines="full" key="none">
            <IonLabel className="ion-text-wrap">{value}</IonLabel>
            <IonRadio slot="start" value={value} />
          </IonItem>
        </IonRadioGroup>
      </IonList>
    </>
  );
};

const Options: React.FC<OptionsProps> = (props) => {
  const {
    value,
    searchValue,
    items,
    itemToValue,
    itemToLabel,
    onSelectValue,
    onSelectSearchValue
  } = props;

  const [nextValues, setNextValues] = useImmer<string[]>(toArray(value));

  if (items === undefined) {
    return null;
  }

  if (!items.length) {
    return (
      <>
        <div className="tw-mt-4">No results for "{searchValue}"</div>
        <SchoolNotFound
          value={searchValue}
          onSelectSearchValue={onSelectSearchValue}
        />
      </>
    );
  }

  return (
    <>
      <IonList>
        <IonRadioGroup
          value={nextValues.length ? nextValues[0] : undefined}
          onIonChange={(e) => {
            const nextState = [e.detail.value];

            setNextValues(nextState);
            onSelectValue(nextState);
          }}
        >
          {items.map((item) => {
            const value = itemToValue(item);
            const label = itemToLabel(item);

            return (
              <IonItem className="item-radio" lines="full" key={value}>
                <IonLabel className="ion-text-wrap">{label}</IonLabel>
                <IonRadio slot="start" value={value} />
              </IonItem>
            );
          })}
        </IonRadioGroup>
      </IonList>
      <SchoolNotFound
        value={searchValue}
        onSelectSearchValue={onSelectSearchValue}
      />
    </>
  );
};

type ProfileSchoolSelectProps = Pick<
  SelectProps,
  'disabled' | 'helper' | 'label' | 'name'
>;

const ProfileSchoolSelect: React.FC<ProfileSchoolSelectProps> = (props) => {
  const {name, label, helper, disabled} = props;
  const {setValue} = useFormContext();
  const idName = name + '_id';
  const schoolName = name + '_name';

  return (
    <>
      <TextInput name={idName} type="number" readonly hidden />
      <Select
        name={schoolName}
        label={label}
        helper={helper}
        disabled={disabled}
        searchable
        searchPlaceholder="Search schools"
        itemsQueryFn={useSearchMasterSchoolsQuery}
        itemsQueryArgs={(inputValue) => ({
          country_code: REGION.ZA,
          school_name: inputValue
        })}
        itemToValue={({name}) => name}
        itemToLabel={({name}) => name}
        optionsComponent={Options}
        onSelectValue={({items}) => {
          setValue(idName, items.length ? items[0].id : null);
        }}
        onSelectSearchValue={() => {
          setValue(idName, null);
        }}
      />
    </>
  );
};

export default ProfileSchoolSelect;
