import {yupResolver} from '@hookform/resolvers/yup';
import {IonButton} from '@ionic/react';
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {
  Page,
  Message,
  Select,
  CountrySelect,
  DiallingCodeSelect,
  ProfileSchoolSelect
} from '../../../core/components';
import {
  countriesSelectors,
  selectDiallingCodes
} from '../../../core/models/country/country.slice';
import yup from '../../../core/vendor/yup';

const books = [
  {author: 'Harper Lee', title: 'To Kill a Mockingbird'},
  {author: 'Lev Tolstoy', title: 'War and Peace'},
  {author: 'Fyodor Dostoyevsy', title: 'The Idiot'},
  {author: 'Oscar Wilde', title: 'The Picture of Dorian Gray'},
  {author: 'George Orwell', title: '1984'},
  {author: 'Jane Austen', title: 'Pride and Prejudice'},
  {author: 'Marcus Aurelius', title: 'Meditations'},
  {author: 'Fyodor Dostoevsky', title: 'The Brothers Karamazov'},
  {author: 'Lev Tolstoy', title: 'Anna Karenina'},
  {author: 'Fyodor Dostoevsky', title: 'Crime and Punishment'}
];

const bookItems = books.map(({title, author}) => ({
  value: title,
  label: title + ' - ' + author
}));

const SearchInputPage: React.FC = () => {
  const countryIds = useSelector(countriesSelectors.selectIds);
  const countryIdAssert = yup
    .string()
    .ensure()
    .required('Required')
    .oneOf(countryIds as string[], 'Must be a valid country code');

  const diallingCodes = useSelector(selectDiallingCodes);
  const diallingCodeAssert = yup
    .string()
    .ensure()
    .required('Required')
    .oneOf(diallingCodes, 'Must be a valid dialling code');

  const singleAssert = yup.string().ensure().required('Required');
  const multipleAssert = yup
    .array(yup.string())
    .compact()
    .required('Required')
    .min(1, 'Required');

  const schema = yup.object({
    empty_single_select: singleAssert,
    completed_single_select: singleAssert,
    disabled_single_select: singleAssert,

    empty_multiple_select: multipleAssert,
    completed_multiple_select: multipleAssert,
    disabled_multiple_select: multipleAssert,

    empty_country_select: countryIdAssert,
    completed_country_select: countryIdAssert,
    disabled_country_select: countryIdAssert,

    empty_dialling_code_select: diallingCodeAssert,
    completed_dialling_code_select: diallingCodeAssert,
    disabled_dialling_code_select: diallingCodeAssert,

    empty_profile_school_select_name: singleAssert,
    completed_profile_school_select_name: singleAssert,
    disabled_profile_school_select_name: singleAssert
  });

  const form = useForm({
    defaultValues: {
      empty_single_select: null,
      completed_single_select: bookItems[1].value,
      disabled_single_select: bookItems[1].value,

      empty_multiple_select: [],
      completed_multiple_select: [bookItems[1].value, bookItems[8].value],
      disabled_multiple_select: [bookItems[1].value, bookItems[8].value],

      empty_country_select: null,
      completed_country_select: 'ZA',
      disabled_country_select: 'ZA',

      empty_dialling_code_select: null,
      completed_dialling_code_select: '27',
      disabled_dialling_code_select: '27',

      empty_profile_school_select_id: null,
      empty_profile_school_select_name: null,
      completed_profile_school_select_id: 24040,
      completed_profile_school_select_name:
        'Worcester Sekondêre Skool (Worcester)',
      disabled_profile_school_select_id: 24040,
      disabled_profile_school_select_name:
        'Worcester Sekondêre Skool (Worcester)'
    },
    resolver: yupResolver(schema)
  });
  const errors = Object.entries(form.formState.errors).map(([field, error]) => [
    field,
    error.message
  ]);
  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
  };

  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Select</h1>

        <div className="tw-not-prose">
          {!!errors.length && (
            <>
              {errors.map((error) => (
                <Message key={error[0]} color="danger">
                  {error[0]}: {error[1]}
                </Message>
              ))}
              <br />
            </>
          )}
        </div>

        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h2>Single select</h2>

            <div className="tw-not-prose">
              <Select
                name="empty_single_select"
                label="Empty single select"
                helper="Lorem ipsum dolor sit amet"
                items={bookItems}
              />
              <br />
              <Select
                name="completed_single_select"
                label="Completed single select"
                helper="Lorem ipsum dolor sit amet"
                items={bookItems}
              />
              <br />
              <Select
                name="disabled_single_select"
                label="Disabled single select"
                helper="Lorem ipsum dolor sit amet"
                items={bookItems}
                disabled
              />
            </div>

            <h2>Multiple select</h2>

            <div className="tw-not-prose">
              <Select
                name="empty_multiple_select"
                label="Empty multiple select"
                helper="Lorem ipsum dolor sit amet"
                items={bookItems}
                multiple
              />
              <br />
              <Select
                name="completed_multiple_select"
                label="Completed multiple select"
                helper="Lorem ipsum dolor sit amet"
                items={bookItems}
                multiple
              />
              <br />
              <Select
                name="disabled_multiple_select"
                label="Disabled multiple select"
                helper="Lorem ipsum dolor sit amet"
                items={bookItems}
                multiple
                disabled
              />
            </div>

            <h2>Country select</h2>

            <div className="tw-not-prose">
              <CountrySelect
                name="empty_country_select"
                label="Empty country select"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <CountrySelect
                name="completed_country_select"
                label="Completed country select"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <CountrySelect
                name="disabled_country_select"
                label="Disabled country select"
                helper="Lorem ipsum dolor sit amet"
                disabled
              />
            </div>

            <h2>Dialling code select</h2>

            <div className="tw-not-prose">
              <DiallingCodeSelect
                name="empty_dialling_code_select"
                label="Empty dialling code select"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <DiallingCodeSelect
                name="completed_dialling_code_select"
                label="Completed dialling code select"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />
              <DiallingCodeSelect
                name="disabled_dialling_code_select"
                label="Disabled dialling code select"
                helper="Lorem ipsum dolor sit amet"
                disabled
              />
            </div>

            <h2>Profile school select</h2>

            <div className="tw-not-prose">
              <ProfileSchoolSelect
                name="empty_profile_school_select"
                label="Empty profile school select"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />

              <ProfileSchoolSelect
                name="completed_profile_school_select"
                label="Complete profile school select"
                helper="Lorem ipsum dolor sit amet"
              />
              <br />

              <ProfileSchoolSelect
                name="disabled_profile_school_select"
                label="Disabled profile school select"
                helper="Lorem ipsum dolor sit amet"
                disabled
              />
              <br />

              <IonButton type="submit">Submit</IonButton>
            </div>
          </form>
        </FormProvider>
      </article>
    </Page>
  );
};

export default SearchInputPage;
