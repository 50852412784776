/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'1 million maths'}</title>
    <circle fill="#ef6a2c" cx={256} cy={256} r={256} />
    <path
      fill="#962bab"
      d="M174.319 254.657 112 364h72l36 60 36-79.354 25.23-55.616A108.286 108.286 0 0 1 256 292c-32.629 0-61.877-14.47-81.681-37.343z"
    />
    <path
      fill="#6e1886"
      d="M337.682 254.658c-11.308 13.059-25.695 23.379-42.033 29.83l-33.563 73.569L292 424l36-60h72l-62.318-109.342z"
    />
    <path
      fill="#ffd500"
      d="M184 184c0-39.768 32.232-72 72-72V88c-53.033 0-96 42.967-96 96s42.967 96 96 96v-24c-39.768 0-72-32.232-72-72z"
    />
    <path
      fill="#ebb200"
      d="M320 184c0-35.346-28.653-64-64-64v128c35.347 0 64-28.654 64-64z"
    />
    <path
      fill="#ffd500"
      d="M192 184c0 35.346 28.654 64 64 64V120c-35.346 0-64 28.654-64 64z"
    />
    <path
      fill="#ebb200"
      d="M328 184c0 39.768-32.231 72-72 72v24c53.034 0 96-42.967 96-96s-42.966-96-96-96v24c39.769 0 72 32.232 72 72z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
