export type Emoji = {name: string; image: string};

const map = new Map<string, Emoji>([
  ['100', {name: 'hundred points symbol', image: '1f4af'}],
  ['1234', {name: 'input symbol for numbers', image: '1f522'}],
  ['hash', {name: 'hash key', image: '0023_20e3'}],
  ['keycap_star', {name: 'keycap: *', image: '002a_20e3'}],
  ['zero', {name: 'keycap 0', image: '0030_20e3'}],
  ['one', {name: 'keycap 1', image: '0031_20e3'}],
  ['two', {name: 'keycap 2', image: '0032_20e3'}],
  ['three', {name: 'keycap 3', image: '0033_20e3'}],
  ['four', {name: 'keycap 4', image: '0034_20e3'}],
  ['five', {name: 'keycap 5', image: '0035_20e3'}],
  ['six', {name: 'keycap 6', image: '0036_20e3'}],
  ['seven', {name: 'keycap 7', image: '0037_20e3'}],
  ['eight', {name: 'keycap 8', image: '0038_20e3'}],
  ['nine', {name: 'keycap 9', image: '0039_20e3'}],
  ['copyright', {name: 'copyright sign', image: '00a9'}],
  ['registered', {name: 'registered sign', image: '00ae'}],
  ['mahjong', {name: 'mahjong tile red dragon', image: '1f004'}],
  ['black_joker', {name: 'playing card black joker', image: '1f0cf'}],
  ['a', {name: 'negative squared latin capital letter a', image: '1f170'}],
  ['b', {name: 'negative squared latin capital letter b', image: '1f171'}],
  ['o2', {name: 'negative squared latin capital letter o', image: '1f17e'}],
  [
    'parking',
    {name: 'negative squared latin capital letter p', image: '1f17f'}
  ],
  ['ab', {name: 'negative squared ab', image: '1f18e'}],
  ['cl', {name: 'squared cl', image: '1f191'}],
  ['cool', {name: 'squared cool', image: '1f192'}],
  ['free', {name: 'squared free', image: '1f193'}],
  ['id', {name: 'squared id', image: '1f194'}],
  ['new', {name: 'squared new', image: '1f195'}],
  ['ng', {name: 'squared ng', image: '1f196'}],
  ['ok', {name: 'squared ok', image: '1f197'}],
  ['sos', {name: 'squared sos', image: '1f198'}],
  ['up', {name: 'squared up with exclamation mark', image: '1f199'}],
  ['vs', {name: 'squared vs', image: '1f19a'}],
  ['koko', {name: 'squared katakana koko', image: '1f201'}],
  ['sa', {name: 'squared katakana sa', image: '1f202'}],
  ['u7121', {name: 'squared cjk unified ideograph-7121', image: '1f21a'}],
  ['u6307', {name: 'squared cjk unified ideograph-6307', image: '1f22f'}],
  ['u7981', {name: 'squared cjk unified ideograph-7981', image: '1f232'}],
  ['u7a7a', {name: 'squared cjk unified ideograph-7a7a', image: '1f233'}],
  ['u5408', {name: 'squared cjk unified ideograph-5408', image: '1f234'}],
  ['u6e80', {name: 'squared cjk unified ideograph-6e80', image: '1f235'}],
  ['u6709', {name: 'squared cjk unified ideograph-6709', image: '1f236'}],
  ['u6708', {name: 'squared cjk unified ideograph-6708', image: '1f237'}],
  ['u7533', {name: 'squared cjk unified ideograph-7533', image: '1f238'}],
  ['u5272', {name: 'squared cjk unified ideograph-5272', image: '1f239'}],
  ['u55b6', {name: 'squared cjk unified ideograph-55b6', image: '1f23a'}],
  [
    'ideograph_advantage',
    {name: 'circled ideograph advantage', image: '1f250'}
  ],
  ['accept', {name: 'circled ideograph accept', image: '1f251'}],
  ['cyclone', {name: 'cyclone', image: '1f300'}],
  ['foggy', {name: 'foggy', image: '1f301'}],
  ['closed_umbrella', {name: 'closed umbrella', image: '1f302'}],
  ['night_with_stars', {name: 'night with stars', image: '1f303'}],
  ['sunrise_over_mountains', {name: 'sunrise over mountains', image: '1f304'}],
  ['sunrise', {name: 'sunrise', image: '1f305'}],
  ['city_sunset', {name: 'cityscape at dusk', image: '1f306'}],
  ['city_sunrise', {name: 'sunset over buildings', image: '1f307'}],
  ['rainbow', {name: 'rainbow', image: '1f308'}],
  ['bridge_at_night', {name: 'bridge at night', image: '1f309'}],
  ['ocean', {name: 'water wave', image: '1f30a'}],
  ['volcano', {name: 'volcano', image: '1f30b'}],
  ['milky_way', {name: 'milky way', image: '1f30c'}],
  ['earth_africa', {name: 'earth globe europe-africa', image: '1f30d'}],
  ['earth_americas', {name: 'earth globe americas', image: '1f30e'}],
  ['earth_asia', {name: 'earth globe asia-australia', image: '1f30f'}],
  ['globe_with_meridians', {name: 'globe with meridians', image: '1f310'}],
  ['new_moon', {name: 'new moon symbol', image: '1f311'}],
  [
    'waxing_crescent_moon',
    {name: 'waxing crescent moon symbol', image: '1f312'}
  ],
  ['first_quarter_moon', {name: 'first quarter moon symbol', image: '1f313'}],
  ['moon', {name: 'waxing gibbous moon symbol', image: '1f314'}],
  ['full_moon', {name: 'full moon symbol', image: '1f315'}],
  ['waning_gibbous_moon', {name: 'waning gibbous moon symbol', image: '1f316'}],
  ['last_quarter_moon', {name: 'last quarter moon symbol', image: '1f317'}],
  [
    'waning_crescent_moon',
    {name: 'waning crescent moon symbol', image: '1f318'}
  ],
  ['crescent_moon', {name: 'crescent moon', image: '1f319'}],
  ['new_moon_with_face', {name: 'new moon with face', image: '1f31a'}],
  [
    'first_quarter_moon_with_face',
    {name: 'first quarter moon with face', image: '1f31b'}
  ],
  [
    'last_quarter_moon_with_face',
    {name: 'last quarter moon with face', image: '1f31c'}
  ],
  ['full_moon_with_face', {name: 'full moon with face', image: '1f31d'}],
  ['sun_with_face', {name: 'sun with face', image: '1f31e'}],
  ['star2', {name: 'glowing star', image: '1f31f'}],
  ['stars', {name: 'shooting star', image: '1f320'}],
  ['thermometer', {name: 'thermometer', image: '1f321'}],
  ['mostly_sunny', {name: 'sun behind small cloud', image: '1f324'}],
  ['barely_sunny', {name: 'sun behind large cloud', image: '1f325'}],
  ['partly_sunny_rain', {name: 'sun behind rain cloud', image: '1f326'}],
  ['rain_cloud', {name: 'cloud with rain', image: '1f327'}],
  ['snow_cloud', {name: 'cloud with snow', image: '1f328'}],
  ['lightning', {name: 'cloud with lightning', image: '1f329'}],
  ['tornado', {name: 'tornado', image: '1f32a'}],
  ['fog', {name: 'fog', image: '1f32b'}],
  ['wind_blowing_face', {name: 'wind face', image: '1f32c'}],
  ['hotdog', {name: 'hot dog', image: '1f32d'}],
  ['taco', {name: 'taco', image: '1f32e'}],
  ['burrito', {name: 'burrito', image: '1f32f'}],
  ['chestnut', {name: 'chestnut', image: '1f330'}],
  ['seedling', {name: 'seedling', image: '1f331'}],
  ['evergreen_tree', {name: 'evergreen tree', image: '1f332'}],
  ['deciduous_tree', {name: 'deciduous tree', image: '1f333'}],
  ['palm_tree', {name: 'palm tree', image: '1f334'}],
  ['cactus', {name: 'cactus', image: '1f335'}],
  ['hot_pepper', {name: 'hot pepper', image: '1f336'}],
  ['tulip', {name: 'tulip', image: '1f337'}],
  ['cherry_blossom', {name: 'cherry blossom', image: '1f338'}],
  ['rose', {name: 'rose', image: '1f339'}],
  ['hibiscus', {name: 'hibiscus', image: '1f33a'}],
  ['sunflower', {name: 'sunflower', image: '1f33b'}],
  ['blossom', {name: 'blossom', image: '1f33c'}],
  ['corn', {name: 'ear of maize', image: '1f33d'}],
  ['ear_of_rice', {name: 'ear of rice', image: '1f33e'}],
  ['herb', {name: 'herb', image: '1f33f'}],
  ['four_leaf_clover', {name: 'four leaf clover', image: '1f340'}],
  ['maple_leaf', {name: 'maple leaf', image: '1f341'}],
  ['fallen_leaf', {name: 'fallen leaf', image: '1f342'}],
  ['leaves', {name: 'leaf fluttering in wind', image: '1f343'}],
  ['mushroom', {name: 'mushroom', image: '1f344'}],
  ['tomato', {name: 'tomato', image: '1f345'}],
  ['eggplant', {name: 'aubergine', image: '1f346'}],
  ['grapes', {name: 'grapes', image: '1f347'}],
  ['melon', {name: 'melon', image: '1f348'}],
  ['watermelon', {name: 'watermelon', image: '1f349'}],
  ['tangerine', {name: 'tangerine', image: '1f34a'}],
  ['lemon', {name: 'lemon', image: '1f34b'}],
  ['banana', {name: 'banana', image: '1f34c'}],
  ['pineapple', {name: 'pineapple', image: '1f34d'}],
  ['apple', {name: 'red apple', image: '1f34e'}],
  ['green_apple', {name: 'green apple', image: '1f34f'}],
  ['pear', {name: 'pear', image: '1f350'}],
  ['peach', {name: 'peach', image: '1f351'}],
  ['cherries', {name: 'cherries', image: '1f352'}],
  ['strawberry', {name: 'strawberry', image: '1f353'}],
  ['hamburger', {name: 'hamburger', image: '1f354'}],
  ['pizza', {name: 'slice of pizza', image: '1f355'}],
  ['meat_on_bone', {name: 'meat on bone', image: '1f356'}],
  ['poultry_leg', {name: 'poultry leg', image: '1f357'}],
  ['rice_cracker', {name: 'rice cracker', image: '1f358'}],
  ['rice_ball', {name: 'rice ball', image: '1f359'}],
  ['rice', {name: 'cooked rice', image: '1f35a'}],
  ['curry', {name: 'curry and rice', image: '1f35b'}],
  ['ramen', {name: 'steaming bowl', image: '1f35c'}],
  ['spaghetti', {name: 'spaghetti', image: '1f35d'}],
  ['bread', {name: 'bread', image: '1f35e'}],
  ['fries', {name: 'french fries', image: '1f35f'}],
  ['sweet_potato', {name: 'roasted sweet potato', image: '1f360'}],
  ['dango', {name: 'dango', image: '1f361'}],
  ['oden', {name: 'oden', image: '1f362'}],
  ['sushi', {name: 'sushi', image: '1f363'}],
  ['fried_shrimp', {name: 'fried shrimp', image: '1f364'}],
  ['fish_cake', {name: 'fish cake with swirl design', image: '1f365'}],
  ['icecream', {name: 'soft ice cream', image: '1f366'}],
  ['shaved_ice', {name: 'shaved ice', image: '1f367'}],
  ['ice_cream', {name: 'ice cream', image: '1f368'}],
  ['doughnut', {name: 'doughnut', image: '1f369'}],
  ['cookie', {name: 'cookie', image: '1f36a'}],
  ['chocolate_bar', {name: 'chocolate bar', image: '1f36b'}],
  ['candy', {name: 'candy', image: '1f36c'}],
  ['lollipop', {name: 'lollipop', image: '1f36d'}],
  ['custard', {name: 'custard', image: '1f36e'}],
  ['honey_pot', {name: 'honey pot', image: '1f36f'}],
  ['cake', {name: 'shortcake', image: '1f370'}],
  ['bento', {name: 'bento box', image: '1f371'}],
  ['stew', {name: 'pot of food', image: '1f372'}],
  ['fried_egg', {name: 'cooking', image: '1f373'}],
  ['fork_and_knife', {name: 'fork and knife', image: '1f374'}],
  ['tea', {name: 'teacup without handle', image: '1f375'}],
  ['sake', {name: 'sake bottle and cup', image: '1f376'}],
  ['wine_glass', {name: 'wine glass', image: '1f377'}],
  ['cocktail', {name: 'cocktail glass', image: '1f378'}],
  ['tropical_drink', {name: 'tropical drink', image: '1f379'}],
  ['beer', {name: 'beer mug', image: '1f37a'}],
  ['beers', {name: 'clinking beer mugs', image: '1f37b'}],
  ['baby_bottle', {name: 'baby bottle', image: '1f37c'}],
  ['knife_fork_plate', {name: 'fork and knife with plate', image: '1f37d'}],
  ['champagne', {name: 'bottle with popping cork', image: '1f37e'}],
  ['popcorn', {name: 'popcorn', image: '1f37f'}],
  ['ribbon', {name: 'ribbon', image: '1f380'}],
  ['gift', {name: 'wrapped present', image: '1f381'}],
  ['birthday', {name: 'birthday cake', image: '1f382'}],
  ['jack_o_lantern', {name: 'jack-o-lantern', image: '1f383'}],
  ['christmas_tree', {name: 'christmas tree', image: '1f384'}],
  ['santa', {name: 'father christmas', image: '1f385'}],
  ['santa_skin-tone-2', {name: 'father christmas', image: '1f385_1f3fb'}],
  ['santa_skin-tone-3', {name: 'father christmas', image: '1f385_1f3fc'}],
  ['santa_skin-tone-4', {name: 'father christmas', image: '1f385_1f3fd'}],
  ['santa_skin-tone-5', {name: 'father christmas', image: '1f385_1f3fe'}],
  ['santa_skin-tone-6', {name: 'father christmas', image: '1f385_1f3ff'}],
  ['fireworks', {name: 'fireworks', image: '1f386'}],
  ['sparkler', {name: 'firework sparkler', image: '1f387'}],
  ['balloon', {name: 'balloon', image: '1f388'}],
  ['tada', {name: 'party popper', image: '1f389'}],
  ['confetti_ball', {name: 'confetti ball', image: '1f38a'}],
  ['tanabata_tree', {name: 'tanabata tree', image: '1f38b'}],
  ['crossed_flags', {name: 'crossed flags', image: '1f38c'}],
  ['bamboo', {name: 'pine decoration', image: '1f38d'}],
  ['dolls', {name: 'japanese dolls', image: '1f38e'}],
  ['flags', {name: 'carp streamer', image: '1f38f'}],
  ['wind_chime', {name: 'wind chime', image: '1f390'}],
  ['rice_scene', {name: 'moon viewing ceremony', image: '1f391'}],
  ['school_satchel', {name: 'school satchel', image: '1f392'}],
  ['mortar_board', {name: 'graduation cap', image: '1f393'}],
  ['medal', {name: 'military medal', image: '1f396'}],
  ['reminder_ribbon', {name: 'reminder ribbon', image: '1f397'}],
  ['studio_microphone', {name: 'studio microphone', image: '1f399'}],
  ['level_slider', {name: 'level slider', image: '1f39a'}],
  ['control_knobs', {name: 'control knobs', image: '1f39b'}],
  ['film_frames', {name: 'film frames', image: '1f39e'}],
  ['admission_tickets', {name: 'admission tickets', image: '1f39f'}],
  ['carousel_horse', {name: 'carousel horse', image: '1f3a0'}],
  ['ferris_wheel', {name: 'ferris wheel', image: '1f3a1'}],
  ['roller_coaster', {name: 'roller coaster', image: '1f3a2'}],
  ['fishing_pole_and_fish', {name: 'fishing pole and fish', image: '1f3a3'}],
  ['microphone', {name: 'microphone', image: '1f3a4'}],
  ['movie_camera', {name: 'movie camera', image: '1f3a5'}],
  ['cinema', {name: 'cinema', image: '1f3a6'}],
  ['headphones', {name: 'headphone', image: '1f3a7'}],
  ['art', {name: 'artist palette', image: '1f3a8'}],
  ['tophat', {name: 'top hat', image: '1f3a9'}],
  ['circus_tent', {name: 'circus tent', image: '1f3aa'}],
  ['ticket', {name: 'ticket', image: '1f3ab'}],
  ['clapper', {name: 'clapper board', image: '1f3ac'}],
  ['performing_arts', {name: 'performing arts', image: '1f3ad'}],
  ['video_game', {name: 'video game', image: '1f3ae'}],
  ['dart', {name: 'direct hit', image: '1f3af'}],
  ['slot_machine', {name: 'slot machine', image: '1f3b0'}],
  ['8ball', {name: 'billiards', image: '1f3b1'}],
  ['game_die', {name: 'game die', image: '1f3b2'}],
  ['bowling', {name: 'bowling', image: '1f3b3'}],
  ['flower_playing_cards', {name: 'flower playing cards', image: '1f3b4'}],
  ['musical_note', {name: 'musical note', image: '1f3b5'}],
  ['notes', {name: 'multiple musical notes', image: '1f3b6'}],
  ['saxophone', {name: 'saxophone', image: '1f3b7'}],
  ['guitar', {name: 'guitar', image: '1f3b8'}],
  ['musical_keyboard', {name: 'musical keyboard', image: '1f3b9'}],
  ['trumpet', {name: 'trumpet', image: '1f3ba'}],
  ['violin', {name: 'violin', image: '1f3bb'}],
  ['musical_score', {name: 'musical score', image: '1f3bc'}],
  [
    'running_shirt_with_sash',
    {name: 'running shirt with sash', image: '1f3bd'}
  ],
  ['tennis', {name: 'tennis racquet and ball', image: '1f3be'}],
  ['ski', {name: 'ski and ski boot', image: '1f3bf'}],
  ['basketball', {name: 'basketball and hoop', image: '1f3c0'}],
  ['checkered_flag', {name: 'chequered flag', image: '1f3c1'}],
  ['snowboarder', {name: 'snowboarder', image: '1f3c2'}],
  ['snowboarder_skin-tone-2', {name: 'snowboarder', image: '1f3c2_1f3fb'}],
  ['snowboarder_skin-tone-3', {name: 'snowboarder', image: '1f3c2_1f3fc'}],
  ['snowboarder_skin-tone-4', {name: 'snowboarder', image: '1f3c2_1f3fd'}],
  ['snowboarder_skin-tone-5', {name: 'snowboarder', image: '1f3c2_1f3fe'}],
  ['snowboarder_skin-tone-6', {name: 'snowboarder', image: '1f3c2_1f3ff'}],
  ['woman-running', {name: 'woman running', image: '1f3c3_200d_2640'}],
  ['man-running', {name: 'man running', image: '1f3c3_200d_2642'}],
  ['runner', {name: 'runner', image: '1f3c3'}],
  ['runner_skin-tone-2', {name: 'runner', image: '1f3c3_1f3fb'}],
  ['runner_skin-tone-3', {name: 'runner', image: '1f3c3_1f3fc'}],
  ['runner_skin-tone-4', {name: 'runner', image: '1f3c3_1f3fd'}],
  ['runner_skin-tone-5', {name: 'runner', image: '1f3c3_1f3fe'}],
  ['runner_skin-tone-6', {name: 'runner', image: '1f3c3_1f3ff'}],
  ['woman-surfing', {name: 'woman surfing', image: '1f3c4_200d_2640'}],
  ['man-surfing', {name: 'man surfing', image: '1f3c4_200d_2642'}],
  ['surfer', {name: 'surfer', image: '1f3c4'}],
  ['surfer_skin-tone-2', {name: 'surfer', image: '1f3c4_1f3fb'}],
  ['surfer_skin-tone-3', {name: 'surfer', image: '1f3c4_1f3fc'}],
  ['surfer_skin-tone-4', {name: 'surfer', image: '1f3c4_1f3fd'}],
  ['surfer_skin-tone-5', {name: 'surfer', image: '1f3c4_1f3fe'}],
  ['surfer_skin-tone-6', {name: 'surfer', image: '1f3c4_1f3ff'}],
  ['sports_medal', {name: 'sports medal', image: '1f3c5'}],
  ['trophy', {name: 'trophy', image: '1f3c6'}],
  ['horse_racing', {name: 'horse racing', image: '1f3c7'}],
  ['horse_racing_skin-tone-2', {name: 'horse racing', image: '1f3c7_1f3fb'}],
  ['horse_racing_skin-tone-3', {name: 'horse racing', image: '1f3c7_1f3fc'}],
  ['horse_racing_skin-tone-4', {name: 'horse racing', image: '1f3c7_1f3fd'}],
  ['horse_racing_skin-tone-5', {name: 'horse racing', image: '1f3c7_1f3fe'}],
  ['horse_racing_skin-tone-6', {name: 'horse racing', image: '1f3c7_1f3ff'}],
  ['football', {name: 'american football', image: '1f3c8'}],
  ['rugby_football', {name: 'rugby football', image: '1f3c9'}],
  ['woman-swimming', {name: 'woman swimming', image: '1f3ca_200d_2640'}],
  ['man-swimming', {name: 'man swimming', image: '1f3ca_200d_2642'}],
  ['swimmer', {name: 'swimmer', image: '1f3ca'}],
  ['swimmer_skin-tone-2', {name: 'swimmer', image: '1f3ca_1f3fb'}],
  ['swimmer_skin-tone-3', {name: 'swimmer', image: '1f3ca_1f3fc'}],
  ['swimmer_skin-tone-4', {name: 'swimmer', image: '1f3ca_1f3fd'}],
  ['swimmer_skin-tone-5', {name: 'swimmer', image: '1f3ca_1f3fe'}],
  ['swimmer_skin-tone-6', {name: 'swimmer', image: '1f3ca_1f3ff'}],
  ['weight_lifter', {name: 'person lifting weights', image: '1f3cb'}],
  [
    'weight_lifter_skin-tone-2',
    {name: 'person lifting weights', image: '1f3cb_1f3fb'}
  ],
  [
    'weight_lifter_skin-tone-3',
    {name: 'person lifting weights', image: '1f3cb_1f3fc'}
  ],
  [
    'weight_lifter_skin-tone-4',
    {name: 'person lifting weights', image: '1f3cb_1f3fd'}
  ],
  [
    'weight_lifter_skin-tone-5',
    {name: 'person lifting weights', image: '1f3cb_1f3fe'}
  ],
  [
    'weight_lifter_skin-tone-6',
    {name: 'person lifting weights', image: '1f3cb_1f3ff'}
  ],
  ['golfer', {name: 'person golfing', image: '1f3cc'}],
  ['golfer_skin-tone-2', {name: 'person golfing', image: '1f3cc_1f3fb'}],
  ['golfer_skin-tone-3', {name: 'person golfing', image: '1f3cc_1f3fc'}],
  ['golfer_skin-tone-4', {name: 'person golfing', image: '1f3cc_1f3fd'}],
  ['golfer_skin-tone-5', {name: 'person golfing', image: '1f3cc_1f3fe'}],
  ['golfer_skin-tone-6', {name: 'person golfing', image: '1f3cc_1f3ff'}],
  ['racing_motorcycle', {name: 'motorcycle', image: '1f3cd'}],
  ['racing_car', {name: 'racing car', image: '1f3ce'}],
  ['cricket_bat_and_ball', {name: 'cricket bat and ball', image: '1f3cf'}],
  ['volleyball', {name: 'volleyball', image: '1f3d0'}],
  [
    'field_hockey_stick_and_ball',
    {name: 'field hockey stick and ball', image: '1f3d1'}
  ],
  [
    'ice_hockey_stick_and_puck',
    {name: 'ice hockey stick and puck', image: '1f3d2'}
  ],
  [
    'table_tennis_paddle_and_ball',
    {name: 'table tennis paddle and ball', image: '1f3d3'}
  ],
  ['snow_capped_mountain', {name: 'snow-capped mountain', image: '1f3d4'}],
  ['camping', {name: 'camping', image: '1f3d5'}],
  ['beach_with_umbrella', {name: 'beach with umbrella', image: '1f3d6'}],
  ['building_construction', {name: 'building construction', image: '1f3d7'}],
  ['house_buildings', {name: 'houses', image: '1f3d8'}],
  ['cityscape', {name: 'cityscape', image: '1f3d9'}],
  ['derelict_house_building', {name: 'derelict house', image: '1f3da'}],
  ['classical_building', {name: 'classical building', image: '1f3db'}],
  ['desert', {name: 'desert', image: '1f3dc'}],
  ['desert_island', {name: 'desert island', image: '1f3dd'}],
  ['national_park', {name: 'national park', image: '1f3de'}],
  ['stadium', {name: 'stadium', image: '1f3df'}],
  ['house', {name: 'house building', image: '1f3e0'}],
  ['house_with_garden', {name: 'house with garden', image: '1f3e1'}],
  ['office', {name: 'office building', image: '1f3e2'}],
  ['post_office', {name: 'japanese post office', image: '1f3e3'}],
  ['european_post_office', {name: 'european post office', image: '1f3e4'}],
  ['hospital', {name: 'hospital', image: '1f3e5'}],
  ['bank', {name: 'bank', image: '1f3e6'}],
  ['atm', {name: 'automated teller machine', image: '1f3e7'}],
  ['hotel', {name: 'hotel', image: '1f3e8'}],
  ['love_hotel', {name: 'love hotel', image: '1f3e9'}],
  ['convenience_store', {name: 'convenience store', image: '1f3ea'}],
  ['school', {name: 'school', image: '1f3eb'}],
  ['department_store', {name: 'department store', image: '1f3ec'}],
  ['factory', {name: 'factory', image: '1f3ed'}],
  ['izakaya_lantern', {name: 'izakaya lantern', image: '1f3ee'}],
  ['japanese_castle', {name: 'japanese castle', image: '1f3ef'}],
  ['european_castle', {name: 'european castle', image: '1f3f0'}],
  ['rainbow-flag', {name: 'rainbow flag', image: '1f3f3_200d_1f308'}],
  ['waving_white_flag', {name: 'white flag', image: '1f3f3'}],
  ['pirate_flag', {name: 'pirate flag', image: '1f3f4_200d_2620'}],
  ['waving_black_flag', {name: 'waving black flag', image: '1f3f4'}],
  ['rosette', {name: 'rosette', image: '1f3f5'}],
  ['label', {name: 'label', image: '1f3f7'}],
  [
    'badminton_racquet_and_shuttlecock',
    {name: 'badminton racquet and shuttlecock', image: '1f3f8'}
  ],
  ['bow_and_arrow', {name: 'bow and arrow', image: '1f3f9'}],
  ['amphora', {name: 'amphora', image: '1f3fa'}],
  [
    'skin-tone-2',
    {name: 'emoji modifier fitzpatrick type-1-2', image: '1f3fb'}
  ],
  ['skin-tone-3', {name: 'emoji modifier fitzpatrick type-3', image: '1f3fc'}],
  ['skin-tone-4', {name: 'emoji modifier fitzpatrick type-4', image: '1f3fd'}],
  ['skin-tone-5', {name: 'emoji modifier fitzpatrick type-5', image: '1f3fe'}],
  ['skin-tone-6', {name: 'emoji modifier fitzpatrick type-6', image: '1f3ff'}],
  ['rat', {name: 'rat', image: '1f400'}],
  ['mouse2', {name: 'mouse', image: '1f401'}],
  ['ox', {name: 'ox', image: '1f402'}],
  ['water_buffalo', {name: 'water buffalo', image: '1f403'}],
  ['cow2', {name: 'cow', image: '1f404'}],
  ['tiger2', {name: 'tiger', image: '1f405'}],
  ['leopard', {name: 'leopard', image: '1f406'}],
  ['rabbit2', {name: 'rabbit', image: '1f407'}],
  ['black_cat', {name: 'black cat', image: '1f408_200d_2b1b'}],
  ['cat2', {name: 'cat', image: '1f408'}],
  ['dragon', {name: 'dragon', image: '1f409'}],
  ['crocodile', {name: 'crocodile', image: '1f40a'}],
  ['whale2', {name: 'whale', image: '1f40b'}],
  ['snail', {name: 'snail', image: '1f40c'}],
  ['snake', {name: 'snake', image: '1f40d'}],
  ['racehorse', {name: 'horse', image: '1f40e'}],
  ['ram', {name: 'ram', image: '1f40f'}],
  ['goat', {name: 'goat', image: '1f410'}],
  ['sheep', {name: 'sheep', image: '1f411'}],
  ['monkey', {name: 'monkey', image: '1f412'}],
  ['rooster', {name: 'rooster', image: '1f413'}],
  ['chicken', {name: 'chicken', image: '1f414'}],
  ['service_dog', {name: 'service dog', image: '1f415_200d_1f9ba'}],
  ['dog2', {name: 'dog', image: '1f415'}],
  ['pig2', {name: 'pig', image: '1f416'}],
  ['boar', {name: 'boar', image: '1f417'}],
  ['elephant', {name: 'elephant', image: '1f418'}],
  ['octopus', {name: 'octopus', image: '1f419'}],
  ['shell', {name: 'spiral shell', image: '1f41a'}],
  ['bug', {name: 'bug', image: '1f41b'}],
  ['ant', {name: 'ant', image: '1f41c'}],
  ['bee', {name: 'honeybee', image: '1f41d'}],
  ['ladybug', {name: 'lady beetle', image: '1f41e'}],
  ['fish', {name: 'fish', image: '1f41f'}],
  ['tropical_fish', {name: 'tropical fish', image: '1f420'}],
  ['blowfish', {name: 'blowfish', image: '1f421'}],
  ['turtle', {name: 'turtle', image: '1f422'}],
  ['hatching_chick', {name: 'hatching chick', image: '1f423'}],
  ['baby_chick', {name: 'baby chick', image: '1f424'}],
  ['hatched_chick', {name: 'front-facing baby chick', image: '1f425'}],
  ['bird', {name: 'bird', image: '1f426'}],
  ['penguin', {name: 'penguin', image: '1f427'}],
  ['koala', {name: 'koala', image: '1f428'}],
  ['poodle', {name: 'poodle', image: '1f429'}],
  ['dromedary_camel', {name: 'dromedary camel', image: '1f42a'}],
  ['camel', {name: 'bactrian camel', image: '1f42b'}],
  ['dolphin', {name: 'dolphin', image: '1f42c'}],
  ['mouse', {name: 'mouse face', image: '1f42d'}],
  ['cow', {name: 'cow face', image: '1f42e'}],
  ['tiger', {name: 'tiger face', image: '1f42f'}],
  ['rabbit', {name: 'rabbit face', image: '1f430'}],
  ['cat', {name: 'cat face', image: '1f431'}],
  ['dragon_face', {name: 'dragon face', image: '1f432'}],
  ['whale', {name: 'spouting whale', image: '1f433'}],
  ['horse', {name: 'horse face', image: '1f434'}],
  ['monkey_face', {name: 'monkey face', image: '1f435'}],
  ['dog', {name: 'dog face', image: '1f436'}],
  ['pig', {name: 'pig face', image: '1f437'}],
  ['frog', {name: 'frog face', image: '1f438'}],
  ['hamster', {name: 'hamster face', image: '1f439'}],
  ['wolf', {name: 'wolf face', image: '1f43a'}],
  ['polar_bear', {name: 'polar bear', image: '1f43b_200d_2744'}],
  ['bear', {name: 'bear face', image: '1f43b'}],
  ['panda_face', {name: 'panda face', image: '1f43c'}],
  ['pig_nose', {name: 'pig nose', image: '1f43d'}],
  ['feet', {name: 'paw prints', image: '1f43e'}],
  ['chipmunk', {name: 'chipmunk', image: '1f43f'}],
  ['eyes', {name: 'eyes', image: '1f440'}],
  ['eye', {name: 'eye', image: '1f441'}],
  ['ear', {name: 'ear', image: '1f442'}],
  ['ear_skin-tone-2', {name: 'ear', image: '1f442_1f3fb'}],
  ['ear_skin-tone-3', {name: 'ear', image: '1f442_1f3fc'}],
  ['ear_skin-tone-4', {name: 'ear', image: '1f442_1f3fd'}],
  ['ear_skin-tone-5', {name: 'ear', image: '1f442_1f3fe'}],
  ['ear_skin-tone-6', {name: 'ear', image: '1f442_1f3ff'}],
  ['nose', {name: 'nose', image: '1f443'}],
  ['nose_skin-tone-2', {name: 'nose', image: '1f443_1f3fb'}],
  ['nose_skin-tone-3', {name: 'nose', image: '1f443_1f3fc'}],
  ['nose_skin-tone-4', {name: 'nose', image: '1f443_1f3fd'}],
  ['nose_skin-tone-5', {name: 'nose', image: '1f443_1f3fe'}],
  ['nose_skin-tone-6', {name: 'nose', image: '1f443_1f3ff'}],
  ['lips', {name: 'mouth', image: '1f444'}],
  ['tongue', {name: 'tongue', image: '1f445'}],
  ['point_up_2', {name: 'white up pointing backhand index', image: '1f446'}],
  [
    'point_up_2_skin-tone-2',
    {name: 'white up pointing backhand index', image: '1f446_1f3fb'}
  ],
  [
    'point_up_2_skin-tone-3',
    {name: 'white up pointing backhand index', image: '1f446_1f3fc'}
  ],
  [
    'point_up_2_skin-tone-4',
    {name: 'white up pointing backhand index', image: '1f446_1f3fd'}
  ],
  [
    'point_up_2_skin-tone-5',
    {name: 'white up pointing backhand index', image: '1f446_1f3fe'}
  ],
  [
    'point_up_2_skin-tone-6',
    {name: 'white up pointing backhand index', image: '1f446_1f3ff'}
  ],
  ['point_down', {name: 'white down pointing backhand index', image: '1f447'}],
  [
    'point_down_skin-tone-2',
    {name: 'white down pointing backhand index', image: '1f447_1f3fb'}
  ],
  [
    'point_down_skin-tone-3',
    {name: 'white down pointing backhand index', image: '1f447_1f3fc'}
  ],
  [
    'point_down_skin-tone-4',
    {name: 'white down pointing backhand index', image: '1f447_1f3fd'}
  ],
  [
    'point_down_skin-tone-5',
    {name: 'white down pointing backhand index', image: '1f447_1f3fe'}
  ],
  [
    'point_down_skin-tone-6',
    {name: 'white down pointing backhand index', image: '1f447_1f3ff'}
  ],
  ['point_left', {name: 'white left pointing backhand index', image: '1f448'}],
  [
    'point_left_skin-tone-2',
    {name: 'white left pointing backhand index', image: '1f448_1f3fb'}
  ],
  [
    'point_left_skin-tone-3',
    {name: 'white left pointing backhand index', image: '1f448_1f3fc'}
  ],
  [
    'point_left_skin-tone-4',
    {name: 'white left pointing backhand index', image: '1f448_1f3fd'}
  ],
  [
    'point_left_skin-tone-5',
    {name: 'white left pointing backhand index', image: '1f448_1f3fe'}
  ],
  [
    'point_left_skin-tone-6',
    {name: 'white left pointing backhand index', image: '1f448_1f3ff'}
  ],
  [
    'point_right',
    {name: 'white right pointing backhand index', image: '1f449'}
  ],
  [
    'point_right_skin-tone-2',
    {name: 'white right pointing backhand index', image: '1f449_1f3fb'}
  ],
  [
    'point_right_skin-tone-3',
    {name: 'white right pointing backhand index', image: '1f449_1f3fc'}
  ],
  [
    'point_right_skin-tone-4',
    {name: 'white right pointing backhand index', image: '1f449_1f3fd'}
  ],
  [
    'point_right_skin-tone-5',
    {name: 'white right pointing backhand index', image: '1f449_1f3fe'}
  ],
  [
    'point_right_skin-tone-6',
    {name: 'white right pointing backhand index', image: '1f449_1f3ff'}
  ],
  ['facepunch', {name: 'fisted hand sign', image: '1f44a'}],
  ['facepunch_skin-tone-2', {name: 'fisted hand sign', image: '1f44a_1f3fb'}],
  ['facepunch_skin-tone-3', {name: 'fisted hand sign', image: '1f44a_1f3fc'}],
  ['facepunch_skin-tone-4', {name: 'fisted hand sign', image: '1f44a_1f3fd'}],
  ['facepunch_skin-tone-5', {name: 'fisted hand sign', image: '1f44a_1f3fe'}],
  ['facepunch_skin-tone-6', {name: 'fisted hand sign', image: '1f44a_1f3ff'}],
  ['wave', {name: 'waving hand sign', image: '1f44b'}],
  ['wave_skin-tone-2', {name: 'waving hand sign', image: '1f44b_1f3fb'}],
  ['wave_skin-tone-3', {name: 'waving hand sign', image: '1f44b_1f3fc'}],
  ['wave_skin-tone-4', {name: 'waving hand sign', image: '1f44b_1f3fd'}],
  ['wave_skin-tone-5', {name: 'waving hand sign', image: '1f44b_1f3fe'}],
  ['wave_skin-tone-6', {name: 'waving hand sign', image: '1f44b_1f3ff'}],
  ['ok_hand', {name: 'ok hand sign', image: '1f44c'}],
  ['ok_hand_skin-tone-2', {name: 'ok hand sign', image: '1f44c_1f3fb'}],
  ['ok_hand_skin-tone-3', {name: 'ok hand sign', image: '1f44c_1f3fc'}],
  ['ok_hand_skin-tone-4', {name: 'ok hand sign', image: '1f44c_1f3fd'}],
  ['ok_hand_skin-tone-5', {name: 'ok hand sign', image: '1f44c_1f3fe'}],
  ['ok_hand_skin-tone-6', {name: 'ok hand sign', image: '1f44c_1f3ff'}],
  ['+1', {name: 'thumbs up sign', image: '1f44d'}],
  ['+1_skin-tone-2', {name: 'thumbs up sign', image: '1f44d_1f3fb'}],
  ['+1_skin-tone-3', {name: 'thumbs up sign', image: '1f44d_1f3fc'}],
  ['+1_skin-tone-4', {name: 'thumbs up sign', image: '1f44d_1f3fd'}],
  ['+1_skin-tone-5', {name: 'thumbs up sign', image: '1f44d_1f3fe'}],
  ['+1_skin-tone-6', {name: 'thumbs up sign', image: '1f44d_1f3ff'}],
  ['-1', {name: 'thumbs down sign', image: '1f44e'}],
  ['-1_skin-tone-2', {name: 'thumbs down sign', image: '1f44e_1f3fb'}],
  ['-1_skin-tone-3', {name: 'thumbs down sign', image: '1f44e_1f3fc'}],
  ['-1_skin-tone-4', {name: 'thumbs down sign', image: '1f44e_1f3fd'}],
  ['-1_skin-tone-5', {name: 'thumbs down sign', image: '1f44e_1f3fe'}],
  ['-1_skin-tone-6', {name: 'thumbs down sign', image: '1f44e_1f3ff'}],
  ['clap', {name: 'clapping hands sign', image: '1f44f'}],
  ['clap_skin-tone-2', {name: 'clapping hands sign', image: '1f44f_1f3fb'}],
  ['clap_skin-tone-3', {name: 'clapping hands sign', image: '1f44f_1f3fc'}],
  ['clap_skin-tone-4', {name: 'clapping hands sign', image: '1f44f_1f3fd'}],
  ['clap_skin-tone-5', {name: 'clapping hands sign', image: '1f44f_1f3fe'}],
  ['clap_skin-tone-6', {name: 'clapping hands sign', image: '1f44f_1f3ff'}],
  ['open_hands', {name: 'open hands sign', image: '1f450'}],
  ['open_hands_skin-tone-2', {name: 'open hands sign', image: '1f450_1f3fb'}],
  ['open_hands_skin-tone-3', {name: 'open hands sign', image: '1f450_1f3fc'}],
  ['open_hands_skin-tone-4', {name: 'open hands sign', image: '1f450_1f3fd'}],
  ['open_hands_skin-tone-5', {name: 'open hands sign', image: '1f450_1f3fe'}],
  ['open_hands_skin-tone-6', {name: 'open hands sign', image: '1f450_1f3ff'}],
  ['crown', {name: 'crown', image: '1f451'}],
  ['womans_hat', {name: 'womans hat', image: '1f452'}],
  ['eyeglasses', {name: 'eyeglasses', image: '1f453'}],
  ['necktie', {name: 'necktie', image: '1f454'}],
  ['shirt', {name: 't-shirt', image: '1f455'}],
  ['jeans', {name: 'jeans', image: '1f456'}],
  ['dress', {name: 'dress', image: '1f457'}],
  ['kimono', {name: 'kimono', image: '1f458'}],
  ['bikini', {name: 'bikini', image: '1f459'}],
  ['womans_clothes', {name: 'womans clothes', image: '1f45a'}],
  ['purse', {name: 'purse', image: '1f45b'}],
  ['handbag', {name: 'handbag', image: '1f45c'}],
  ['pouch', {name: 'pouch', image: '1f45d'}],
  ['mans_shoe', {name: 'mans shoe', image: '1f45e'}],
  ['athletic_shoe', {name: 'athletic shoe', image: '1f45f'}],
  ['high_heel', {name: 'high-heeled shoe', image: '1f460'}],
  ['sandal', {name: 'womans sandal', image: '1f461'}],
  ['boot', {name: 'womans boots', image: '1f462'}],
  ['footprints', {name: 'footprints', image: '1f463'}],
  ['bust_in_silhouette', {name: 'bust in silhouette', image: '1f464'}],
  ['busts_in_silhouette', {name: 'busts in silhouette', image: '1f465'}],
  ['boy', {name: 'boy', image: '1f466'}],
  ['boy_skin-tone-2', {name: 'boy', image: '1f466_1f3fb'}],
  ['boy_skin-tone-3', {name: 'boy', image: '1f466_1f3fc'}],
  ['boy_skin-tone-4', {name: 'boy', image: '1f466_1f3fd'}],
  ['boy_skin-tone-5', {name: 'boy', image: '1f466_1f3fe'}],
  ['boy_skin-tone-6', {name: 'boy', image: '1f466_1f3ff'}],
  ['girl', {name: 'girl', image: '1f467'}],
  ['girl_skin-tone-2', {name: 'girl', image: '1f467_1f3fb'}],
  ['girl_skin-tone-3', {name: 'girl', image: '1f467_1f3fc'}],
  ['girl_skin-tone-4', {name: 'girl', image: '1f467_1f3fd'}],
  ['girl_skin-tone-5', {name: 'girl', image: '1f467_1f3fe'}],
  ['girl_skin-tone-6', {name: 'girl', image: '1f467_1f3ff'}],
  ['male-farmer', {name: 'man farmer', image: '1f468_200d_1f33e'}],
  ['male-cook', {name: 'man cook', image: '1f468_200d_1f373'}],
  ['man_feeding_baby', {name: 'man feeding baby', image: '1f468_200d_1f37c'}],
  ['male-student', {name: 'man student', image: '1f468_200d_1f393'}],
  ['male-singer', {name: 'man singer', image: '1f468_200d_1f3a4'}],
  ['male-artist', {name: 'man artist', image: '1f468_200d_1f3a8'}],
  ['male-teacher', {name: 'man teacher', image: '1f468_200d_1f3eb'}],
  [
    'male-factory-worker',
    {name: 'man factory worker', image: '1f468_200d_1f3ed'}
  ],
  [
    'man-boy-boy',
    {name: 'family: man, boy, boy', image: '1f468_200d_1f466_200d_1f466'}
  ],
  ['man-boy', {name: 'family: man, boy', image: '1f468_200d_1f466'}],
  [
    'man-girl-boy',
    {name: 'family: man, girl, boy', image: '1f468_200d_1f467_200d_1f466'}
  ],
  [
    'man-girl-girl',
    {name: 'family: man, girl, girl', image: '1f468_200d_1f467_200d_1f467'}
  ],
  ['man-girl', {name: 'family: man, girl', image: '1f468_200d_1f467'}],
  [
    'man-man-boy',
    {name: 'family: man, man, boy', image: '1f468_200d_1f468_200d_1f466'}
  ],
  [
    'man-man-boy-boy',
    {
      name: 'family: man, man, boy, boy',
      image: '1f468_200d_1f468_200d_1f466_200d_1f466'
    }
  ],
  [
    'man-man-girl',
    {name: 'family: man, man, girl', image: '1f468_200d_1f468_200d_1f467'}
  ],
  [
    'man-man-girl-boy',
    {
      name: 'family: man, man, girl, boy',
      image: '1f468_200d_1f468_200d_1f467_200d_1f466'
    }
  ],
  [
    'man-man-girl-girl',
    {
      name: 'family: man, man, girl, girl',
      image: '1f468_200d_1f468_200d_1f467_200d_1f467'
    }
  ],
  [
    'man-woman-boy',
    {name: 'family: man, woman, boy', image: '1f468_200d_1f469_200d_1f466'}
  ],
  [
    'man-woman-boy-boy',
    {
      name: 'family: man, woman, boy, boy',
      image: '1f468_200d_1f469_200d_1f466_200d_1f466'
    }
  ],
  [
    'man-woman-girl',
    {name: 'family: man, woman, girl', image: '1f468_200d_1f469_200d_1f467'}
  ],
  [
    'man-woman-girl-boy',
    {
      name: 'family: man, woman, girl, boy',
      image: '1f468_200d_1f469_200d_1f467_200d_1f466'
    }
  ],
  [
    'man-woman-girl-girl',
    {
      name: 'family: man, woman, girl, girl',
      image: '1f468_200d_1f469_200d_1f467_200d_1f467'
    }
  ],
  ['male-technologist', {name: 'man technologist', image: '1f468_200d_1f4bb'}],
  [
    'male-office-worker',
    {name: 'man office worker', image: '1f468_200d_1f4bc'}
  ],
  ['male-mechanic', {name: 'man mechanic', image: '1f468_200d_1f527'}],
  ['male-scientist', {name: 'man scientist', image: '1f468_200d_1f52c'}],
  ['male-astronaut', {name: 'man astronaut', image: '1f468_200d_1f680'}],
  ['male-firefighter', {name: 'man firefighter', image: '1f468_200d_1f692'}],
  [
    'man_with_probing_cane',
    {name: 'man with white cane', image: '1f468_200d_1f9af'}
  ],
  ['red_haired_man', {name: 'man: red hair', image: '1f468_200d_1f9b0'}],
  ['curly_haired_man', {name: 'man: curly hair', image: '1f468_200d_1f9b1'}],
  ['bald_man', {name: 'man: bald', image: '1f468_200d_1f9b2'}],
  ['white_haired_man', {name: 'man: white hair', image: '1f468_200d_1f9b3'}],
  [
    'man_in_motorized_wheelchair',
    {name: 'man in motorized wheelchair', image: '1f468_200d_1f9bc'}
  ],
  [
    'man_in_manual_wheelchair',
    {name: 'man in manual wheelchair', image: '1f468_200d_1f9bd'}
  ],
  ['male-doctor', {name: 'man health worker', image: '1f468_200d_2695'}],
  ['male-judge', {name: 'man judge', image: '1f468_200d_2696'}],
  ['male-pilot', {name: 'man pilot', image: '1f468_200d_2708'}],
  [
    'man-heart-man',
    {name: 'couple with heart: man, man', image: '1f468_200d_2764_200d_1f468'}
  ],
  [
    'man-kiss-man',
    {name: 'kiss: man, man', image: '1f468_200d_2764_200d_1f48b_200d_1f468'}
  ],
  ['man', {name: 'man', image: '1f468'}],
  ['man_skin-tone-2', {name: 'man', image: '1f468_1f3fb'}],
  ['man_skin-tone-3', {name: 'man', image: '1f468_1f3fc'}],
  ['man_skin-tone-4', {name: 'man', image: '1f468_1f3fd'}],
  ['man_skin-tone-5', {name: 'man', image: '1f468_1f3fe'}],
  ['man_skin-tone-6', {name: 'man', image: '1f468_1f3ff'}],
  ['female-farmer', {name: 'woman farmer', image: '1f469_200d_1f33e'}],
  ['female-cook', {name: 'woman cook', image: '1f469_200d_1f373'}],
  [
    'woman_feeding_baby',
    {name: 'woman feeding baby', image: '1f469_200d_1f37c'}
  ],
  ['female-student', {name: 'woman student', image: '1f469_200d_1f393'}],
  ['female-singer', {name: 'woman singer', image: '1f469_200d_1f3a4'}],
  ['female-artist', {name: 'woman artist', image: '1f469_200d_1f3a8'}],
  ['female-teacher', {name: 'woman teacher', image: '1f469_200d_1f3eb'}],
  [
    'female-factory-worker',
    {name: 'woman factory worker', image: '1f469_200d_1f3ed'}
  ],
  [
    'woman-boy-boy',
    {name: 'family: woman, boy, boy', image: '1f469_200d_1f466_200d_1f466'}
  ],
  ['woman-boy', {name: 'family: woman, boy', image: '1f469_200d_1f466'}],
  [
    'woman-girl-boy',
    {name: 'family: woman, girl, boy', image: '1f469_200d_1f467_200d_1f466'}
  ],
  [
    'woman-girl-girl',
    {name: 'family: woman, girl, girl', image: '1f469_200d_1f467_200d_1f467'}
  ],
  ['woman-girl', {name: 'family: woman, girl', image: '1f469_200d_1f467'}],
  [
    'woman-woman-boy',
    {name: 'family: woman, woman, boy', image: '1f469_200d_1f469_200d_1f466'}
  ],
  [
    'woman-woman-boy-boy',
    {
      name: 'family: woman, woman, boy, boy',
      image: '1f469_200d_1f469_200d_1f466_200d_1f466'
    }
  ],
  [
    'woman-woman-girl',
    {name: 'family: woman, woman, girl', image: '1f469_200d_1f469_200d_1f467'}
  ],
  [
    'woman-woman-girl-boy',
    {
      name: 'family: woman, woman, girl, boy',
      image: '1f469_200d_1f469_200d_1f467_200d_1f466'
    }
  ],
  [
    'woman-woman-girl-girl',
    {
      name: 'family: woman, woman, girl, girl',
      image: '1f469_200d_1f469_200d_1f467_200d_1f467'
    }
  ],
  [
    'female-technologist',
    {name: 'woman technologist', image: '1f469_200d_1f4bb'}
  ],
  [
    'female-office-worker',
    {name: 'woman office worker', image: '1f469_200d_1f4bc'}
  ],
  ['female-mechanic', {name: 'woman mechanic', image: '1f469_200d_1f527'}],
  ['female-scientist', {name: 'woman scientist', image: '1f469_200d_1f52c'}],
  ['female-astronaut', {name: 'woman astronaut', image: '1f469_200d_1f680'}],
  [
    'female-firefighter',
    {name: 'woman firefighter', image: '1f469_200d_1f692'}
  ],
  [
    'woman_with_probing_cane',
    {name: 'woman with white cane', image: '1f469_200d_1f9af'}
  ],
  ['red_haired_woman', {name: 'woman: red hair', image: '1f469_200d_1f9b0'}],
  [
    'curly_haired_woman',
    {name: 'woman: curly hair', image: '1f469_200d_1f9b1'}
  ],
  ['bald_woman', {name: 'woman: bald', image: '1f469_200d_1f9b2'}],
  [
    'white_haired_woman',
    {name: 'woman: white hair', image: '1f469_200d_1f9b3'}
  ],
  [
    'woman_in_motorized_wheelchair',
    {name: 'woman in motorized wheelchair', image: '1f469_200d_1f9bc'}
  ],
  [
    'woman_in_manual_wheelchair',
    {name: 'woman in manual wheelchair', image: '1f469_200d_1f9bd'}
  ],
  ['female-doctor', {name: 'woman health worker', image: '1f469_200d_2695'}],
  ['female-judge', {name: 'woman judge', image: '1f469_200d_2696'}],
  ['female-pilot', {name: 'woman pilot', image: '1f469_200d_2708'}],
  [
    'woman-heart-man',
    {name: 'couple with heart: woman, man', image: '1f469_200d_2764_200d_1f468'}
  ],
  [
    'woman-heart-woman',
    {
      name: 'couple with heart: woman, woman',
      image: '1f469_200d_2764_200d_1f469'
    }
  ],
  [
    'woman-kiss-man',
    {name: 'kiss: woman, man', image: '1f469_200d_2764_200d_1f48b_200d_1f468'}
  ],
  [
    'woman-kiss-woman',
    {name: 'kiss: woman, woman', image: '1f469_200d_2764_200d_1f48b_200d_1f469'}
  ],
  ['woman', {name: 'woman', image: '1f469'}],
  ['woman_skin-tone-2', {name: 'woman', image: '1f469_1f3fb'}],
  ['woman_skin-tone-3', {name: 'woman', image: '1f469_1f3fc'}],
  ['woman_skin-tone-4', {name: 'woman', image: '1f469_1f3fd'}],
  ['woman_skin-tone-5', {name: 'woman', image: '1f469_1f3fe'}],
  ['woman_skin-tone-6', {name: 'woman', image: '1f469_1f3ff'}],
  ['family', {name: 'family', image: '1f46a'}],
  [
    'man_and_woman_holding_hands',
    {name: 'man and woman holding hands', image: '1f46b'}
  ],
  [
    'man_and_woman_holding_hands_skin-tone-2',
    {name: 'man and woman holding hands', image: '1f46b_1f3fb'}
  ],
  [
    'man_and_woman_holding_hands_skin-tone-3',
    {name: 'man and woman holding hands', image: '1f46b_1f3fc'}
  ],
  [
    'man_and_woman_holding_hands_skin-tone-4',
    {name: 'man and woman holding hands', image: '1f46b_1f3fd'}
  ],
  [
    'man_and_woman_holding_hands_skin-tone-5',
    {name: 'man and woman holding hands', image: '1f46b_1f3fe'}
  ],
  [
    'man_and_woman_holding_hands_skin-tone-6',
    {name: 'man and woman holding hands', image: '1f46b_1f3ff'}
  ],
  ['two_men_holding_hands', {name: 'two men holding hands', image: '1f46c'}],
  [
    'two_men_holding_hands_skin-tone-2',
    {name: 'two men holding hands', image: '1f46c_1f3fb'}
  ],
  [
    'two_men_holding_hands_skin-tone-3',
    {name: 'two men holding hands', image: '1f46c_1f3fc'}
  ],
  [
    'two_men_holding_hands_skin-tone-4',
    {name: 'two men holding hands', image: '1f46c_1f3fd'}
  ],
  [
    'two_men_holding_hands_skin-tone-5',
    {name: 'two men holding hands', image: '1f46c_1f3fe'}
  ],
  [
    'two_men_holding_hands_skin-tone-6',
    {name: 'two men holding hands', image: '1f46c_1f3ff'}
  ],
  [
    'two_women_holding_hands',
    {name: 'two women holding hands', image: '1f46d'}
  ],
  [
    'two_women_holding_hands_skin-tone-2',
    {name: 'two women holding hands', image: '1f46d_1f3fb'}
  ],
  [
    'two_women_holding_hands_skin-tone-3',
    {name: 'two women holding hands', image: '1f46d_1f3fc'}
  ],
  [
    'two_women_holding_hands_skin-tone-4',
    {name: 'two women holding hands', image: '1f46d_1f3fd'}
  ],
  [
    'two_women_holding_hands_skin-tone-5',
    {name: 'two women holding hands', image: '1f46d_1f3fe'}
  ],
  [
    'two_women_holding_hands_skin-tone-6',
    {name: 'two women holding hands', image: '1f46d_1f3ff'}
  ],
  [
    'female-police-officer',
    {name: 'woman police officer', image: '1f46e_200d_2640'}
  ],
  [
    'male-police-officer',
    {name: 'man police officer', image: '1f46e_200d_2642'}
  ],
  ['cop', {name: 'police officer', image: '1f46e'}],
  ['cop_skin-tone-2', {name: 'police officer', image: '1f46e_1f3fb'}],
  ['cop_skin-tone-3', {name: 'police officer', image: '1f46e_1f3fc'}],
  ['cop_skin-tone-4', {name: 'police officer', image: '1f46e_1f3fd'}],
  ['cop_skin-tone-5', {name: 'police officer', image: '1f46e_1f3fe'}],
  ['cop_skin-tone-6', {name: 'police officer', image: '1f46e_1f3ff'}],
  [
    'women-with-bunny-ears-partying',
    {name: 'women with bunny ears', image: '1f46f_200d_2640'}
  ],
  [
    'men-with-bunny-ears-partying',
    {name: 'men with bunny ears', image: '1f46f_200d_2642'}
  ],
  ['dancers', {name: 'woman with bunny ears', image: '1f46f'}],
  ['woman_with_veil', {name: 'woman with veil', image: '1f470_200d_2640'}],
  ['man_with_veil', {name: 'man with veil', image: '1f470_200d_2642'}],
  ['bride_with_veil', {name: 'bride with veil', image: '1f470'}],
  [
    'bride_with_veil_skin-tone-2',
    {name: 'bride with veil', image: '1f470_1f3fb'}
  ],
  [
    'bride_with_veil_skin-tone-3',
    {name: 'bride with veil', image: '1f470_1f3fc'}
  ],
  [
    'bride_with_veil_skin-tone-4',
    {name: 'bride with veil', image: '1f470_1f3fd'}
  ],
  [
    'bride_with_veil_skin-tone-5',
    {name: 'bride with veil', image: '1f470_1f3fe'}
  ],
  [
    'bride_with_veil_skin-tone-6',
    {name: 'bride with veil', image: '1f470_1f3ff'}
  ],
  ['blond-haired-woman', {name: 'woman: blond hair', image: '1f471_200d_2640'}],
  ['blond-haired-man', {name: 'man: blond hair', image: '1f471_200d_2642'}],
  ['person_with_blond_hair', {name: 'person with blond hair', image: '1f471'}],
  [
    'person_with_blond_hair_skin-tone-2',
    {name: 'person with blond hair', image: '1f471_1f3fb'}
  ],
  [
    'person_with_blond_hair_skin-tone-3',
    {name: 'person with blond hair', image: '1f471_1f3fc'}
  ],
  [
    'person_with_blond_hair_skin-tone-4',
    {name: 'person with blond hair', image: '1f471_1f3fd'}
  ],
  [
    'person_with_blond_hair_skin-tone-5',
    {name: 'person with blond hair', image: '1f471_1f3fe'}
  ],
  [
    'person_with_blond_hair_skin-tone-6',
    {name: 'person with blond hair', image: '1f471_1f3ff'}
  ],
  ['man_with_gua_pi_mao', {name: 'man with gua pi mao', image: '1f472'}],
  [
    'man_with_gua_pi_mao_skin-tone-2',
    {name: 'man with gua pi mao', image: '1f472_1f3fb'}
  ],
  [
    'man_with_gua_pi_mao_skin-tone-3',
    {name: 'man with gua pi mao', image: '1f472_1f3fc'}
  ],
  [
    'man_with_gua_pi_mao_skin-tone-4',
    {name: 'man with gua pi mao', image: '1f472_1f3fd'}
  ],
  [
    'man_with_gua_pi_mao_skin-tone-5',
    {name: 'man with gua pi mao', image: '1f472_1f3fe'}
  ],
  [
    'man_with_gua_pi_mao_skin-tone-6',
    {name: 'man with gua pi mao', image: '1f472_1f3ff'}
  ],
  [
    'woman-wearing-turban',
    {name: 'woman wearing turban', image: '1f473_200d_2640'}
  ],
  [
    'man-wearing-turban',
    {name: 'man wearing turban', image: '1f473_200d_2642'}
  ],
  ['man_with_turban', {name: 'man with turban', image: '1f473'}],
  [
    'man_with_turban_skin-tone-2',
    {name: 'man with turban', image: '1f473_1f3fb'}
  ],
  [
    'man_with_turban_skin-tone-3',
    {name: 'man with turban', image: '1f473_1f3fc'}
  ],
  [
    'man_with_turban_skin-tone-4',
    {name: 'man with turban', image: '1f473_1f3fd'}
  ],
  [
    'man_with_turban_skin-tone-5',
    {name: 'man with turban', image: '1f473_1f3fe'}
  ],
  [
    'man_with_turban_skin-tone-6',
    {name: 'man with turban', image: '1f473_1f3ff'}
  ],
  ['older_man', {name: 'older man', image: '1f474'}],
  ['older_man_skin-tone-2', {name: 'older man', image: '1f474_1f3fb'}],
  ['older_man_skin-tone-3', {name: 'older man', image: '1f474_1f3fc'}],
  ['older_man_skin-tone-4', {name: 'older man', image: '1f474_1f3fd'}],
  ['older_man_skin-tone-5', {name: 'older man', image: '1f474_1f3fe'}],
  ['older_man_skin-tone-6', {name: 'older man', image: '1f474_1f3ff'}],
  ['older_woman', {name: 'older woman', image: '1f475'}],
  ['older_woman_skin-tone-2', {name: 'older woman', image: '1f475_1f3fb'}],
  ['older_woman_skin-tone-3', {name: 'older woman', image: '1f475_1f3fc'}],
  ['older_woman_skin-tone-4', {name: 'older woman', image: '1f475_1f3fd'}],
  ['older_woman_skin-tone-5', {name: 'older woman', image: '1f475_1f3fe'}],
  ['older_woman_skin-tone-6', {name: 'older woman', image: '1f475_1f3ff'}],
  ['baby', {name: 'baby', image: '1f476'}],
  ['baby_skin-tone-2', {name: 'baby', image: '1f476_1f3fb'}],
  ['baby_skin-tone-3', {name: 'baby', image: '1f476_1f3fc'}],
  ['baby_skin-tone-4', {name: 'baby', image: '1f476_1f3fd'}],
  ['baby_skin-tone-5', {name: 'baby', image: '1f476_1f3fe'}],
  ['baby_skin-tone-6', {name: 'baby', image: '1f476_1f3ff'}],
  [
    'female-construction-worker',
    {name: 'woman construction worker', image: '1f477_200d_2640'}
  ],
  [
    'male-construction-worker',
    {name: 'man construction worker', image: '1f477_200d_2642'}
  ],
  ['construction_worker', {name: 'construction worker', image: '1f477'}],
  [
    'construction_worker_skin-tone-2',
    {name: 'construction worker', image: '1f477_1f3fb'}
  ],
  [
    'construction_worker_skin-tone-3',
    {name: 'construction worker', image: '1f477_1f3fc'}
  ],
  [
    'construction_worker_skin-tone-4',
    {name: 'construction worker', image: '1f477_1f3fd'}
  ],
  [
    'construction_worker_skin-tone-5',
    {name: 'construction worker', image: '1f477_1f3fe'}
  ],
  [
    'construction_worker_skin-tone-6',
    {name: 'construction worker', image: '1f477_1f3ff'}
  ],
  ['princess', {name: 'princess', image: '1f478'}],
  ['princess_skin-tone-2', {name: 'princess', image: '1f478_1f3fb'}],
  ['princess_skin-tone-3', {name: 'princess', image: '1f478_1f3fc'}],
  ['princess_skin-tone-4', {name: 'princess', image: '1f478_1f3fd'}],
  ['princess_skin-tone-5', {name: 'princess', image: '1f478_1f3fe'}],
  ['princess_skin-tone-6', {name: 'princess', image: '1f478_1f3ff'}],
  ['japanese_ogre', {name: 'japanese ogre', image: '1f479'}],
  ['japanese_goblin', {name: 'japanese goblin', image: '1f47a'}],
  ['ghost', {name: 'ghost', image: '1f47b'}],
  ['angel', {name: 'baby angel', image: '1f47c'}],
  ['angel_skin-tone-2', {name: 'baby angel', image: '1f47c_1f3fb'}],
  ['angel_skin-tone-3', {name: 'baby angel', image: '1f47c_1f3fc'}],
  ['angel_skin-tone-4', {name: 'baby angel', image: '1f47c_1f3fd'}],
  ['angel_skin-tone-5', {name: 'baby angel', image: '1f47c_1f3fe'}],
  ['angel_skin-tone-6', {name: 'baby angel', image: '1f47c_1f3ff'}],
  ['alien', {name: 'extraterrestrial alien', image: '1f47d'}],
  ['space_invader', {name: 'alien monster', image: '1f47e'}],
  ['imp', {name: 'imp', image: '1f47f'}],
  ['skull', {name: 'skull', image: '1f480'}],
  [
    'woman-tipping-hand',
    {name: 'woman tipping hand', image: '1f481_200d_2640'}
  ],
  ['man-tipping-hand', {name: 'man tipping hand', image: '1f481_200d_2642'}],
  [
    'information_desk_person',
    {name: 'information desk person', image: '1f481'}
  ],
  [
    'information_desk_person_skin-tone-2',
    {name: 'information desk person', image: '1f481_1f3fb'}
  ],
  [
    'information_desk_person_skin-tone-3',
    {name: 'information desk person', image: '1f481_1f3fc'}
  ],
  [
    'information_desk_person_skin-tone-4',
    {name: 'information desk person', image: '1f481_1f3fd'}
  ],
  [
    'information_desk_person_skin-tone-5',
    {name: 'information desk person', image: '1f481_1f3fe'}
  ],
  [
    'information_desk_person_skin-tone-6',
    {name: 'information desk person', image: '1f481_1f3ff'}
  ],
  ['female-guard', {name: 'woman guard', image: '1f482_200d_2640'}],
  ['male-guard', {name: 'man guard', image: '1f482_200d_2642'}],
  ['guardsman', {name: 'guardsman', image: '1f482'}],
  ['guardsman_skin-tone-2', {name: 'guardsman', image: '1f482_1f3fb'}],
  ['guardsman_skin-tone-3', {name: 'guardsman', image: '1f482_1f3fc'}],
  ['guardsman_skin-tone-4', {name: 'guardsman', image: '1f482_1f3fd'}],
  ['guardsman_skin-tone-5', {name: 'guardsman', image: '1f482_1f3fe'}],
  ['guardsman_skin-tone-6', {name: 'guardsman', image: '1f482_1f3ff'}],
  ['dancer', {name: 'dancer', image: '1f483'}],
  ['dancer_skin-tone-2', {name: 'dancer', image: '1f483_1f3fb'}],
  ['dancer_skin-tone-3', {name: 'dancer', image: '1f483_1f3fc'}],
  ['dancer_skin-tone-4', {name: 'dancer', image: '1f483_1f3fd'}],
  ['dancer_skin-tone-5', {name: 'dancer', image: '1f483_1f3fe'}],
  ['dancer_skin-tone-6', {name: 'dancer', image: '1f483_1f3ff'}],
  ['lipstick', {name: 'lipstick', image: '1f484'}],
  ['nail_care', {name: 'nail polish', image: '1f485'}],
  ['nail_care_skin-tone-2', {name: 'nail polish', image: '1f485_1f3fb'}],
  ['nail_care_skin-tone-3', {name: 'nail polish', image: '1f485_1f3fc'}],
  ['nail_care_skin-tone-4', {name: 'nail polish', image: '1f485_1f3fd'}],
  ['nail_care_skin-tone-5', {name: 'nail polish', image: '1f485_1f3fe'}],
  ['nail_care_skin-tone-6', {name: 'nail polish', image: '1f485_1f3ff'}],
  [
    'woman-getting-massage',
    {name: 'woman getting massage', image: '1f486_200d_2640'}
  ],
  [
    'man-getting-massage',
    {name: 'man getting massage', image: '1f486_200d_2642'}
  ],
  ['massage', {name: 'face massage', image: '1f486'}],
  ['massage_skin-tone-2', {name: 'face massage', image: '1f486_1f3fb'}],
  ['massage_skin-tone-3', {name: 'face massage', image: '1f486_1f3fc'}],
  ['massage_skin-tone-4', {name: 'face massage', image: '1f486_1f3fd'}],
  ['massage_skin-tone-5', {name: 'face massage', image: '1f486_1f3fe'}],
  ['massage_skin-tone-6', {name: 'face massage', image: '1f486_1f3ff'}],
  [
    'woman-getting-haircut',
    {name: 'woman getting haircut', image: '1f487_200d_2640'}
  ],
  [
    'man-getting-haircut',
    {name: 'man getting haircut', image: '1f487_200d_2642'}
  ],
  ['haircut', {name: 'haircut', image: '1f487'}],
  ['haircut_skin-tone-2', {name: 'haircut', image: '1f487_1f3fb'}],
  ['haircut_skin-tone-3', {name: 'haircut', image: '1f487_1f3fc'}],
  ['haircut_skin-tone-4', {name: 'haircut', image: '1f487_1f3fd'}],
  ['haircut_skin-tone-5', {name: 'haircut', image: '1f487_1f3fe'}],
  ['haircut_skin-tone-6', {name: 'haircut', image: '1f487_1f3ff'}],
  ['barber', {name: 'barber pole', image: '1f488'}],
  ['syringe', {name: 'syringe', image: '1f489'}],
  ['pill', {name: 'pill', image: '1f48a'}],
  ['kiss', {name: 'kiss mark', image: '1f48b'}],
  ['love_letter', {name: 'love letter', image: '1f48c'}],
  ['ring', {name: 'ring', image: '1f48d'}],
  ['gem', {name: 'gem stone', image: '1f48e'}],
  ['couplekiss', {name: 'kiss', image: '1f48f'}],
  ['couplekiss_skin-tone-2', {name: 'kiss', image: '1f48f_1f3fb'}],
  ['couplekiss_skin-tone-3', {name: 'kiss', image: '1f48f_1f3fc'}],
  ['couplekiss_skin-tone-4', {name: 'kiss', image: '1f48f_1f3fd'}],
  ['couplekiss_skin-tone-5', {name: 'kiss', image: '1f48f_1f3fe'}],
  ['couplekiss_skin-tone-6', {name: 'kiss', image: '1f48f_1f3ff'}],
  ['bouquet', {name: 'bouquet', image: '1f490'}],
  ['couple_with_heart', {name: 'couple with heart', image: '1f491'}],
  [
    'couple_with_heart_skin-tone-2',
    {name: 'couple with heart', image: '1f491_1f3fb'}
  ],
  [
    'couple_with_heart_skin-tone-3',
    {name: 'couple with heart', image: '1f491_1f3fc'}
  ],
  [
    'couple_with_heart_skin-tone-4',
    {name: 'couple with heart', image: '1f491_1f3fd'}
  ],
  [
    'couple_with_heart_skin-tone-5',
    {name: 'couple with heart', image: '1f491_1f3fe'}
  ],
  [
    'couple_with_heart_skin-tone-6',
    {name: 'couple with heart', image: '1f491_1f3ff'}
  ],
  ['wedding', {name: 'wedding', image: '1f492'}],
  ['heartbeat', {name: 'beating heart', image: '1f493'}],
  ['broken_heart', {name: 'broken heart', image: '1f494'}],
  ['two_hearts', {name: 'two hearts', image: '1f495'}],
  ['sparkling_heart', {name: 'sparkling heart', image: '1f496'}],
  ['heartpulse', {name: 'growing heart', image: '1f497'}],
  ['cupid', {name: 'heart with arrow', image: '1f498'}],
  ['blue_heart', {name: 'blue heart', image: '1f499'}],
  ['green_heart', {name: 'green heart', image: '1f49a'}],
  ['yellow_heart', {name: 'yellow heart', image: '1f49b'}],
  ['purple_heart', {name: 'purple heart', image: '1f49c'}],
  ['gift_heart', {name: 'heart with ribbon', image: '1f49d'}],
  ['revolving_hearts', {name: 'revolving hearts', image: '1f49e'}],
  ['heart_decoration', {name: 'heart decoration', image: '1f49f'}],
  [
    'diamond_shape_with_a_dot_inside',
    {name: 'diamond shape with a dot inside', image: '1f4a0'}
  ],
  ['bulb', {name: 'electric light bulb', image: '1f4a1'}],
  ['anger', {name: 'anger symbol', image: '1f4a2'}],
  ['bomb', {name: 'bomb', image: '1f4a3'}],
  ['zzz', {name: 'sleeping symbol', image: '1f4a4'}],
  ['boom', {name: 'collision symbol', image: '1f4a5'}],
  ['sweat_drops', {name: 'splashing sweat symbol', image: '1f4a6'}],
  ['droplet', {name: 'droplet', image: '1f4a7'}],
  ['dash', {name: 'dash symbol', image: '1f4a8'}],
  ['hankey', {name: 'pile of poo', image: '1f4a9'}],
  ['muscle', {name: 'flexed biceps', image: '1f4aa'}],
  ['muscle_skin-tone-2', {name: 'flexed biceps', image: '1f4aa_1f3fb'}],
  ['muscle_skin-tone-3', {name: 'flexed biceps', image: '1f4aa_1f3fc'}],
  ['muscle_skin-tone-4', {name: 'flexed biceps', image: '1f4aa_1f3fd'}],
  ['muscle_skin-tone-5', {name: 'flexed biceps', image: '1f4aa_1f3fe'}],
  ['muscle_skin-tone-6', {name: 'flexed biceps', image: '1f4aa_1f3ff'}],
  ['dizzy', {name: 'dizzy symbol', image: '1f4ab'}],
  ['speech_balloon', {name: 'speech balloon', image: '1f4ac'}],
  ['thought_balloon', {name: 'thought balloon', image: '1f4ad'}],
  ['white_flower', {name: 'white flower', image: '1f4ae'}],
  ['moneybag', {name: 'money bag', image: '1f4b0'}],
  ['currency_exchange', {name: 'currency exchange', image: '1f4b1'}],
  ['heavy_dollar_sign', {name: 'heavy dollar sign', image: '1f4b2'}],
  ['credit_card', {name: 'credit card', image: '1f4b3'}],
  ['yen', {name: 'banknote with yen sign', image: '1f4b4'}],
  ['dollar', {name: 'banknote with dollar sign', image: '1f4b5'}],
  ['euro', {name: 'banknote with euro sign', image: '1f4b6'}],
  ['pound', {name: 'banknote with pound sign', image: '1f4b7'}],
  ['money_with_wings', {name: 'money with wings', image: '1f4b8'}],
  ['chart', {name: 'chart with upwards trend and yen sign', image: '1f4b9'}],
  ['seat', {name: 'seat', image: '1f4ba'}],
  ['computer', {name: 'personal computer', image: '1f4bb'}],
  ['briefcase', {name: 'briefcase', image: '1f4bc'}],
  ['minidisc', {name: 'minidisc', image: '1f4bd'}],
  ['floppy_disk', {name: 'floppy disk', image: '1f4be'}],
  ['cd', {name: 'optical disc', image: '1f4bf'}],
  ['dvd', {name: 'dvd', image: '1f4c0'}],
  ['file_folder', {name: 'file folder', image: '1f4c1'}],
  ['open_file_folder', {name: 'open file folder', image: '1f4c2'}],
  ['page_with_curl', {name: 'page with curl', image: '1f4c3'}],
  ['page_facing_up', {name: 'page facing up', image: '1f4c4'}],
  ['date', {name: 'calendar', image: '1f4c5'}],
  ['calendar', {name: 'tear-off calendar', image: '1f4c6'}],
  ['card_index', {name: 'card index', image: '1f4c7'}],
  [
    'chart_with_upwards_trend',
    {name: 'chart with upwards trend', image: '1f4c8'}
  ],
  [
    'chart_with_downwards_trend',
    {name: 'chart with downwards trend', image: '1f4c9'}
  ],
  ['bar_chart', {name: 'bar chart', image: '1f4ca'}],
  ['clipboard', {name: 'clipboard', image: '1f4cb'}],
  ['pushpin', {name: 'pushpin', image: '1f4cc'}],
  ['round_pushpin', {name: 'round pushpin', image: '1f4cd'}],
  ['paperclip', {name: 'paperclip', image: '1f4ce'}],
  ['straight_ruler', {name: 'straight ruler', image: '1f4cf'}],
  ['triangular_ruler', {name: 'triangular ruler', image: '1f4d0'}],
  ['bookmark_tabs', {name: 'bookmark tabs', image: '1f4d1'}],
  ['ledger', {name: 'ledger', image: '1f4d2'}],
  ['notebook', {name: 'notebook', image: '1f4d3'}],
  [
    'notebook_with_decorative_cover',
    {name: 'notebook with decorative cover', image: '1f4d4'}
  ],
  ['closed_book', {name: 'closed book', image: '1f4d5'}],
  ['book', {name: 'open book', image: '1f4d6'}],
  ['green_book', {name: 'green book', image: '1f4d7'}],
  ['blue_book', {name: 'blue book', image: '1f4d8'}],
  ['orange_book', {name: 'orange book', image: '1f4d9'}],
  ['books', {name: 'books', image: '1f4da'}],
  ['name_badge', {name: 'name badge', image: '1f4db'}],
  ['scroll', {name: 'scroll', image: '1f4dc'}],
  ['memo', {name: 'memo', image: '1f4dd'}],
  ['telephone_receiver', {name: 'telephone receiver', image: '1f4de'}],
  ['pager', {name: 'pager', image: '1f4df'}],
  ['fax', {name: 'fax machine', image: '1f4e0'}],
  ['satellite_antenna', {name: 'satellite antenna', image: '1f4e1'}],
  ['loudspeaker', {name: 'public address loudspeaker', image: '1f4e2'}],
  ['mega', {name: 'cheering megaphone', image: '1f4e3'}],
  ['outbox_tray', {name: 'outbox tray', image: '1f4e4'}],
  ['inbox_tray', {name: 'inbox tray', image: '1f4e5'}],
  ['package', {name: 'package', image: '1f4e6'}],
  ['e-mail', {name: 'e-mail symbol', image: '1f4e7'}],
  ['incoming_envelope', {name: 'incoming envelope', image: '1f4e8'}],
  [
    'envelope_with_arrow',
    {name: 'envelope with downwards arrow above', image: '1f4e9'}
  ],
  [
    'mailbox_closed',
    {name: 'closed mailbox with lowered flag', image: '1f4ea'}
  ],
  ['mailbox', {name: 'closed mailbox with raised flag', image: '1f4eb'}],
  [
    'mailbox_with_mail',
    {name: 'open mailbox with raised flag', image: '1f4ec'}
  ],
  [
    'mailbox_with_no_mail',
    {name: 'open mailbox with lowered flag', image: '1f4ed'}
  ],
  ['postbox', {name: 'postbox', image: '1f4ee'}],
  ['postal_horn', {name: 'postal horn', image: '1f4ef'}],
  ['newspaper', {name: 'newspaper', image: '1f4f0'}],
  ['iphone', {name: 'mobile phone', image: '1f4f1'}],
  [
    'calling',
    {name: 'mobile phone with rightwards arrow at left', image: '1f4f2'}
  ],
  ['vibration_mode', {name: 'vibration mode', image: '1f4f3'}],
  ['mobile_phone_off', {name: 'mobile phone off', image: '1f4f4'}],
  ['no_mobile_phones', {name: 'no mobile phones', image: '1f4f5'}],
  ['signal_strength', {name: 'antenna with bars', image: '1f4f6'}],
  ['camera', {name: 'camera', image: '1f4f7'}],
  ['camera_with_flash', {name: 'camera with flash', image: '1f4f8'}],
  ['video_camera', {name: 'video camera', image: '1f4f9'}],
  ['tv', {name: 'television', image: '1f4fa'}],
  ['radio', {name: 'radio', image: '1f4fb'}],
  ['vhs', {name: 'videocassette', image: '1f4fc'}],
  ['film_projector', {name: 'film projector', image: '1f4fd'}],
  ['prayer_beads', {name: 'prayer beads', image: '1f4ff'}],
  [
    'twisted_rightwards_arrows',
    {name: 'twisted rightwards arrows', image: '1f500'}
  ],
  [
    'repeat',
    {
      name: 'clockwise rightwards and leftwards open circle arrows',
      image: '1f501'
    }
  ],
  [
    'repeat_one',
    {
      name: 'clockwise rightwards and leftwards open circle arrows with circled one overlay',
      image: '1f502'
    }
  ],
  [
    'arrows_clockwise',
    {name: 'clockwise downwards and upwards open circle arrows', image: '1f503'}
  ],
  [
    'arrows_counterclockwise',
    {
      name: 'anticlockwise downwards and upwards open circle arrows',
      image: '1f504'
    }
  ],
  ['low_brightness', {name: 'low brightness symbol', image: '1f505'}],
  ['high_brightness', {name: 'high brightness symbol', image: '1f506'}],
  ['mute', {name: 'speaker with cancellation stroke', image: '1f507'}],
  ['speaker', {name: 'speaker', image: '1f508'}],
  ['sound', {name: 'speaker with one sound wave', image: '1f509'}],
  ['loud_sound', {name: 'speaker with three sound waves', image: '1f50a'}],
  ['battery', {name: 'battery', image: '1f50b'}],
  ['electric_plug', {name: 'electric plug', image: '1f50c'}],
  ['mag', {name: 'left-pointing magnifying glass', image: '1f50d'}],
  ['mag_right', {name: 'right-pointing magnifying glass', image: '1f50e'}],
  ['lock_with_ink_pen', {name: 'lock with ink pen', image: '1f50f'}],
  ['closed_lock_with_key', {name: 'closed lock with key', image: '1f510'}],
  ['key', {name: 'key', image: '1f511'}],
  ['lock', {name: 'lock', image: '1f512'}],
  ['unlock', {name: 'open lock', image: '1f513'}],
  ['bell', {name: 'bell', image: '1f514'}],
  ['no_bell', {name: 'bell with cancellation stroke', image: '1f515'}],
  ['bookmark', {name: 'bookmark', image: '1f516'}],
  ['link', {name: 'link symbol', image: '1f517'}],
  ['radio_button', {name: 'radio button', image: '1f518'}],
  ['back', {name: 'back with leftwards arrow above', image: '1f519'}],
  ['end', {name: 'end with leftwards arrow above', image: '1f51a'}],
  [
    'on',
    {
      name: 'on with exclamation mark with left right arrow above',
      image: '1f51b'
    }
  ],
  ['soon', {name: 'soon with rightwards arrow above', image: '1f51c'}],
  ['top', {name: 'top with upwards arrow above', image: '1f51d'}],
  ['underage', {name: 'no one under eighteen symbol', image: '1f51e'}],
  ['keycap_ten', {name: 'keycap ten', image: '1f51f'}],
  [
    'capital_abcd',
    {name: 'input symbol for latin capital letters', image: '1f520'}
  ],
  ['abcd', {name: 'input symbol for latin small letters', image: '1f521'}],
  ['symbols', {name: 'input symbol for symbols', image: '1f523'}],
  ['abc', {name: 'input symbol for latin letters', image: '1f524'}],
  ['fire', {name: 'fire', image: '1f525'}],
  ['flashlight', {name: 'electric torch', image: '1f526'}],
  ['wrench', {name: 'wrench', image: '1f527'}],
  ['hammer', {name: 'hammer', image: '1f528'}],
  ['nut_and_bolt', {name: 'nut and bolt', image: '1f529'}],
  ['hocho', {name: 'hocho', image: '1f52a'}],
  ['gun', {name: 'pistol', image: '1f52b'}],
  ['microscope', {name: 'microscope', image: '1f52c'}],
  ['telescope', {name: 'telescope', image: '1f52d'}],
  ['crystal_ball', {name: 'crystal ball', image: '1f52e'}],
  [
    'six_pointed_star',
    {name: 'six pointed star with middle dot', image: '1f52f'}
  ],
  ['beginner', {name: 'japanese symbol for beginner', image: '1f530'}],
  ['trident', {name: 'trident emblem', image: '1f531'}],
  ['black_square_button', {name: 'black square button', image: '1f532'}],
  ['white_square_button', {name: 'white square button', image: '1f533'}],
  ['red_circle', {name: 'large red circle', image: '1f534'}],
  ['large_blue_circle', {name: 'large blue circle', image: '1f535'}],
  ['large_orange_diamond', {name: 'large orange diamond', image: '1f536'}],
  ['large_blue_diamond', {name: 'large blue diamond', image: '1f537'}],
  ['small_orange_diamond', {name: 'small orange diamond', image: '1f538'}],
  ['small_blue_diamond', {name: 'small blue diamond', image: '1f539'}],
  ['small_red_triangle', {name: 'up-pointing red triangle', image: '1f53a'}],
  [
    'small_red_triangle_down',
    {name: 'down-pointing red triangle', image: '1f53b'}
  ],
  ['arrow_up_small', {name: 'up-pointing small red triangle', image: '1f53c'}],
  [
    'arrow_down_small',
    {name: 'down-pointing small red triangle', image: '1f53d'}
  ],
  ['om_symbol', {name: 'om', image: '1f549'}],
  ['dove_of_peace', {name: 'dove', image: '1f54a'}],
  ['kaaba', {name: 'kaaba', image: '1f54b'}],
  ['mosque', {name: 'mosque', image: '1f54c'}],
  ['synagogue', {name: 'synagogue', image: '1f54d'}],
  [
    'menorah_with_nine_branches',
    {name: 'menorah with nine branches', image: '1f54e'}
  ],
  ['clock1', {name: 'clock face one oclock', image: '1f550'}],
  ['clock2', {name: 'clock face two oclock', image: '1f551'}],
  ['clock3', {name: 'clock face three oclock', image: '1f552'}],
  ['clock4', {name: 'clock face four oclock', image: '1f553'}],
  ['clock5', {name: 'clock face five oclock', image: '1f554'}],
  ['clock6', {name: 'clock face six oclock', image: '1f555'}],
  ['clock7', {name: 'clock face seven oclock', image: '1f556'}],
  ['clock8', {name: 'clock face eight oclock', image: '1f557'}],
  ['clock9', {name: 'clock face nine oclock', image: '1f558'}],
  ['clock10', {name: 'clock face ten oclock', image: '1f559'}],
  ['clock11', {name: 'clock face eleven oclock', image: '1f55a'}],
  ['clock12', {name: 'clock face twelve oclock', image: '1f55b'}],
  ['clock130', {name: 'clock face one-thirty', image: '1f55c'}],
  ['clock230', {name: 'clock face two-thirty', image: '1f55d'}],
  ['clock330', {name: 'clock face three-thirty', image: '1f55e'}],
  ['clock430', {name: 'clock face four-thirty', image: '1f55f'}],
  ['clock530', {name: 'clock face five-thirty', image: '1f560'}],
  ['clock630', {name: 'clock face six-thirty', image: '1f561'}],
  ['clock730', {name: 'clock face seven-thirty', image: '1f562'}],
  ['clock830', {name: 'clock face eight-thirty', image: '1f563'}],
  ['clock930', {name: 'clock face nine-thirty', image: '1f564'}],
  ['clock1030', {name: 'clock face ten-thirty', image: '1f565'}],
  ['clock1130', {name: 'clock face eleven-thirty', image: '1f566'}],
  ['clock1230', {name: 'clock face twelve-thirty', image: '1f567'}],
  ['candle', {name: 'candle', image: '1f56f'}],
  ['mantelpiece_clock', {name: 'mantelpiece clock', image: '1f570'}],
  ['hole', {name: 'hole', image: '1f573'}],
  [
    'man_in_business_suit_levitating',
    {name: 'person in suit levitating', image: '1f574'}
  ],
  [
    'man_in_business_suit_levitating_skin-tone-2',
    {name: 'person in suit levitating', image: '1f574_1f3fb'}
  ],
  [
    'man_in_business_suit_levitating_skin-tone-3',
    {name: 'person in suit levitating', image: '1f574_1f3fc'}
  ],
  [
    'man_in_business_suit_levitating_skin-tone-4',
    {name: 'person in suit levitating', image: '1f574_1f3fd'}
  ],
  [
    'man_in_business_suit_levitating_skin-tone-5',
    {name: 'person in suit levitating', image: '1f574_1f3fe'}
  ],
  [
    'man_in_business_suit_levitating_skin-tone-6',
    {name: 'person in suit levitating', image: '1f574_1f3ff'}
  ],
  ['sleuth_or_spy', {name: 'detective', image: '1f575'}],
  ['sleuth_or_spy_skin-tone-2', {name: 'detective', image: '1f575_1f3fb'}],
  ['sleuth_or_spy_skin-tone-3', {name: 'detective', image: '1f575_1f3fc'}],
  ['sleuth_or_spy_skin-tone-4', {name: 'detective', image: '1f575_1f3fd'}],
  ['sleuth_or_spy_skin-tone-5', {name: 'detective', image: '1f575_1f3fe'}],
  ['sleuth_or_spy_skin-tone-6', {name: 'detective', image: '1f575_1f3ff'}],
  ['dark_sunglasses', {name: 'sunglasses', image: '1f576'}],
  ['spider', {name: 'spider', image: '1f577'}],
  ['spider_web', {name: 'spider web', image: '1f578'}],
  ['joystick', {name: 'joystick', image: '1f579'}],
  ['man_dancing', {name: 'man dancing', image: '1f57a'}],
  ['man_dancing_skin-tone-2', {name: 'man dancing', image: '1f57a_1f3fb'}],
  ['man_dancing_skin-tone-3', {name: 'man dancing', image: '1f57a_1f3fc'}],
  ['man_dancing_skin-tone-4', {name: 'man dancing', image: '1f57a_1f3fd'}],
  ['man_dancing_skin-tone-5', {name: 'man dancing', image: '1f57a_1f3fe'}],
  ['man_dancing_skin-tone-6', {name: 'man dancing', image: '1f57a_1f3ff'}],
  ['linked_paperclips', {name: 'linked paperclips', image: '1f587'}],
  ['lower_left_ballpoint_pen', {name: 'pen', image: '1f58a'}],
  ['lower_left_fountain_pen', {name: 'fountain pen', image: '1f58b'}],
  ['lower_left_paintbrush', {name: 'paintbrush', image: '1f58c'}],
  ['lower_left_crayon', {name: 'crayon', image: '1f58d'}],
  [
    'raised_hand_with_fingers_splayed',
    {name: 'hand with fingers splayed', image: '1f590'}
  ],
  [
    'raised_hand_with_fingers_splayed_skin-tone-2',
    {name: 'hand with fingers splayed', image: '1f590_1f3fb'}
  ],
  [
    'raised_hand_with_fingers_splayed_skin-tone-3',
    {name: 'hand with fingers splayed', image: '1f590_1f3fc'}
  ],
  [
    'raised_hand_with_fingers_splayed_skin-tone-4',
    {name: 'hand with fingers splayed', image: '1f590_1f3fd'}
  ],
  [
    'raised_hand_with_fingers_splayed_skin-tone-5',
    {name: 'hand with fingers splayed', image: '1f590_1f3fe'}
  ],
  [
    'raised_hand_with_fingers_splayed_skin-tone-6',
    {name: 'hand with fingers splayed', image: '1f590_1f3ff'}
  ],
  [
    'middle_finger',
    {name: 'reversed hand with middle finger extended', image: '1f595'}
  ],
  [
    'middle_finger_skin-tone-2',
    {name: 'reversed hand with middle finger extended', image: '1f595_1f3fb'}
  ],
  [
    'middle_finger_skin-tone-3',
    {name: 'reversed hand with middle finger extended', image: '1f595_1f3fc'}
  ],
  [
    'middle_finger_skin-tone-4',
    {name: 'reversed hand with middle finger extended', image: '1f595_1f3fd'}
  ],
  [
    'middle_finger_skin-tone-5',
    {name: 'reversed hand with middle finger extended', image: '1f595_1f3fe'}
  ],
  [
    'middle_finger_skin-tone-6',
    {name: 'reversed hand with middle finger extended', image: '1f595_1f3ff'}
  ],
  [
    'spock-hand',
    {
      name: 'raised hand with part between middle and ring fingers',
      image: '1f596'
    }
  ],
  [
    'spock-hand_skin-tone-2',
    {
      name: 'raised hand with part between middle and ring fingers',
      image: '1f596_1f3fb'
    }
  ],
  [
    'spock-hand_skin-tone-3',
    {
      name: 'raised hand with part between middle and ring fingers',
      image: '1f596_1f3fc'
    }
  ],
  [
    'spock-hand_skin-tone-4',
    {
      name: 'raised hand with part between middle and ring fingers',
      image: '1f596_1f3fd'
    }
  ],
  [
    'spock-hand_skin-tone-5',
    {
      name: 'raised hand with part between middle and ring fingers',
      image: '1f596_1f3fe'
    }
  ],
  [
    'spock-hand_skin-tone-6',
    {
      name: 'raised hand with part between middle and ring fingers',
      image: '1f596_1f3ff'
    }
  ],
  ['black_heart', {name: 'black heart', image: '1f5a4'}],
  ['desktop_computer', {name: 'desktop computer', image: '1f5a5'}],
  ['printer', {name: 'printer', image: '1f5a8'}],
  ['three_button_mouse', {name: 'computer mouse', image: '1f5b1'}],
  ['trackball', {name: 'trackball', image: '1f5b2'}],
  ['frame_with_picture', {name: 'framed picture', image: '1f5bc'}],
  ['card_index_dividers', {name: 'card index dividers', image: '1f5c2'}],
  ['card_file_box', {name: 'card file box', image: '1f5c3'}],
  ['file_cabinet', {name: 'file cabinet', image: '1f5c4'}],
  ['wastebasket', {name: 'wastebasket', image: '1f5d1'}],
  ['spiral_note_pad', {name: 'spiral notepad', image: '1f5d2'}],
  ['spiral_calendar_pad', {name: 'spiral calendar', image: '1f5d3'}],
  ['compression', {name: 'clamp', image: '1f5dc'}],
  ['old_key', {name: 'old key', image: '1f5dd'}],
  ['rolled_up_newspaper', {name: 'rolled-up newspaper', image: '1f5de'}],
  ['dagger_knife', {name: 'dagger', image: '1f5e1'}],
  ['speaking_head_in_silhouette', {name: 'speaking head', image: '1f5e3'}],
  ['left_speech_bubble', {name: 'left speech bubble', image: '1f5e8'}],
  ['right_anger_bubble', {name: 'right anger bubble', image: '1f5ef'}],
  ['ballot_box_with_ballot', {name: 'ballot box with ballot', image: '1f5f3'}],
  ['world_map', {name: 'world map', image: '1f5fa'}],
  ['mount_fuji', {name: 'mount fuji', image: '1f5fb'}],
  ['tokyo_tower', {name: 'tokyo tower', image: '1f5fc'}],
  ['statue_of_liberty', {name: 'statue of liberty', image: '1f5fd'}],
  ['japan', {name: 'silhouette of japan', image: '1f5fe'}],
  ['moyai', {name: 'moyai', image: '1f5ff'}],
  ['grinning', {name: 'grinning face', image: '1f600'}],
  ['grin', {name: 'grinning face with smiling eyes', image: '1f601'}],
  ['joy', {name: 'face with tears of joy', image: '1f602'}],
  ['smiley', {name: 'smiling face with open mouth', image: '1f603'}],
  [
    'smile',
    {name: 'smiling face with open mouth and smiling eyes', image: '1f604'}
  ],
  [
    'sweat_smile',
    {name: 'smiling face with open mouth and cold sweat', image: '1f605'}
  ],
  [
    'laughing',
    {
      name: 'smiling face with open mouth and tightly-closed eyes',
      image: '1f606'
    }
  ],
  ['innocent', {name: 'smiling face with halo', image: '1f607'}],
  ['smiling_imp', {name: 'smiling face with horns', image: '1f608'}],
  ['wink', {name: 'winking face', image: '1f609'}],
  ['blush', {name: 'smiling face with smiling eyes', image: '1f60a'}],
  ['yum', {name: 'face savouring delicious food', image: '1f60b'}],
  ['relieved', {name: 'relieved face', image: '1f60c'}],
  ['heart_eyes', {name: 'smiling face with heart-shaped eyes', image: '1f60d'}],
  ['sunglasses', {name: 'smiling face with sunglasses', image: '1f60e'}],
  ['smirk', {name: 'smirking face', image: '1f60f'}],
  ['neutral_face', {name: 'neutral face', image: '1f610'}],
  ['expressionless', {name: 'expressionless face', image: '1f611'}],
  ['unamused', {name: 'unamused face', image: '1f612'}],
  ['sweat', {name: 'face with cold sweat', image: '1f613'}],
  ['pensive', {name: 'pensive face', image: '1f614'}],
  ['confused', {name: 'confused face', image: '1f615'}],
  ['confounded', {name: 'confounded face', image: '1f616'}],
  ['kissing', {name: 'kissing face', image: '1f617'}],
  ['kissing_heart', {name: 'face throwing a kiss', image: '1f618'}],
  [
    'kissing_smiling_eyes',
    {name: 'kissing face with smiling eyes', image: '1f619'}
  ],
  [
    'kissing_closed_eyes',
    {name: 'kissing face with closed eyes', image: '1f61a'}
  ],
  ['stuck_out_tongue', {name: 'face with stuck-out tongue', image: '1f61b'}],
  [
    'stuck_out_tongue_winking_eye',
    {name: 'face with stuck-out tongue and winking eye', image: '1f61c'}
  ],
  [
    'stuck_out_tongue_closed_eyes',
    {name: 'face with stuck-out tongue and tightly-closed eyes', image: '1f61d'}
  ],
  ['disappointed', {name: 'disappointed face', image: '1f61e'}],
  ['worried', {name: 'worried face', image: '1f61f'}],
  ['angry', {name: 'angry face', image: '1f620'}],
  ['rage', {name: 'pouting face', image: '1f621'}],
  ['cry', {name: 'crying face', image: '1f622'}],
  ['persevere', {name: 'persevering face', image: '1f623'}],
  ['triumph', {name: 'face with look of triumph', image: '1f624'}],
  [
    'disappointed_relieved',
    {name: 'disappointed but relieved face', image: '1f625'}
  ],
  ['frowning', {name: 'frowning face with open mouth', image: '1f626'}],
  ['anguished', {name: 'anguished face', image: '1f627'}],
  ['fearful', {name: 'fearful face', image: '1f628'}],
  ['weary', {name: 'weary face', image: '1f629'}],
  ['sleepy', {name: 'sleepy face', image: '1f62a'}],
  ['tired_face', {name: 'tired face', image: '1f62b'}],
  ['grimacing', {name: 'grimacing face', image: '1f62c'}],
  ['sob', {name: 'loudly crying face', image: '1f62d'}],
  ['face_exhaling', {name: 'face exhaling', image: '1f62e_200d_1f4a8'}],
  ['open_mouth', {name: 'face with open mouth', image: '1f62e'}],
  ['hushed', {name: 'hushed face', image: '1f62f'}],
  ['cold_sweat', {name: 'face with open mouth and cold sweat', image: '1f630'}],
  ['scream', {name: 'face screaming in fear', image: '1f631'}],
  ['astonished', {name: 'astonished face', image: '1f632'}],
  ['flushed', {name: 'flushed face', image: '1f633'}],
  ['sleeping', {name: 'sleeping face', image: '1f634'}],
  [
    'face_with_spiral_eyes',
    {name: 'face with spiral eyes', image: '1f635_200d_1f4ab'}
  ],
  ['dizzy_face', {name: 'dizzy face', image: '1f635'}],
  ['face_in_clouds', {name: 'face in clouds', image: '1f636_200d_1f32b'}],
  ['no_mouth', {name: 'face without mouth', image: '1f636'}],
  ['mask', {name: 'face with medical mask', image: '1f637'}],
  ['smile_cat', {name: 'grinning cat face with smiling eyes', image: '1f638'}],
  ['joy_cat', {name: 'cat face with tears of joy', image: '1f639'}],
  ['smiley_cat', {name: 'smiling cat face with open mouth', image: '1f63a'}],
  [
    'heart_eyes_cat',
    {name: 'smiling cat face with heart-shaped eyes', image: '1f63b'}
  ],
  ['smirk_cat', {name: 'cat face with wry smile', image: '1f63c'}],
  ['kissing_cat', {name: 'kissing cat face with closed eyes', image: '1f63d'}],
  ['pouting_cat', {name: 'pouting cat face', image: '1f63e'}],
  ['crying_cat_face', {name: 'crying cat face', image: '1f63f'}],
  ['scream_cat', {name: 'weary cat face', image: '1f640'}],
  ['slightly_frowning_face', {name: 'slightly frowning face', image: '1f641'}],
  ['slightly_smiling_face', {name: 'slightly smiling face', image: '1f642'}],
  ['upside_down_face', {name: 'upside-down face', image: '1f643'}],
  ['face_with_rolling_eyes', {name: 'face with rolling eyes', image: '1f644'}],
  [
    'woman-gesturing-no',
    {name: 'woman gesturing no', image: '1f645_200d_2640'}
  ],
  ['man-gesturing-no', {name: 'man gesturing no', image: '1f645_200d_2642'}],
  ['no_good', {name: 'face with no good gesture', image: '1f645'}],
  [
    'no_good_skin-tone-2',
    {name: 'face with no good gesture', image: '1f645_1f3fb'}
  ],
  [
    'no_good_skin-tone-3',
    {name: 'face with no good gesture', image: '1f645_1f3fc'}
  ],
  [
    'no_good_skin-tone-4',
    {name: 'face with no good gesture', image: '1f645_1f3fd'}
  ],
  [
    'no_good_skin-tone-5',
    {name: 'face with no good gesture', image: '1f645_1f3fe'}
  ],
  [
    'no_good_skin-tone-6',
    {name: 'face with no good gesture', image: '1f645_1f3ff'}
  ],
  [
    'woman-gesturing-ok',
    {name: 'woman gesturing ok', image: '1f646_200d_2640'}
  ],
  ['man-gesturing-ok', {name: 'man gesturing ok', image: '1f646_200d_2642'}],
  ['ok_woman', {name: 'face with ok gesture', image: '1f646'}],
  [
    'ok_woman_skin-tone-2',
    {name: 'face with ok gesture', image: '1f646_1f3fb'}
  ],
  [
    'ok_woman_skin-tone-3',
    {name: 'face with ok gesture', image: '1f646_1f3fc'}
  ],
  [
    'ok_woman_skin-tone-4',
    {name: 'face with ok gesture', image: '1f646_1f3fd'}
  ],
  [
    'ok_woman_skin-tone-5',
    {name: 'face with ok gesture', image: '1f646_1f3fe'}
  ],
  [
    'ok_woman_skin-tone-6',
    {name: 'face with ok gesture', image: '1f646_1f3ff'}
  ],
  ['woman-bowing', {name: 'woman bowing', image: '1f647_200d_2640'}],
  ['man-bowing', {name: 'man bowing', image: '1f647_200d_2642'}],
  ['bow', {name: 'person bowing deeply', image: '1f647'}],
  ['bow_skin-tone-2', {name: 'person bowing deeply', image: '1f647_1f3fb'}],
  ['bow_skin-tone-3', {name: 'person bowing deeply', image: '1f647_1f3fc'}],
  ['bow_skin-tone-4', {name: 'person bowing deeply', image: '1f647_1f3fd'}],
  ['bow_skin-tone-5', {name: 'person bowing deeply', image: '1f647_1f3fe'}],
  ['bow_skin-tone-6', {name: 'person bowing deeply', image: '1f647_1f3ff'}],
  ['see_no_evil', {name: 'see-no-evil monkey', image: '1f648'}],
  ['hear_no_evil', {name: 'hear-no-evil monkey', image: '1f649'}],
  ['speak_no_evil', {name: 'speak-no-evil monkey', image: '1f64a'}],
  [
    'woman-raising-hand',
    {name: 'woman raising hand', image: '1f64b_200d_2640'}
  ],
  ['man-raising-hand', {name: 'man raising hand', image: '1f64b_200d_2642'}],
  ['raising_hand', {name: 'happy person raising one hand', image: '1f64b'}],
  [
    'raising_hand_skin-tone-2',
    {name: 'happy person raising one hand', image: '1f64b_1f3fb'}
  ],
  [
    'raising_hand_skin-tone-3',
    {name: 'happy person raising one hand', image: '1f64b_1f3fc'}
  ],
  [
    'raising_hand_skin-tone-4',
    {name: 'happy person raising one hand', image: '1f64b_1f3fd'}
  ],
  [
    'raising_hand_skin-tone-5',
    {name: 'happy person raising one hand', image: '1f64b_1f3fe'}
  ],
  [
    'raising_hand_skin-tone-6',
    {name: 'happy person raising one hand', image: '1f64b_1f3ff'}
  ],
  [
    'raised_hands',
    {name: 'person raising both hands in celebration', image: '1f64c'}
  ],
  [
    'raised_hands_skin-tone-2',
    {name: 'person raising both hands in celebration', image: '1f64c_1f3fb'}
  ],
  [
    'raised_hands_skin-tone-3',
    {name: 'person raising both hands in celebration', image: '1f64c_1f3fc'}
  ],
  [
    'raised_hands_skin-tone-4',
    {name: 'person raising both hands in celebration', image: '1f64c_1f3fd'}
  ],
  [
    'raised_hands_skin-tone-5',
    {name: 'person raising both hands in celebration', image: '1f64c_1f3fe'}
  ],
  [
    'raised_hands_skin-tone-6',
    {name: 'person raising both hands in celebration', image: '1f64c_1f3ff'}
  ],
  ['woman-frowning', {name: 'woman frowning', image: '1f64d_200d_2640'}],
  ['man-frowning', {name: 'man frowning', image: '1f64d_200d_2642'}],
  ['person_frowning', {name: 'person frowning', image: '1f64d'}],
  [
    'person_frowning_skin-tone-2',
    {name: 'person frowning', image: '1f64d_1f3fb'}
  ],
  [
    'person_frowning_skin-tone-3',
    {name: 'person frowning', image: '1f64d_1f3fc'}
  ],
  [
    'person_frowning_skin-tone-4',
    {name: 'person frowning', image: '1f64d_1f3fd'}
  ],
  [
    'person_frowning_skin-tone-5',
    {name: 'person frowning', image: '1f64d_1f3fe'}
  ],
  [
    'person_frowning_skin-tone-6',
    {name: 'person frowning', image: '1f64d_1f3ff'}
  ],
  ['woman-pouting', {name: 'woman pouting', image: '1f64e_200d_2640'}],
  ['man-pouting', {name: 'man pouting', image: '1f64e_200d_2642'}],
  [
    'person_with_pouting_face',
    {name: 'person with pouting face', image: '1f64e'}
  ],
  [
    'person_with_pouting_face_skin-tone-2',
    {name: 'person with pouting face', image: '1f64e_1f3fb'}
  ],
  [
    'person_with_pouting_face_skin-tone-3',
    {name: 'person with pouting face', image: '1f64e_1f3fc'}
  ],
  [
    'person_with_pouting_face_skin-tone-4',
    {name: 'person with pouting face', image: '1f64e_1f3fd'}
  ],
  [
    'person_with_pouting_face_skin-tone-5',
    {name: 'person with pouting face', image: '1f64e_1f3fe'}
  ],
  [
    'person_with_pouting_face_skin-tone-6',
    {name: 'person with pouting face', image: '1f64e_1f3ff'}
  ],
  ['pray', {name: 'person with folded hands', image: '1f64f'}],
  [
    'pray_skin-tone-2',
    {name: 'person with folded hands', image: '1f64f_1f3fb'}
  ],
  [
    'pray_skin-tone-3',
    {name: 'person with folded hands', image: '1f64f_1f3fc'}
  ],
  [
    'pray_skin-tone-4',
    {name: 'person with folded hands', image: '1f64f_1f3fd'}
  ],
  [
    'pray_skin-tone-5',
    {name: 'person with folded hands', image: '1f64f_1f3fe'}
  ],
  [
    'pray_skin-tone-6',
    {name: 'person with folded hands', image: '1f64f_1f3ff'}
  ],
  ['rocket', {name: 'rocket', image: '1f680'}],
  ['helicopter', {name: 'helicopter', image: '1f681'}],
  ['steam_locomotive', {name: 'steam locomotive', image: '1f682'}],
  ['railway_car', {name: 'railway car', image: '1f683'}],
  ['bullettrain_side', {name: 'high-speed train', image: '1f684'}],
  [
    'bullettrain_front',
    {name: 'high-speed train with bullet nose', image: '1f685'}
  ],
  ['train2', {name: 'train', image: '1f686'}],
  ['metro', {name: 'metro', image: '1f687'}],
  ['light_rail', {name: 'light rail', image: '1f688'}],
  ['station', {name: 'station', image: '1f689'}],
  ['tram', {name: 'tram', image: '1f68a'}],
  ['train', {name: 'tram car', image: '1f68b'}],
  ['bus', {name: 'bus', image: '1f68c'}],
  ['oncoming_bus', {name: 'oncoming bus', image: '1f68d'}],
  ['trolleybus', {name: 'trolleybus', image: '1f68e'}],
  ['busstop', {name: 'bus stop', image: '1f68f'}],
  ['minibus', {name: 'minibus', image: '1f690'}],
  ['ambulance', {name: 'ambulance', image: '1f691'}],
  ['fire_engine', {name: 'fire engine', image: '1f692'}],
  ['police_car', {name: 'police car', image: '1f693'}],
  ['oncoming_police_car', {name: 'oncoming police car', image: '1f694'}],
  ['taxi', {name: 'taxi', image: '1f695'}],
  ['oncoming_taxi', {name: 'oncoming taxi', image: '1f696'}],
  ['car', {name: 'automobile', image: '1f697'}],
  ['oncoming_automobile', {name: 'oncoming automobile', image: '1f698'}],
  ['blue_car', {name: 'recreational vehicle', image: '1f699'}],
  ['truck', {name: 'delivery truck', image: '1f69a'}],
  ['articulated_lorry', {name: 'articulated lorry', image: '1f69b'}],
  ['tractor', {name: 'tractor', image: '1f69c'}],
  ['monorail', {name: 'monorail', image: '1f69d'}],
  ['mountain_railway', {name: 'mountain railway', image: '1f69e'}],
  ['suspension_railway', {name: 'suspension railway', image: '1f69f'}],
  ['mountain_cableway', {name: 'mountain cableway', image: '1f6a0'}],
  ['aerial_tramway', {name: 'aerial tramway', image: '1f6a1'}],
  ['ship', {name: 'ship', image: '1f6a2'}],
  ['woman-rowing-boat', {name: 'woman rowing boat', image: '1f6a3_200d_2640'}],
  ['man-rowing-boat', {name: 'man rowing boat', image: '1f6a3_200d_2642'}],
  ['rowboat', {name: 'rowboat', image: '1f6a3'}],
  ['rowboat_skin-tone-2', {name: 'rowboat', image: '1f6a3_1f3fb'}],
  ['rowboat_skin-tone-3', {name: 'rowboat', image: '1f6a3_1f3fc'}],
  ['rowboat_skin-tone-4', {name: 'rowboat', image: '1f6a3_1f3fd'}],
  ['rowboat_skin-tone-5', {name: 'rowboat', image: '1f6a3_1f3fe'}],
  ['rowboat_skin-tone-6', {name: 'rowboat', image: '1f6a3_1f3ff'}],
  ['speedboat', {name: 'speedboat', image: '1f6a4'}],
  ['traffic_light', {name: 'horizontal traffic light', image: '1f6a5'}],
  ['vertical_traffic_light', {name: 'vertical traffic light', image: '1f6a6'}],
  ['construction', {name: 'construction sign', image: '1f6a7'}],
  ['rotating_light', {name: 'police cars revolving light', image: '1f6a8'}],
  [
    'triangular_flag_on_post',
    {name: 'triangular flag on post', image: '1f6a9'}
  ],
  ['door', {name: 'door', image: '1f6aa'}],
  ['no_entry_sign', {name: 'no entry sign', image: '1f6ab'}],
  ['smoking', {name: 'smoking symbol', image: '1f6ac'}],
  ['no_smoking', {name: 'no smoking symbol', image: '1f6ad'}],
  [
    'put_litter_in_its_place',
    {name: 'put litter in its place symbol', image: '1f6ae'}
  ],
  ['do_not_litter', {name: 'do not litter symbol', image: '1f6af'}],
  ['potable_water', {name: 'potable water symbol', image: '1f6b0'}],
  ['non-potable_water', {name: 'non-potable water symbol', image: '1f6b1'}],
  ['bike', {name: 'bicycle', image: '1f6b2'}],
  ['no_bicycles', {name: 'no bicycles', image: '1f6b3'}],
  ['woman-biking', {name: 'woman biking', image: '1f6b4_200d_2640'}],
  ['man-biking', {name: 'man biking', image: '1f6b4_200d_2642'}],
  ['bicyclist', {name: 'bicyclist', image: '1f6b4'}],
  ['bicyclist_skin-tone-2', {name: 'bicyclist', image: '1f6b4_1f3fb'}],
  ['bicyclist_skin-tone-3', {name: 'bicyclist', image: '1f6b4_1f3fc'}],
  ['bicyclist_skin-tone-4', {name: 'bicyclist', image: '1f6b4_1f3fd'}],
  ['bicyclist_skin-tone-5', {name: 'bicyclist', image: '1f6b4_1f3fe'}],
  ['bicyclist_skin-tone-6', {name: 'bicyclist', image: '1f6b4_1f3ff'}],
  [
    'woman-mountain-biking',
    {name: 'woman mountain biking', image: '1f6b5_200d_2640'}
  ],
  [
    'man-mountain-biking',
    {name: 'man mountain biking', image: '1f6b5_200d_2642'}
  ],
  ['mountain_bicyclist', {name: 'mountain bicyclist', image: '1f6b5'}],
  [
    'mountain_bicyclist_skin-tone-2',
    {name: 'mountain bicyclist', image: '1f6b5_1f3fb'}
  ],
  [
    'mountain_bicyclist_skin-tone-3',
    {name: 'mountain bicyclist', image: '1f6b5_1f3fc'}
  ],
  [
    'mountain_bicyclist_skin-tone-4',
    {name: 'mountain bicyclist', image: '1f6b5_1f3fd'}
  ],
  [
    'mountain_bicyclist_skin-tone-5',
    {name: 'mountain bicyclist', image: '1f6b5_1f3fe'}
  ],
  [
    'mountain_bicyclist_skin-tone-6',
    {name: 'mountain bicyclist', image: '1f6b5_1f3ff'}
  ],
  ['woman-walking', {name: 'woman walking', image: '1f6b6_200d_2640'}],
  ['man-walking', {name: 'man walking', image: '1f6b6_200d_2642'}],
  ['walking', {name: 'pedestrian', image: '1f6b6'}],
  ['walking_skin-tone-2', {name: 'pedestrian', image: '1f6b6_1f3fb'}],
  ['walking_skin-tone-3', {name: 'pedestrian', image: '1f6b6_1f3fc'}],
  ['walking_skin-tone-4', {name: 'pedestrian', image: '1f6b6_1f3fd'}],
  ['walking_skin-tone-5', {name: 'pedestrian', image: '1f6b6_1f3fe'}],
  ['walking_skin-tone-6', {name: 'pedestrian', image: '1f6b6_1f3ff'}],
  ['no_pedestrians', {name: 'no pedestrians', image: '1f6b7'}],
  ['children_crossing', {name: 'children crossing', image: '1f6b8'}],
  ['mens', {name: 'mens symbol', image: '1f6b9'}],
  ['womens', {name: 'womens symbol', image: '1f6ba'}],
  ['restroom', {name: 'restroom', image: '1f6bb'}],
  ['baby_symbol', {name: 'baby symbol', image: '1f6bc'}],
  ['toilet', {name: 'toilet', image: '1f6bd'}],
  ['wc', {name: 'water closet', image: '1f6be'}],
  ['shower', {name: 'shower', image: '1f6bf'}],
  ['bath', {name: 'bath', image: '1f6c0'}],
  ['bath_skin-tone-2', {name: 'bath', image: '1f6c0_1f3fb'}],
  ['bath_skin-tone-3', {name: 'bath', image: '1f6c0_1f3fc'}],
  ['bath_skin-tone-4', {name: 'bath', image: '1f6c0_1f3fd'}],
  ['bath_skin-tone-5', {name: 'bath', image: '1f6c0_1f3fe'}],
  ['bath_skin-tone-6', {name: 'bath', image: '1f6c0_1f3ff'}],
  ['bathtub', {name: 'bathtub', image: '1f6c1'}],
  ['passport_control', {name: 'passport control', image: '1f6c2'}],
  ['customs', {name: 'customs', image: '1f6c3'}],
  ['baggage_claim', {name: 'baggage claim', image: '1f6c4'}],
  ['left_luggage', {name: 'left luggage', image: '1f6c5'}],
  ['couch_and_lamp', {name: 'couch and lamp', image: '1f6cb'}],
  ['sleeping_accommodation', {name: 'sleeping accommodation', image: '1f6cc'}],
  [
    'sleeping_accommodation_skin-tone-2',
    {name: 'sleeping accommodation', image: '1f6cc_1f3fb'}
  ],
  [
    'sleeping_accommodation_skin-tone-3',
    {name: 'sleeping accommodation', image: '1f6cc_1f3fc'}
  ],
  [
    'sleeping_accommodation_skin-tone-4',
    {name: 'sleeping accommodation', image: '1f6cc_1f3fd'}
  ],
  [
    'sleeping_accommodation_skin-tone-5',
    {name: 'sleeping accommodation', image: '1f6cc_1f3fe'}
  ],
  [
    'sleeping_accommodation_skin-tone-6',
    {name: 'sleeping accommodation', image: '1f6cc_1f3ff'}
  ],
  ['shopping_bags', {name: 'shopping bags', image: '1f6cd'}],
  ['bellhop_bell', {name: 'bellhop bell', image: '1f6ce'}],
  ['bed', {name: 'bed', image: '1f6cf'}],
  ['place_of_worship', {name: 'place of worship', image: '1f6d0'}],
  ['octagonal_sign', {name: 'octagonal sign', image: '1f6d1'}],
  ['shopping_trolley', {name: 'shopping trolley', image: '1f6d2'}],
  ['hindu_temple', {name: 'hindu temple', image: '1f6d5'}],
  ['hut', {name: 'hut', image: '1f6d6'}],
  ['elevator', {name: 'elevator', image: '1f6d7'}],
  ['playground_slide', {name: 'playground slide', image: '1f6dd'}],
  ['wheel', {name: 'wheel', image: '1f6de'}],
  ['ring_buoy', {name: 'ring buoy', image: '1f6df'}],
  ['hammer_and_wrench', {name: 'hammer and wrench', image: '1f6e0'}],
  ['shield', {name: 'shield', image: '1f6e1'}],
  ['oil_drum', {name: 'oil drum', image: '1f6e2'}],
  ['motorway', {name: 'motorway', image: '1f6e3'}],
  ['railway_track', {name: 'railway track', image: '1f6e4'}],
  ['motor_boat', {name: 'motor boat', image: '1f6e5'}],
  ['small_airplane', {name: 'small airplane', image: '1f6e9'}],
  ['airplane_departure', {name: 'airplane departure', image: '1f6eb'}],
  ['airplane_arriving', {name: 'airplane arriving', image: '1f6ec'}],
  ['satellite', {name: 'satellite', image: '1f6f0'}],
  ['passenger_ship', {name: 'passenger ship', image: '1f6f3'}],
  ['scooter', {name: 'scooter', image: '1f6f4'}],
  ['motor_scooter', {name: 'motor scooter', image: '1f6f5'}],
  ['canoe', {name: 'canoe', image: '1f6f6'}],
  ['sled', {name: 'sled', image: '1f6f7'}],
  ['flying_saucer', {name: 'flying saucer', image: '1f6f8'}],
  ['skateboard', {name: 'skateboard', image: '1f6f9'}],
  ['auto_rickshaw', {name: 'auto rickshaw', image: '1f6fa'}],
  ['pickup_truck', {name: 'pickup truck', image: '1f6fb'}],
  ['roller_skate', {name: 'roller skate', image: '1f6fc'}],
  ['large_orange_circle', {name: 'large orange circle', image: '1f7e0'}],
  ['large_yellow_circle', {name: 'large yellow circle', image: '1f7e1'}],
  ['large_green_circle', {name: 'large green circle', image: '1f7e2'}],
  ['large_purple_circle', {name: 'large purple circle', image: '1f7e3'}],
  ['large_brown_circle', {name: 'large brown circle', image: '1f7e4'}],
  ['large_red_square', {name: 'large red square', image: '1f7e5'}],
  ['large_blue_square', {name: 'large blue square', image: '1f7e6'}],
  ['large_orange_square', {name: 'large orange square', image: '1f7e7'}],
  ['large_yellow_square', {name: 'large yellow square', image: '1f7e8'}],
  ['large_green_square', {name: 'large green square', image: '1f7e9'}],
  ['large_purple_square', {name: 'large purple square', image: '1f7ea'}],
  ['large_brown_square', {name: 'large brown square', image: '1f7eb'}],
  ['heavy_equals_sign', {name: 'heavy equals sign', image: '1f7f0'}],
  ['pinched_fingers', {name: 'pinched fingers', image: '1f90c'}],
  [
    'pinched_fingers_skin-tone-2',
    {name: 'pinched fingers', image: '1f90c_1f3fb'}
  ],
  [
    'pinched_fingers_skin-tone-3',
    {name: 'pinched fingers', image: '1f90c_1f3fc'}
  ],
  [
    'pinched_fingers_skin-tone-4',
    {name: 'pinched fingers', image: '1f90c_1f3fd'}
  ],
  [
    'pinched_fingers_skin-tone-5',
    {name: 'pinched fingers', image: '1f90c_1f3fe'}
  ],
  [
    'pinched_fingers_skin-tone-6',
    {name: 'pinched fingers', image: '1f90c_1f3ff'}
  ],
  ['white_heart', {name: 'white heart', image: '1f90d'}],
  ['brown_heart', {name: 'brown heart', image: '1f90e'}],
  ['pinching_hand', {name: 'pinching hand', image: '1f90f'}],
  ['pinching_hand_skin-tone-2', {name: 'pinching hand', image: '1f90f_1f3fb'}],
  ['pinching_hand_skin-tone-3', {name: 'pinching hand', image: '1f90f_1f3fc'}],
  ['pinching_hand_skin-tone-4', {name: 'pinching hand', image: '1f90f_1f3fd'}],
  ['pinching_hand_skin-tone-5', {name: 'pinching hand', image: '1f90f_1f3fe'}],
  ['pinching_hand_skin-tone-6', {name: 'pinching hand', image: '1f90f_1f3ff'}],
  ['zipper_mouth_face', {name: 'zipper-mouth face', image: '1f910'}],
  ['money_mouth_face', {name: 'money-mouth face', image: '1f911'}],
  ['face_with_thermometer', {name: 'face with thermometer', image: '1f912'}],
  ['nerd_face', {name: 'nerd face', image: '1f913'}],
  ['thinking_face', {name: 'thinking face', image: '1f914'}],
  ['face_with_head_bandage', {name: 'face with head-bandage', image: '1f915'}],
  ['robot_face', {name: 'robot face', image: '1f916'}],
  ['hugging_face', {name: 'hugging face', image: '1f917'}],
  ['the_horns', {name: 'sign of the horns', image: '1f918'}],
  ['the_horns_skin-tone-2', {name: 'sign of the horns', image: '1f918_1f3fb'}],
  ['the_horns_skin-tone-3', {name: 'sign of the horns', image: '1f918_1f3fc'}],
  ['the_horns_skin-tone-4', {name: 'sign of the horns', image: '1f918_1f3fd'}],
  ['the_horns_skin-tone-5', {name: 'sign of the horns', image: '1f918_1f3fe'}],
  ['the_horns_skin-tone-6', {name: 'sign of the horns', image: '1f918_1f3ff'}],
  ['call_me_hand', {name: 'call me hand', image: '1f919'}],
  ['call_me_hand_skin-tone-2', {name: 'call me hand', image: '1f919_1f3fb'}],
  ['call_me_hand_skin-tone-3', {name: 'call me hand', image: '1f919_1f3fc'}],
  ['call_me_hand_skin-tone-4', {name: 'call me hand', image: '1f919_1f3fd'}],
  ['call_me_hand_skin-tone-5', {name: 'call me hand', image: '1f919_1f3fe'}],
  ['call_me_hand_skin-tone-6', {name: 'call me hand', image: '1f919_1f3ff'}],
  ['raised_back_of_hand', {name: 'raised back of hand', image: '1f91a'}],
  [
    'raised_back_of_hand_skin-tone-2',
    {name: 'raised back of hand', image: '1f91a_1f3fb'}
  ],
  [
    'raised_back_of_hand_skin-tone-3',
    {name: 'raised back of hand', image: '1f91a_1f3fc'}
  ],
  [
    'raised_back_of_hand_skin-tone-4',
    {name: 'raised back of hand', image: '1f91a_1f3fd'}
  ],
  [
    'raised_back_of_hand_skin-tone-5',
    {name: 'raised back of hand', image: '1f91a_1f3fe'}
  ],
  [
    'raised_back_of_hand_skin-tone-6',
    {name: 'raised back of hand', image: '1f91a_1f3ff'}
  ],
  ['left-facing_fist', {name: 'left-facing fist', image: '1f91b'}],
  [
    'left-facing_fist_skin-tone-2',
    {name: 'left-facing fist', image: '1f91b_1f3fb'}
  ],
  [
    'left-facing_fist_skin-tone-3',
    {name: 'left-facing fist', image: '1f91b_1f3fc'}
  ],
  [
    'left-facing_fist_skin-tone-4',
    {name: 'left-facing fist', image: '1f91b_1f3fd'}
  ],
  [
    'left-facing_fist_skin-tone-5',
    {name: 'left-facing fist', image: '1f91b_1f3fe'}
  ],
  [
    'left-facing_fist_skin-tone-6',
    {name: 'left-facing fist', image: '1f91b_1f3ff'}
  ],
  ['right-facing_fist', {name: 'right-facing fist', image: '1f91c'}],
  [
    'right-facing_fist_skin-tone-2',
    {name: 'right-facing fist', image: '1f91c_1f3fb'}
  ],
  [
    'right-facing_fist_skin-tone-3',
    {name: 'right-facing fist', image: '1f91c_1f3fc'}
  ],
  [
    'right-facing_fist_skin-tone-4',
    {name: 'right-facing fist', image: '1f91c_1f3fd'}
  ],
  [
    'right-facing_fist_skin-tone-5',
    {name: 'right-facing fist', image: '1f91c_1f3fe'}
  ],
  [
    'right-facing_fist_skin-tone-6',
    {name: 'right-facing fist', image: '1f91c_1f3ff'}
  ],
  ['handshake', {name: 'handshake', image: '1f91d'}],
  ['handshake_skin-tone-2', {name: 'handshake', image: '1f91d_1f3fb'}],
  ['handshake_skin-tone-3', {name: 'handshake', image: '1f91d_1f3fc'}],
  ['handshake_skin-tone-4', {name: 'handshake', image: '1f91d_1f3fd'}],
  ['handshake_skin-tone-5', {name: 'handshake', image: '1f91d_1f3fe'}],
  ['handshake_skin-tone-6', {name: 'handshake', image: '1f91d_1f3ff'}],
  [
    'crossed_fingers',
    {name: 'hand with index and middle fingers crossed', image: '1f91e'}
  ],
  [
    'crossed_fingers_skin-tone-2',
    {name: 'hand with index and middle fingers crossed', image: '1f91e_1f3fb'}
  ],
  [
    'crossed_fingers_skin-tone-3',
    {name: 'hand with index and middle fingers crossed', image: '1f91e_1f3fc'}
  ],
  [
    'crossed_fingers_skin-tone-4',
    {name: 'hand with index and middle fingers crossed', image: '1f91e_1f3fd'}
  ],
  [
    'crossed_fingers_skin-tone-5',
    {name: 'hand with index and middle fingers crossed', image: '1f91e_1f3fe'}
  ],
  [
    'crossed_fingers_skin-tone-6',
    {name: 'hand with index and middle fingers crossed', image: '1f91e_1f3ff'}
  ],
  ['i_love_you_hand_sign', {name: 'i love you hand sign', image: '1f91f'}],
  [
    'i_love_you_hand_sign_skin-tone-2',
    {name: 'i love you hand sign', image: '1f91f_1f3fb'}
  ],
  [
    'i_love_you_hand_sign_skin-tone-3',
    {name: 'i love you hand sign', image: '1f91f_1f3fc'}
  ],
  [
    'i_love_you_hand_sign_skin-tone-4',
    {name: 'i love you hand sign', image: '1f91f_1f3fd'}
  ],
  [
    'i_love_you_hand_sign_skin-tone-5',
    {name: 'i love you hand sign', image: '1f91f_1f3fe'}
  ],
  [
    'i_love_you_hand_sign_skin-tone-6',
    {name: 'i love you hand sign', image: '1f91f_1f3ff'}
  ],
  ['face_with_cowboy_hat', {name: 'face with cowboy hat', image: '1f920'}],
  ['clown_face', {name: 'clown face', image: '1f921'}],
  ['nauseated_face', {name: 'nauseated face', image: '1f922'}],
  [
    'rolling_on_the_floor_laughing',
    {name: 'rolling on the floor laughing', image: '1f923'}
  ],
  ['drooling_face', {name: 'drooling face', image: '1f924'}],
  ['lying_face', {name: 'lying face', image: '1f925'}],
  ['woman-facepalming', {name: 'woman facepalming', image: '1f926_200d_2640'}],
  ['man-facepalming', {name: 'man facepalming', image: '1f926_200d_2642'}],
  ['face_palm', {name: 'face palm', image: '1f926'}],
  ['face_palm_skin-tone-2', {name: 'face palm', image: '1f926_1f3fb'}],
  ['face_palm_skin-tone-3', {name: 'face palm', image: '1f926_1f3fc'}],
  ['face_palm_skin-tone-4', {name: 'face palm', image: '1f926_1f3fd'}],
  ['face_palm_skin-tone-5', {name: 'face palm', image: '1f926_1f3fe'}],
  ['face_palm_skin-tone-6', {name: 'face palm', image: '1f926_1f3ff'}],
  ['sneezing_face', {name: 'sneezing face', image: '1f927'}],
  [
    'face_with_raised_eyebrow',
    {name: 'face with one eyebrow raised', image: '1f928'}
  ],
  ['star-struck', {name: 'grinning face with star eyes', image: '1f929'}],
  [
    'zany_face',
    {name: 'grinning face with one large and one small eye', image: '1f92a'}
  ],
  [
    'shushing_face',
    {name: 'face with finger covering closed lips', image: '1f92b'}
  ],
  [
    'face_with_symbols_on_mouth',
    {name: 'serious face with symbols covering mouth', image: '1f92c'}
  ],
  [
    'face_with_hand_over_mouth',
    {
      name: 'smiling face with smiling eyes and hand covering mouth',
      image: '1f92d'
    }
  ],
  ['face_vomiting', {name: 'face with open mouth vomiting', image: '1f92e'}],
  [
    'exploding_head',
    {name: 'shocked face with exploding head', image: '1f92f'}
  ],
  ['pregnant_woman', {name: 'pregnant woman', image: '1f930'}],
  [
    'pregnant_woman_skin-tone-2',
    {name: 'pregnant woman', image: '1f930_1f3fb'}
  ],
  [
    'pregnant_woman_skin-tone-3',
    {name: 'pregnant woman', image: '1f930_1f3fc'}
  ],
  [
    'pregnant_woman_skin-tone-4',
    {name: 'pregnant woman', image: '1f930_1f3fd'}
  ],
  [
    'pregnant_woman_skin-tone-5',
    {name: 'pregnant woman', image: '1f930_1f3fe'}
  ],
  [
    'pregnant_woman_skin-tone-6',
    {name: 'pregnant woman', image: '1f930_1f3ff'}
  ],
  ['breast-feeding', {name: 'breast-feeding', image: '1f931'}],
  [
    'breast-feeding_skin-tone-2',
    {name: 'breast-feeding', image: '1f931_1f3fb'}
  ],
  [
    'breast-feeding_skin-tone-3',
    {name: 'breast-feeding', image: '1f931_1f3fc'}
  ],
  [
    'breast-feeding_skin-tone-4',
    {name: 'breast-feeding', image: '1f931_1f3fd'}
  ],
  [
    'breast-feeding_skin-tone-5',
    {name: 'breast-feeding', image: '1f931_1f3fe'}
  ],
  [
    'breast-feeding_skin-tone-6',
    {name: 'breast-feeding', image: '1f931_1f3ff'}
  ],
  ['palms_up_together', {name: 'palms up together', image: '1f932'}],
  [
    'palms_up_together_skin-tone-2',
    {name: 'palms up together', image: '1f932_1f3fb'}
  ],
  [
    'palms_up_together_skin-tone-3',
    {name: 'palms up together', image: '1f932_1f3fc'}
  ],
  [
    'palms_up_together_skin-tone-4',
    {name: 'palms up together', image: '1f932_1f3fd'}
  ],
  [
    'palms_up_together_skin-tone-5',
    {name: 'palms up together', image: '1f932_1f3fe'}
  ],
  [
    'palms_up_together_skin-tone-6',
    {name: 'palms up together', image: '1f932_1f3ff'}
  ],
  ['selfie', {name: 'selfie', image: '1f933'}],
  ['selfie_skin-tone-2', {name: 'selfie', image: '1f933_1f3fb'}],
  ['selfie_skin-tone-3', {name: 'selfie', image: '1f933_1f3fc'}],
  ['selfie_skin-tone-4', {name: 'selfie', image: '1f933_1f3fd'}],
  ['selfie_skin-tone-5', {name: 'selfie', image: '1f933_1f3fe'}],
  ['selfie_skin-tone-6', {name: 'selfie', image: '1f933_1f3ff'}],
  ['prince', {name: 'prince', image: '1f934'}],
  ['prince_skin-tone-2', {name: 'prince', image: '1f934_1f3fb'}],
  ['prince_skin-tone-3', {name: 'prince', image: '1f934_1f3fc'}],
  ['prince_skin-tone-4', {name: 'prince', image: '1f934_1f3fd'}],
  ['prince_skin-tone-5', {name: 'prince', image: '1f934_1f3fe'}],
  ['prince_skin-tone-6', {name: 'prince', image: '1f934_1f3ff'}],
  ['woman_in_tuxedo', {name: 'woman in tuxedo', image: '1f935_200d_2640'}],
  ['man_in_tuxedo', {name: 'man in tuxedo', image: '1f935_200d_2642'}],
  ['person_in_tuxedo', {name: 'man in tuxedo', image: '1f935'}],
  [
    'person_in_tuxedo_skin-tone-2',
    {name: 'man in tuxedo', image: '1f935_1f3fb'}
  ],
  [
    'person_in_tuxedo_skin-tone-3',
    {name: 'man in tuxedo', image: '1f935_1f3fc'}
  ],
  [
    'person_in_tuxedo_skin-tone-4',
    {name: 'man in tuxedo', image: '1f935_1f3fd'}
  ],
  [
    'person_in_tuxedo_skin-tone-5',
    {name: 'man in tuxedo', image: '1f935_1f3fe'}
  ],
  [
    'person_in_tuxedo_skin-tone-6',
    {name: 'man in tuxedo', image: '1f935_1f3ff'}
  ],
  ['mrs_claus', {name: 'mother christmas', image: '1f936'}],
  ['mrs_claus_skin-tone-2', {name: 'mother christmas', image: '1f936_1f3fb'}],
  ['mrs_claus_skin-tone-3', {name: 'mother christmas', image: '1f936_1f3fc'}],
  ['mrs_claus_skin-tone-4', {name: 'mother christmas', image: '1f936_1f3fd'}],
  ['mrs_claus_skin-tone-5', {name: 'mother christmas', image: '1f936_1f3fe'}],
  ['mrs_claus_skin-tone-6', {name: 'mother christmas', image: '1f936_1f3ff'}],
  ['woman-shrugging', {name: 'woman shrugging', image: '1f937_200d_2640'}],
  ['man-shrugging', {name: 'man shrugging', image: '1f937_200d_2642'}],
  ['shrug', {name: 'shrug', image: '1f937'}],
  ['shrug_skin-tone-2', {name: 'shrug', image: '1f937_1f3fb'}],
  ['shrug_skin-tone-3', {name: 'shrug', image: '1f937_1f3fc'}],
  ['shrug_skin-tone-4', {name: 'shrug', image: '1f937_1f3fd'}],
  ['shrug_skin-tone-5', {name: 'shrug', image: '1f937_1f3fe'}],
  ['shrug_skin-tone-6', {name: 'shrug', image: '1f937_1f3ff'}],
  [
    'woman-cartwheeling',
    {name: 'woman cartwheeling', image: '1f938_200d_2640'}
  ],
  ['man-cartwheeling', {name: 'man cartwheeling', image: '1f938_200d_2642'}],
  ['person_doing_cartwheel', {name: 'person doing cartwheel', image: '1f938'}],
  [
    'person_doing_cartwheel_skin-tone-2',
    {name: 'person doing cartwheel', image: '1f938_1f3fb'}
  ],
  [
    'person_doing_cartwheel_skin-tone-3',
    {name: 'person doing cartwheel', image: '1f938_1f3fc'}
  ],
  [
    'person_doing_cartwheel_skin-tone-4',
    {name: 'person doing cartwheel', image: '1f938_1f3fd'}
  ],
  [
    'person_doing_cartwheel_skin-tone-5',
    {name: 'person doing cartwheel', image: '1f938_1f3fe'}
  ],
  [
    'person_doing_cartwheel_skin-tone-6',
    {name: 'person doing cartwheel', image: '1f938_1f3ff'}
  ],
  ['woman-juggling', {name: 'woman juggling', image: '1f939_200d_2640'}],
  ['man-juggling', {name: 'man juggling', image: '1f939_200d_2642'}],
  ['juggling', {name: 'juggling', image: '1f939'}],
  ['juggling_skin-tone-2', {name: 'juggling', image: '1f939_1f3fb'}],
  ['juggling_skin-tone-3', {name: 'juggling', image: '1f939_1f3fc'}],
  ['juggling_skin-tone-4', {name: 'juggling', image: '1f939_1f3fd'}],
  ['juggling_skin-tone-5', {name: 'juggling', image: '1f939_1f3fe'}],
  ['juggling_skin-tone-6', {name: 'juggling', image: '1f939_1f3ff'}],
  ['fencer', {name: 'fencer', image: '1f93a'}],
  ['woman-wrestling', {name: 'women wrestling', image: '1f93c_200d_2640'}],
  ['man-wrestling', {name: 'men wrestling', image: '1f93c_200d_2642'}],
  ['wrestlers', {name: 'wrestlers', image: '1f93c'}],
  [
    'woman-playing-water-polo',
    {name: 'woman playing water polo', image: '1f93d_200d_2640'}
  ],
  [
    'man-playing-water-polo',
    {name: 'man playing water polo', image: '1f93d_200d_2642'}
  ],
  ['water_polo', {name: 'water polo', image: '1f93d'}],
  ['water_polo_skin-tone-2', {name: 'water polo', image: '1f93d_1f3fb'}],
  ['water_polo_skin-tone-3', {name: 'water polo', image: '1f93d_1f3fc'}],
  ['water_polo_skin-tone-4', {name: 'water polo', image: '1f93d_1f3fd'}],
  ['water_polo_skin-tone-5', {name: 'water polo', image: '1f93d_1f3fe'}],
  ['water_polo_skin-tone-6', {name: 'water polo', image: '1f93d_1f3ff'}],
  [
    'woman-playing-handball',
    {name: 'woman playing handball', image: '1f93e_200d_2640'}
  ],
  [
    'man-playing-handball',
    {name: 'man playing handball', image: '1f93e_200d_2642'}
  ],
  ['handball', {name: 'handball', image: '1f93e'}],
  ['handball_skin-tone-2', {name: 'handball', image: '1f93e_1f3fb'}],
  ['handball_skin-tone-3', {name: 'handball', image: '1f93e_1f3fc'}],
  ['handball_skin-tone-4', {name: 'handball', image: '1f93e_1f3fd'}],
  ['handball_skin-tone-5', {name: 'handball', image: '1f93e_1f3fe'}],
  ['handball_skin-tone-6', {name: 'handball', image: '1f93e_1f3ff'}],
  ['diving_mask', {name: 'diving mask', image: '1f93f'}],
  ['wilted_flower', {name: 'wilted flower', image: '1f940'}],
  ['drum_with_drumsticks', {name: 'drum with drumsticks', image: '1f941'}],
  ['clinking_glasses', {name: 'clinking glasses', image: '1f942'}],
  ['tumbler_glass', {name: 'tumbler glass', image: '1f943'}],
  ['spoon', {name: 'spoon', image: '1f944'}],
  ['goal_net', {name: 'goal net', image: '1f945'}],
  ['first_place_medal', {name: 'first place medal', image: '1f947'}],
  ['second_place_medal', {name: 'second place medal', image: '1f948'}],
  ['third_place_medal', {name: 'third place medal', image: '1f949'}],
  ['boxing_glove', {name: 'boxing glove', image: '1f94a'}],
  ['martial_arts_uniform', {name: 'martial arts uniform', image: '1f94b'}],
  ['curling_stone', {name: 'curling stone', image: '1f94c'}],
  ['lacrosse', {name: 'lacrosse stick and ball', image: '1f94d'}],
  ['softball', {name: 'softball', image: '1f94e'}],
  ['flying_disc', {name: 'flying disc', image: '1f94f'}],
  ['croissant', {name: 'croissant', image: '1f950'}],
  ['avocado', {name: 'avocado', image: '1f951'}],
  ['cucumber', {name: 'cucumber', image: '1f952'}],
  ['bacon', {name: 'bacon', image: '1f953'}],
  ['potato', {name: 'potato', image: '1f954'}],
  ['carrot', {name: 'carrot', image: '1f955'}],
  ['baguette_bread', {name: 'baguette bread', image: '1f956'}],
  ['green_salad', {name: 'green salad', image: '1f957'}],
  ['shallow_pan_of_food', {name: 'shallow pan of food', image: '1f958'}],
  ['stuffed_flatbread', {name: 'stuffed flatbread', image: '1f959'}],
  ['egg', {name: 'egg', image: '1f95a'}],
  ['glass_of_milk', {name: 'glass of milk', image: '1f95b'}],
  ['peanuts', {name: 'peanuts', image: '1f95c'}],
  ['kiwifruit', {name: 'kiwifruit', image: '1f95d'}],
  ['pancakes', {name: 'pancakes', image: '1f95e'}],
  ['dumpling', {name: 'dumpling', image: '1f95f'}],
  ['fortune_cookie', {name: 'fortune cookie', image: '1f960'}],
  ['takeout_box', {name: 'takeout box', image: '1f961'}],
  ['chopsticks', {name: 'chopsticks', image: '1f962'}],
  ['bowl_with_spoon', {name: 'bowl with spoon', image: '1f963'}],
  ['cup_with_straw', {name: 'cup with straw', image: '1f964'}],
  ['coconut', {name: 'coconut', image: '1f965'}],
  ['broccoli', {name: 'broccoli', image: '1f966'}],
  ['pie', {name: 'pie', image: '1f967'}],
  ['pretzel', {name: 'pretzel', image: '1f968'}],
  ['cut_of_meat', {name: 'cut of meat', image: '1f969'}],
  ['sandwich', {name: 'sandwich', image: '1f96a'}],
  ['canned_food', {name: 'canned food', image: '1f96b'}],
  ['leafy_green', {name: 'leafy green', image: '1f96c'}],
  ['mango', {name: 'mango', image: '1f96d'}],
  ['moon_cake', {name: 'moon cake', image: '1f96e'}],
  ['bagel', {name: 'bagel', image: '1f96f'}],
  [
    'smiling_face_with_3_hearts',
    {name: 'smiling face with smiling eyes and three hearts', image: '1f970'}
  ],
  ['yawning_face', {name: 'yawning face', image: '1f971'}],
  ['smiling_face_with_tear', {name: 'smiling face with tear', image: '1f972'}],
  [
    'partying_face',
    {name: 'face with party horn and party hat', image: '1f973'}
  ],
  [
    'woozy_face',
    {name: 'face with uneven eyes and wavy mouth', image: '1f974'}
  ],
  ['hot_face', {name: 'overheated face', image: '1f975'}],
  ['cold_face', {name: 'freezing face', image: '1f976'}],
  ['ninja', {name: 'ninja', image: '1f977'}],
  ['ninja_skin-tone-2', {name: 'ninja', image: '1f977_1f3fb'}],
  ['ninja_skin-tone-3', {name: 'ninja', image: '1f977_1f3fc'}],
  ['ninja_skin-tone-4', {name: 'ninja', image: '1f977_1f3fd'}],
  ['ninja_skin-tone-5', {name: 'ninja', image: '1f977_1f3fe'}],
  ['ninja_skin-tone-6', {name: 'ninja', image: '1f977_1f3ff'}],
  ['disguised_face', {name: 'disguised face', image: '1f978'}],
  [
    'face_holding_back_tears',
    {name: 'face holding back tears', image: '1f979'}
  ],
  ['pleading_face', {name: 'face with pleading eyes', image: '1f97a'}],
  ['sari', {name: 'sari', image: '1f97b'}],
  ['lab_coat', {name: 'lab coat', image: '1f97c'}],
  ['goggles', {name: 'goggles', image: '1f97d'}],
  ['hiking_boot', {name: 'hiking boot', image: '1f97e'}],
  ['womans_flat_shoe', {name: 'flat shoe', image: '1f97f'}],
  ['crab', {name: 'crab', image: '1f980'}],
  ['lion_face', {name: 'lion face', image: '1f981'}],
  ['scorpion', {name: 'scorpion', image: '1f982'}],
  ['turkey', {name: 'turkey', image: '1f983'}],
  ['unicorn_face', {name: 'unicorn face', image: '1f984'}],
  ['eagle', {name: 'eagle', image: '1f985'}],
  ['duck', {name: 'duck', image: '1f986'}],
  ['bat', {name: 'bat', image: '1f987'}],
  ['shark', {name: 'shark', image: '1f988'}],
  ['owl', {name: 'owl', image: '1f989'}],
  ['fox_face', {name: 'fox face', image: '1f98a'}],
  ['butterfly', {name: 'butterfly', image: '1f98b'}],
  ['deer', {name: 'deer', image: '1f98c'}],
  ['gorilla', {name: 'gorilla', image: '1f98d'}],
  ['lizard', {name: 'lizard', image: '1f98e'}],
  ['rhinoceros', {name: 'rhinoceros', image: '1f98f'}],
  ['shrimp', {name: 'shrimp', image: '1f990'}],
  ['squid', {name: 'squid', image: '1f991'}],
  ['giraffe_face', {name: 'giraffe face', image: '1f992'}],
  ['zebra_face', {name: 'zebra face', image: '1f993'}],
  ['hedgehog', {name: 'hedgehog', image: '1f994'}],
  ['sauropod', {name: 'sauropod', image: '1f995'}],
  ['t-rex', {name: 't-rex', image: '1f996'}],
  ['cricket', {name: 'cricket', image: '1f997'}],
  ['kangaroo', {name: 'kangaroo', image: '1f998'}],
  ['llama', {name: 'llama', image: '1f999'}],
  ['peacock', {name: 'peacock', image: '1f99a'}],
  ['hippopotamus', {name: 'hippopotamus', image: '1f99b'}],
  ['parrot', {name: 'parrot', image: '1f99c'}],
  ['raccoon', {name: 'raccoon', image: '1f99d'}],
  ['lobster', {name: 'lobster', image: '1f99e'}],
  ['mosquito', {name: 'mosquito', image: '1f99f'}],
  ['microbe', {name: 'microbe', image: '1f9a0'}],
  ['badger', {name: 'badger', image: '1f9a1'}],
  ['swan', {name: 'swan', image: '1f9a2'}],
  ['mammoth', {name: 'mammoth', image: '1f9a3'}],
  ['dodo', {name: 'dodo', image: '1f9a4'}],
  ['sloth', {name: 'sloth', image: '1f9a5'}],
  ['otter', {name: 'otter', image: '1f9a6'}],
  ['orangutan', {name: 'orangutan', image: '1f9a7'}],
  ['skunk', {name: 'skunk', image: '1f9a8'}],
  ['flamingo', {name: 'flamingo', image: '1f9a9'}],
  ['oyster', {name: 'oyster', image: '1f9aa'}],
  ['beaver', {name: 'beaver', image: '1f9ab'}],
  ['bison', {name: 'bison', image: '1f9ac'}],
  ['seal', {name: 'seal', image: '1f9ad'}],
  ['guide_dog', {name: 'guide dog', image: '1f9ae'}],
  ['probing_cane', {name: 'probing cane', image: '1f9af'}],
  ['bone', {name: 'bone', image: '1f9b4'}],
  ['leg', {name: 'leg', image: '1f9b5'}],
  ['leg_skin-tone-2', {name: 'leg', image: '1f9b5_1f3fb'}],
  ['leg_skin-tone-3', {name: 'leg', image: '1f9b5_1f3fc'}],
  ['leg_skin-tone-4', {name: 'leg', image: '1f9b5_1f3fd'}],
  ['leg_skin-tone-5', {name: 'leg', image: '1f9b5_1f3fe'}],
  ['leg_skin-tone-6', {name: 'leg', image: '1f9b5_1f3ff'}],
  ['foot', {name: 'foot', image: '1f9b6'}],
  ['foot_skin-tone-2', {name: 'foot', image: '1f9b6_1f3fb'}],
  ['foot_skin-tone-3', {name: 'foot', image: '1f9b6_1f3fc'}],
  ['foot_skin-tone-4', {name: 'foot', image: '1f9b6_1f3fd'}],
  ['foot_skin-tone-5', {name: 'foot', image: '1f9b6_1f3fe'}],
  ['foot_skin-tone-6', {name: 'foot', image: '1f9b6_1f3ff'}],
  ['tooth', {name: 'tooth', image: '1f9b7'}],
  ['female_superhero', {name: 'woman superhero', image: '1f9b8_200d_2640'}],
  ['male_superhero', {name: 'man superhero', image: '1f9b8_200d_2642'}],
  ['superhero', {name: 'superhero', image: '1f9b8'}],
  ['superhero_skin-tone-2', {name: 'superhero', image: '1f9b8_1f3fb'}],
  ['superhero_skin-tone-3', {name: 'superhero', image: '1f9b8_1f3fc'}],
  ['superhero_skin-tone-4', {name: 'superhero', image: '1f9b8_1f3fd'}],
  ['superhero_skin-tone-5', {name: 'superhero', image: '1f9b8_1f3fe'}],
  ['superhero_skin-tone-6', {name: 'superhero', image: '1f9b8_1f3ff'}],
  [
    'female_supervillain',
    {name: 'woman supervillain', image: '1f9b9_200d_2640'}
  ],
  ['male_supervillain', {name: 'man supervillain', image: '1f9b9_200d_2642'}],
  ['supervillain', {name: 'supervillain', image: '1f9b9'}],
  ['supervillain_skin-tone-2', {name: 'supervillain', image: '1f9b9_1f3fb'}],
  ['supervillain_skin-tone-3', {name: 'supervillain', image: '1f9b9_1f3fc'}],
  ['supervillain_skin-tone-4', {name: 'supervillain', image: '1f9b9_1f3fd'}],
  ['supervillain_skin-tone-5', {name: 'supervillain', image: '1f9b9_1f3fe'}],
  ['supervillain_skin-tone-6', {name: 'supervillain', image: '1f9b9_1f3ff'}],
  ['safety_vest', {name: 'safety vest', image: '1f9ba'}],
  ['ear_with_hearing_aid', {name: 'ear with hearing aid', image: '1f9bb'}],
  [
    'ear_with_hearing_aid_skin-tone-2',
    {name: 'ear with hearing aid', image: '1f9bb_1f3fb'}
  ],
  [
    'ear_with_hearing_aid_skin-tone-3',
    {name: 'ear with hearing aid', image: '1f9bb_1f3fc'}
  ],
  [
    'ear_with_hearing_aid_skin-tone-4',
    {name: 'ear with hearing aid', image: '1f9bb_1f3fd'}
  ],
  [
    'ear_with_hearing_aid_skin-tone-5',
    {name: 'ear with hearing aid', image: '1f9bb_1f3fe'}
  ],
  [
    'ear_with_hearing_aid_skin-tone-6',
    {name: 'ear with hearing aid', image: '1f9bb_1f3ff'}
  ],
  ['motorized_wheelchair', {name: 'motorized wheelchair', image: '1f9bc'}],
  ['manual_wheelchair', {name: 'manual wheelchair', image: '1f9bd'}],
  ['mechanical_arm', {name: 'mechanical arm', image: '1f9be'}],
  ['mechanical_leg', {name: 'mechanical leg', image: '1f9bf'}],
  ['cheese_wedge', {name: 'cheese wedge', image: '1f9c0'}],
  ['cupcake', {name: 'cupcake', image: '1f9c1'}],
  ['salt', {name: 'salt shaker', image: '1f9c2'}],
  ['beverage_box', {name: 'beverage box', image: '1f9c3'}],
  ['garlic', {name: 'garlic', image: '1f9c4'}],
  ['onion', {name: 'onion', image: '1f9c5'}],
  ['falafel', {name: 'falafel', image: '1f9c6'}],
  ['waffle', {name: 'waffle', image: '1f9c7'}],
  ['butter', {name: 'butter', image: '1f9c8'}],
  ['mate_drink', {name: 'mate drink', image: '1f9c9'}],
  ['ice_cube', {name: 'ice cube', image: '1f9ca'}],
  ['bubble_tea', {name: 'bubble tea', image: '1f9cb'}],
  ['troll', {name: 'troll', image: '1f9cc'}],
  ['woman_standing', {name: 'woman standing', image: '1f9cd_200d_2640'}],
  ['man_standing', {name: 'man standing', image: '1f9cd_200d_2642'}],
  ['standing_person', {name: 'standing person', image: '1f9cd'}],
  [
    'standing_person_skin-tone-2',
    {name: 'standing person', image: '1f9cd_1f3fb'}
  ],
  [
    'standing_person_skin-tone-3',
    {name: 'standing person', image: '1f9cd_1f3fc'}
  ],
  [
    'standing_person_skin-tone-4',
    {name: 'standing person', image: '1f9cd_1f3fd'}
  ],
  [
    'standing_person_skin-tone-5',
    {name: 'standing person', image: '1f9cd_1f3fe'}
  ],
  [
    'standing_person_skin-tone-6',
    {name: 'standing person', image: '1f9cd_1f3ff'}
  ],
  ['woman_kneeling', {name: 'woman kneeling', image: '1f9ce_200d_2640'}],
  ['man_kneeling', {name: 'man kneeling', image: '1f9ce_200d_2642'}],
  ['kneeling_person', {name: 'kneeling person', image: '1f9ce'}],
  [
    'kneeling_person_skin-tone-2',
    {name: 'kneeling person', image: '1f9ce_1f3fb'}
  ],
  [
    'kneeling_person_skin-tone-3',
    {name: 'kneeling person', image: '1f9ce_1f3fc'}
  ],
  [
    'kneeling_person_skin-tone-4',
    {name: 'kneeling person', image: '1f9ce_1f3fd'}
  ],
  [
    'kneeling_person_skin-tone-5',
    {name: 'kneeling person', image: '1f9ce_1f3fe'}
  ],
  [
    'kneeling_person_skin-tone-6',
    {name: 'kneeling person', image: '1f9ce_1f3ff'}
  ],
  ['deaf_woman', {name: 'deaf woman', image: '1f9cf_200d_2640'}],
  ['deaf_man', {name: 'deaf man', image: '1f9cf_200d_2642'}],
  ['deaf_person', {name: 'deaf person', image: '1f9cf'}],
  ['deaf_person_skin-tone-2', {name: 'deaf person', image: '1f9cf_1f3fb'}],
  ['deaf_person_skin-tone-3', {name: 'deaf person', image: '1f9cf_1f3fc'}],
  ['deaf_person_skin-tone-4', {name: 'deaf person', image: '1f9cf_1f3fd'}],
  ['deaf_person_skin-tone-5', {name: 'deaf person', image: '1f9cf_1f3fe'}],
  ['deaf_person_skin-tone-6', {name: 'deaf person', image: '1f9cf_1f3ff'}],
  ['face_with_monocle', {name: 'face with monocle', image: '1f9d0'}],
  ['farmer', {name: 'farmer', image: '1f9d1_200d_1f33e'}],
  ['cook', {name: 'cook', image: '1f9d1_200d_1f373'}],
  [
    'person_feeding_baby',
    {name: 'person feeding baby', image: '1f9d1_200d_1f37c'}
  ],
  ['mx_claus', {name: 'mx claus', image: '1f9d1_200d_1f384'}],
  ['student', {name: 'student', image: '1f9d1_200d_1f393'}],
  ['singer', {name: 'singer', image: '1f9d1_200d_1f3a4'}],
  ['artist', {name: 'artist', image: '1f9d1_200d_1f3a8'}],
  ['teacher', {name: 'teacher', image: '1f9d1_200d_1f3eb'}],
  ['factory_worker', {name: 'factory worker', image: '1f9d1_200d_1f3ed'}],
  ['technologist', {name: 'technologist', image: '1f9d1_200d_1f4bb'}],
  ['office_worker', {name: 'office worker', image: '1f9d1_200d_1f4bc'}],
  ['mechanic', {name: 'mechanic', image: '1f9d1_200d_1f527'}],
  ['scientist', {name: 'scientist', image: '1f9d1_200d_1f52c'}],
  ['astronaut', {name: 'astronaut', image: '1f9d1_200d_1f680'}],
  ['firefighter', {name: 'firefighter', image: '1f9d1_200d_1f692'}],
  [
    'people_holding_hands',
    {name: 'people holding hands', image: '1f9d1_200d_1f91d_200d_1f9d1'}
  ],
  [
    'person_with_probing_cane',
    {name: 'person with white cane', image: '1f9d1_200d_1f9af'}
  ],
  ['red_haired_person', {name: 'person: red hair', image: '1f9d1_200d_1f9b0'}],
  [
    'curly_haired_person',
    {name: 'person: curly hair', image: '1f9d1_200d_1f9b1'}
  ],
  ['bald_person', {name: 'person: bald', image: '1f9d1_200d_1f9b2'}],
  [
    'white_haired_person',
    {name: 'person: white hair', image: '1f9d1_200d_1f9b3'}
  ],
  [
    'person_in_motorized_wheelchair',
    {name: 'person in motorized wheelchair', image: '1f9d1_200d_1f9bc'}
  ],
  [
    'person_in_manual_wheelchair',
    {name: 'person in manual wheelchair', image: '1f9d1_200d_1f9bd'}
  ],
  ['health_worker', {name: 'health worker', image: '1f9d1_200d_2695'}],
  ['judge', {name: 'judge', image: '1f9d1_200d_2696'}],
  ['pilot', {name: 'pilot', image: '1f9d1_200d_2708'}],
  ['adult', {name: 'adult', image: '1f9d1'}],
  ['adult_skin-tone-2', {name: 'adult', image: '1f9d1_1f3fb'}],
  ['adult_skin-tone-3', {name: 'adult', image: '1f9d1_1f3fc'}],
  ['adult_skin-tone-4', {name: 'adult', image: '1f9d1_1f3fd'}],
  ['adult_skin-tone-5', {name: 'adult', image: '1f9d1_1f3fe'}],
  ['adult_skin-tone-6', {name: 'adult', image: '1f9d1_1f3ff'}],
  ['child', {name: 'child', image: '1f9d2'}],
  ['child_skin-tone-2', {name: 'child', image: '1f9d2_1f3fb'}],
  ['child_skin-tone-3', {name: 'child', image: '1f9d2_1f3fc'}],
  ['child_skin-tone-4', {name: 'child', image: '1f9d2_1f3fd'}],
  ['child_skin-tone-5', {name: 'child', image: '1f9d2_1f3fe'}],
  ['child_skin-tone-6', {name: 'child', image: '1f9d2_1f3ff'}],
  ['older_adult', {name: 'older adult', image: '1f9d3'}],
  ['older_adult_skin-tone-2', {name: 'older adult', image: '1f9d3_1f3fb'}],
  ['older_adult_skin-tone-3', {name: 'older adult', image: '1f9d3_1f3fc'}],
  ['older_adult_skin-tone-4', {name: 'older adult', image: '1f9d3_1f3fd'}],
  ['older_adult_skin-tone-5', {name: 'older adult', image: '1f9d3_1f3fe'}],
  ['older_adult_skin-tone-6', {name: 'older adult', image: '1f9d3_1f3ff'}],
  ['woman_with_beard', {name: 'woman: beard', image: '1f9d4_200d_2640'}],
  ['man_with_beard', {name: 'man: beard', image: '1f9d4_200d_2642'}],
  ['bearded_person', {name: 'bearded person', image: '1f9d4'}],
  [
    'bearded_person_skin-tone-2',
    {name: 'bearded person', image: '1f9d4_1f3fb'}
  ],
  [
    'bearded_person_skin-tone-3',
    {name: 'bearded person', image: '1f9d4_1f3fc'}
  ],
  [
    'bearded_person_skin-tone-4',
    {name: 'bearded person', image: '1f9d4_1f3fd'}
  ],
  [
    'bearded_person_skin-tone-5',
    {name: 'bearded person', image: '1f9d4_1f3fe'}
  ],
  [
    'bearded_person_skin-tone-6',
    {name: 'bearded person', image: '1f9d4_1f3ff'}
  ],
  ['person_with_headscarf', {name: 'person with headscarf', image: '1f9d5'}],
  [
    'person_with_headscarf_skin-tone-2',
    {name: 'person with headscarf', image: '1f9d5_1f3fb'}
  ],
  [
    'person_with_headscarf_skin-tone-3',
    {name: 'person with headscarf', image: '1f9d5_1f3fc'}
  ],
  [
    'person_with_headscarf_skin-tone-4',
    {name: 'person with headscarf', image: '1f9d5_1f3fd'}
  ],
  [
    'person_with_headscarf_skin-tone-5',
    {name: 'person with headscarf', image: '1f9d5_1f3fe'}
  ],
  [
    'person_with_headscarf_skin-tone-6',
    {name: 'person with headscarf', image: '1f9d5_1f3ff'}
  ],
  [
    'woman_in_steamy_room',
    {name: 'woman in steamy room', image: '1f9d6_200d_2640'}
  ],
  [
    'man_in_steamy_room',
    {name: 'man in steamy room', image: '1f9d6_200d_2642'}
  ],
  ['person_in_steamy_room', {name: 'person in steamy room', image: '1f9d6'}],
  [
    'person_in_steamy_room_skin-tone-2',
    {name: 'person in steamy room', image: '1f9d6_1f3fb'}
  ],
  [
    'person_in_steamy_room_skin-tone-3',
    {name: 'person in steamy room', image: '1f9d6_1f3fc'}
  ],
  [
    'person_in_steamy_room_skin-tone-4',
    {name: 'person in steamy room', image: '1f9d6_1f3fd'}
  ],
  [
    'person_in_steamy_room_skin-tone-5',
    {name: 'person in steamy room', image: '1f9d6_1f3fe'}
  ],
  [
    'person_in_steamy_room_skin-tone-6',
    {name: 'person in steamy room', image: '1f9d6_1f3ff'}
  ],
  ['woman_climbing', {name: 'woman climbing', image: '1f9d7_200d_2640'}],
  ['man_climbing', {name: 'man climbing', image: '1f9d7_200d_2642'}],
  ['person_climbing', {name: 'person climbing', image: '1f9d7'}],
  [
    'person_climbing_skin-tone-2',
    {name: 'person climbing', image: '1f9d7_1f3fb'}
  ],
  [
    'person_climbing_skin-tone-3',
    {name: 'person climbing', image: '1f9d7_1f3fc'}
  ],
  [
    'person_climbing_skin-tone-4',
    {name: 'person climbing', image: '1f9d7_1f3fd'}
  ],
  [
    'person_climbing_skin-tone-5',
    {name: 'person climbing', image: '1f9d7_1f3fe'}
  ],
  [
    'person_climbing_skin-tone-6',
    {name: 'person climbing', image: '1f9d7_1f3ff'}
  ],
  [
    'woman_in_lotus_position',
    {name: 'woman in lotus position', image: '1f9d8_200d_2640'}
  ],
  [
    'man_in_lotus_position',
    {name: 'man in lotus position', image: '1f9d8_200d_2642'}
  ],
  [
    'person_in_lotus_position',
    {name: 'person in lotus position', image: '1f9d8'}
  ],
  [
    'person_in_lotus_position_skin-tone-2',
    {name: 'person in lotus position', image: '1f9d8_1f3fb'}
  ],
  [
    'person_in_lotus_position_skin-tone-3',
    {name: 'person in lotus position', image: '1f9d8_1f3fc'}
  ],
  [
    'person_in_lotus_position_skin-tone-4',
    {name: 'person in lotus position', image: '1f9d8_1f3fd'}
  ],
  [
    'person_in_lotus_position_skin-tone-5',
    {name: 'person in lotus position', image: '1f9d8_1f3fe'}
  ],
  [
    'person_in_lotus_position_skin-tone-6',
    {name: 'person in lotus position', image: '1f9d8_1f3ff'}
  ],
  ['female_mage', {name: 'woman mage', image: '1f9d9_200d_2640'}],
  ['male_mage', {name: 'man mage', image: '1f9d9_200d_2642'}],
  ['mage', {name: 'mage', image: '1f9d9'}],
  ['mage_skin-tone-2', {name: 'mage', image: '1f9d9_1f3fb'}],
  ['mage_skin-tone-3', {name: 'mage', image: '1f9d9_1f3fc'}],
  ['mage_skin-tone-4', {name: 'mage', image: '1f9d9_1f3fd'}],
  ['mage_skin-tone-5', {name: 'mage', image: '1f9d9_1f3fe'}],
  ['mage_skin-tone-6', {name: 'mage', image: '1f9d9_1f3ff'}],
  ['female_fairy', {name: 'woman fairy', image: '1f9da_200d_2640'}],
  ['male_fairy', {name: 'man fairy', image: '1f9da_200d_2642'}],
  ['fairy', {name: 'fairy', image: '1f9da'}],
  ['fairy_skin-tone-2', {name: 'fairy', image: '1f9da_1f3fb'}],
  ['fairy_skin-tone-3', {name: 'fairy', image: '1f9da_1f3fc'}],
  ['fairy_skin-tone-4', {name: 'fairy', image: '1f9da_1f3fd'}],
  ['fairy_skin-tone-5', {name: 'fairy', image: '1f9da_1f3fe'}],
  ['fairy_skin-tone-6', {name: 'fairy', image: '1f9da_1f3ff'}],
  ['female_vampire', {name: 'woman vampire', image: '1f9db_200d_2640'}],
  ['male_vampire', {name: 'man vampire', image: '1f9db_200d_2642'}],
  ['vampire', {name: 'vampire', image: '1f9db'}],
  ['vampire_skin-tone-2', {name: 'vampire', image: '1f9db_1f3fb'}],
  ['vampire_skin-tone-3', {name: 'vampire', image: '1f9db_1f3fc'}],
  ['vampire_skin-tone-4', {name: 'vampire', image: '1f9db_1f3fd'}],
  ['vampire_skin-tone-5', {name: 'vampire', image: '1f9db_1f3fe'}],
  ['vampire_skin-tone-6', {name: 'vampire', image: '1f9db_1f3ff'}],
  ['mermaid', {name: 'mermaid', image: '1f9dc_200d_2640'}],
  ['merman', {name: 'merman', image: '1f9dc_200d_2642'}],
  ['merperson', {name: 'merperson', image: '1f9dc'}],
  ['merperson_skin-tone-2', {name: 'merperson', image: '1f9dc_1f3fb'}],
  ['merperson_skin-tone-3', {name: 'merperson', image: '1f9dc_1f3fc'}],
  ['merperson_skin-tone-4', {name: 'merperson', image: '1f9dc_1f3fd'}],
  ['merperson_skin-tone-5', {name: 'merperson', image: '1f9dc_1f3fe'}],
  ['merperson_skin-tone-6', {name: 'merperson', image: '1f9dc_1f3ff'}],
  ['female_elf', {name: 'woman elf', image: '1f9dd_200d_2640'}],
  ['male_elf', {name: 'man elf', image: '1f9dd_200d_2642'}],
  ['elf', {name: 'elf', image: '1f9dd'}],
  ['elf_skin-tone-2', {name: 'elf', image: '1f9dd_1f3fb'}],
  ['elf_skin-tone-3', {name: 'elf', image: '1f9dd_1f3fc'}],
  ['elf_skin-tone-4', {name: 'elf', image: '1f9dd_1f3fd'}],
  ['elf_skin-tone-5', {name: 'elf', image: '1f9dd_1f3fe'}],
  ['elf_skin-tone-6', {name: 'elf', image: '1f9dd_1f3ff'}],
  ['female_genie', {name: 'woman genie', image: '1f9de_200d_2640'}],
  ['male_genie', {name: 'man genie', image: '1f9de_200d_2642'}],
  ['genie', {name: 'genie', image: '1f9de'}],
  ['female_zombie', {name: 'woman zombie', image: '1f9df_200d_2640'}],
  ['male_zombie', {name: 'man zombie', image: '1f9df_200d_2642'}],
  ['zombie', {name: 'zombie', image: '1f9df'}],
  ['brain', {name: 'brain', image: '1f9e0'}],
  ['orange_heart', {name: 'orange heart', image: '1f9e1'}],
  ['billed_cap', {name: 'billed cap', image: '1f9e2'}],
  ['scarf', {name: 'scarf', image: '1f9e3'}],
  ['gloves', {name: 'gloves', image: '1f9e4'}],
  ['coat', {name: 'coat', image: '1f9e5'}],
  ['socks', {name: 'socks', image: '1f9e6'}],
  ['red_envelope', {name: 'red gift envelope', image: '1f9e7'}],
  ['firecracker', {name: 'firecracker', image: '1f9e8'}],
  ['jigsaw', {name: 'jigsaw puzzle piece', image: '1f9e9'}],
  ['test_tube', {name: 'test tube', image: '1f9ea'}],
  ['petri_dish', {name: 'petri dish', image: '1f9eb'}],
  ['dna', {name: 'dna double helix', image: '1f9ec'}],
  ['compass', {name: 'compass', image: '1f9ed'}],
  ['abacus', {name: 'abacus', image: '1f9ee'}],
  ['fire_extinguisher', {name: 'fire extinguisher', image: '1f9ef'}],
  ['toolbox', {name: 'toolbox', image: '1f9f0'}],
  ['bricks', {name: 'brick', image: '1f9f1'}],
  ['magnet', {name: 'magnet', image: '1f9f2'}],
  ['luggage', {name: 'luggage', image: '1f9f3'}],
  ['lotion_bottle', {name: 'lotion bottle', image: '1f9f4'}],
  ['thread', {name: 'spool of thread', image: '1f9f5'}],
  ['yarn', {name: 'ball of yarn', image: '1f9f6'}],
  ['safety_pin', {name: 'safety pin', image: '1f9f7'}],
  ['teddy_bear', {name: 'teddy bear', image: '1f9f8'}],
  ['broom', {name: 'broom', image: '1f9f9'}],
  ['basket', {name: 'basket', image: '1f9fa'}],
  ['roll_of_paper', {name: 'roll of paper', image: '1f9fb'}],
  ['soap', {name: 'bar of soap', image: '1f9fc'}],
  ['sponge', {name: 'sponge', image: '1f9fd'}],
  ['receipt', {name: 'receipt', image: '1f9fe'}],
  ['nazar_amulet', {name: 'nazar amulet', image: '1f9ff'}],
  ['ballet_shoes', {name: 'ballet shoes', image: '1fa70'}],
  ['one-piece_swimsuit', {name: 'one-piece swimsuit', image: '1fa71'}],
  ['briefs', {name: 'briefs', image: '1fa72'}],
  ['shorts', {name: 'shorts', image: '1fa73'}],
  ['thong_sandal', {name: 'thong sandal', image: '1fa74'}],
  ['drop_of_blood', {name: 'drop of blood', image: '1fa78'}],
  ['adhesive_bandage', {name: 'adhesive bandage', image: '1fa79'}],
  ['stethoscope', {name: 'stethoscope', image: '1fa7a'}],
  ['x-ray', {name: 'x-ray', image: '1fa7b'}],
  ['crutch', {name: 'crutch', image: '1fa7c'}],
  ['yo-yo', {name: 'yo-yo', image: '1fa80'}],
  ['kite', {name: 'kite', image: '1fa81'}],
  ['parachute', {name: 'parachute', image: '1fa82'}],
  ['boomerang', {name: 'boomerang', image: '1fa83'}],
  ['magic_wand', {name: 'magic wand', image: '1fa84'}],
  ['pinata', {name: 'pinata', image: '1fa85'}],
  ['nesting_dolls', {name: 'nesting dolls', image: '1fa86'}],
  ['ringed_planet', {name: 'ringed planet', image: '1fa90'}],
  ['chair', {name: 'chair', image: '1fa91'}],
  ['razor', {name: 'razor', image: '1fa92'}],
  ['axe', {name: 'axe', image: '1fa93'}],
  ['diya_lamp', {name: 'diya lamp', image: '1fa94'}],
  ['banjo', {name: 'banjo', image: '1fa95'}],
  ['military_helmet', {name: 'military helmet', image: '1fa96'}],
  ['accordion', {name: 'accordion', image: '1fa97'}],
  ['long_drum', {name: 'long drum', image: '1fa98'}],
  ['coin', {name: 'coin', image: '1fa99'}],
  ['carpentry_saw', {name: 'carpentry saw', image: '1fa9a'}],
  ['screwdriver', {name: 'screwdriver', image: '1fa9b'}],
  ['ladder', {name: 'ladder', image: '1fa9c'}],
  ['hook', {name: 'hook', image: '1fa9d'}],
  ['mirror', {name: 'mirror', image: '1fa9e'}],
  ['window', {name: 'window', image: '1fa9f'}],
  ['plunger', {name: 'plunger', image: '1faa0'}],
  ['sewing_needle', {name: 'sewing needle', image: '1faa1'}],
  ['knot', {name: 'knot', image: '1faa2'}],
  ['bucket', {name: 'bucket', image: '1faa3'}],
  ['mouse_trap', {name: 'mouse trap', image: '1faa4'}],
  ['toothbrush', {name: 'toothbrush', image: '1faa5'}],
  ['headstone', {name: 'headstone', image: '1faa6'}],
  ['placard', {name: 'placard', image: '1faa7'}],
  ['rock', {name: 'rock', image: '1faa8'}],
  ['mirror_ball', {name: 'mirror ball', image: '1faa9'}],
  ['identification_card', {name: 'identification card', image: '1faaa'}],
  ['low_battery', {name: 'low battery', image: '1faab'}],
  ['hamsa', {name: 'hamsa', image: '1faac'}],
  ['fly', {name: 'fly', image: '1fab0'}],
  ['worm', {name: 'worm', image: '1fab1'}],
  ['beetle', {name: 'beetle', image: '1fab2'}],
  ['cockroach', {name: 'cockroach', image: '1fab3'}],
  ['potted_plant', {name: 'potted plant', image: '1fab4'}],
  ['wood', {name: 'wood', image: '1fab5'}],
  ['feather', {name: 'feather', image: '1fab6'}],
  ['lotus', {name: 'lotus', image: '1fab7'}],
  ['coral', {name: 'coral', image: '1fab8'}],
  ['empty_nest', {name: 'empty nest', image: '1fab9'}],
  ['nest_with_eggs', {name: 'nest with eggs', image: '1faba'}],
  ['anatomical_heart', {name: 'anatomical heart', image: '1fac0'}],
  ['lungs', {name: 'lungs', image: '1fac1'}],
  ['people_hugging', {name: 'people hugging', image: '1fac2'}],
  ['pregnant_man', {name: 'pregnant man', image: '1fac3'}],
  ['pregnant_man_skin-tone-2', {name: 'pregnant man', image: '1fac3_1f3fb'}],
  ['pregnant_man_skin-tone-3', {name: 'pregnant man', image: '1fac3_1f3fc'}],
  ['pregnant_man_skin-tone-4', {name: 'pregnant man', image: '1fac3_1f3fd'}],
  ['pregnant_man_skin-tone-5', {name: 'pregnant man', image: '1fac3_1f3fe'}],
  ['pregnant_man_skin-tone-6', {name: 'pregnant man', image: '1fac3_1f3ff'}],
  ['pregnant_person', {name: 'pregnant person', image: '1fac4'}],
  [
    'pregnant_person_skin-tone-2',
    {name: 'pregnant person', image: '1fac4_1f3fb'}
  ],
  [
    'pregnant_person_skin-tone-3',
    {name: 'pregnant person', image: '1fac4_1f3fc'}
  ],
  [
    'pregnant_person_skin-tone-4',
    {name: 'pregnant person', image: '1fac4_1f3fd'}
  ],
  [
    'pregnant_person_skin-tone-5',
    {name: 'pregnant person', image: '1fac4_1f3fe'}
  ],
  [
    'pregnant_person_skin-tone-6',
    {name: 'pregnant person', image: '1fac4_1f3ff'}
  ],
  ['person_with_crown', {name: 'person with crown', image: '1fac5'}],
  [
    'person_with_crown_skin-tone-2',
    {name: 'person with crown', image: '1fac5_1f3fb'}
  ],
  [
    'person_with_crown_skin-tone-3',
    {name: 'person with crown', image: '1fac5_1f3fc'}
  ],
  [
    'person_with_crown_skin-tone-4',
    {name: 'person with crown', image: '1fac5_1f3fd'}
  ],
  [
    'person_with_crown_skin-tone-5',
    {name: 'person with crown', image: '1fac5_1f3fe'}
  ],
  [
    'person_with_crown_skin-tone-6',
    {name: 'person with crown', image: '1fac5_1f3ff'}
  ],
  ['blueberries', {name: 'blueberries', image: '1fad0'}],
  ['bell_pepper', {name: 'bell pepper', image: '1fad1'}],
  ['olive', {name: 'olive', image: '1fad2'}],
  ['flatbread', {name: 'flatbread', image: '1fad3'}],
  ['tamale', {name: 'tamale', image: '1fad4'}],
  ['fondue', {name: 'fondue', image: '1fad5'}],
  ['teapot', {name: 'teapot', image: '1fad6'}],
  ['pouring_liquid', {name: 'pouring liquid', image: '1fad7'}],
  ['beans', {name: 'beans', image: '1fad8'}],
  ['jar', {name: 'jar', image: '1fad9'}],
  ['melting_face', {name: 'melting face', image: '1fae0'}],
  ['saluting_face', {name: 'saluting face', image: '1fae1'}],
  [
    'face_with_open_eyes_and_hand_over_mouth',
    {name: 'face with open eyes and hand over mouth', image: '1fae2'}
  ],
  ['face_with_peeking_eye', {name: 'face with peeking eye', image: '1fae3'}],
  [
    'face_with_diagonal_mouth',
    {name: 'face with diagonal mouth', image: '1fae4'}
  ],
  ['dotted_line_face', {name: 'dotted line face', image: '1fae5'}],
  ['biting_lip', {name: 'biting lip', image: '1fae6'}],
  ['bubbles', {name: 'bubbles', image: '1fae7'}],
  [
    'hand_with_index_finger_and_thumb_crossed',
    {name: 'hand with index finger and thumb crossed', image: '1faf0'}
  ],
  [
    'hand_with_index_finger_and_thumb_crossed_skin-tone-2',
    {name: 'hand with index finger and thumb crossed', image: '1faf0_1f3fb'}
  ],
  [
    'hand_with_index_finger_and_thumb_crossed_skin-tone-3',
    {name: 'hand with index finger and thumb crossed', image: '1faf0_1f3fc'}
  ],
  [
    'hand_with_index_finger_and_thumb_crossed_skin-tone-4',
    {name: 'hand with index finger and thumb crossed', image: '1faf0_1f3fd'}
  ],
  [
    'hand_with_index_finger_and_thumb_crossed_skin-tone-5',
    {name: 'hand with index finger and thumb crossed', image: '1faf0_1f3fe'}
  ],
  [
    'hand_with_index_finger_and_thumb_crossed_skin-tone-6',
    {name: 'hand with index finger and thumb crossed', image: '1faf0_1f3ff'}
  ],
  ['rightwards_hand', {name: 'rightwards hand', image: '1faf1'}],
  [
    'rightwards_hand_skin-tone-2',
    {name: 'rightwards hand', image: '1faf1_1f3fb'}
  ],
  [
    'rightwards_hand_skin-tone-3',
    {name: 'rightwards hand', image: '1faf1_1f3fc'}
  ],
  [
    'rightwards_hand_skin-tone-4',
    {name: 'rightwards hand', image: '1faf1_1f3fd'}
  ],
  [
    'rightwards_hand_skin-tone-5',
    {name: 'rightwards hand', image: '1faf1_1f3fe'}
  ],
  [
    'rightwards_hand_skin-tone-6',
    {name: 'rightwards hand', image: '1faf1_1f3ff'}
  ],
  ['leftwards_hand', {name: 'leftwards hand', image: '1faf2'}],
  [
    'leftwards_hand_skin-tone-2',
    {name: 'leftwards hand', image: '1faf2_1f3fb'}
  ],
  [
    'leftwards_hand_skin-tone-3',
    {name: 'leftwards hand', image: '1faf2_1f3fc'}
  ],
  [
    'leftwards_hand_skin-tone-4',
    {name: 'leftwards hand', image: '1faf2_1f3fd'}
  ],
  [
    'leftwards_hand_skin-tone-5',
    {name: 'leftwards hand', image: '1faf2_1f3fe'}
  ],
  [
    'leftwards_hand_skin-tone-6',
    {name: 'leftwards hand', image: '1faf2_1f3ff'}
  ],
  ['palm_down_hand', {name: 'palm down hand', image: '1faf3'}],
  [
    'palm_down_hand_skin-tone-2',
    {name: 'palm down hand', image: '1faf3_1f3fb'}
  ],
  [
    'palm_down_hand_skin-tone-3',
    {name: 'palm down hand', image: '1faf3_1f3fc'}
  ],
  [
    'palm_down_hand_skin-tone-4',
    {name: 'palm down hand', image: '1faf3_1f3fd'}
  ],
  [
    'palm_down_hand_skin-tone-5',
    {name: 'palm down hand', image: '1faf3_1f3fe'}
  ],
  [
    'palm_down_hand_skin-tone-6',
    {name: 'palm down hand', image: '1faf3_1f3ff'}
  ],
  ['palm_up_hand', {name: 'palm up hand', image: '1faf4'}],
  ['palm_up_hand_skin-tone-2', {name: 'palm up hand', image: '1faf4_1f3fb'}],
  ['palm_up_hand_skin-tone-3', {name: 'palm up hand', image: '1faf4_1f3fc'}],
  ['palm_up_hand_skin-tone-4', {name: 'palm up hand', image: '1faf4_1f3fd'}],
  ['palm_up_hand_skin-tone-5', {name: 'palm up hand', image: '1faf4_1f3fe'}],
  ['palm_up_hand_skin-tone-6', {name: 'palm up hand', image: '1faf4_1f3ff'}],
  [
    'index_pointing_at_the_viewer',
    {name: 'index pointing at the viewer', image: '1faf5'}
  ],
  [
    'index_pointing_at_the_viewer_skin-tone-2',
    {name: 'index pointing at the viewer', image: '1faf5_1f3fb'}
  ],
  [
    'index_pointing_at_the_viewer_skin-tone-3',
    {name: 'index pointing at the viewer', image: '1faf5_1f3fc'}
  ],
  [
    'index_pointing_at_the_viewer_skin-tone-4',
    {name: 'index pointing at the viewer', image: '1faf5_1f3fd'}
  ],
  [
    'index_pointing_at_the_viewer_skin-tone-5',
    {name: 'index pointing at the viewer', image: '1faf5_1f3fe'}
  ],
  [
    'index_pointing_at_the_viewer_skin-tone-6',
    {name: 'index pointing at the viewer', image: '1faf5_1f3ff'}
  ],
  ['heart_hands', {name: 'heart hands', image: '1faf6'}],
  ['heart_hands_skin-tone-2', {name: 'heart hands', image: '1faf6_1f3fb'}],
  ['heart_hands_skin-tone-3', {name: 'heart hands', image: '1faf6_1f3fc'}],
  ['heart_hands_skin-tone-4', {name: 'heart hands', image: '1faf6_1f3fd'}],
  ['heart_hands_skin-tone-5', {name: 'heart hands', image: '1faf6_1f3fe'}],
  ['heart_hands_skin-tone-6', {name: 'heart hands', image: '1faf6_1f3ff'}],
  ['bangbang', {name: 'double exclamation mark', image: '203c'}],
  ['interrobang', {name: 'exclamation question mark', image: '2049'}],
  ['tm', {name: 'trade mark sign', image: '2122'}],
  ['information_source', {name: 'information source', image: '2139'}],
  ['left_right_arrow', {name: 'left right arrow', image: '2194'}],
  ['arrow_up_down', {name: 'up down arrow', image: '2195'}],
  ['arrow_upper_left', {name: 'north west arrow', image: '2196'}],
  ['arrow_upper_right', {name: 'north east arrow', image: '2197'}],
  ['arrow_lower_right', {name: 'south east arrow', image: '2198'}],
  ['arrow_lower_left', {name: 'south west arrow', image: '2199'}],
  [
    'leftwards_arrow_with_hook',
    {name: 'leftwards arrow with hook', image: '21a9'}
  ],
  ['arrow_right_hook', {name: 'rightwards arrow with hook', image: '21aa'}],
  ['watch', {name: 'watch', image: '231a'}],
  ['hourglass', {name: 'hourglass', image: '231b'}],
  ['keyboard', {name: 'keyboard', image: '2328'}],
  ['eject', {name: 'eject button', image: '23cf'}],
  [
    'fast_forward',
    {name: 'black right-pointing double triangle', image: '23e9'}
  ],
  ['rewind', {name: 'black left-pointing double triangle', image: '23ea'}],
  [
    'arrow_double_up',
    {name: 'black up-pointing double triangle', image: '23eb'}
  ],
  [
    'arrow_double_down',
    {name: 'black down-pointing double triangle', image: '23ec'}
  ],
  [
    'black_right_pointing_double_triangle_with_vertical_bar',
    {name: 'next track button', image: '23ed'}
  ],
  [
    'black_left_pointing_double_triangle_with_vertical_bar',
    {name: 'last track button', image: '23ee'}
  ],
  [
    'black_right_pointing_triangle_with_double_vertical_bar',
    {name: 'play or pause button', image: '23ef'}
  ],
  ['alarm_clock', {name: 'alarm clock', image: '23f0'}],
  ['stopwatch', {name: 'stopwatch', image: '23f1'}],
  ['timer_clock', {name: 'timer clock', image: '23f2'}],
  [
    'hourglass_flowing_sand',
    {name: 'hourglass with flowing sand', image: '23f3'}
  ],
  ['double_vertical_bar', {name: 'pause button', image: '23f8'}],
  ['black_square_for_stop', {name: 'stop button', image: '23f9'}],
  ['black_circle_for_record', {name: 'record button', image: '23fa'}],
  ['m', {name: 'circled latin capital letter m', image: '24c2'}],
  ['black_small_square', {name: 'black small square', image: '25aa'}],
  ['white_small_square', {name: 'white small square', image: '25ab'}],
  ['arrow_forward', {name: 'black right-pointing triangle', image: '25b6'}],
  ['arrow_backward', {name: 'black left-pointing triangle', image: '25c0'}],
  ['white_medium_square', {name: 'white medium square', image: '25fb'}],
  ['black_medium_square', {name: 'black medium square', image: '25fc'}],
  [
    'white_medium_small_square',
    {name: 'white medium small square', image: '25fd'}
  ],
  [
    'black_medium_small_square',
    {name: 'black medium small square', image: '25fe'}
  ],
  ['sunny', {name: 'black sun with rays', image: '2600'}],
  ['cloud', {name: 'cloud', image: '2601'}],
  ['umbrella', {name: 'umbrella', image: '2602'}],
  ['snowman', {name: 'snowman', image: '2603'}],
  ['comet', {name: 'comet', image: '2604'}],
  ['phone', {name: 'black telephone', image: '260e'}],
  ['ballot_box_with_check', {name: 'ballot box with check', image: '2611'}],
  [
    'umbrella_with_rain_drops',
    {name: 'umbrella with rain drops', image: '2614'}
  ],
  ['coffee', {name: 'hot beverage', image: '2615'}],
  ['shamrock', {name: 'shamrock', image: '2618'}],
  ['point_up', {name: 'white up pointing index', image: '261d'}],
  [
    'point_up_skin-tone-2',
    {name: 'white up pointing index', image: '261d_1f3fb'}
  ],
  [
    'point_up_skin-tone-3',
    {name: 'white up pointing index', image: '261d_1f3fc'}
  ],
  [
    'point_up_skin-tone-4',
    {name: 'white up pointing index', image: '261d_1f3fd'}
  ],
  [
    'point_up_skin-tone-5',
    {name: 'white up pointing index', image: '261d_1f3fe'}
  ],
  [
    'point_up_skin-tone-6',
    {name: 'white up pointing index', image: '261d_1f3ff'}
  ],
  ['skull_and_crossbones', {name: 'skull and crossbones', image: '2620'}],
  ['radioactive_sign', {name: 'radioactive', image: '2622'}],
  ['biohazard_sign', {name: 'biohazard', image: '2623'}],
  ['orthodox_cross', {name: 'orthodox cross', image: '2626'}],
  ['star_and_crescent', {name: 'star and crescent', image: '262a'}],
  ['peace_symbol', {name: 'peace symbol', image: '262e'}],
  ['yin_yang', {name: 'yin yang', image: '262f'}],
  ['wheel_of_dharma', {name: 'wheel of dharma', image: '2638'}],
  ['white_frowning_face', {name: 'frowning face', image: '2639'}],
  ['relaxed', {name: 'white smiling face', image: '263a'}],
  ['female_sign', {name: 'female sign', image: '2640'}],
  ['male_sign', {name: 'male sign', image: '2642'}],
  ['aries', {name: 'aries', image: '2648'}],
  ['taurus', {name: 'taurus', image: '2649'}],
  ['gemini', {name: 'gemini', image: '264a'}],
  ['cancer', {name: 'cancer', image: '264b'}],
  ['leo', {name: 'leo', image: '264c'}],
  ['virgo', {name: 'virgo', image: '264d'}],
  ['libra', {name: 'libra', image: '264e'}],
  ['scorpius', {name: 'scorpius', image: '264f'}],
  ['sagittarius', {name: 'sagittarius', image: '2650'}],
  ['capricorn', {name: 'capricorn', image: '2651'}],
  ['aquarius', {name: 'aquarius', image: '2652'}],
  ['pisces', {name: 'pisces', image: '2653'}],
  ['chess_pawn', {name: 'chess pawn', image: '265f'}],
  ['spades', {name: 'black spade suit', image: '2660'}],
  ['clubs', {name: 'black club suit', image: '2663'}],
  ['hearts', {name: 'black heart suit', image: '2665'}],
  ['diamonds', {name: 'black diamond suit', image: '2666'}],
  ['hotsprings', {name: 'hot springs', image: '2668'}],
  ['recycle', {name: 'black universal recycling symbol', image: '267b'}],
  ['infinity', {name: 'infinity', image: '267e'}],
  ['wheelchair', {name: 'wheelchair symbol', image: '267f'}],
  ['hammer_and_pick', {name: 'hammer and pick', image: '2692'}],
  ['anchor', {name: 'anchor', image: '2693'}],
  ['crossed_swords', {name: 'crossed swords', image: '2694'}],
  ['medical_symbol', {name: 'medical symbol', image: '2695'}],
  ['scales', {name: 'balance scale', image: '2696'}],
  ['alembic', {name: 'alembic', image: '2697'}],
  ['gear', {name: 'gear', image: '2699'}],
  ['atom_symbol', {name: 'atom symbol', image: '269b'}],
  ['fleur_de_lis', {name: 'fleur-de-lis', image: '269c'}],
  ['warning', {name: 'warning sign', image: '26a0'}],
  ['zap', {name: 'high voltage sign', image: '26a1'}],
  ['transgender_symbol', {name: 'transgender symbol', image: '26a7'}],
  ['white_circle', {name: 'medium white circle', image: '26aa'}],
  ['black_circle', {name: 'medium black circle', image: '26ab'}],
  ['coffin', {name: 'coffin', image: '26b0'}],
  ['funeral_urn', {name: 'funeral urn', image: '26b1'}],
  ['soccer', {name: 'soccer ball', image: '26bd'}],
  ['baseball', {name: 'baseball', image: '26be'}],
  ['snowman_without_snow', {name: 'snowman without snow', image: '26c4'}],
  ['partly_sunny', {name: 'sun behind cloud', image: '26c5'}],
  [
    'thunder_cloud_and_rain',
    {name: 'cloud with lightning and rain', image: '26c8'}
  ],
  ['ophiuchus', {name: 'ophiuchus', image: '26ce'}],
  ['pick', {name: 'pick', image: '26cf'}],
  ['helmet_with_white_cross', {name: 'rescue worker’s helmet', image: '26d1'}],
  ['chains', {name: 'chains', image: '26d3'}],
  ['no_entry', {name: 'no entry', image: '26d4'}],
  ['shinto_shrine', {name: 'shinto shrine', image: '26e9'}],
  ['church', {name: 'church', image: '26ea'}],
  ['mountain', {name: 'mountain', image: '26f0'}],
  ['umbrella_on_ground', {name: 'umbrella on ground', image: '26f1'}],
  ['fountain', {name: 'fountain', image: '26f2'}],
  ['golf', {name: 'flag in hole', image: '26f3'}],
  ['ferry', {name: 'ferry', image: '26f4'}],
  ['boat', {name: 'sailboat', image: '26f5'}],
  ['skier', {name: 'skier', image: '26f7'}],
  ['ice_skate', {name: 'ice skate', image: '26f8'}],
  ['person_with_ball', {name: 'person bouncing ball', image: '26f9'}],
  [
    'person_with_ball_skin-tone-2',
    {name: 'person bouncing ball', image: '26f9_1f3fb'}
  ],
  [
    'person_with_ball_skin-tone-3',
    {name: 'person bouncing ball', image: '26f9_1f3fc'}
  ],
  [
    'person_with_ball_skin-tone-4',
    {name: 'person bouncing ball', image: '26f9_1f3fd'}
  ],
  [
    'person_with_ball_skin-tone-5',
    {name: 'person bouncing ball', image: '26f9_1f3fe'}
  ],
  [
    'person_with_ball_skin-tone-6',
    {name: 'person bouncing ball', image: '26f9_1f3ff'}
  ],
  ['tent', {name: 'tent', image: '26fa'}],
  ['fuelpump', {name: 'fuel pump', image: '26fd'}],
  ['scissors', {name: 'black scissors', image: '2702'}],
  ['white_check_mark', {name: 'white heavy check mark', image: '2705'}],
  ['airplane', {name: 'airplane', image: '2708'}],
  ['email', {name: 'envelope', image: '2709'}],
  ['fist', {name: 'raised fist', image: '270a'}],
  ['fist_skin-tone-2', {name: 'raised fist', image: '270a_1f3fb'}],
  ['fist_skin-tone-3', {name: 'raised fist', image: '270a_1f3fc'}],
  ['fist_skin-tone-4', {name: 'raised fist', image: '270a_1f3fd'}],
  ['fist_skin-tone-5', {name: 'raised fist', image: '270a_1f3fe'}],
  ['fist_skin-tone-6', {name: 'raised fist', image: '270a_1f3ff'}],
  ['hand', {name: 'raised hand', image: '270b'}],
  ['hand_skin-tone-2', {name: 'raised hand', image: '270b_1f3fb'}],
  ['hand_skin-tone-3', {name: 'raised hand', image: '270b_1f3fc'}],
  ['hand_skin-tone-4', {name: 'raised hand', image: '270b_1f3fd'}],
  ['hand_skin-tone-5', {name: 'raised hand', image: '270b_1f3fe'}],
  ['hand_skin-tone-6', {name: 'raised hand', image: '270b_1f3ff'}],
  ['v', {name: 'victory hand', image: '270c'}],
  ['v_skin-tone-2', {name: 'victory hand', image: '270c_1f3fb'}],
  ['v_skin-tone-3', {name: 'victory hand', image: '270c_1f3fc'}],
  ['v_skin-tone-4', {name: 'victory hand', image: '270c_1f3fd'}],
  ['v_skin-tone-5', {name: 'victory hand', image: '270c_1f3fe'}],
  ['v_skin-tone-6', {name: 'victory hand', image: '270c_1f3ff'}],
  ['writing_hand', {name: 'writing hand', image: '270d'}],
  ['writing_hand_skin-tone-2', {name: 'writing hand', image: '270d_1f3fb'}],
  ['writing_hand_skin-tone-3', {name: 'writing hand', image: '270d_1f3fc'}],
  ['writing_hand_skin-tone-4', {name: 'writing hand', image: '270d_1f3fd'}],
  ['writing_hand_skin-tone-5', {name: 'writing hand', image: '270d_1f3fe'}],
  ['writing_hand_skin-tone-6', {name: 'writing hand', image: '270d_1f3ff'}],
  ['pencil2', {name: 'pencil', image: '270f'}],
  ['black_nib', {name: 'black nib', image: '2712'}],
  ['heavy_check_mark', {name: 'heavy check mark', image: '2714'}],
  ['heavy_multiplication_x', {name: 'heavy multiplication x', image: '2716'}],
  ['latin_cross', {name: 'latin cross', image: '271d'}],
  ['star_of_david', {name: 'star of david', image: '2721'}],
  ['sparkles', {name: 'sparkles', image: '2728'}],
  ['eight_spoked_asterisk', {name: 'eight spoked asterisk', image: '2733'}],
  [
    'eight_pointed_black_star',
    {name: 'eight pointed black star', image: '2734'}
  ],
  ['snowflake', {name: 'snowflake', image: '2744'}],
  ['sparkle', {name: 'sparkle', image: '2747'}],
  ['x', {name: 'cross mark', image: '274c'}],
  [
    'negative_squared_cross_mark',
    {name: 'negative squared cross mark', image: '274e'}
  ],
  ['question', {name: 'black question mark ornament', image: '2753'}],
  ['grey_question', {name: 'white question mark ornament', image: '2754'}],
  [
    'grey_exclamation',
    {name: 'white exclamation mark ornament', image: '2755'}
  ],
  ['exclamation', {name: 'heavy exclamation mark symbol', image: '2757'}],
  [
    'heavy_heart_exclamation_mark_ornament',
    {name: 'heart exclamation', image: '2763'}
  ],
  ['heart_on_fire', {name: 'heart on fire', image: '2764_200d_1f525'}],
  ['mending_heart', {name: 'mending heart', image: '2764_200d_1fa79'}],
  ['heart', {name: 'heavy black heart', image: '2764'}],
  ['heavy_plus_sign', {name: 'heavy plus sign', image: '2795'}],
  ['heavy_minus_sign', {name: 'heavy minus sign', image: '2796'}],
  ['heavy_division_sign', {name: 'heavy division sign', image: '2797'}],
  ['arrow_right', {name: 'black rightwards arrow', image: '27a1'}],
  ['curly_loop', {name: 'curly loop', image: '27b0'}],
  ['loop', {name: 'double curly loop', image: '27bf'}],
  [
    'arrow_heading_up',
    {name: 'arrow pointing rightwards then curving upwards', image: '2934'}
  ],
  [
    'arrow_heading_down',
    {name: 'arrow pointing rightwards then curving downwards', image: '2935'}
  ],
  ['arrow_left', {name: 'leftwards black arrow', image: '2b05'}],
  ['arrow_up', {name: 'upwards black arrow', image: '2b06'}],
  ['arrow_down', {name: 'downwards black arrow', image: '2b07'}],
  ['black_large_square', {name: 'black large square', image: '2b1b'}],
  ['white_large_square', {name: 'white large square', image: '2b1c'}],
  ['star', {name: 'white medium star', image: '2b50'}],
  ['o', {name: 'heavy large circle', image: '2b55'}],
  ['wavy_dash', {name: 'wavy dash', image: '3030'}],
  ['part_alternation_mark', {name: 'part alternation mark', image: '303d'}],
  [
    'congratulations',
    {name: 'circled ideograph congratulation', image: '3297'}
  ],
  ['secret', {name: 'circled ideograph secret', image: '3299'}]
]);

export default map;
