/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-bullet-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'chemistry'}</title>
    <circle fill="#a4c624" cx={256} cy={256} r={256} />
    <path
      fill="#FFF"
      d="M343.949 292.07c-1.684 23.256-10.268 41.563-25.754 54.922-15.488 13.359-35.898 20.039-61.23 20.039-27.709 0-49.504-9.326-65.387-27.98-15.883-18.652-23.824-44.258-23.824-76.816v-13.211c0-20.781 3.66-39.088 10.984-54.922 7.322-15.832 17.787-27.98 31.395-36.441 13.605-8.461 29.414-12.691 47.426-12.691 24.938 0 45.025 6.68 60.266 20.039 15.238 13.359 24.047 32.113 26.422 56.258h-44.531c-1.09-13.953-4.973-24.07-11.652-30.355-6.68-6.283-16.848-9.426-30.504-9.426-14.844 0-25.953 5.32-33.324 15.957-7.373 10.639-11.158 27.141-11.355 49.504v16.328c0 23.355 3.537 40.426 10.613 51.211 7.074 10.787 18.232 16.18 33.473 16.18 13.754 0 24.021-3.141 30.801-9.426 6.777-6.283 10.662-16.006 11.652-29.168h44.529z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
