import {
  getConfig,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  useIonModal,
  IonButton
} from '@ionic/react';
import {skipToken} from '@reduxjs/toolkit/query/react';
import clsx from 'clsx';
import _ from 'lodash';
import React, {useRef} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {useImmer} from 'use-immer';
import Icon from '../Icon/Icon';
import Modal from './Modal';
import type {SelectItem, SelectProps} from './types';
import {SEARCH_TYPE} from './types';
import {toArray} from './utils';
import './Select.md.scss';

const Select: React.FC<SelectProps> = (props) => {
  const {
    getValues,
    setValue,
    control,
    formState: {errors}
  } = useFormContext();

  const {
    clearInput = false,
    disabled,
    helper,
    label,
    name,
    placeholder,
    multiple = false,
    showError = true,
    searchable = false,
    searchPlaceholder,
    itemsQueryFn: useItemsQuery = useItemsProp,
    itemsQueryArgs,
    filter = defaultFilter,
    itemToValue = defaultItemToValue,
    itemToLabel = defaultItemToLabel,
    optionComponent,
    optionsComponent,
    onSelectValue = () => {},
    onSelectSearchValue = () => {}
  } = props;

  const memoItemToValue = _.memoize(itemToValue);
  const memoItemToLabel = _.memoize(itemToLabel);

  const error = errors[name]?.message;
  const mode = getConfig()?.get('mode') || 'md';
  const fill = mode === 'md' ? 'outline' : undefined;
  const position = mode === 'md' ? 'floating' : undefined;

  const [isModalOpen, setIsModalOpen] = useImmer<boolean>(false);
  const searchInput = useRef<HTMLIonInputElement>(null);
  const [searchValue, setSearchValue] = useImmer<string>('');
  const [displayValue, setDisplayValue] = useImmer<string | null>(null); // null until option selected in modal

  const searchType =
    useItemsQuery === useItemsProp ? SEARCH_TYPE.FILTER : SEARCH_TYPE.SEARCH;
  const searchMinLength =
    props.searchMinLength === undefined
      ? defaultSearchMinLength[searchType]
      : props.searchMinLength;
  const cleanSearchValue = searchValue.trim();
  const isSearchValueValid = cleanSearchValue.length >= searchMinLength;

  const {data: items, isLoading} = useItemsQuery(
    searchType === SEARCH_TYPE.FILTER
      ? isSearchValueValid
        ? //
          filter(props.items, cleanSearchValue)
        : //
          props.items
      : isSearchValueValid && isModalOpen && itemsQueryArgs
      ? //
        itemsQueryArgs(cleanSearchValue)
      : //
        skipToken
  ) as {data: SelectItem[] | undefined; isLoading: boolean};

  const [presentModal, dismissModal] = useIonModal(Modal, {
    value: getValues(name),
    label,
    multiple,
    searchable,
    searchPlaceholder,
    searchInput,
    searchValue,
    setSearchValue,
    isSearchValueValid,
    isLoading,
    items,
    itemToLabel: memoItemToLabel,
    itemToValue: memoItemToValue,
    optionComponent,
    optionsComponent,
    onSave: (values: string[]) => {
      // NOTE Items are not necessarily unique, e.g. countries that share a dialling code
      const selectedItems = items?.filter((item) =>
        values.includes(memoItemToValue(item))
      );
      const uniqueSelectedValues = _.uniq(selectedItems?.map(memoItemToValue));
      const uniqueSelectedLabels = _.uniq(selectedItems?.map(memoItemToLabel));

      let value: null | string | string[];
      if (multiple) {
        if (uniqueSelectedValues?.length) {
          value = uniqueSelectedValues;
        } else {
          value = [];
        }
      } else {
        if (uniqueSelectedValues?.length) {
          value = uniqueSelectedValues[0];
        } else {
          value = null;
        }
      }

      const displayValue = uniqueSelectedLabels?.join(', ') || '';

      setValue(name, value);
      setDisplayValue(displayValue);
      onSelectValue({
        value: value,
        items: selectedItems?.length ? selectedItems : []
      });
      dismissModal();
    },
    onCancel: () => {
      dismissModal();
    },
    onSelectSearchValue: () => {
      setValue(name, searchValue);
      setDisplayValue(searchValue);
      onSelectSearchValue({value: searchValue});
      dismissModal();
    }
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({field: {value, onBlur, onChange}}) => {
        const valueArr = toArray(value);
        const isEmpty = !valueArr.length;
        const hasClearInput = clearInput && !disabled && !isEmpty;

        // displayValue is null until option selected in modal
        const computedDisplayValue =
          displayValue === null
            ? buildDisplayValue(
                valueArr,
                items,
                memoItemToValue,
                memoItemToLabel
              )
            : displayValue;

        return (
          <div
            className={clsx({
              'item-select': true,
              'has-clear-input': hasClearInput
            })}
          >
            {/* Input with submitted value for form validation */}
            <IonInput hidden value={value} onIonChange={onChange} />

            <IonItem className={clsx({'has-error': error})} fill={fill}>
              {label && (
                <IonLabel position={isEmpty ? undefined : position}>
                  {label}
                </IonLabel>
              )}

              {/* Input with displayed value for presentation only */}
              <IonInput
                readonly
                disabled={disabled}
                value={computedDisplayValue}
                color={error ? 'danger' : undefined}
                placeholder={placeholder}
                onIonFocus={() => {
                  presentModal({
                    onDidPresent: () => {
                      setIsModalOpen(true);
                      searchInput.current?.setFocus();
                    },
                    onDidDismiss: () => {
                      setIsModalOpen(false);
                      setSearchValue('');
                    }
                  });
                }}
                onIonBlur={onBlur}
              />

              {error && showError && (
                <IonNote color="danger" slot="helper">
                  {error}
                </IonNote>
              )}
              {!(error && showError) && helper && (
                <IonNote slot="helper">{helper}</IonNote>
              )}
            </IonItem>

            {hasClearInput && (
              <IonButton fill="clear" onClick={() => setValue(name, null)}>
                <Icon name="close" set="ionicons" slot="icon-only" />
              </IonButton>
            )}
          </div>
        );
      }}
    />
  );
};

const defaultItemToValue = ({value}: SelectItem): string => value.toString();

const defaultItemToLabel = ({label}: SelectItem): string => label.toString();

const defaultFilter = (
  items: SelectItem[] | undefined,
  inputValue: string
): SelectItem[] | undefined =>
  items?.filter((item) =>
    item.value
      .toString()
      .toLocaleLowerCase()
      .includes(inputValue.toLocaleLowerCase())
  );

const defaultSearchMinLength: Record<SEARCH_TYPE, number> = {
  [SEARCH_TYPE.FILTER]: 0,
  [SEARCH_TYPE.SEARCH]: 2
};

const useItemsProp = (data: SelectItem[]) => ({data, isLoading: false});

const buildDisplayValue = (
  values: string[],
  items: SelectItem[] | undefined,
  itemToValue: (item: SelectItem) => string,
  itemToLabel: (item: SelectItem) => string
) => {
  if (!values.length) {
    return '';
  }

  const itemLabels = items
    ?.filter((item) => values.includes(itemToValue(item)))
    .map(itemToLabel);

  if (itemLabels?.length) {
    return itemLabels.join(', ');
  }

  // Default to returning raw values, e.g on page load, a field that's populated by the results
  // from a backend search query doesn't have any items yet
  return values.join(', ');
};

export default Select;
