/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-hero-icon"
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{'trophy'}</title>
    <circle fill="#0b9472" cx={256} cy={256} r={256} />
    <path
      fill="#ffd500"
      d="M182.219 379H174v21h82V112.031c-.028.003-.057.002-.085.005a6.203 6.203 0 0 0-.643-.036h-91.881s-.673 19 .33 41H112v21h.104c.521 16 3.201 39.07 14.356 62.04 9.461 19.483 23.085 35.712 40.496 48.379 14.414 10.486 31.027 18.006 49.132 22.519C226.544 318.097 237 324.213 246 326.687v22.902c-2 6.803-9.38 29.411-63.781 29.411zm-37.266-151.949C135.657 207.906 133.225 188 132.691 174h32.455c.558 5 1.264 11.236 2.157 16.559 5.473 32.599 12.994 61.389 27.227 86.791-21.753-11.02-38.837-28.183-49.577-50.299z"
    />
    <path
      fill="#ebb200"
      d="M399.902 157.791a338.982 338.982 0 0 1-.063-4.791h-51.964c1.004-22 .33-41 .33-41h-91.648c-.188 0-.373.014-.558.031V400h82v-21h-8.193C265.983 379 266 347.714 266 347.714v-21.03c9-2.471 19.252-8.598 29.652-19.699 18.236-4.503 34.83-12.087 49.333-22.638 17.409-12.666 31.059-29.002 40.521-48.486 15.223-31.346 14.693-61.754 14.396-78.07zm-32.866 69.259c-10.804 22.249-28.029 39.414-49.967 50.425 14.28-25.452 21.738-54.199 27.226-86.881.894-5.323 1.599-11.595 2.157-16.595h32.846c-.533 14.001-2.966 33.907-12.262 53.051z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
