export const CHEMCOMPOUND = 'chemcompound';
export const CHEMEQUATION = 'chemequation';
export const CHOICE = 'choice';
export const COORDINATE = 'coordinate';
export const ELECTROCHEMCELL = 'electrochemcell';
export const EQUATION = 'equation';
export const EXPRESSION = 'expression';
export const FRACTION = 'fraction';
export const INTERVAL = 'interval';
export const LIST = 'list';
export const NUMERIC = 'numeric';
export const ONE_OF = 'one-of';
export const POLYNOMIAL = 'polynomial';
export const RELATIONAL = 'relational';
export const SPECTROSCOPIC = 'spectroscopic';
export const STRING = 'string';
export const STRINGSET = 'stringset';
