import {
  getConfig,
  IonInput,
  IonItem,
  IonContent,
  IonButton,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonProgressBar
} from '@ionic/react';
import React from 'react';
import {useImmer} from 'use-immer';
import Icon from '../Icon/Icon';
import Option from './Option';
import Options from './Options';
import type {ModalProps} from './types';
import {toArray} from './utils';

const Modal: React.FC<ModalProps> = (props) => {
  const {
    value,
    label,
    multiple,
    searchable,
    searchPlaceholder,
    searchInput,
    searchValue,
    setSearchValue,
    isSearchValueValid,
    isLoading,
    items,
    itemToValue,
    itemToLabel,
    optionComponent = Option,
    optionsComponent = Options,
    onSave,
    onCancel,
    onSelectSearchValue
  } = props;

  const mode = getConfig()?.get('mode') || 'md';
  const fill = mode === 'md' ? 'outline' : undefined;
  const [nextValues, setNextValues] = useImmer<string[]>(toArray(value));

  return (
    <>
      <IonHeader className="item-select-modal-header">
        <IonToolbar>
          <IonTitle>{label}</IonTitle>
          {isLoading && <IonProgressBar type="indeterminate" />}
        </IonToolbar>
      </IonHeader>

      <IonContent className="item-select-modal-content ion-padding">
        {searchable && (
          <IonItem fill={fill} className="tw-mb-4">
            <IonInput
              ref={searchInput}
              type="search"
              placeholder={searchPlaceholder}
              value={searchValue}
              onIonChange={(e) => {
                const nextState = e.detail.value || '';

                setSearchValue(nextState);
                // onSave([]); // TODO Is this needed?
              }}
            />
            <Icon name="search" set="ionicons" slot="start" />
          </IonItem>
        )}

        {isSearchValueValid &&
          React.createElement(optionsComponent, {
            value,
            multiple,
            searchValue,
            items,
            itemToValue,
            itemToLabel,
            optionComponent,
            onSelectValue: (values) => {
              if (multiple) {
                setNextValues(values);
              } else {
                setNextValues(values);
                onSave(values);
              }
            },
            onSelectSearchValue
          })}
      </IonContent>

      <IonFooter className="item-select-modal-footer">
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton color="primary" onClick={() => onCancel()}>
              Cancel
            </IonButton>
            {multiple && (
              <IonButton color="primary" onClick={() => onSave(nextValues)}>
                OK
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default Modal;
