import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../store';
import type {SUBJECT, SUBJECT_NAME, Subject} from './subject';
import data from '../../data/subjects.json';

const sortComparer = (a: Subject, b: Subject) =>
  a.full_name.localeCompare(b.full_name);

const adapter = createEntityAdapter<Subject>({
  sortComparer
});

const slice = createSlice({
  name: 'subjects',
  initialState: {
    ids: [...(data as Subject[])].sort(sortComparer).map((d) => d.id),
    entities: Object.fromEntries((data as Subject[]).map((d) => [d.id, d]))
  },
  reducers: {
    subjectsSetAll: adapter.setAll
  }
});

export const {subjectsSetAll} = slice.actions;

export default slice.reducer;

export const subjectsSelectors = adapter.getSelectors(
  (state: RootState) => state.subjects
);

export const selectSubjectName = (id?: SUBJECT) => (state: RootState) =>
  id === undefined ? undefined : subjectsSelectors.selectById(state, id)?.name;

export const selectSubjectShortName = (id?: SUBJECT) => (state: RootState) =>
  id === undefined
    ? undefined
    : subjectsSelectors.selectById(state, id)?.short_name;

export const selectSubjectFullName = (id?: SUBJECT) => (state: RootState) =>
  id === undefined
    ? undefined
    : subjectsSelectors.selectById(state, id)?.full_name;

export const selectSubjectIdFromName =
  (name: SUBJECT_NAME) => (state: RootState) =>
    subjectsSelectors
      .selectAll(state)
      .filter((entity) => entity.name === name)
      .pop()?.id;
