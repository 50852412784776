import {IonIcon} from '@ionic/react';
import React from 'react';
import type {HeroOutlineIconName} from './icons';
import icons from './icons';
import './HeroOutlineIcon.css';

type Props = {
  name: HeroOutlineIconName;
} & Pick<
  React.HTMLAttributes<HTMLIonIconElement>,
  'className' | 'color' | 'slot'
>;

const HeroOutlineIcon: React.FC<Props> = (props) => {
  const {className, color, name, slot} = props;
  const icon = icons.get(name);

  if (!icon) {
    return null;
  }

  let computedClassName = 'sv-hero-outline-icon';
  if (className) {
    computedClassName += ' ' + className;
  }

  return (
    <IonIcon
      className={computedClassName}
      icon={icon}
      color={color}
      slot={slot}
    />
  );
};

export default React.memo(HeroOutlineIcon);
