import React from 'react';
import {Page} from '../../core/components';
import logHistory from '../../core/utils/logHistory';

const PublicHomePage: React.FC = () => {
  // logHistory('PublicHomePage');

  return (
    <Page meta={{title: 'Home'}}>
      <div className="tw-page">Public home page</div>
    </Page>
  );
};

export default PublicHomePage;
