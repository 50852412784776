export default function filterText(str: string): string {
  // Exclude all ees and tees in \text{}
  var matches = str.match(/(?!t|x|e)([a-z]){1,1}/gi);
  var uppercases: string[] = ['T', 'E', 'X'];
  var covered: string[] = [];
  var i: number;
  var j: number;

  // Search and replace tees
  if (str.match(/(?!\\|x)(t)/g)) {
    str = str.replace(/(?!\\|x)(t)/g, '\\text{t}');
  }

  // Search and replace ees
  if (str.match(/(e)(?!x)/g)) {
    str = str.replace(/(e)(?!x)/g, '\\text{e}');
  }

  // Search and replace pipes
  if (str.match(/\|/g)) {
    str = str.replace(/\|/g, '\\text{|}');
  }

  // Render all uppercase Ts, Es and Xs
  for (i = 0; i < uppercases.length; i++) {
    var match = new RegExp(uppercases[i], 'g');

    if (str.match(match)) {
      str = str.replace(match, '\\text{' + uppercases[i] + '}');
    }
  }

  if (matches) {
    for (i = 0; i < matches.length; i++) {
      var there = false;

      // Check if the match has already been covered before
      for (j = 0; j < covered.length; j++) {
        if (matches[i] == covered[j]) {
          there = true;
          break;
        }
      }

      // If the match is not in the array
      if (!there) {
        var elem = matches[i];
        var reg = new RegExp(elem, 'g');

        covered.push(elem);
        str = str.replace(reg, '\\text{' + elem + '}');
      }
    }
  }

  return str;
}
