import spliceString from './spliceString';

export default function filterChemSuperscripts(str: string): string {
  const matches = str.match(/[a-z]+[_0-9]*\^[0-9]*[-+]{1,1}/gi);

  if (matches) {
    matches.forEach((match) => {
      const caretIndex = match.indexOf('^');
      const splicedString = spliceString(match, caretIndex + 1, '(') + ')';

      str = str.replace(match, splicedString);
    });
  }

  return str;
}
