import {IonButton} from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import {Page, Icon} from '../../../core/components';
import {Code, Note} from '../../components';

const ButtonPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Button (🚧)</h1>

        <h2>Colours</h2>

        <div className="tw-not-prose">
          <table className="sv-table">
            <thead>
              <tr>
                <th>Colour</th>
                <th>State</th>
                <th>High</th>
                <th>Medium</th>
                <th>Low</th>
                <th>Lowest</th>
              </tr>
            </thead>
            <tbody>
              {[
                'primary',
                'secondary',
                'tertiary',
                'success',
                'warning',
                'danger'
              ].map((color) => (
                <React.Fragment key={color}>
                  <tr>
                    <th>{_.capitalize(color)}</th>
                    <td>Enabled</td>
                    <td>
                      <IonButton color={color} fill="solid">
                        {_.capitalize(color)}
                      </IonButton>
                    </td>
                    <td>TODO</td>
                    <td>
                      <IonButton color={color} fill="outline">
                        {_.capitalize(color)}
                      </IonButton>
                    </td>
                    <td>
                      <IonButton color={color} fill="clear">
                        {_.capitalize(color)}
                      </IonButton>
                    </td>
                  </tr>
                  <tr>
                    <th>{_.capitalize(color)}</th>
                    <td>Disabled</td>
                    <td>
                      <IonButton color={color} fill="solid" disabled>
                        {_.capitalize(color)}
                      </IonButton>
                    </td>
                    <td>TODO</td>
                    <td>
                      <IonButton color={color} fill="outline" disabled>
                        {_.capitalize(color)}
                      </IonButton>
                    </td>
                    <td>
                      <IonButton color={color} fill="clear" disabled>
                        {_.capitalize(color)}
                      </IonButton>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <Note type="info">
            <a
              href="https://m3.material.io/components/all-buttons"
              rel="noopener noreferrer"
              target="_blank"
            >
              Material Design 3 - All buttons
            </a>
          </Note>
        </div>

        <h2>Shapes</h2>

        <div className="tw-not-prose">
          <p>TODO</p>
        </div>

        <h2>Sizes</h2>

        <div className="tw-not-prose">
          <div className="tw-flex tw-items-center tw-space-x-4">
            <IonButton size="small">Small</IonButton>
            <IonButton size="default">Default</IonButton>
            <IonButton size="large">Large</IonButton>
          </div>
          <Code
            code={`
<IonButton size="small">Small</IonButton>
<IonButton size="default">Default</IonButton>
<IonButton size="large">Large</IonButton>
        `}
            language="jsx"
          />
        </div>

        <h2>Icons</h2>

        <div className="tw-not-prose">
          <div className="tw-flex tw-items-center tw-space-x-4">
            <IonButton>
              <Icon slot="icon-only" name="star" />
              <span className="tw-sr-only">Icon description</span>
            </IonButton>
            <IonButton>
              <Icon slot="start" name="cart" />
              Pay now
            </IonButton>
            <IonButton>
              <Icon slot="end" name="arrow-forward" />
              Choose plan
            </IonButton>
          </div>
          <Code
            code={`
<IonButton>
  <Icon slot="icon-only" name="star" />
  <span className="tw-sr-only">Icon description</span>
</IonButton>
<IonButton>
  <Icon slot="start" name="cart" />
  Pay now
</IonButton>
<IonButton>
  <Icon slot="end" name="arrow-forward" />
  Choose plan
</IonButton>
        `}
            language="jsx"
          />
        </div>

        <h2>Practice</h2>

        <div className="tw-not-prose">
          <p>TODO</p>
        </div>

        <h2>Social</h2>

        <div className="tw-not-prose">
          <p>TODO</p>
        </div>
      </article>
    </Page>
  );
};

export default ButtonPage;
