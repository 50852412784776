import {CURRICULUM} from '../curriculum/curriculum';

export type Region = {
  id: REGION;
  full_name: string;
  default_curriculum: CURRICULUM;
};

export const enum REGION {
  ZA = 'ZA',
  RW = 'RW',
  NG = 'NG',
  INTL = 'INTL'
}
