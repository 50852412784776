import {useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import type {RouteProps} from 'react-router-dom';
import {PATH_AUTH_LOGIN} from '../../auth/auth.paths';
import {selectSession} from '../../auth/auth.slice';
import {ROLE} from '../models/user/user';
import logHistory from '../utils/logHistory';
import ForbiddenPage from './ForbiddenPage';

type PrivateRouteProps = RouteProps & {
  requiresLogin?: boolean;
  requiresLearner?: boolean;
  requiresTeacher?: boolean;
};

// function PrivateRoute({children, ...props}: PrivateRouteProps): any {
//   logHistory('PrivateRoute');

//   const {requiresLogin, requiresLearner, requiresTeacher} = props;
//   const {is_authenticated, user} = useSelector(selectSession);

//   if (requiresLogin && !is_authenticated) {
//     return <Redirect to={PATH_LOGIN} />;
//   }

//   if (requiresLearner) {
//     if (!is_authenticated || !user) {
//       return <Redirect to={PATH_LOGIN} />;
//     } else if (user.role_id !== ROLE.STUDENT) {
//       return <ForbiddenPage />;
//     }
//   }

//   if (requiresTeacher) {
//     if (!is_authenticated || !user) {
//       return <Redirect to={PATH_LOGIN} />;
//     } else if (user.role_id !== ROLE.TEACHER) {
//       return <ForbiddenPage />;
//     }
//   }

//   return children;
// }

function PrivateRoute({children, ...props}: PrivateRouteProps): any {
  return children;
}

export default PrivateRoute;
