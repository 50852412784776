import React from 'react';
import {Page} from '../components';
import logHistory from '../utils/logHistory';

const NotFoundPage: React.FC = () => {
  // logHistory('NotFoundPage');

  return <Page meta={{title: '404'}} />;
};

export default NotFoundPage;
