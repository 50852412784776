import React from 'react';
import './HeroIcon.css';

type Props = {
  name: string;
};

const HeroIcon: React.FC<Props> = (props) => {
  const {name: iconName} = props;

  /* eslint @typescript-eslint/no-var-requires: 0 */
  return React.createElement(require('./icons/' + iconName).default);
};

export default React.memo(HeroIcon);
