import {IonItem, IonItemGroup, IonLabel} from '@ionic/react';
import React from 'react';
import {Icon, Page} from '../../core/components';
import {
  PATH_STYLE_GUIDE_TYPOGRAPHY,
  PATH_STYLE_GUIDE_COLOURS,
  PATH_STYLE_GUIDE_ICONS,
  PATH_STYLE_GUIDE_GRIDS,
  PATH_STYLE_GUIDE_QUESTION,
  PATH_STYLE_GUIDE_RADIO,
  PATH_STYLE_GUIDE_CHECKBOX,
  PATH_STYLE_GUIDE_TEXT_INPUT,
  PATH_STYLE_GUIDE_PASSWORD,
  PATH_STYLE_GUIDE_SELECT,
  PATH_STYLE_GUIDE_DATE_INPUT,
  PATH_STYLE_GUIDE_TELEPHONE,
  PATH_STYLE_GUIDE_EMOJI,
  PATH_STYLE_GUIDE_PROGRESS_DONUT,
  PATH_STYLE_GUIDE_MASTERY_METER,
  PATH_STYLE_GUIDE_MASTERY_PROGRESS_DONUT,
  PATH_STYLE_GUIDE_PROGRESS_BAR,
  PATH_STYLE_GUIDE_LIST,
  PATH_STYLE_GUIDE_BUTTON,
  PATH_STYLE_GUIDE_TABLE,
  PATH_STYLE_GUIDE_LANGUAGE_PICKER
} from '../style-guide.paths';

type Item = [string, string | undefined][];
type Group = [string | undefined, Item][];
type Groups = [string, Group][];

const groups: Groups = [
  [
    'Foundations',
    [
      [
        undefined,
        [
          ['Colours', PATH_STYLE_GUIDE_COLOURS],
          ['Emoji', PATH_STYLE_GUIDE_EMOJI],
          ['Icons', PATH_STYLE_GUIDE_ICONS],
          ['Responsive Design and Grids', PATH_STYLE_GUIDE_GRIDS],
          ['Typography', PATH_STYLE_GUIDE_TYPOGRAPHY]
        ]
      ]
    ]
  ],
  [
    'Components',
    [
      [
        'Data',
        [
          ['Activity Chart v2', undefined],
          ['Avatar', 'https://ionicframework.com/docs/api/avatar'],
          ['Badge', 'https://ionicframework.com/docs/api/badge'],
          ['Goal Progress Donut', undefined],
          ['List', PATH_STYLE_GUIDE_LIST],
          ['Mastery Meter', PATH_STYLE_GUIDE_MASTERY_METER],
          ['Mastery Progress Donut', PATH_STYLE_GUIDE_MASTERY_PROGRESS_DONUT],
          ['Progress Bar', PATH_STYLE_GUIDE_PROGRESS_BAR],
          ['Progress Donut', PATH_STYLE_GUIDE_PROGRESS_DONUT],
          ['Table', PATH_STYLE_GUIDE_TABLE],
          ['Weekly Goal Widget', undefined]
        ]
      ],
      [
        'Feedback',
        [
          ['Alert', undefined],
          ['Empty State', undefined],
          ['Inline Message', undefined],
          ['Modal', 'https://ionicframework.com/docs/api/modal'],
          ['Toast', 'https://ionicframework.com/docs/api/toast'],
          ['Tooltip', undefined],
          ['Warning State (deprecated)', undefined]
        ]
      ],
      [
        'Forms',
        [
          ['Button', PATH_STYLE_GUIDE_BUTTON],
          ['Checkbox Button', undefined],
          ['Checkbox', PATH_STYLE_GUIDE_CHECKBOX],
          ['Clipboard', undefined],
          ['Date Input', PATH_STYLE_GUIDE_DATE_INPUT],
          ['Date and Time Picker', undefined],
          ['Password', PATH_STYLE_GUIDE_PASSWORD],
          ['Radio', PATH_STYLE_GUIDE_RADIO],
          ['Radio Button', undefined],
          ['Select', PATH_STYLE_GUIDE_SELECT],
          ['Table', undefined],
          ['Telephone', PATH_STYLE_GUIDE_TELEPHONE],
          ['Text Input', PATH_STYLE_GUIDE_TEXT_INPUT]
        ]
      ],
      [
        'Loading Indicators',
        [
          ['Skeleton', undefined],
          ['Spinner', 'https://ionicframework.com/docs/api/spinner']
        ]
      ],
      [
        'Miscellaneous',
        [
          ['Accordion', 'https://ionicframework.com/docs/api/accordion'],
          ['Card', undefined],
          ['Page Header', undefined]
        ]
      ],
      [
        'Navigation',
        [
          ['Breadcrumbs', 'https://ionicframework.com/docs/api/breadcrumbs'],
          ['Pagination', undefined],
          ['Stepper', undefined],
          ['Tabs', undefined],
          ['Toolbar', undefined],
          ['Table of Contents', undefined]
        ]
      ],
      [
        'Practice',
        [
          ['Grade Picker', undefined],
          ['Language Picker', undefined],
          ['Question', PATH_STYLE_GUIDE_QUESTION],
          ['Marking', undefined],
          ['Mastery Message', undefined]
        ]
      ]
    ]
  ]
];

const IndexPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        {groups.map((group, index) => {
          return (
            <div key={'group-' + index}>
              <h2>{group[0]}</h2>
              {group[1].map((subgroup, index) => (
                <IonItemGroup
                  key={'subgroup-' + index}
                  className="ion-margin-vertical"
                >
                  {subgroup[0] && <h3>{subgroup[0]}</h3>}
                  {subgroup[1].map(([label, href], index) => {
                    const disabled = !href;
                    const isExternal = href && /^https?:\/\//.test(href);
                    const rel = isExternal ? 'noopener noreferrer' : undefined;
                    const target = isExternal ? '_blank' : '_self';

                    return (
                      <IonItem
                        key={index}
                        href={href}
                        rel={rel}
                        target={target}
                        disabled={disabled}
                      >
                        <IonLabel>{label}</IonLabel>
                        {isExternal && (
                          <Icon name="open" size="small" slot="end" />
                        )}
                      </IonItem>
                    );
                  })}
                </IonItemGroup>
              ))}
            </div>
          );
        })}
      </article>
    </Page>
  );
};

export default IndexPage;
