import {demoApi} from '../core/api';
import {REGION} from '../core/models/region/region';
import type {SiyavulaId} from '../core/types';
import type {PreferencesState, TokenState, UserState} from './auth.slice';

export type LoginRequest = {
  region: REGION;
  identifier: string;
  password: string;
};

type LoginResponse = PreferencesState & TokenState & UserState;

type LogoutRequest = {
  siyavula_id: SiyavulaId;
};

type LogoutResponse = {};

type GetUserRequest = {
  siyavula_id: SiyavulaId;
};

const api = demoApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({
        url: 'log-in',
        method: 'POST',
        body: body
      }),
      transformResponse: (value: {data: any}): LoginResponse => value.data
    }),
    logout: build.mutation<LogoutResponse, LogoutRequest>({
      query: ({siyavula_id}) => ({
        url: 'log-out',
        method: 'POST',
        body: {siyavula_id}
      })
    }),
    getUser: build.query<UserState, GetUserRequest>({
      query: ({siyavula_id}) => ({
        url: `user/${siyavula_id}`,
        method: 'GET'
      })
    })
  })
});

export const {useLoginMutation, useLogoutMutation, useGetUserQuery} = api;
