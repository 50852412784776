import React from 'react';
import type {SUBJECT_NAME} from '../../models/subject/subject';
import './BulletIcon.css';

type Props = {
  subject_name: SUBJECT_NAME;
};

const BulletIcon: React.FC<Props> = (props) => {
  const {subject_name: iconName} = props;

  /* eslint @typescript-eslint/no-var-requires: 0 */
  return React.createElement(require('./icons/' + iconName).default);
};

export default React.memo(BulletIcon);
