export default function filterMultipleExponents(str: string): string {
  const matches = str.match(/[a-z,0-9]\^[A-Z,a-z,0-9]+\^[A-Z,a-z,0-9]+/gi);

  if (matches) {
    var stringToList: string[] = str.split('^');
    var firstExponent: string = stringToList[1];
    var secondExponent: string = str.substr(firstExponent.length + 3);
    var stringTail: string = '';
    var addBrackets: boolean = false;
    var index: number;

    if (/[a-z]/i.test(secondExponent[0])) {
      addBrackets = true;
      stringTail = secondExponent.substr(1);
      secondExponent = secondExponent[0];
    }

    if (/[0-9]+/gi.test(secondExponent)) {
      addBrackets = true;
      if (/\s/g.test(secondExponent)) {
        index = secondExponent.search(/\s/i);
        stringTail = secondExponent.substr(index);
        secondExponent = secondExponent.substr(0, index);
        if (secondExponent.search(/[a-z]/i) !== -1) {
          index = secondExponent.search(/[a-z]/i);
          stringTail = secondExponent.substr(index) + stringTail;
          secondExponent = secondExponent.substr(0, index);
        }
      } else if (secondExponent.search(/[a-z]/i) !== -1) {
        index = secondExponent.search(/[a-z]/i);
        stringTail = secondExponent.substr(index);
        secondExponent = secondExponent.substr(0, index);
      }
    }

    if (/[0-9]+/gi.test(firstExponent)) {
      if (/[a-z]/i.test(firstExponent)) {
        addBrackets = false;
      } else if (/\s/g.test(firstExponent)) {
        addBrackets = false;
      }
    }

    if (addBrackets) {
      str =
        stringToList[0] +
        '^(' +
        firstExponent +
        '^' +
        secondExponent +
        ')' +
        stringTail;
    }
  }

  return str;
}
