import {IonText} from '@ionic/react';
import React from 'react';
import Icon from '../Icon/Icon';

type Props = Pick<
  React.HTMLAttributes<HTMLIonTextElement>,
  'className' | 'color'
>;

const Message: React.FC<Props> = (props) => {
  const {children, className, color} = props;

  return (
    <div className={className}>
      {color === 'danger' && (
        <Icon
          name="danger-circled"
          color={color}
          size="small"
          className="tw-mr-2"
        />
      )}
      <IonText color={color}>{children}</IonText>
    </div>
  );
};

export default Message;
