/* eslint @typescript-eslint/consistent-type-imports: 0 */
/* eslint import/newline-after-import: 0 */
import {SVGProps, memo} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="sv-league-icon"
    viewBox="0 0 448 512"
    {...props}
  >
    <title>{'maths gold'}</title>
    <path
      fill="#fb990a"
      d="M224 40.437 80 124.218v167.565l144 83.78 144-83.78V124.218L224 40.437zM348 280.28l-124 72.145-124-72.145V135.72l124-72.146 124 72.146v144.56z"
    />
    <path
      fill="#fb990a"
      d="M108 140.322V275.68l116 67.489 116-67.489V140.322L224 72.831z"
    />
    <path
      fill="#fff"
      d="M100 135.721v144.56l124 72.145 124-72.145v-144.56L224 63.575l-124 72.146zM340 275.68l-116 67.489-116-67.489V140.322l116-67.491 116 67.491V275.68z"
    />
    <path
      fill="#FFF"
      d="m255.031 186.187-.375-.063-24.375 67.375h-12.25l-24.25-67.125-.375.063 1.75 39.938v14.313l9.75 1.875V253.5h-37.688v-10.938l9.688-1.875v-65.313l-9.688-1.875v-11h33.501l23.188 67.063h.375l23.375-67.063h33.625v11l-9.75 1.875v65.313l9.75 1.875V253.5h-37.688v-10.938l9.688-1.875v-14.313l1.749-40.187z"
    />
    <g fill="#b48f03">
      <path d="M171.559 464.191c-2.109 2.526-5.092 4.486-8.945 5.879-3.854 1.393-8.125 2.09-12.813 2.09-4.922 0-9.238-1.074-12.949-3.223s-6.576-5.267-8.594-9.355c-2.019-4.088-3.054-8.893-3.105-14.414v-3.867c0-5.677.957-10.592 2.871-14.746 1.914-4.153 4.674-7.33 8.281-9.531 3.606-2.2 7.832-3.301 12.676-3.301 6.744 0 12.018 1.608 15.82 4.824 3.802 3.217 6.055 7.897 6.758 14.043h-11.406c-.521-3.255-1.674-5.638-3.457-7.148-1.784-1.51-4.238-2.266-7.363-2.266-3.984 0-7.019 1.498-9.102 4.492-2.084 2.995-3.139 7.448-3.164 13.359v3.633c0 5.964 1.133 10.469 3.398 13.516s5.586 4.57 9.961 4.57c4.4 0 7.539-.938 9.414-2.813v-9.805h-10.664v-8.633h22.383v22.696zM227.77 444.23c0 5.6-.99 10.508-2.969 14.727-1.979 4.219-4.812 7.475-8.496 9.766-3.686 2.292-7.91 3.438-12.676 3.438-4.714 0-8.92-1.133-12.617-3.398-3.698-2.266-6.563-5.501-8.594-9.707-2.031-4.205-3.061-9.043-3.086-14.512v-2.813c0-5.599 1.009-10.527 3.027-14.785 2.018-4.258 4.869-7.525 8.555-9.805 3.685-2.278 7.896-3.418 12.637-3.418 4.739 0 8.951 1.14 12.637 3.418 3.685 2.279 6.536 5.547 8.555 9.805 2.018 4.258 3.027 9.174 3.027 14.746v2.538zm-11.875-2.578c0-5.963-1.068-10.494-3.203-13.594-2.136-3.099-5.183-4.648-9.141-4.648-3.933 0-6.967 1.53-9.102 4.59-2.136 3.061-3.217 7.546-3.242 13.457v2.773c0 5.808 1.067 10.313 3.203 13.516 2.135 3.203 5.208 4.805 9.219 4.805 3.932 0 6.953-1.543 9.063-4.629s3.177-7.584 3.203-13.496v-2.774zM248.004 461.965h24.883v9.414h-36.602v-56.875h11.719v47.461zM279.605 471.379v-56.875h17.5c5 0 9.473 1.127 13.418 3.379 3.945 2.253 7.024 5.456 9.238 9.609 2.213 4.154 3.32 8.874 3.32 14.16v2.617c0 5.287-1.088 9.987-3.262 14.102-2.175 4.115-5.241 7.305-9.199 9.57-3.959 2.266-8.425 3.412-13.398 3.438h-17.617zm11.719-47.383v37.969h5.664c4.583 0 8.086-1.497 10.508-4.492 2.422-2.994 3.658-7.278 3.711-12.852v-3.008c0-5.781-1.198-10.162-3.594-13.145-2.396-2.981-5.898-4.473-10.508-4.473h-5.781z" />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
