import {IonLabel} from '@ionic/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {countriesSelectors} from '../../models/country/country.slice';
import FlagIcon from '../FlagIcon/FlagIcon';
import Select from '../Select/Select';
import type {SelectItem, SelectProps} from '../Select/types';

const Option: React.FC<{item: SelectItem}> = ({item}) => (
  <>
    <IonLabel className="ion-text-wrap">
      {item.full_name} +{item.dialling_code}
    </IonLabel>
    <FlagIcon name={item.icon_name} slot="end" />
  </>
);

type DiallingCodeSelectProps = Pick<
  SelectProps,
  'disabled' | 'helper' | 'label' | 'name' | 'showError'
>;

const DiallingCodeSelect: React.FC<DiallingCodeSelectProps> = (props) => {
  const countries = useSelector(countriesSelectors.selectAll);

  return (
    <Select
      {...props}
      items={countries}
      itemToValue={({dialling_code}) => dialling_code}
      itemToLabel={({dialling_code}) => '+' + dialling_code}
      filter={(items, inputValue) =>
        items?.filter(
          ({full_name, dialling_code}) =>
            full_name
              .toLocaleLowerCase()
              .includes(inputValue.toLocaleLowerCase()) ||
            ('+' + dialling_code)
              .toLocaleLowerCase()
              .includes(inputValue.toLocaleLowerCase())
        )
      }
      searchable
      searchPlaceholder="Search by country or dialling code"
      optionComponent={Option}
    />
  );
};

export default DiallingCodeSelect;
