import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../../store';
import type {Region} from './region';
import data from '../../data/regions.json';

const adapter = createEntityAdapter<Region>();

const slice = createSlice({
  name: 'regions',
  initialState: {
    ids: (data as Region[]).map((d) => d.id),
    entities: Object.fromEntries((data as Region[]).map((d) => [d.id, d]))
  },
  reducers: {
    regionsSetAll: adapter.setAll
  }
});

export const {regionsSetAll} = slice.actions;

export default slice.reducer;

export const regionsSelectors = adapter.getSelectors(
  (state: RootState) => state.regions
);
