import React from 'react';
import {Page, Emoji} from '../../../core/components';
import emojify from '../../../core/components/Emoji/utils/emojify';
import {Code} from '../../components';

const EmojiPage: React.FC = () => {
  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Emoji</h1>

        <h2>Component</h2>

        <div className="tw-not-prose">
          <Emoji shortcode=":sunny:" />
          <Emoji shortcode=":hand::skin-tone-6:" />
          <Code
            code={`
<Emoji shortcode=":sunny:" />
<Emoji shortcode=":hand::skin-tone-6:" />
          `}
            language="jsx"
          />
        </div>

        <h2>Replace shortcodes with elements</h2>

        <div className="tw-not-prose">
          <p>{emojify('Hello world! :zebra_face::palm_tree::sunny:')}</p>
          <Code
            code={`emojify('Hello world! :zebra_face::palm_tree::sunny:')`}
            language="jsx"
          />
          <p>
            {emojify(
              ':hand: :hand::skin-tone-2: :hand::skin-tone-3: :hand::skin-tone-4: :hand::skin-tone-5: :hand::skin-tone-6:'
            )}
          </p>
          <Code
            code={`emojify(':hand: :hand::skin-tone-2: :hand::skin-tone-3: :hand::skin-tone-4: :hand::skin-tone-5: :hand::skin-tone-6:')`}
            language="jsx"
          />
        </div>

        <h2>Invalid shortcodes</h2>

        <div className="tw-not-prose">
          <p>
            Shortcodes that cannot be mapped to emoji are preserved by default:
            <br />
            {emojify('One of these is extinct: :bird::butterfly::pterodactyl:')}
          </p>
          <Code
            code={`emojify('One of these is extinct: :bird::butterfly::pterodactyl:')`}
            language="jsx"
          />
          <p>
            To remove them, use the <code>strip</code> option:
            <br />
            {emojify(
              'One of these is extinct: :bird::butterfly::pterodactyl:',
              {
                strip: true
              }
            )}
          </p>
          <Code
            code={`emojify('One of these is extinct: :bird::butterfly::pterodactyl:', {strip: true})`}
            language="jsx"
          />
        </div>
      </article>
    </Page>
  );
};

export default EmojiPage;
