import React from 'react';
import {useSelector} from 'react-redux';
import {
  BulletIcon,
  FlagIcon,
  HeroIcon,
  LeagueIcon,
  HeroOutlineIcon,
  Icon,
  Page
} from '../../../core/components';
import {names as flagIconNames} from '../../../core/components/FlagIcon/icons';
import {names as heroIconNames} from '../../../core/components/HeroIcon/icons';
import {names as heroOutlineIconNames} from '../../../core/components/HeroOutlineIcon/icons';
import {names as ioniconNames} from '../../../core/components/Icon/icons.ionicons';
import {names as uiIconNames} from '../../../core/components/Icon/icons.sv-ui-icon';
import {LEAGUE} from '../../../core/models/public-leaderboard/public-leaderboard';
import type {Subject} from '../../../core/models/subject/subject';
import {SUBJECT_NAME} from '../../../core/models/subject/subject';
import {subjectsSelectors} from '../../../core/models/subject/subject.slice';
import {Code} from '../../components';

const Swatch: React.FC<{title?: string}> = (props) => {
  const {title, children} = props;

  return (
    <div className="tw-rounded tw-bg-grey-50 tw-p-3 tw-text-center">
      <div>{children}</div>
      {title && <div className="tw-mt-2 tw-text-sm">{title}</div>}
    </div>
  );
};

const IconsPage: React.FC = () => {
  const subjects = useSelector(subjectsSelectors.selectAll);
  const leagues = [LEAGUE.BRONZE, LEAGUE.SILVER, LEAGUE.GOLD, LEAGUE.PLATINUM];
  const subjectLeagues = subjects
    .map((subject: Subject) => leagues.map((league) => ({subject, league})))
    .flat();

  return (
    <Page meta={{title: 'Style Guide'}}>
      <article className="tw-page tw-prose lg:tw-prose-lg">
        <h1>Icons</h1>

        <h2>UI icons</h2>

        <h3>Colour</h3>

        <div className="tw-not-prose">
          <span className="tw-text-red-500">
            <Icon name="star" size="large" />
          </span>
          <Icon name="star" size="large" className="tw-text-green-500" />
          <Icon name="star" size="large" color="primary" />
          <Code
            code={`
<span className="tw-text-red-500">
  <Icon name="star" size="large" />
</span>
<Icon name="star" size="large" className="tw-text-green-500" />
<Icon name="star" size="large" color="primary" />
`}
            language="jsx"
          />
        </div>

        <h3>Set: ui</h3>

        <div className="tw-not-prose">
          <Icon name="heart" set="ui" />
          <Code code={`<Icon name="heart" set="ui" />`} language="jsx" />

          <div className="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 2xl:tw-grid-cols-5">
            {uiIconNames.map((name) => (
              <Swatch key={name} title={name}>
                <Icon name={name} set="ui" size="large" />
              </Swatch>
            ))}
          </div>
        </div>

        <h3>Set: ionicons</h3>

        <div className="tw-not-prose">
          <Icon name="heart" set="ionicons" />
          <Code code={`<Icon name="heart" set="ionicons" />`} language="jsx" />

          <div className="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 2xl:tw-grid-cols-5">
            {ioniconNames.map((name) => (
              <Swatch key={name} title={name}>
                <Icon name={name} set="ionicons" size="large" />
              </Swatch>
            ))}
          </div>
        </div>

        <h2>Bullet icons</h2>

        <div className="tw-not-prose">
          <BulletIcon subject_name={SUBJECT_NAME.MATHS} />
          <Code
            code={`<BulletIcon subject_name={SUBJECT_NAME.MATHS} />`}
            language="jsx"
          />

          <div className="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 2xl:tw-grid-cols-5">
            {subjects.map((d) => (
              <Swatch key={d.name} title={d.name}>
                <BulletIcon subject_name={d.name} />
              </Swatch>
            ))}
          </div>
        </div>

        <h2>League icons</h2>

        <div className="tw-not-prose">
          <LeagueIcon
            subject_name={SUBJECT_NAME.MATHS}
            league={LEAGUE.BRONZE}
          />
          <Code
            code={`<LeagueIcon subject_name={SUBJECT_NAME.MATHS} league={LEAGUE.BRONZE}/>`}
            language="jsx"
          />

          <div className="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 2xl:tw-grid-cols-5">
            {subjectLeagues.map((d) => (
              <Swatch
                key={`${d.subject.name}-${d.league}`}
                title={`${d.subject.name}-${d.league}`.toLowerCase()}
              >
                <LeagueIcon subject_name={d.subject.name} league={d.league} />
              </Swatch>
            ))}
          </div>
        </div>

        <h2>Hero icons</h2>

        <div className="tw-not-prose">
          <HeroIcon name="mastery" />
          <Code code={`<HeroIcon name="mastery" />`} language="jsx" />

          <div className="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 2xl:tw-grid-cols-5">
            {heroIconNames.map((name) => (
              <Swatch key={name} title={name}>
                <HeroIcon name={name} />
              </Swatch>
            ))}
          </div>
        </div>

        <h2>Hero outline icons</h2>

        <h3>Colour</h3>

        <div className="tw-not-prose">
          <span className="tw-text-red-500">
            <HeroOutlineIcon name="summit" />
          </span>
          <HeroOutlineIcon name="summit" className="tw-text-green-500" />
          <HeroOutlineIcon name="summit" color="primary" />
          <Code
            code={`
<span className="tw-text-red-500">
  <HeroOutlineIcon name="summit" />
</span>
<HeroOutlineIcon name="summit" className="tw-text-green-500" />
<HeroOutlineIcon name="summit" color="primary" />
`}
            language="jsx"
          />
        </div>

        <h3>Set</h3>

        <div className="tw-not-prose">
          <div className="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 2xl:tw-grid-cols-5">
            {heroOutlineIconNames.map((name) => (
              <Swatch key={name} title={name}>
                <HeroOutlineIcon name={name} />
              </Swatch>
            ))}
          </div>
        </div>

        <h2>Flag icons</h2>

        <div className="tw-not-prose">
          <FlagIcon name="za" />
          <Code code={`<FlagIcon name="za" />`} language="jsx" />

          <div className="tw-grid tw-grid-cols-4 tw-gap-2 md:tw-grid-cols-6 lg:tw-grid-cols-8 2xl:tw-grid-cols-10">
            {flagIconNames.map((name) => (
              <Swatch key={name} title={name}>
                <FlagIcon name={name} />
              </Swatch>
            ))}
          </div>
        </div>
      </article>
    </Page>
  );
};

export default IconsPage;
